import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const defaultAuthState = {
  vehicleDetails: [],
  vehicleDetailsAlt: [],
  userDetails: [],
  liveMetrics: [],
  errorDetails: [],
  vin: "",
  token: "",
  deviceID: "",
  dongleID: "",
  plateNumber: "",
  userLoggedIn: false,
};

let vehicleDetails = createSlice({
  name: "sign up",
  initialState: defaultAuthState,
  reducers: {
    details(state, action) {
      let payload = action.payload;
      state.vehicleDetails.push({
        vehicleName: payload.vehicle_make,
        vehicleYear: payload.vehicle_year,
        vehiclePicture: payload.images,
        vehicleLogo: payload.images,
        vehicleModel: payload.vehicle_model,
      });
    },
    detailsALt(state, action) {
      let payload = action.payload;
      state.vehicleDetailsAlt.push({
        vehicleModel: payload.vehicle_model,
        vehicleName2: payload.make,
        vehicleYear2: payload.year,
        vehicleModel2: payload.model,
        expiryDate: payload.expiryDate,
      });
    },
    metrics(state, action) {
      let payload = action.payload;
      state.liveMetrics.push({
        coolant_temperature: payload.vehicle_live_data.coolant_temperature,
        current_fuel_consumption_rate:
          payload.vehicle_live_data.current_fuel_consumption_rate,
        engine_load: payload.vehicle_live_data.engine_load,
        engine_speed: payload.vehicle_live_data.engine_speed,
        intake_pressure: payload.vehicle_live_data.intake_pressure,
        remaining_fuel: payload.vehicle_live_data.remaining_fuel,
        speed: payload.vehicle_live_data.speed,
        milage: payload.vehicle_live_data.vehicle_mileage,
        pressure: payload.vehicle_live_data.pressure,
        voltage: payload.vehicle_live_data.voltage,
        latitude: payload.vehicle_location.latitude,
        longitude: payload.vehicle_location.longitude,
      });
    },
    user(state, action) {
      let payload = action.payload;
      state.userDetails.push({
        email: payload.basic?.[0].email,
        id: payload.basic?.[0].id,
        name: payload.basic?.[0].name,
        phone_no: payload.basic?.[0].phone_no,
        insurance: payload.insurance?.[0].expiry_date,
        road: payload.road,
        vehicle_license: payload.vehicle_license?.[0].expiry_date,
      });
    },
    error(state, action) {
      let payload = action.payload;
      state.errorDetails = payload.vehicle_errors;
    },
    vin(state, action) {
      let payload = action.payload;
      state.vin = payload;
    },
    deviceID(state, action) {
      let payload = action.payload;
      state.deviceID = payload.id;
    },
    plateNumber(state, action) {
      let payload = action.payload;
      state.plateNumber = payload;
    },
    dongleID(state, action) {
      let payload = action.payload;
      state.dongleID = payload;
    },
    loggedIn(state, action) {
      let payload = action.payload;
      state.token = payload.tokens;
      if (state.token !== "") {
        state.userLoggedIn = true;
      } else {
        state.userLoggedIn = false;
      }
    },
    loggedOut(state) {
      state.userLoggedIn = false;
    },
    resetLiveMetrics(state) {
      state.liveMetrics = [];
    },
    resetUserData(state) {
      state.userDetails = [];
    },
    resetVehicleDetailsAlt(state) {
      state.vehicleDetailsAlt = [];
    },
    resetVehicleData(state) {
      state.vehicleDetails = [];
    },
    resetToDefault(state) {
      state.vin = "";
      state.vehicleDetails = [];
      state.vehicleDetailsAlt = [];
      state.deviceID = "";
      state.token = "";
      state.plateNumber = "";
      state.userDetails = [];
      state.liveMetrics = [];
      state.errorDetails = [];
    },
  },
});
const persistConfig = {
  key: "vehicles",
  storage,
};

let persistedReducer = persistReducer(persistConfig, vehicleDetails.reducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
  },
  middleware: [thunk],
});

export const authActions = vehicleDetails.actions;
export default store;
