import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './HomePageCarousel.module.css'

function MaintenanceTip() {
    const responsive = {
        mobile: {
            breakpoint: { max: 540, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    return (
        <div className={styles.carousel}>
            <Carousel
                draggable={true}
                showDots={false}
                ssr
                responsive={responsive}
            >
                <div className={styles.wrapper}>
                    <div className={styles.text2}>
                        <h4>Maintenance Tip</h4>
                        <p>Did you know that car preventive maintenanc is a major money saver?</p>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text3}>
                        <h4>Maintenance Tip</h4>
                        <p>Did you know that car preventive maintenanc is a major money saver?</p>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text2}>
                        <h4>Maintenance Tip</h4>
                        <p>Did you know that car preventive maintenanc is a major money saver?</p>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text3}>
                        <h4>Maintenance Tip</h4>
                        <p>Did you know that car preventive maintenanc is a major money saver?</p>
                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default MaintenanceTip