import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { authActions } from "../store/redux-store/Index";
import styles from "./NavBar.module.css";

function NavBar(props) {
  let [status, setStatus] = useState("");
  let [data, setData] = useState([]);
  let deviceID = useSelector((state) => state.auth.deviceID);
  const menuToggleHandler = () => {
    props.setShowMenu(true);
  };
  let dispatch = useDispatch();
  useEffect(() => {
    let liveData = async () => {
      let url = `https://api.dev.motormata.com/api/v1/dongle/vehicle/live-data/${deviceID}`;
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data1 = await res.json();
      if (res.ok) {
        setData(data1?.vehicle_live_data?.online);
      } else {
        console.log(data1);
      }
    };
    liveData();
    if (data === true) {
      setStatus("#4FB128");
    } else if (data === false) {
      setStatus("#3C3E3F");
    }
  }, [deviceID, dispatch, data]);
  return (
    <section className={styles.navBar}>
      <div className={styles.navBar__body}>
        <div onClick={menuToggleHandler} className={styles.navBar__hamburger}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.navBar__title}>
          <p>{props.title}</p>
        </div>
        <div className={styles.navBar__notifications}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9996 20.3996C13.3251 20.3996 14.3996 19.3251 14.3996 17.9996C14.3996 16.6741 13.3251 15.5996 11.9996 15.5996C10.6741 15.5996 9.59961 16.6741 9.59961 17.9996C9.59961 19.3251 10.6741 20.3996 11.9996 20.3996Z"
              fill={status}
            />
            <path
              d="M1.19922 8.8802C2.55054 7.22026 4.21244 5.89082 6.07451 4.9802C7.93659 4.06958 9.95621 3.59863 11.9992 3.59863C14.0422 3.59863 16.0619 4.06958 17.9239 4.9802C19.786 5.89082 21.4479 7.22026 22.7992 8.8802L20.9992 10.5602C19.8731 9.14287 18.4882 8.00773 16.9365 7.2302C15.3847 6.45268 13.7017 6.05055 11.9992 6.05055C10.2967 6.05055 8.61369 6.45268 7.06197 7.2302C5.51024 8.00773 4.12532 9.14287 2.99922 10.5602L1.19922 8.8802ZM5.63922 12.7202C6.42773 11.7456 7.41075 10.9623 8.51963 10.425C9.6285 9.88771 10.8365 9.60933 12.0592 9.60933C13.2819 9.60933 14.4899 9.88771 15.5988 10.425C16.7077 10.9623 17.6907 11.7456 18.4792 12.7202L16.7992 14.4002C16.237 13.6574 15.5246 13.0579 14.7146 12.646C13.9046 12.234 13.0176 12.0202 12.1192 12.0202C11.2208 12.0202 10.3339 12.234 9.52385 12.646C8.71383 13.0579 8.00143 13.6574 7.43922 14.4002L5.63922 12.7202Z"
              fill={status}
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default NavBar;
