import React, { useEffect, useState } from "react";
import styles from "./LiveMetrics.module.css";
import { Gauge } from "@ant-design/plots";

function LiveMetrics(props) {
  let [fuelHeight, setFuelHeight] = useState(null);
  let [color, setColor] = useState(null);
  let [fig, setFig] = useState(null);
  let [fig2, setFig2] = useState(null);
  let [fig4, setFig4] = useState(null);
  let [fig5, setFig5] = useState(null);
  let [fig3, setFig3] = useState(null);
  let [text, setText] = useState("");
  let [bColor2, setbColor2] = useState(null);
  let [bColor, setbColor] = useState(null);

  let rpm = props.data?.engine_speed / 7000;
  let speed = props.data?.speed / 200;
  let vehicle_milage = parseInt(props.data?.vehicle_mileage) / 10000;
  let air_pressure = props.data?.pressure / 10000;

  useEffect(() => {
    let barFillHeight = `${props.data?.remaining_fuel}%`;
    setFuelHeight(barFillHeight);
    setbColor("#E2FBD7");
    if (fuelHeight <= `${20}%`) {
      setColor("#FD4D1E");
      setbColor2("#FFDFD7");
      setText("Very Low");
    } else if (fuelHeight <= `${50}%`) {
      setColor("#FFB200");
      setbColor2("#FFF5CC");
      setText("Average");
    }
    if (fuelHeight > `${50}%`) {
      setColor("#0AB34E");
      setbColor2("#E2FBD7");
      setText("Fine");
    }
    if (fuelHeight === `${100}%`) {
      setColor("#0AB34E");
      setbColor2("#E2FBD7");
      setText("Fine");
    }
    if (
      fuelHeight === `${0}%` &&
      props.data?.engine_speed > 0 &&
      props.data?.speed > 0
    ) {
      setColor("#0AB34E");
      setbColor2("#E2FBD7");
      setText("Feature not supported");
    }
  }, [fuelHeight, props.data]);

  useEffect(() => {
    const Speed = {
      percent: speed,

      range: {
        transition: "all 0.3s ease-out",
        color: "l(0) 0:#0AB34E 1:#FF3A29",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: 11,
          style: {
            fontSize: "8px",
            color: "#4B535E",
          },
          formatter: () => "",
        },
        content: {
          offsetY: -5,
          style: {
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#4B535E",
          },
          formatter: () => ``,
        },
      },
    };

    const pressure = {
      percent: air_pressure,

      range: {
        transition: "all 0.3s ease-out",
        color: "l(0) 0:#0AB34E 1:#FF3A29",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: 11,
          style: {
            fontSize: "8px",
            color: "#4B535E",
          },
          formatter: () => "",
        },
        content: {
          offsetY: -5,
          style: {
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#4B535E",
          },
          formatter: () => ``,
        },
      },
    };

    const fuel = {
      percent: vehicle_milage,

      range: {
        transition: "all 0.3s ease-out",
        color: "l(0) 0:#0AB34E 1:#FF3A29",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: 11,
          style: {
            fontSize: "8px",
            color: "#4B535E",
          },
          formatter: () => "",
        },
        content: {
          offsetY: -5,
          style: {
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#4B535E",
          },
          formatter: () => ``,
        },
      },
    };

    const milage = {
      percent: rpm,
      range: {
        transition: "all 0.3s ease-out",
        color: "l(0) 0:#0AB34E 1:#FF3A29",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: 11,
          style: {
            fontSize: "8px",
            color: "#4B535E",
          },
          formatter: () => "",
        },
        content: {
          offsetY: -5,
          style: {
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#4B535E",
          },
          formatter: () => ``,
        },
      },
    };

    const nothing = {
      percent: 0,
      range: {
        transition: "all 0.3s ease-out",
        color: "l(0) 0:#0AB34E 1:#FF3A29",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: 11,
          style: {
            fontSize: "8px",
            color: "#4B535E",
          },
          formatter: () => "",
        },
        content: {
          offsetY: -5,
          style: {
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#4B535E",
          },
          formatter: () => ``,
        },
      },
    };

    setFig3(milage);
    setFig2(fuel);
    setFig(Speed);
    setFig4(pressure);
    setFig5(nothing);
  }, [rpm, speed, vehicle_milage , air_pressure]);

  return (
    <div className={styles.Livemetrics}>
      <div className={styles.Livemetrics__inner}>
        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div>
              <div className={styles.info}>
                <svg
                  width="40"
                  height="55"
                  viewBox="0 0 41 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1"
                    y="0.5"
                    width="39"
                    height="54"
                    rx="7.5"
                    stroke={color}
                  />
                  <path
                    d="M1 26.5H40V47C40 51.1421 36.6421 54.5 32.5 54.5H8.5C4.35786 54.5 1 51.1421 1 47V26.5Z"
                    fill={color}
                    stroke={color}
                  />
                </svg>
              </div>
              <div className={styles.info}>
                <h4>{fuelHeight}</h4>
                <p>{text}</p>
                <h5>Fuel Guage</h5>
              </div>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor2 }}
          ></div>
        </div>

        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div className={styles.info}>
              {" "}
              <svg
                width="76"
                height="66"
                viewBox="0 0 76 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.3327 27.5H50.666V49.5H34.8327L28.4994 44H22.166V30.25M22.166 11V16.5H31.666V22H22.166L15.8327 27.5V35.75H9.49935V27.5H3.16602V49.5H9.49935V41.25H15.8327V49.5H25.3327L31.666 55H56.9994V44H63.3327V52.25H72.8327V24.75H63.3327V33H56.9994V22H37.9994V16.5H47.4994V11H22.166Z"
                  fill="#4FB128"
                />
              </svg>
            </div>

            <div className={styles.info}>
              <h4>{props.data?.engine_load}</h4>
              <h5>Engine Load</h5>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>
      </div>

      <div className={styles.Livemetrics__inner}>
        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div>
              <div className={styles.guage}>
                {" "}
                <Gauge {...fig} />
              </div>
              <div className={styles.info}>
                <h4>{props.data?.speed}Km/hr</h4>
                <h5>Speed</h5>
              </div>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>

        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div>
              <div className={styles.guage}>
                {" "}
                <Gauge {...fig4} />
              </div>
            </div>

            <div className={styles.info}>
              <h4>{props.data?.pressure}</h4>
              <h5>Air Pressure</h5>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>
      </div>

      <div className={styles.Livemetrics__inner}>
        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div>
              <div className={styles.guage}>
                {" "}
                <Gauge {...fig2} />
              </div>
              <div className={styles.info}>
                <h4>{props.data?.vehicle_mileage}</h4>
                <h5>Mileage</h5>
              </div>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>

        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div className={styles.guage}>
              {" "}
              <Gauge {...fig3} />
            </div>
            <div className={styles.info}>
              {" "}
              <h4>{props.data?.engine_speed}Km/hr</h4>
              <h5>RPM</h5>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>
      </div>

      <div className={styles.Livemetrics__inner}>
        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div>
              <div className={styles.guage}>
                {" "}
                <Gauge {...fig5} />
              </div>
              <div className={styles.info}>
                <h4>{props.data?.current_fuel_consumption_rate}</h4>
                <h5>Fuel Cons. Rate</h5>
              </div>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>

        <div className={styles.Livemetrics__inner__content}>
          <div className={styles.Livemetrics__inner__content__inner}>
            <div className={styles.guage}>
              {" "}
              <Gauge {...fig5} />
            </div>
            <div className={styles.info}>
              {" "}
              <h4>{props.data?.coolant_temperature}</h4>
              <h5>Coolant temperature</h5>
            </div>
          </div>
          <div
            className={styles.fuelGuage_measurment}
            style={{ backgroundColor: bColor }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default LiveMetrics;
