import React from "react";
import styles from "./Menu.module.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authActions } from "../store/redux-store/Index";

function Menu() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(authActions.loggedOut());
    navigate("/");
  };
  let aboutHandler = () => {
    toast.warn(`Coming soon🔜🚧🚜`, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <div className={styles.menu__modal}>
      <div className={styles.menu__modal__inner}>
        <div className={styles.menu__modal__list}>
          <div onClick={aboutHandler}>
            <div className={styles.svg}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" rx="4" fill="#FAFAFA" />
                <path
                  d="M2.66602 12H8.66602V4L5.33268 5.33333V8.66667L2.66602 10V4.66667L8.66602 2L13.3327 3.33333V12L8.66602 14L2.66602 12Z"
                  stroke="#4FB128"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className={styles.title}>
              <p>About Us</p>
            </div>
          </div>

          <div onClick={logoutHandler}>
            <div className={styles.svg}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="4" fill="#FAFAFA" />
                <path
                  d="M3 15H9C9.26514 14.9997 9.51934 14.8943 9.70681 14.7068C9.89429 14.5193 9.99974 14.2651 10 14V12.5H9V14H3V2H9V3.5H10V2C9.99974 1.73486 9.89429 1.48066 9.70681 1.29319C9.51934 1.10571 9.26514 1.00026 9 1H3C2.73486 1.00026 2.48066 1.10571 2.29319 1.29319C2.10571 1.48066 2.00026 1.73486 2 2V14C2.00026 14.2651 2.10571 14.5193 2.29319 14.7068C2.48066 14.8943 2.73486 14.9997 3 15Z"
                  fill="#4FB128"
                />
                <path
                  d="M10.293 10.293L12.086 8.5H5V7.5H12.086L10.293 5.707L11 5L14 8L11 11L10.293 10.293Z"
                  fill="#4FB128"
                />
              </svg>
            </div>
            <div className={styles.title}>
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
