import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import EmailInput from "../../components/EmailInput";
import Input from "../../components/Input";
import { ToastContainer, toast } from "react-toastify";
import { authActions } from "../../store/redux-store/Index";
import styles from "./DiagnosesForm.module.css";
import "react-toastify/dist/ReactToastify.css";

function DiagnosisForm(props) {
  let [name, setname] = useState("");
  let [time, setTime] = useState([]);
  let [email, setEmail] = useState("");
  let [loading, setLoading] = useState(false);
  let [validEmail, setValidEmail] = useState(false);
  let [phone, setphone] = useState("");
  let [validphone, setValidphone] = useState(false);
  let [formIsValid, setFormIsValid] = useState(false);

  let plate_no = useSelector((state) => state.auth.plateNumber);
  let validPhoneNumber = phone.slice(1);
  let details = useSelector((state) => state.auth);

  let dispatch = useDispatch();

  let emailInputHandler = (e) => {
    setEmail(e.target.value);
  };
  let nameInputHandler = (e) => {
    setname(e.target.value);
  };
  let phoneInputHandler = (e) => {
    setphone(e.target.value);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      let date = new Date();
      let month = date.getUTCMonth() + 1; //months from 1-12
      let day = date.getUTCDate();
      let year = date.getUTCFullYear();
      let h = date.toLocaleString("en-US", {
        hour: "numeric",
        hour12: false,
        timeZone: "Africa/Lagos",
      });
      let s = date.toLocaleString("en-US", {
        second: "2-digit",
        timeZone: "Africa/Lagos",
      });
      let m = date.toLocaleString("en-US", {
        minute: "2-digit",
        timeZone: "Africa/Lagos",
      });
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      setTime(`${year}-${month}-${day} ${h}:${m}:${s}`);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  let submitFormHandler = (e) => {
    e.preventDefault();
    let url2 = `https://bpms.motormata.com/api/v1/dongle/report/post`;
    setFormIsValid(false);
    setLoading(true);
    let postReport = async () => {
      let response = await fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          plate_no: plate_no,
          test_time: time,
          customer_id: details.userDetails[0].id,
          customer_contact: details.userDetails[0].email,
          year:
            details.vehicleDetails[0]?.vehicleYear ||
            details.vehicleDetailsAlt[0]?.vehicleYear2,
          make:
            details.vehicleDetails[0]?.vehicleName ||
            details.vehicleDetailsAlt[0]?.vehicleName2,
          model:
            details.vehicleDetails[0]?.vehicleModel ||
            details.vehicleDetailsAlt[0]?.vehicleModel2,
          vin: details.vin || "Not available",
          engine_size: "No data",
          odometer: details.liveMetrics[0].milage,
          vehicle_software_version: "No data",
          diagnostic: "No data",
          insurance: details.vehicleDetailsAlt[0].expiryDate,
          vehicle_license: details.userDetails[0].vehicle_license,
          road: details.userDetails[0].road,
          fine: "",
          battery: details.liveMetrics[0].pressure,
          mileage: "",
          rpm: details.liveMetrics[0].engine_speed,
          temperature: details.liveMetrics[0].coolant_temperature,
          engine_load: details.liveMetrics[0].engine_load,
          av_speed: details.liveMetrics[0].speed,
          fuel_consumption_rate:
            details.liveMetrics[0].current_fuel_consumption_rate,
          fuel_level: details.liveMetrics[0].remaining_fuel,
          error_codes: `${details.errorDetails}`,
          phone_no: details.userDetails[0].phone_no || `234${validPhoneNumber}`,
          email: "" || details.userDetails[0].email || email,
          longitude: details.liveMetrics[0].longitude,
          latitude: details.liveMetrics[0].latitude,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const reportData = await response.json();
      setFormIsValid(true);
      setLoading(false);
      if (response.ok) {
        console.log(reportData);
        toast.success(
          `Report successfully sent 👍🏾. You'll recieve a text message soon 🕺🏾💃🏽.`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setFormIsValid(true);
        setLoading(false);
      } else {
        toast.error(
          `Something went wrong 😢. Check details and try again later`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setFormIsValid(true);
        setLoading(false);
      }
    };
    if (props.personalData.basic === "No Record Found") {
      let userData = async () => {
        let url2 = `https://bpms.motormata.com/api/v1/noauth/cusinfo/${plate_no}`;
        let res = await fetch(url2, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        let data1 = await res.json();
        if (res.ok) {
          dispatch(authActions.resetUserData());
          dispatch(authActions.user(data1));
        } else {
          console.log(data1);
        }
      };

      let sigUpHandler = async () => {
        let url = `https://bpms.motormata.com/api/v1/dongle/registration`;
        setFormIsValid(false);
        setLoading(true);
        let res = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            name: name,
            phone_no: phone,
            plate_no: plate_no,
            email: "" || email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        let data = await res.json();
        setFormIsValid(true);
        setLoading(false);
        if (res.ok) {
          props.setDiagReportForm(false);
          toast.success(
            `Details successfully sent 👍🏾. You'll recieve a text message soon 🕺🏾💃🏽.`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setname("");
          setphone("");
          setEmail("");
        } else {
          console.log(data);
          toast.error(
            `Something went wrong 😢. Check details and try again later`,
            {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        }
      };
      sigUpHandler();
      userData();
      postReport();
      setFormIsValid(true);
      setLoading(false);
    } else if (props.personalData.basic !== "No Record Found") {
      postReport();
      setFormIsValid(true);
      setLoading(false);
    }
    setInterval(() => {
      setFormIsValid(true);
      setLoading(false);
    }, 7000);
  };
  useEffect(() => {
    if (phone.trim().length >= 11 && name.trim().length >= 1) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [phone, name]);

  useEffect(() => {
    if (
      email.trim().length >= 1 &&
      email.includes("@") &&
      email.includes(".com")
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    if (phone.trim().length >= 11) {
      setValidphone(true);
    } else {
      setValidphone(false);
    }
  }, [email, phone]);
  return (
    <div className={styles.form}>
      <ToastContainer />
      {props.report && (
        <p> Click the button below to get your car diagnostic report</p>
      )}
      <form onSubmit={submitFormHandler}>
        {props.report && (
          <div>
            <Input
              placeholder="Enter your full name"
              onChange={nameInputHandler}
              value={name}
              type="text"
            />
            <EmailInput
              value={phone}
              type="number"
              validNumber={validphone}
              placeholder="Enter your whatsapp number"
              onChange={phoneInputHandler}
            />
            <EmailInput
              value={email}
              validNumber={validEmail}
              placeholder="Enter email (optional)"
              type="email"
              onChange={emailInputHandler}
            />
            {!loading && (
              <Button disabled={!formIsValid}>Send Full Report</Button>
            )}
            {loading && <Button disabled={!formIsValid}>Loading...</Button>}
          </div>
        )}
        {!props.report && (
          <div>
            {!loading && (
              <Button disabled={formIsValid}>Send Full Report</Button>
            )}
            {loading && <Button disabled={!formIsValid}>Loading...</Button>}
          </div>
        )}
      </form>
    </div>
  );
}

export default DiagnosisForm;
