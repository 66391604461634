import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import Logo from "../../components/Logo";
import Input from "../../components/Input";
import device from "../../Assets/diagnoseCar.jpeg";
import EmailInput from "../../components/EmailInput";
import Button from "../../components/Button";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/redux-store/Index";

function Login() {
  let [number, setNumber] = useState("");
  let [loading, setLoading] = useState(false);
  let [deviceId, setDeviceId] = useState("");
  let [validNumber, setValidNumber] = useState(false);
  let [formIsValid, setFormIsValid] = useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let submitHandler = (e) => {
    e.preventDefault();

    let loginAuth = async () => {
      let url = `https://bpms.motormata.com/api/v1/dongle/verification/${deviceId}`;
      setFormIsValid(false);
      setLoading(true);
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.ok) {
        if (data.status === "invalid") {
          toast.error(`Invalid Device ID ❌`, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setFormIsValid(true);
          setLoading(false);
          return;
        } else if (data.status === "valid") {
          dispatch(authActions.plateNumber(number));

          let liveData = async () => {
            let url = `https://api.dev.motormata.com/api/v1/dongle/vehicle/live-data/${data.id}`;
            setLoading(true);
            let res = await fetch(url, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            let data3 = await res.json();
            if (res.ok) {
              let fetchUserData = async () => {
                let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/get/${number}`;
                let res = await fetch(url2, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                let data1 = await res.json();
                console.log(data1);
                if (res.ok) {
                  if (data1 !== "NO RECORD FOUND") {
                    // console.log(dongleVin);
                    dispatch(authActions.resetVehicleDetailsAlt());
                    dispatch(authActions.resetVehicleData());
                    dispatch(authActions.plateNumber(number));
                    dispatch(authActions.deviceID(data));
                    dispatch(authActions.detailsALt(data1[0]));
                    dispatch(authActions.vin(data3.vehicle_location.vehicle_vin));
                    dispatch(authActions.loggedIn(data));
                    navigate("/home");
                  } else if (data1 === "NO RECORD FOUND") {
                    let plateNumberHandler = async () => {
                      let url1 = `https://a6111.imas.octamile.com:62004/`;
                      setFormIsValid(false);
                      setLoading(true);
                      dispatch(authActions.resetToDefault());
                      let res = await fetch(url1, {
                        method: "POST",
                        body: JSON.stringify({
                          srvcVrsn: "2",
                          userInfo: {
                            id: process.env.REACT_APP_USER_ID,
                            athrzt: {
                              id: process.env.REACT_APP_AUTH_ID,
                              key: process.env.REACT_APP_KEY2,
                            },
                          },
                          cmmnd: {
                            cmmnd: `dump vins_regId:${number.toLowerCase()}*I_PRFL`,
                          },
                        }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      });
                      let data2 = await res.json();
                      console.log(data2);
                      setFormIsValid(true);
                      setLoading(false);
                      if (res.ok) {
                        let postReq = async () => {
                          let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/post`;
                          let response = await fetch(url2, {
                            method: "POST",
                            body: JSON.stringify({
                              plate_no: number,
                              chsssId: data2.chsssId,
                              cmmncmDate: data2.insrncDetail[0].cmmncmDate,
                              expiryDate: data2.insrncDetail[0].expiryDate,
                              type: data2.insrncDetail[0].type,
                              insrnStatus: data2.insrncStatus,
                              year: "",
                              make: "",
                              model: "",
                            }),
                            headers: {
                              "Content-Type": "application/json",
                            },
                          });
                          const OctaData = await response.json();
                          if (response.ok) {
                            console.log(OctaData);
                          } else {
                            console.log(OctaData);
                          }
                        };
                        postReq();
                        if (data3.vehicle_location.vehicle_vin === "") {
                          dispatch(authActions.plateNumber(number));
                          dispatch(authActions.deviceID(data));
                          navigate("/registervehicle");
                        } else {
                          dispatch(authActions.plateNumber(number));
                          dispatch(authActions.deviceID(data));
                          dispatch(authActions.loggedIn(data));
                          navigate("/home");
                        }
                      } else {
                        console.log(data2);
                      }
                    };
                    plateNumberHandler();
                  }
                } else {
                  console.log(data1);
                }
              };
              fetchUserData();
            } else {
              console.log(data3);
            }
          };
          liveData();
        }
      } else {
        console.log(data);
      }
    };
    loginAuth();
  };

  useEffect(() => {
    if (number.trim().length >= 3 && deviceId.trim().length >= 5) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [number, deviceId]);
  useEffect(() => {
    if (number.trim().length >= 7) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }, [number]);
  let numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  let deviceIdChangeHandler = (e) => {
    setDeviceId(e.target.value);
  };
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>SmartCar</p>
        </div>
        <div className={styles.wrapper}>
          <img src={device} alt="devie id" />
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <Input
            label="Device ID."
            value={deviceId}
            onChange={deviceIdChangeHandler}
            type="text"
            name="number"
            placeholder="Enter your device ID."
          />
          <EmailInput
            label="Plate Number"
            value={number}
            onChange={numberInputHandler}
            validNumber={validNumber}
            name="plate number"
            placeholder="ABC111DD"
          />
          {!loading && <Button disabled={!formIsValid}>Get Started</Button>}
          {loading && <Button disabled={!formIsValid}>Loading...</Button>}
        </form>
      </div>
    </section>
  );
}

export default Login;
