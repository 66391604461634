import React from 'react'
import logo from '../Assets/logo.png'
import styles from './NavBar2.module.css'

function NavBar() {
    return (
        <section className={styles.navBar}>
            <div className={styles.navBar__body}>
                <div className={styles.logo}>
                    <div className={styles.wrapper}>
                        <img src={logo} alt='MotorMata-logo' />
                    </div>
                    <div>
                        <p>Motormata</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NavBar