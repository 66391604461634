import React from "react";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import styles from "./NotFound.module.css";

function NotFound() {
  let navigate = useNavigate();
  let returnHomeHandler = () => {
    navigate("/home");
  };
  return (
    <div className={styles.body}>
      <p>Page Not Found</p>
      <Button onClick={returnHomeHandler}>Return Home</Button>
    </div>
  );
}

export default NotFound;
