import React, { useState, useEffect } from "react";
import styles from "./RegisterVehicle.module.css";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import cars from "../../utils/CarList";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { authActions } from "../../store/redux-store/Index";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const initialBoolenValues = {
  modelInvalid: false,
  formIsValid: false,
};

function RegisterVehicle() {
  const [loading, setLoading] = useState(false);
  const [selectInput, setSelectInput] = useState("");
  const [selectInput2, setSelectInput2] = useState("");
  const [selectInput3, setSelectInput3] = useState("");
  const [boolen, setBoolen] = useState(initialBoolenValues);

  let model = cars?.find((model, index) => model.brand === selectInput2);
  //Input state handler end//
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let deviceId = useSelector((state) => state.auth.dongleID);
  let number = useSelector((state) => state.auth.plateNumber);
  useEffect(() => {
    if (
      selectInput.trim().length >= 1 &&
      selectInput3.trim().length >= 1 &&
      selectInput2.trim().length >= 1
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [selectInput3, selectInput, selectInput2]);

  //Form Submission//
  let submitHandler = (e) => {
    e.preventDefault();

    let loginAuth = async () => {
      let url = `https://bpms.motormata.com/api/v1/dongle/verification/${deviceId}`;
      setBoolen({ formIsValid: false });
      setLoading(true);
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      setBoolen({ formIsValid: true });
      setLoading(false);
      if (res.ok) {
        dispatch(authActions.loggedIn(data));
      } else {
        console.log(data);
      }
    };
    loginAuth();

    let postReq = async () => {
      let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/post`;
      let response = await fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          plate_no: number,
          chsssId: "",
          cmmncmDate: "",
          expiryDate: "",
          type: "",
          insrnStatus: "",
          year: selectInput,
          make: selectInput2,
          model: selectInput3,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const OctaData = await response.json();
      if (response.ok) {
        console.log(OctaData);
        let fetchUserData = async () => {
          let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/get/${number}`;
          let res = await fetch(url2, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          let data1 = await res.json();
          if (res.ok) {
            if (data1 !== "NO RECORD FOUND") {
              dispatch(authActions.detailsALt(data1[0]));
              navigate("/home");
            }
          } else {
            console.log(data1);
            toast.error(`Something went wrong 😢. Try again`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        };
        fetchUserData();
      } else {
        console.log(OctaData);
      }
    };
    postReq();
  };
  //Form Submission end//
  //vin Toggle Handler end//
  let selectChangeHandler = (e) => {
    setSelectInput(e.target.value);
  };
  let brandSelectChangeHandler = (e) => {
    setSelectInput2(e.target.value);
  };
  let modelSelectChangeHandler = (e) => {
    setSelectInput3(e.target.value);
  };
  //Input Blur Handler Handler end//
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Add Vehicle</p>
        </div>
        <p style={{ margin: "14px 15px", textAlign: "center" }}>
          Unfortunately, we couldn't get information about your car this moment.
        </p>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Brand</label>
            <div className={styles.form__passwordInput}>
              <select onChange={brandSelectChangeHandler}>
                {cars.map((data, index) => (
                  <option value={data.brand}>{data.brand}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Model</label>
            <div className={styles.form__passwordInput}>
              <select onChange={modelSelectChangeHandler}>
                {model?.models.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Year</label>
            <div className={styles.form__passwordInput}>
              <select onChange={selectChangeHandler}>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>
          {!loading && <Button disabled={!boolen.formIsValid}>Continue</Button>}
          {loading && (
            <Button disabled={!boolen.formIsValid}>Loading...</Button>
          )}
        </form>
      </div>
    </section>
  );
}

export default RegisterVehicle;
