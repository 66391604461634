const Data = [
    {
        vehicle_error_id: "1",
        title: "P0000/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "2",
        title: "P0001",
        desc: "Fuel Volume Regulator Control Circuit/Open",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "3",
        title: "P0002",
        desc: "Fuel Volume Regulator Control Circuit Range/Performance",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "4",
        title: "P0003",
        desc: "Fuel Volume Regulator Control Circuit Low",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "5",
        title: "P0004",
        desc: "Fuel Volume Regulator Control Circuit High",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "6",
        title: "P0005",
        desc: "Fuel Shutoff ValveAControl Circuit/Open",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "7",
        title: "P0006",
        desc: "Fuel Shutoff ValveAControl Circuit Low",
        created_at: "2022-08-18 19:35:36",
        updated_at: "2022-08-18 19:35:36",
    },
    {
        vehicle_error_id: "8",
        title: "P0007",
        desc: "Fuel Shutoff ValveAControl Circuit High",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "9",
        title: "P0008",
        desc: "Engine Positions System Performance Bank 1",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "10",
        title: "P0009",
        desc: "Engine Position System Performance Bank 2",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "11",
        title: "P000A",
        desc: " ACamshaft Position Slow Response Bank 1",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "12",
        title: "P000B",
        desc: " BCamshaft Position Slow Response Bank 1",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "13",
        title: "P000C",
        desc: " ACamshaft Position Slow Response Bank 2",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "14",
        title: "P000D",
        desc: " BCamshaft Position Slow Response Bank 2",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "15",
        title: "P000E",
        desc: "Fuel Volume Regulator Control Exceeded Learning Limit",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "16",
        title: "P000F",
        desc: "Fuel System Over Pressure Relief Valve Activated",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "17",
        title: "P0010",
        desc: " ACamshaft Position Actuator Circuit (Bank 1)",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "18",
        title: "P0011",
        desc: " ACamshaft Position - Timing Over-Advanced or System Performance (Bank 1)",
        created_at: "2022-08-18 19:35:37",
        updated_at: "2022-08-18 19:35:37",
    },
    {
        vehicle_error_id: "19",
        title: "P0012",
        desc: " ACamshaft Position - Timing Over-Retarded (Bank 1)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "20",
        title: "P0013",
        desc: " BCamshaft Position - Actuator Circuit (Bank 1)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "21",
        title: "P0014",
        desc: " BCamshaft Position - Timing Over-Advanced or System Performance (Bank 1)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "22",
        title: "P0015",
        desc: " BCamshaft Position -Timing Over-Retarded (Bank 1)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "23",
        title: "P0016",
        desc: "Crankshaft Position - Camshaft Position Correlation (Bank 1 Sensor A)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "24",
        title: "P0017",
        desc: "Crankshaft Position - Camshaft Position Correlation (Bank 1 Sensor B)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "25",
        title: "P0018",
        desc: "Crankshaft Position - Camshaft Position Correlation (Bank 2 Sensor A)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "26",
        title: "P0019",
        desc: "Crankshaft Position - Camshaft Position Correlation (Bank 2 Sensor B)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "27",
        title: "P001A",
        desc: " ACamshaft Profile Control Circuit/Open Bank 1",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "28",
        title: "P001B",
        desc: " ACamshaft Profile Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "29",
        title: "P001C",
        desc: " ACamshaft Profile Control Circuit High Bank 1",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "30",
        title: "P001D",
        desc: " ACamshaft Profile Control Circuit/Open Bank 2",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "31",
        title: "P001E",
        desc: " ACamshaft Profile Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "32",
        title: "P001F",
        desc: " ACamshaft Profile Control Circuit High Bank 2",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "33",
        title: "P0020",
        desc: " ACamshaft Position Actuator Circuit (Bank 2)",
        created_at: "2022-08-18 19:35:38",
        updated_at: "2022-08-18 19:35:38",
    },
    {
        vehicle_error_id: "34",
        title: "P0021",
        desc: " ACamshaft Position - Timing Over-Advanced or System Performance (Bank 2)",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "35",
        title: "P0022",
        desc: " ACamshaft Position - Timing Over-Retarded (Bank 2)",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "36",
        title: "P0023",
        desc: " BCamshaft Position - Actuator Circuit (Bank 2)",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "37",
        title: "P0024",
        desc: " BCamshaft Position - Timing Over-Advanced or System Performance (Bank 2)",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "38",
        title: "P0025",
        desc: " BCamshaft Position - Timing Over-Retarded (Bank 2)",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "39",
        title: "P0026",
        desc: "Intake Valve Control Solenoid Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "40",
        title: "P0027",
        desc: "Exhaust Valve Control solenoid Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "41",
        title: "P0028",
        desc: "Intake valve Control Solenoid Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "42",
        title: "P0029",
        desc: "Exhaust Valve Control Solenoid Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "43",
        title: "P002A",
        desc: " BCamshaft Profile Control Circuit/Open Bank 1",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "44",
        title: "P002B",
        desc: " BCamshaft Profile Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "45",
        title: "P002C",
        desc: " BCamshaft Profile Control Circuit High Bank 1",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "46",
        title: "P002D",
        desc: " BCamshaft Profile Control Circuit/Open Bank 2",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "47",
        title: "P002E",
        desc: " BCamshaft Profile Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "48",
        title: "P002F",
        desc: " BCamshaft Profile Control Circuit High Bank 2",
        created_at: "2022-08-18 19:35:39",
        updated_at: "2022-08-18 19:35:39",
    },
    {
        vehicle_error_id: "49",
        title: "P0030",
        desc: "HO2S Heater Control Circuit (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "50",
        title: "P0031",
        desc: "HO2S Heater Control Circuit Low (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "51",
        title: "P0032",
        desc: "HO2S Heater Control Circuit High (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "52",
        title: "P0033",
        desc: "Turbo Charger Bypass Valve Control Circuit",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "53",
        title: "P0034",
        desc: "Turbo Charger Bypass Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "54",
        title: "P0035",
        desc: "Turbo Charger Bypass Valve Control Circuit High",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "55",
        title: "P0036",
        desc: "HO2S Heater Control Circuit (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "56",
        title: "P0037",
        desc: "HO2S Heater Control Circuit Low (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "57",
        title: "P0038",
        desc: "HO2S Heater Control Circuit High (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "58",
        title: "P0039",
        desc: "Turbo/Super Charger Bypass Valve Control Circuit Range/Performance",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "59",
        title: "P003A",
        desc: "Turbocharger/Supercharger Boost ControlAPosition Exceeded Learning Limit",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "60",
        title: "P003B",
        desc: "Turbocharger/Supercharger Boost ControlBPosition Exceeded Learning Limit",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "61",
        title: "P003C",
        desc: " ACamshaft Profile Control Performance/Stuck Off Bank 1",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "62",
        title: "P003D",
        desc: " ACamshaft Profile Control Stuck On Bank 1",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "63",
        title: "P003E",
        desc: " ACamshaft Profile Control Performance/Stuck Off Bank 2",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "64",
        title: "P003F",
        desc: " ACamshaft Profile Control Stuck On Bank 2",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "65",
        title: "P0040",
        desc: "Upstream Oxygen Sensors Swapped From Bank To Bank",
        created_at: "2022-08-18 19:35:40",
        updated_at: "2022-08-18 19:35:40",
    },
    {
        vehicle_error_id: "66",
        title: "P0041",
        desc: "Downstream Oxygen Sensors Swapped From Bank To Bank",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "67",
        title: "P0042",
        desc: "HO2S Heater Control Circuit (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "68",
        title: "P0043",
        desc: "HO2S Heater Control Circuit Low (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "69",
        title: "P0044",
        desc: "HO2S Heater Control Circuit High (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "70",
        title: "P0045",
        desc: "Turbocharger/Supercharger Boost ControlACircuit/Open",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "71",
        title: "P0046",
        desc: "Turbocharger/Supercharger Boost ControlACircuit Range/Performance",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "72",
        title: "P0047",
        desc: "Turbocharger/Supercharger Boost ControlACircuit Low",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "73",
        title: "P0048",
        desc: "Turbocharger/Supercharger Boost ControlACircuit High",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "74",
        title: "P0049",
        desc: "Turbocharger/Supercharger Turbine Overspeed",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "75",
        title: "P004A",
        desc: "Turbocharger/Supercharger Boost ControlBCircuit/Open",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "76",
        title: "P004B",
        desc: "Turbocharger/Supercharger Boost ControlBCircuit Range/Performance",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "77",
        title: "P004C",
        desc: "Turbocharger/Supercharger Boost ControlBCircuit Low",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "78",
        title: "P004D",
        desc: "Turbocharger/Supercharger Boost ControlBCircuit High",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "79",
        title: "P004E",
        desc: "Turbocharger/Supercharger Boost ControlACircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "80",
        title: "P004F",
        desc: "Turbocharger/Supercharger Boost ControlBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "81",
        title: "P0050",
        desc: "HO2S Heater Control Circuit (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "82",
        title: "P0051",
        desc: "HO2S Heater Control Circuit Low (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "83",
        title: "P0052",
        desc: "HO2S Heater Control Circuit High (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "84",
        title: "P0053",
        desc: "HO2S Heater Resistance (Bank 1, Sensor 1)",
        created_at: "2022-08-18 19:35:41",
        updated_at: "2022-08-18 19:35:41",
    },
    {
        vehicle_error_id: "85",
        title: "P0054",
        desc: "HO2S Heater Resistance (Bank 1, Sensor 2)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "86",
        title: "P0055",
        desc: "HO2S Heater Resistance (Bank 1, Sensor 3)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "87",
        title: "P0056",
        desc: "HO2S Heater Control Circuit (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "88",
        title: "P0057",
        desc: "HO2S Heater Control Circuit Low (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "89",
        title: "P0058",
        desc: "HO2S Heater Control Circuit High (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "90",
        title: "P0059",
        desc: "HO2S Heater Resistance (Bank 2, Sensor 1)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "91",
        title: "P005A",
        desc: " BCamshaft Profile Control Performance/Stuck Off Bank 1",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "92",
        title: "P005B",
        desc: " BCamshaft Profile Control Stuck On Bank 1",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "93",
        title: "P005C",
        desc: " BCamshaft Profile Control Performance/Stuck Off Bank 2",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "94",
        title: "P005D",
        desc: " BCamshaft Profile Control Stuck On Bank 2",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "95",
        title: "P005E",
        desc: "Turbocharger/Supercharger Boost ControlBSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "96",
        title: "P005F",
        desc: "Turbocharger/Supercharger Boost ControlBSupply Voltage Circuit High",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "97",
        title: "P0060",
        desc: "HO2S Heater Resistance (Bank 2, Sensor 2)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "98",
        title: "P0061",
        desc: "HO2S Heater Resistance (Bank 2, Sensor 3)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "99",
        title: "P0062",
        desc: "HO2S Heater Control Circuit (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "100",
        title: "P0063",
        desc: "HO2S Heater Control Circuit Low (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "101",
        title: "P0064",
        desc: "HO2S Heater Control Circuit High (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "102",
        title: "P0065",
        desc: "Air Assisted Injector Control Range/Performance",
        created_at: "2022-08-18 19:35:42",
        updated_at: "2022-08-18 19:35:42",
    },
    {
        vehicle_error_id: "103",
        title: "P0066",
        desc: "Air Assisted Injector Control Circuit or Circuit Low",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "104",
        title: "P0067",
        desc: "Air Assisted Injector Control Circuit High",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "105",
        title: "P0068",
        desc: "MAP/MAF - Throttle Position Correlation",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "106",
        title: "P0069",
        desc: "Manifold Absolute Pressure - Barometric Pressure Correlation",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "107",
        title: "P006A",
        desc: "MAP - Mass or Volume Air Flow Correlation Bank 1",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "108",
        title: "P006B",
        desc: "MAP - Exhaust Pressure Correlation",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "109",
        title: "P006C",
        desc: "MAP - Turbocharger/Supercharger Inlet Pressure Correlation",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "110",
        title: "P006D",
        desc: "Barometric Pressure - Turbocharger/Supercharger Inlet Pressure Correlation",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "111",
        title: "P006E",
        desc: "Turbocharger/Supercharger Boost ControlASupply Voltage Circuit Low",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "112",
        title: "P006F",
        desc: "Turbocharger/Supercharger Boost ControlASupply Voltage Circuit High",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "113",
        title: "P0070",
        desc: "Ambient Air Temperature Sensor Circuit",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "114",
        title: "P0071",
        desc: "Ambient Air Temperature Sensor Range/Performance",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "115",
        title: "P0072",
        desc: "Ambient Air Temperature Sensor Circuit Low Input",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "116",
        title: "P0073",
        desc: "Ambient Air Temperature Sensor Circuit High Input",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "117",
        title: "P0074",
        desc: "Ambient Air Temperature Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "118",
        title: "P0075",
        desc: "Intake Valve Control Solenoid Circuit (Bank 1)",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "119",
        title: "P0076",
        desc: "Intake Valve Control Solenoid Circuit Low (Bank 1)",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "120",
        title: "P0077",
        desc: "Intake Valve Control Solenoid Circuit High (Bank 1)",
        created_at: "2022-08-18 19:35:43",
        updated_at: "2022-08-18 19:35:43",
    },
    {
        vehicle_error_id: "121",
        title: "P0078",
        desc: "Exhaust Valve Control Solenoid Circuit (Bank 1)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "122",
        title: "P0079",
        desc: "Exhaust Valve Control Solenoid Circuit Low (Bank 1)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "123",
        title: "P007A",
        desc: "Charge Air Cooler Temperature Sensor Circuit Bank 1",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "124",
        title: "P007B",
        desc: "Charge Air Cooler Temperature Sensor Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "125",
        title: "P007C",
        desc: "Charge Air Cooler Temperature Sensor Circuit Low Bank 1",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "126",
        title: "P007D",
        desc: "Charge Air Cooler Temperature Sensor Circuit High Bank 1",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "127",
        title: "P007E",
        desc: "Charge Air Cooler Temperature Sensor Circuit Intermittent/Erratic Bank 1",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "128",
        title: "P007F",
        desc: "Charge Air Cooler Temperature Sensor Bank1/Bank2 Correlation",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "129",
        title: "P0080",
        desc: "Exhaust Valve Control Solenoid Circuit High (Bank 1)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "130",
        title: "P0081",
        desc: "Intake valve Control Solenoid Circuit (Bank 2)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "131",
        title: "P0082",
        desc: "Intake Valve Control Solenoid Circuit Low (Bank 2)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "132",
        title: "P0083",
        desc: "Intake Valve Control Solenoid Circuit High (Bank 2)",
        created_at: "2022-08-18 19:35:44",
        updated_at: "2022-08-18 19:35:44",
    },
    {
        vehicle_error_id: "133",
        title: "P0084",
        desc: "Exhaust Valve Control Solenoid Circuit (Bank 2)",
        created_at: "2022-08-18 19:35:45",
        updated_at: "2022-08-18 19:35:45",
    },
    {
        vehicle_error_id: "134",
        title: "P0085",
        desc: "Exhaust Valve Control Solenoid Circuit Low (Bank 2)",
        created_at: "2022-08-18 19:35:45",
        updated_at: "2022-08-18 19:35:45",
    },
    {
        vehicle_error_id: "135",
        title: "P0086",
        desc: "Exhaust Valve Control Solenoid Circuit High (Bank 2)",
        created_at: "2022-08-18 19:35:45",
        updated_at: "2022-08-18 19:35:45",
    },
    {
        vehicle_error_id: "136",
        title: "P0087",
        desc: "Fuel Rail/System Pressure - Too Low",
        created_at: "2022-08-18 19:35:45",
        updated_at: "2022-08-18 19:35:45",
    },
    {
        vehicle_error_id: "137",
        title: "P0088",
        desc: "Fuel Rail/System Pressure - Too High",
        created_at: "2022-08-18 19:35:45",
        updated_at: "2022-08-18 19:35:45",
    },
    {
        vehicle_error_id: "138",
        title: "P0089",
        desc: "Fuel Pressure Regulator 1 Performance",
        created_at: "2022-08-18 19:35:46",
        updated_at: "2022-08-18 19:35:46",
    },
    {
        vehicle_error_id: "139",
        title: "P008A",
        desc: "Low Pressure Fuel System Pressure - Too Low",
        created_at: "2022-08-18 19:35:46",
        updated_at: "2022-08-18 19:35:46",
    },
    {
        vehicle_error_id: "140",
        title: "P008B",
        desc: "Low Pressure Fuel System Pressure - Too High",
        created_at: "2022-08-18 19:35:46",
        updated_at: "2022-08-18 19:35:46",
    },
    {
        vehicle_error_id: "141",
        title: "P008C",
        desc: "Fuel Cooler Pump Control Circuit Open",
        created_at: "2022-08-18 19:35:46",
        updated_at: "2022-08-18 19:35:46",
    },
    {
        vehicle_error_id: "142",
        title: "P008D",
        desc: "Fuel Cooler Pump Control Circuit Low",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "143",
        title: "P008E",
        desc: "Fuel Cooler Pump Control Circuit High",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "144",
        title: "P008F",
        desc: "Engine Coolant Temperature/Fuel Temperature Correlation",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "145",
        title: "P0090",
        desc: "Fuel Pressure Regulator 1 Control Circuit",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "146",
        title: "P0091",
        desc: "Fuel Pressure Regulator 1 Control Circuit Low",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "147",
        title: "P0092",
        desc: "Fuel Pressure Regulator 1 Control Circuit High",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "148",
        title: "P0093",
        desc: "Fuel System Leak Detected - Large Leak",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "149",
        title: "P0094",
        desc: "Fuel System Leak Detected - Small Leak",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "150",
        title: "P0095",
        desc: "Intake Air Temperature Sensor 2 Circuit Bank 1",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "151",
        title: "P0096",
        desc: "Intake Air Temperature Sensor 2 Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "152",
        title: "P0097",
        desc: "Intake Air Temperature Sensor 2 Circuit Low Bank 1",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "153",
        title: "P0098",
        desc: "Intake Air Temperature Sensor 2 Circuit High Bank 1",
        created_at: "2022-08-18 19:35:47",
        updated_at: "2022-08-18 19:35:47",
    },
    {
        vehicle_error_id: "154",
        title: "P0099",
        desc: "Intake Air Temperature Sensor 2 Circuit Intermittent/Erratic Bank 1",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "155",
        title: "P009A",
        desc: "Intake Air Temperature/Ambient Air Temperature Correlation",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "156",
        title: "P009B",
        desc: "Fuel Pressure Relief Control Circuit/Open",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "157",
        title: "P009C",
        desc: "Fuel Pressure Relief Control Circuit Low",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "158",
        title: "P009D",
        desc: "Fuel Pressure Relief Control Circuit High",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "159",
        title: "P009E",
        desc: "Fuel Pressure Relief Control Performance/Stuck Off",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "160",
        title: "P009F",
        desc: "Fuel Pressure Relief Control Stuck On",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "161",
        title: "P00A0",
        desc: "Charge Air Cooler Temperature Sensor Circuit Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "162",
        title: "P00A1",
        desc: "Charge Air Cooler Temperature Sensor Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "163",
        title: "P00A2",
        desc: "Charge Air Cooler Temperature Sensor Circuit Low Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "164",
        title: "P00A3",
        desc: "Charge Air Cooler Temperature Sensor Circuit High Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "165",
        title: "P00A4",
        desc: "Charge Air Cooler Temperature Sensor Circuit Intermittent/Erratic Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "166",
        title: "P00A5",
        desc: "Intake Air Temperature Sensor 2 Circuit Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "167",
        title: "P00A6",
        desc: "Intake Air Temperature Sensor 2 Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "168",
        title: "P00A7",
        desc: "Intake Air Temperature Sensor 2 Circuit Low Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "169",
        title: "P00A8",
        desc: "Intake Air Temperature Sensor 2 Circuit High Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "170",
        title: "P00A9",
        desc: "Intake Air Temperature Sensor 2 Circuit Intermittent/Erratic Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "171",
        title: "P00AA",
        desc: "Intake Air Temperature Sensor 1 Circuit Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "172",
        title: "P00AB",
        desc: "Intake Air Temperature Sensor 1 Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:35:48",
        updated_at: "2022-08-18 19:35:48",
    },
    {
        vehicle_error_id: "173",
        title: "P00AC",
        desc: "Intake Air Temperature Sensor 1 Circuit Low Bank 2",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "174",
        title: "P00AD",
        desc: "Intake Air Temperature Sensor 1 Circuit High Bank 2",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "175",
        title: "P00AE",
        desc: "Intake Air Temperature Sensor 1 Circuit Intermittent/Erratic Bank 2",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "176",
        title: "P00AF",
        desc: "Turbocharger/Supercharger Boost ControlAModule Performance",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "177",
        title: "P00B0",
        desc: "Turbocharger/Supercharger Boost ControlBModule Performance",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "178",
        title: "P00B1",
        desc: "Radiator Coolant Temperature Sensor Circuit",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "179",
        title: "P00B2",
        desc: "Radiator Coolant Temperature Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "180",
        title: "P00B3",
        desc: "Radiator Coolant Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "181",
        title: "P00B4",
        desc: "Radiator Coolant Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "182",
        title: "P00B5",
        desc: "Radiator Coolant Temperature Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "183",
        title: "P00B6",
        desc: "Radiator Coolant Temperature/Engine Coolant Temperature Correlation",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "184",
        title: "P00B7",
        desc: "Engine Coolant Flow Low/Performance",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "185",
        title: "P00B8",
        desc: "MAP - Mass or Volume Air Flow Correlation Bank 2",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "186",
        title: "P00B9",
        desc: "Low Pressure Fuel System Pressure - Too Low, Low Ambient Temperature",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "187",
        title: "P00BA",
        desc: "Low Fuel Pressure - Forced Limited Power",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "188",
        title: "P00BB",
        desc: "Fuel Injector Insufficient Flow - Forced Limited Power",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "189",
        title: "P00BC",
        desc: "Mass or Volume Air FlowACircuit Range/Performance - Air Flow Too Low",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "190",
        title: "P00BD",
        desc: "Mass or Volume Air FlowACircuit Range/Performance - Air Flow Too High",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "191",
        title: "P00BE",
        desc: "Mass or Volume Air FlowBCircuit Range/Performance - Air Flow Too Low",
        created_at: "2022-08-18 19:35:49",
        updated_at: "2022-08-18 19:35:49",
    },
    {
        vehicle_error_id: "192",
        title: "P00BF",
        desc: "Mass or Volume Air FlowBCircuit Range/Performance - Air Flow Too High",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "193",
        title: "P00C0",
        desc: "P00FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "194",
        title: "P3400",
        desc: "Cylinder Deactivation System Bank 1",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "195",
        title: "P3401",
        desc: "Cylinder 1 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "196",
        title: "P3402",
        desc: "Cylinder 1 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "197",
        title: "P3403",
        desc: "Cylinder 1 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "198",
        title: "P3404",
        desc: "Cylinder 1 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "199",
        title: "P3405",
        desc: "Cylinder 1 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "200",
        title: "P3406",
        desc: "Cylinder 1 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "201",
        title: "P3407",
        desc: "Cylinder 1 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "202",
        title: "P3408",
        desc: "Cylinder 1 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "203",
        title: "P3409",
        desc: "Cylinder 2 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "204",
        title: "P340A,",
        desc: "P340B, P340C, P340D, P340E, P340F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "205",
        title: "P3410",
        desc: "Cylinder 2 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "206",
        title: "P3411",
        desc: "Cylinder 2 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "207",
        title: "P3412",
        desc: "Cylinder 2 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "208",
        title: "P3413",
        desc: "Cylinder 2 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "209",
        title: "P3414",
        desc: "Cylinder 2 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "210",
        title: "P3415",
        desc: "Cylinder 2 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:50",
        updated_at: "2022-08-18 19:35:50",
    },
    {
        vehicle_error_id: "211",
        title: "P3416",
        desc: "Cylinder 2 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "212",
        title: "P3417",
        desc: "Cylinder 3 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "213",
        title: "P3418",
        desc: "Cylinder 3 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "214",
        title: "P3419",
        desc: "Cylinder 3 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "215",
        title: "P341A,",
        desc: "P341B, P341C, P341D, P341E, P341F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "216",
        title: "P3420",
        desc: "Cylinder 3 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "217",
        title: "P3421",
        desc: "Cylinder 3 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "218",
        title: "P3422",
        desc: "Cylinder 3 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "219",
        title: "P3423",
        desc: "Cylinder 3 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "220",
        title: "P3424",
        desc: "Cylinder 3 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "221",
        title: "P3425",
        desc: "Cylinder 4 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "222",
        title: "P3426",
        desc: "Cylinder 4 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "223",
        title: "P3427",
        desc: "Cylinder 4 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "224",
        title: "P3428",
        desc: "Cylinder 4 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "225",
        title: "P3429",
        desc: "Cylinder 4 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:51",
        updated_at: "2022-08-18 19:35:51",
    },
    {
        vehicle_error_id: "226",
        title: "P342A,",
        desc: "P342B, P342C, P342D, P342E, P342F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "227",
        title: "P3430",
        desc: "Cylinder 4 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "228",
        title: "P3431",
        desc: "Cylinder 4 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "229",
        title: "P3432",
        desc: "Cylinder 4 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "230",
        title: "P3433",
        desc: "Cylinder 5 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "231",
        title: "P3434",
        desc: "Cylinder 5 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "232",
        title: "P3435",
        desc: "Cylinder 5 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "233",
        title: "P3436",
        desc: "Cylinder 5 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "234",
        title: "P3437",
        desc: "Cylinder 5 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "235",
        title: "P3438",
        desc: "Cylinder 5 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "236",
        title: "P3439",
        desc: "Cylinder 5 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "237",
        title: "P343A,",
        desc: "P343B, P343C, P343D, P343E, P343F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "238",
        title: "P3440",
        desc: "Cylinder 5 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "239",
        title: "P3441",
        desc: "Cylinder 6 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "240",
        title: "P3442",
        desc: "Cylinder 6 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "241",
        title: "P3443",
        desc: "Cylinder 6 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:52",
        updated_at: "2022-08-18 19:35:52",
    },
    {
        vehicle_error_id: "242",
        title: "P3444",
        desc: "Cylinder 6 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "243",
        title: "P3445",
        desc: "Cylinder 6 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "244",
        title: "P3446",
        desc: "Cylinder 6 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "245",
        title: "P3447",
        desc: "Cylinder 6 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "246",
        title: "P3448",
        desc: "Cylinder 6 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "247",
        title: "P3449",
        desc: "Cylinder 7 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "248",
        title: "P344A,",
        desc: "P344B, P344C, P344D, P344E, P344F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:53",
        updated_at: "2022-08-18 19:35:53",
    },
    {
        vehicle_error_id: "249",
        title: "P3450",
        desc: "Cylinder 7 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "250",
        title: "P3451",
        desc: "Cylinder 7 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "251",
        title: "P3452",
        desc: "Cylinder 7 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "252",
        title: "P3453",
        desc: "Cylinder 7 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "253",
        title: "P3454",
        desc: "Cylinder 7 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "254",
        title: "P3455",
        desc: "Cylinder 7 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "255",
        title: "P3456",
        desc: "Cylinder 7 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "256",
        title: "P3457",
        desc: "Cylinder 8 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "257",
        title: "P3458",
        desc: "Cylinder 8 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "258",
        title: "P3459",
        desc: "Cylinder 8 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "259",
        title: "P345A,",
        desc: "P345B, P345C, P345D, P345E, P345F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "260",
        title: "P3460",
        desc: "Cylinder 8 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "261",
        title: "P3461",
        desc: "Cylinder 8 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "262",
        title: "P3462",
        desc: "Cylinder 8 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:54",
        updated_at: "2022-08-18 19:35:54",
    },
    {
        vehicle_error_id: "263",
        title: "P3463",
        desc: "Cylinder 8 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "264",
        title: "P3464",
        desc: "Cylinder 8 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "265",
        title: "P3465",
        desc: "Cylinder 9 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "266",
        title: "P3466",
        desc: "Cylinder 9 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "267",
        title: "P3467",
        desc: "Cylinder 9 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "268",
        title: "P3468",
        desc: "Cylinder 9 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "269",
        title: "P3469",
        desc: "Cylinder 9 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "270",
        title: "P346A,",
        desc: "P346B, P346C, P346D, P346E, P346F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "271",
        title: "P3470",
        desc: "Cylinder 9 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "272",
        title: "P3471",
        desc: "Cylinder 9 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "273",
        title: "P3472",
        desc: "Cylinder 9 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "274",
        title: "P3473",
        desc: "Cylinder 10 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "275",
        title: "P3474",
        desc: "Cylinder 10 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:55",
        updated_at: "2022-08-18 19:35:55",
    },
    {
        vehicle_error_id: "276",
        title: "P3475",
        desc: "Cylinder 10 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "277",
        title: "P3476",
        desc: "Cylinder 10 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "278",
        title: "P3477",
        desc: "Cylinder 10 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "279",
        title: "P3478",
        desc: "Cylinder 10 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "280",
        title: "P3479",
        desc: "Cylinder 10 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "281",
        title: "P347A,",
        desc: "P347B, P347C, P347D, P347E, P347F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "282",
        title: "P3480",
        desc: "Cylinder 10 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "283",
        title: "P3481",
        desc: "Cylinder 11 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "284",
        title: "P3482",
        desc: "Cylinder 11 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "285",
        title: "P3483",
        desc: "Cylinder 11 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:56",
        updated_at: "2022-08-18 19:35:56",
    },
    {
        vehicle_error_id: "286",
        title: "P3484",
        desc: "Cylinder 11 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "287",
        title: "P3485",
        desc: "Cylinder 11 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "288",
        title: "P3486",
        desc: "Cylinder 11 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "289",
        title: "P3487",
        desc: "Cylinder 11 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "290",
        title: "P3488",
        desc: "Cylinder 11 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "291",
        title: "P3489",
        desc: "Cylinder 12 Deactivation/Intake Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "292",
        title: "P348A,",
        desc: "P348B, P348C, P348D, P348E, P348F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "293",
        title: "P3490",
        desc: "Cylinder 12 Deactivation/Intake Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "294",
        title: "P3491",
        desc: "Cylinder 12 Deactivation/Intake Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "295",
        title: "P3492",
        desc: "Cylinder 12 Deactivation/Intake Valve Control Circuit High",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "296",
        title: "P3493",
        desc: "Cylinder 12 Exhaust Valve Control Circuit/Open",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "297",
        title: "P3494",
        desc: "Cylinder 12 Exhaust Valve Control Circuit Performance",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "298",
        title: "P3495",
        desc: "Cylinder 12 Exhaust Valve Control Circuit Low",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "299",
        title: "P3496",
        desc: "Cylinder 12 Exhaust Valve Control Circuit High",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "300",
        title: "P3497",
        desc: "Cylinder Deactivation System Bank 2 ",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "301",
        title: "P3498",
        desc: "P34FF, P3500, P3600, P3700, P3800, P3900, P3A00, P3B00, P3C00, P3D00, P3E00, P3F00 ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:57",
        updated_at: "2022-08-18 19:35:57",
    },
    {
        vehicle_error_id: "302",
        title: "P2800",
        desc: "Range SensorBCircuit (PRNDL Input) ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "303",
        title: "P2801",
        desc: "Range SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "304",
        title: "P2802",
        desc: "Range SensorBCircuit Low",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "305",
        title: "P2803",
        desc: "Range SensorBCircuit High ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "306",
        title: "P2804",
        desc: "Range SensorBCircuit Intermittent",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "307",
        title: "P2808",
        desc: "Control SolenoidGPerformance/Stuck Off ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "308",
        title: "P2809",
        desc: "Control SolenoidGStuck On ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "309",
        title: "P280C,",
        desc: "P280D, P280E, P280F ISO/SAE Reserved",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "310",
        title: "P2811",
        desc: "Control SolenoidGIntermittent ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "311",
        title: "P2812",
        desc: "Control SolenoidGControl Circuit/Open ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "312",
        title: "P2813",
        desc: "Control SolenoidGControl Circuit Range/Performance",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "313",
        title: "P2814",
        desc: "Control SolenoidGControl Circuit Low ",
        created_at: "2022-08-18 19:35:58",
        updated_at: "2022-08-18 19:35:58",
    },
    {
        vehicle_error_id: "314",
        title: "P2815",
        desc: "Control SolenoidGControl Circuit High ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "315",
        title: "P2816",
        desc: "Control SolenoidH",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "316",
        title: "P2817",
        desc: "Control SolenoidHPerformance/Stuck Off ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "317",
        title: "P2818",
        desc: "Control SolenoidHStuck On",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "318",
        title: "P281A",
        desc: "Control SolenoidHIntermittent ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "319",
        title: "P281B",
        desc: "Control SolenoidHControl Circuit/Open",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "320",
        title: "P281C",
        desc: "Control SolenoidHControl Circuit Range/Performance",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "321",
        title: "P281D",
        desc: "Control SolenoidHControl Circuit Low",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "322",
        title: "P281F",
        desc: "Control SolenoidJ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "323",
        title: "P2820",
        desc: "Control SolenoidJPerformance/Stuck Off ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "324",
        title: "P2821",
        desc: "Control SolenoidJStuck On ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "325",
        title: "P2822",
        desc: "Control SolenoidJElectrical ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "326",
        title: "P2823",
        desc: "Control SolenoidJIntermittent ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "327",
        title: "P2824",
        desc: "Control SolenoidJControl Circuit/Open ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "328",
        title: "P2825",
        desc: "Control SolenoidJControl Circuit Range/Performance",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "329",
        title: "P2826",
        desc: "Control SolenoidJControl Circuit Low ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "330",
        title: "P2827",
        desc: "Control SolenoidJControl Circuit High ",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "331",
        title: "P2828",
        desc: "Control SolenoidK",
        created_at: "2022-08-18 19:35:59",
        updated_at: "2022-08-18 19:35:59",
    },
    {
        vehicle_error_id: "332",
        title: "P2829",
        desc: "Control SolenoidKPerformance/Stuck Off ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "333",
        title: "P282A",
        desc: "Control SolenoidKStuck On ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "334",
        title: "P282B",
        desc: "Control SolenoidKElectrical ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "335",
        title: "P282C",
        desc: "Control SolenoidKIntermittent",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "336",
        title: "P282D",
        desc: "Control SolenoidKControl Circuit/Open ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "337",
        title: "P282E",
        desc: "Control SolenoidKControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "338",
        title: "P282F",
        desc: "Control SolenoidKControl Circuit Low",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "339",
        title: "P2830",
        desc: "Control SolenoidKControl Circuit High ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "340",
        title: "P2A02",
        desc: "Sensor Circuit Range/Performance Bank 1 Sensor 3 ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "341",
        title: "P2A03",
        desc: "Sensor Circuit Range/Performance Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "342",
        title: "P2A04",
        desc: "Sensor Circuit Range/Performance Bank 2 Sensor 2 ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "343",
        title: "P2A05",
        desc: "Sensor Circuit Range/Performance Bank 2 Sensor 3 ",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "344",
        title: "P2A0A,",
        desc: "P2A0B, P2A0C, P2A0D, P2A0E, P2A0F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "345",
        title: "P2BAC",
        desc: "Exceedence - Deactivation of EGR",
        created_at: "2022-08-18 19:36:00",
        updated_at: "2022-08-18 19:36:00",
    },
    {
        vehicle_error_id: "346",
        title: "P2BAF",
        desc: "P2BFF, P2C00, P2D00, P2E00, P2F00 ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "347",
        title: "P2700",
        desc: "Friction ElementAApply Time Range/Performance ",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "348",
        title: "P2701",
        desc: "Friction ElementBApply Time Range/Performance",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "349",
        title: "P2703",
        desc: "Friction ElementDApply Time Range/Performance",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "350",
        title: "P2704",
        desc: "Friction ElementEApply Time Range/Performance",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "351",
        title: "P2705",
        desc: "Friction ElementFApply Time Range/Performance",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "352",
        title: "P2706",
        desc: "Solenoid F Malfunction",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "353",
        title: "P2707",
        desc: "Solenoid F Performance/Stuck Off",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "354",
        title: "P2708",
        desc: "Solenoid F Stuck On",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "355",
        title: "P2709",
        desc: "Solenoid F Electrical ",
        created_at: "2022-08-18 19:36:01",
        updated_at: "2022-08-18 19:36:01",
    },
    {
        vehicle_error_id: "356",
        title: "P270A,",
        desc: "P270B, P270C, P270D, P270E, P270F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "357",
        title: "P2714",
        desc: "Control SolenoidDPerformance/Stuck Off ",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "358",
        title: "P2715",
        desc: "Control SolenoidDStuck On",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "359",
        title: "P2716",
        desc: "Control SolenoidDElectrical ",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "360",
        title: "P2717",
        desc: "Control SolenoidDIntermittent ",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "361",
        title: "P2718",
        desc: "Control SolenoidDControl Circuit/Open",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "362",
        title: "P2719",
        desc: "Control SolenoidDControl Circuit Range/Performance ",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "363",
        title: "P271A,",
        desc: "P271B, P271C, P271D, P271E, P271F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "364",
        title: "P2720",
        desc: "Control SolenoidDControl Circuit Low",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "365",
        title: "P2721",
        desc: "Control SolenoidDControl Circuit High",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "366",
        title: "P2722",
        desc: "Control SolenoidE",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "367",
        title: "P2723",
        desc: "Control SolenoidEPerformance/Stuck Off ",
        created_at: "2022-08-18 19:36:02",
        updated_at: "2022-08-18 19:36:02",
    },
    {
        vehicle_error_id: "368",
        title: "P2724",
        desc: "Control SolenoidEStuck On ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "369",
        title: "P2725",
        desc: "Control SolenoidEElectrical ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "370",
        title: "P2726",
        desc: "Control SolenoidEIntermittent ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "371",
        title: "P2727",
        desc: "Control SolenoidEControl Circuit/Open",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "372",
        title: "P2728",
        desc: "Control SolenoidEControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "373",
        title: "P2729",
        desc: "Control SolenoidEControl Circuit Low",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "374",
        title: "P2730",
        desc: "Control SolenoidEControl Circuit High ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "375",
        title: "P2731",
        desc: "Control SolenoidF",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "376",
        title: "P2732",
        desc: "Control SolenoidFPerformance/Stuck Off ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "377",
        title: "P2733",
        desc: "Control SolenoidFStuck On ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "378",
        title: "P2734",
        desc: "Control SolenoidFElectrical ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "379",
        title: "P2735",
        desc: "Control SolenoidFIntermittent",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "380",
        title: "P2737",
        desc: "Control SolenoidFControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "381",
        title: "P2738",
        desc: "Control SolenoidFControl Circuit Low ",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "382",
        title: "P2739",
        desc: "Control SolenoidFControl Circuit High",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "383",
        title: "P2740",
        desc: "Fluid Temperature SensorBCircuit",
        created_at: "2022-08-18 19:36:03",
        updated_at: "2022-08-18 19:36:03",
    },
    {
        vehicle_error_id: "384",
        title: "P2741",
        desc: "Fluid Temperature SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "385",
        title: "P2743",
        desc: "Fluid Temperature SensorBCircuit High",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "386",
        title: "P2744",
        desc: "Fluid Temperature SensorBCircuit Intermittent",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "387",
        title: "P2745",
        desc: "Shaft Speed Sensor B Circuit",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "388",
        title: "P2748",
        desc: "Shaft Speed Sensor B Circuit Intermittent",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "389",
        title: "P2749",
        desc: "Shaft Speed Sensor C Circuit ",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "390",
        title: "P274A,",
        desc: "P274B, P274C, P274D, P274E, P274F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "391",
        title: "P2750",
        desc: "Shaft Speed Sensor C Circuit Range/Performance",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "392",
        title: "P2751",
        desc: "Shaft Speed Sensor C Circuit No Signal",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "393",
        title: "P2752",
        desc: "Shaft Speed Sensor C Circuit Intermittent",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "394",
        title: "P275A,",
        desc: "P275B, P275C, P275D, P275E, P275F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:04",
        updated_at: "2022-08-18 19:36:04",
    },
    {
        vehicle_error_id: "395",
        title: "P2765/Turbine",
        desc: "Speed SensorBCircuit ",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "396",
        title: "P2766/Turbine",
        desc: "Speed SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "397",
        title: "P2768/Turbine",
        desc: "Speed SensorBCircuit Intermittent ",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "398",
        title: "P2769",
        desc: "Converter Clutch Circuit Low ",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "399",
        title: "P276A,",
        desc: "P276B, P276C, P276D, P276E, P276F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "400",
        title: "P2770",
        desc: "Converter Clutch Circuit High",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "401",
        title: "P277A,",
        desc: "P277B, P277C, P277D, P277E, P277F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "402",
        title: "P27A1",
        desc: "P27FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "403",
        title: "P2600",
        desc: "PumpAControl Circuit/Open",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "404",
        title: "P2601",
        desc: "PumpAControl Circuit Range/Performance ",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "405",
        title: "P2602",
        desc: "PumpAControl Circuit Low",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "406",
        title: "P2603",
        desc: "PumpAControl Circuit High",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "407",
        title: "P2604",
        desc: "Air HeaterACircuit Range/Performance",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "408",
        title: "P2605",
        desc: "Air HeaterBCircuit/Open",
        created_at: "2022-08-18 19:36:05",
        updated_at: "2022-08-18 19:36:05",
    },
    {
        vehicle_error_id: "409",
        title: "P2606",
        desc: "Air HeaterBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "410",
        title: "P2607",
        desc: "Air HeaterBCircuit Low",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "411",
        title: "P2609",
        desc: "Air Heater System Performance",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "412",
        title: "P260A",
        desc: "Control Circuit /Open",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "413",
        title: "P260B",
        desc: "Control Circuit Low",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "414",
        title: "P260D",
        desc: "Engaged Lamp Control Circuit ",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "415",
        title: "P2610/PCM",
        desc: "Internal Engine Off Timer Performance",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "416",
        title: "P261A",
        desc: "PumpBControl Circuit/Open ",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "417",
        title: "P261B",
        desc: "PumpBControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "418",
        title: "P261C",
        desc: "PumpBControl Circuit Low",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "419",
        title: "P261D",
        desc: "PumpBControl Circuit High",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "420",
        title: "P261E,",
        desc: "P261F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "421",
        title: "P2626",
        desc: "Sensor Pumping Current Trim Circuit/Open Bank 1 Sensor 1 ",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "422",
        title: "P2627",
        desc: "Sensor Pumping Current Trim Circuit Low Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "423",
        title: "P2628",
        desc: "Sensor Pumping Current Trim Circuit High Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:06",
        updated_at: "2022-08-18 19:36:06",
    },
    {
        vehicle_error_id: "424",
        title: "P262A,",
        desc: "P262B, P262C, P262D, P262E, P262F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "425",
        title: "P2630",
        desc: "Sensor Pumping Current Trim Circuit Low Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "426",
        title: "P2631",
        desc: "Sensor Pumping Current Trim Circuit High Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "427",
        title: "P2632",
        desc: "PumpBControl Circuit /Open ",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "428",
        title: "P2633",
        desc: "PumpBControl Circuit Low",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "429",
        title: "P2634",
        desc: "PumpBControl Circuit High",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "430",
        title: "P264F/SAE",
        desc: "Reserved ",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "431",
        title: "P265F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "432",
        title: "P2669",
        desc: "Supply VoltageBCircuit /Open",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "433",
        title: "P266F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "434",
        title: "P2671",
        desc: "Supply VoltageBCircuit High",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "435",
        title: "P2684",
        desc: "Supply VoltageCCircuit/Open ",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "436",
        title: "P2685",
        desc: "Supply VoltageCCircuit Low",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "437",
        title: "P2686",
        desc: "Supply VoltageCCircuit High",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "438",
        title: "P26A3",
        desc: "P26FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "439",
        title: "P2500",
        desc: "Lamp/L-Terminal Circuit Low ",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "440",
        title: "P2501",
        desc: "Lamp/L-Terminal Circuit High",
        created_at: "2022-08-18 19:36:07",
        updated_at: "2022-08-18 19:36:07",
    },
    {
        vehicle_error_id: "441",
        title: "P2509/PCM",
        desc: "Power Input Signal Intermittent",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "442",
        title: "P250A",
        desc: "Oil Level Sensor Circuit ",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "443",
        title: "P250B",
        desc: "Oil Level Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "444",
        title: "P250C",
        desc: "Oil Level Sensor Circuit Low",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "445",
        title: "P250E",
        desc: "Oil Level Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "446",
        title: "P250F",
        desc: "Oil Level Too Low",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "447",
        title: "P2515/C",
        desc: "Refrigerant Pressure SensorBCircuit",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "448",
        title: "P252E",
        desc: "Oil Quality Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "449",
        title: "P252F",
        desc: "Oil Level Too High",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "450",
        title: "P253A",
        desc: "Sense Circuit/Open",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "451",
        title: "P253C",
        desc: "Sense Circuit Low",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "452",
        title: "P253D",
        desc: "Sense Circuit High",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "453",
        title: "P253E",
        desc: "Sense Circuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "454",
        title: "P253F",
        desc: "Oil Deteriorated ",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "455",
        title: "P2540",
        desc: "Pressure Fuel System Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "456",
        title: "P2542",
        desc: "Pressure Fuel System Sensor Circuit High ",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "457",
        title: "P2543",
        desc: "Pressure Fuel System Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "458",
        title: "P254A",
        desc: "Speed Selector Sensor/Switch 1 Circuit/Open",
        created_at: "2022-08-18 19:36:08",
        updated_at: "2022-08-18 19:36:08",
    },
    {
        vehicle_error_id: "459",
        title: "P254B",
        desc: "Speed Selector Sensor/Switch 1 Range/Performance ",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "460",
        title: "P254C",
        desc: "Speed Selector Sensor/Switch 1 Circuit Low",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "461",
        title: "P2556",
        desc: "Coolant Level Sensor/Switch Circuit",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "462",
        title: "P2558",
        desc: "Coolant Level Sensor/Switch Circuit Low",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "463",
        title: "P255D",
        desc: "Speed Selector Sensor/Switch 2 Circuit High",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "464",
        title: "P255E",
        desc: "Speed Selector Sensor/Switch 2 Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "465",
        title: "P2560",
        desc: "Coolant Level Low ",
        created_at: "2022-08-18 19:36:09",
        updated_at: "2022-08-18 19:36:09",
    },
    {
        vehicle_error_id: "466",
        title: "P2563",
        desc: "Boost Control Position SensorACircuit Range/Performance ",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "467",
        title: "P2564",
        desc: "Boost Control Position SensorACircuit Low",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "468",
        title: "P2566",
        desc: "Boost Control Position SensorACircuit Intermittent",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "469",
        title: "P2586",
        desc: "Boost Control Position SensorBCircuit",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "470",
        title: "P2588",
        desc: "Boost Control Position SensorBCircuit Low ",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "471",
        title: "P2589",
        desc: "Turbocharger Boost Control Position SensorBCircuit High",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "472",
        title: "P2590",
        desc: "Boost Control Position SensorBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "473",
        title: "P240D,",
        desc: "P240E, P240F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "474",
        title: "P241A,",
        desc: "P241B, P241C, P241D, P241E, P241F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "475",
        title: "P2425",
        desc: "Gas Recirculation Cooling Valve Control Circuit/Open",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "476",
        title: "P2426",
        desc: "Gas Recirculation Cooling Valve Control Circuit Low",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "477",
        title: "P2427",
        desc: "Gas Recirculation Cooling Valve Control Circuit High",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "478",
        title: "P242F",
        desc: "Diesel Particulate Filter Restriction - Ash Accumulation ",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "479",
        title: "P2430",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit Bank 1 ",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "480",
        title: "P2431",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit Range/Performance Bank 1 ",
        created_at: "2022-08-18 19:36:10",
        updated_at: "2022-08-18 19:36:10",
    },
    {
        vehicle_error_id: "481",
        title: "P2432",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit Low Bank 1 ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "482",
        title: "P2433",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit High Bank 1",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "483",
        title: "P2435",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit Bank 2",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "484",
        title: "P2437",
        desc: "Air Injection System Air Flow/Pressure Sensor Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "485",
        title: "P2452",
        desc: "Particulate Filter Pressure SensorACircuit",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "486",
        title: "P2453",
        desc: "Particulate Filter Pressure SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "487",
        title: "P2454",
        desc: "Particulate Filter Pressure SensorACircuit Low",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "488",
        title: "P2455",
        desc: "Particulate Filter Pressure SensorACircuit High ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "489",
        title: "P2456",
        desc: "Particulate Filter Pressure SensorACircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "490",
        title: "P2457",
        desc: "Gas Recirculation Cooling System Performance ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "491",
        title: "P2458",
        desc: "Particulate Filter Regeneration Duration ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "492",
        title: "P2459",
        desc: "Particulate Filter Regeneration Frequency ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "493",
        title: "P245A",
        desc: "Gas Recirculation Cooler Bypass Control Circuit/Open ",
        created_at: "2022-08-18 19:36:11",
        updated_at: "2022-08-18 19:36:11",
    },
    {
        vehicle_error_id: "494",
        title: "P245B",
        desc: "Gas Recirculation Cooler Bypass Control Circuit Range/Performance",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "495",
        title: "P245C",
        desc: "Gas Recirculation Cooler Bypass Control Circuit Low",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "496",
        title: "P245E",
        desc: "Particulate Filter Pressure SensorBCircuit ",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "497",
        title: "P245F",
        desc: "Particulate Filter Pressure SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "498",
        title: "P2461",
        desc: "Particulate Filter Pressure SensorBCircuit High ",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "499",
        title: "P2462",
        desc: "Particulate Filter Pressure SensorBCircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "500",
        title: "P2463",
        desc: "Particulate Filter Restriction - Soot Accumulation",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "501",
        title: "P2478",
        desc: "Gas Temperature Out of Range Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "502",
        title: "P2479",
        desc: "Gas Temperature Out of Range Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "503",
        title: "P247A",
        desc: "Gas Temperature Out of Range Bank 1 Sensor 3",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "504",
        title: "P247B",
        desc: "Gas Temperature Out of Range Bank 1 Sensor 4",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "505",
        title: "P247C",
        desc: "Gas Temperature Out of Range Bank 2 Sensor 1 ",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "506",
        title: "P247D",
        desc: "Gas Temperature Out of Range Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:12",
        updated_at: "2022-08-18 19:36:12",
    },
    {
        vehicle_error_id: "507",
        title: "P247E",
        desc: "Gas Temperature Out of Range Bank 2 Sensor 3",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "508",
        title: "P247F",
        desc: "Gas Temperature Out of Range Bank 2 Sensor 4",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "509",
        title: "P248A",
        desc: "P24FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "510",
        title: "P2300",
        desc: "Ignition CoilAPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "511",
        title: "P2301",
        desc: "Ignition CoilAPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "512",
        title: "P2302",
        desc: "Ignition CoilASecondary Circuit",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "513",
        title: "P2303",
        desc: "Ignition CoilBPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "514",
        title: "P2304",
        desc: "Ignition CoilBPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "515",
        title: "P2305",
        desc: "Ignition CoilBSecondary Circuit",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "516",
        title: "P2306",
        desc: "Ignition CoilCPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "517",
        title: "P2307",
        desc: "Ignition CoilCPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "518",
        title: "P2308",
        desc: "Ignition CoilCSecondary Circuit",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "519",
        title: "P2309",
        desc: "Ignition CoilDPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "520",
        title: "P2310",
        desc: "Ignition CoilDPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "521",
        title: "P2311",
        desc: "Ignition CoilDSecondary Circuit",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "522",
        title: "P2312",
        desc: "Ignition CoilEPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:13",
        updated_at: "2022-08-18 19:36:13",
    },
    {
        vehicle_error_id: "523",
        title: "P2313",
        desc: "Ignition CoilEPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "524",
        title: "P2314",
        desc: "Ignition CoilESecondary Circuit",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "525",
        title: "P2315",
        desc: "Ignition CoilFPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "526",
        title: "P2316",
        desc: "Ignition CoilFPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "527",
        title: "P2317",
        desc: "Ignition CoilFSecondary Circuit",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "528",
        title: "P2318",
        desc: "Ignition CoilGPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "529",
        title: "P2319",
        desc: "Ignition CoilGPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "530",
        title: "P231A,",
        desc: "P231B, P231C, P231D, P231E, P231F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "531",
        title: "P2320",
        desc: "Ignition CoilGSecondary Circuit",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "532",
        title: "P2321",
        desc: "Ignition CoilHPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "533",
        title: "P2322",
        desc: "Ignition CoilHPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "534",
        title: "P2323",
        desc: "Ignition CoilHSecondary Circuit",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "535",
        title: "P2324",
        desc: "Ignition CoilIPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:14",
        updated_at: "2022-08-18 19:36:14",
    },
    {
        vehicle_error_id: "536",
        title: "P2325",
        desc: "Ignition CoilIPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "537",
        title: "P2326",
        desc: "Ignition CoilISecondary Circuit",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "538",
        title: "P2327",
        desc: "Ignition CoilJPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "539",
        title: "P2328",
        desc: "Ignition CoilJPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "540",
        title: "P2329",
        desc: "Ignition CoilJSecondary Circuit",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "541",
        title: "P2330",
        desc: "Ignition CoilKPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "542",
        title: "P2331",
        desc: "Ignition CoilKPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "543",
        title: "P2332",
        desc: "Ignition CoilKSecondary Circuit",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "544",
        title: "P2333",
        desc: "Ignition CoilLPrimary Control Circuit Low",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "545",
        title: "P2334",
        desc: "Ignition CoilLPrimary Control Circuit High",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "546",
        title: "P2335",
        desc: "Ignition CoilLSecondary Circuit",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "547",
        title: "P2336",
        desc: "1 Above Knock Threshold",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "548",
        title: "P2337",
        desc: "2 Above Knock Threshold",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "549",
        title: "P2338",
        desc: "3 Above Knock Threshold",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "550",
        title: "P2339",
        desc: "4 Above Knock Threshold",
        created_at: "2022-08-18 19:36:15",
        updated_at: "2022-08-18 19:36:15",
    },
    {
        vehicle_error_id: "551",
        title: "P233A,",
        desc: "P233B, P233C, P233D, P233E, P233F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "552",
        title: "P2340",
        desc: "5 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "553",
        title: "P2341",
        desc: "6 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "554",
        title: "P2342",
        desc: "7 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "555",
        title: "P2343",
        desc: "8 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "556",
        title: "P2344",
        desc: "9 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "557",
        title: "P2345",
        desc: "10 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "558",
        title: "P2346",
        desc: "11 Above Knock Threshold",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "559",
        title: "P2347",
        desc: "12 Above Knock Threshold ",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "560",
        title: "P2348",
        desc: "P23FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "561",
        title: "P2200",
        desc: "Sensor Circuit Bank 1 ",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "562",
        title: "P2201",
        desc: "Sensor Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "563",
        title: "P2202",
        desc: "Sensor Circuit Low Bank 1 ",
        created_at: "2022-08-18 19:36:16",
        updated_at: "2022-08-18 19:36:16",
    },
    {
        vehicle_error_id: "564",
        title: "P2203",
        desc: "Sensor Circuit High Bank 1 ",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "565",
        title: "P2204",
        desc: "Sensor Circuit Intermittent Bank 1",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "566",
        title: "P2205",
        desc: "Sensor Heater Control Circuit/Open Bank 1 ",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "567",
        title: "P2206",
        desc: "Sensor Heater Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "568",
        title: "P2209",
        desc: "Sensor Heater Sense Circuit Range/Performance Bank 1 ",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "569",
        title: "P220A,",
        desc: "P220B, P220C, P220D, P220E, P220F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "570",
        title: "P2211",
        desc: "Sensor Heater Sense Circuit High Bank 1",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "571",
        title: "P2212",
        desc: "Sensor Heater Sense Circuit Intermittent Bank 1",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "572",
        title: "P2213",
        desc: "Sensor Circuit Bank 2",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "573",
        title: "P2214",
        desc: "Sensor Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "574",
        title: "P2219",
        desc: "Sensor Heater Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "575",
        title: "P221A,",
        desc: "P221B, P221C, P221D, P221E, P221F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:17",
        updated_at: "2022-08-18 19:36:17",
    },
    {
        vehicle_error_id: "576",
        title: "P2222",
        desc: "Sensor Heater Sense Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "577",
        title: "P2225",
        desc: "Sensor Heater Sense Circuit Intermittent Bank 2",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "578",
        title: "P2226",
        desc: "Pressure SensorACircuit ",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "579",
        title: "P2227",
        desc: "Pressure SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "580",
        title: "P222A",
        desc: "Pressure SensorBCircuit",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "581",
        title: "P222E",
        desc: "Pressure SensorBCircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "582",
        title: "P222F",
        desc: "Pressure SensorA / BCorrelation",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "583",
        title: "P2230",
        desc: "Pressure SensorACircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "584",
        title: "P2231",
        desc: "Sensor Signal Circuit Shorted to Heater Circuit Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "585",
        title: "P2234",
        desc: "Sensor Signal Circuit Shorted to Heater Circuit Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "586",
        title: "P2235",
        desc: "Sensor Signal Circuit Shorted to Heater Circuit Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "587",
        title: "P2237",
        desc: "Sensor Positive Current Control Circuit/Open Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:18",
        updated_at: "2022-08-18 19:36:18",
    },
    {
        vehicle_error_id: "588",
        title: "P2241",
        desc: "Sensor Positive Current Control Circuit Low Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "589",
        title: "P2242",
        desc: "Sensor Positive Current Control Circuit High Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "590",
        title: "P2243",
        desc: "Sensor Reference Voltage Circuit/Open Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "591",
        title: "P2244",
        desc: "Sensor Reference Voltage Performance Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "592",
        title: "P2245",
        desc: "Sensor Reference Voltage Circuit Low Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "593",
        title: "P2246",
        desc: "Sensor Reference Voltage Circuit High Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "594",
        title: "P2247",
        desc: "Sensor Reference Voltage Circuit/Open Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "595",
        title: "P2248",
        desc: "Sensor Reference Voltage Performance Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "596",
        title: "P2249",
        desc: "Sensor Reference Voltage Circuit Low Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "597",
        title: "P224A,",
        desc: "P224B, P224C, P224D, P224E, P224F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "598",
        title: "P2250",
        desc: "Sensor Reference Voltage Circuit High Bank 2 Sensor 1 ",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "599",
        title: "P2251",
        desc: "Sensor Negative Current Control Circuit/Open Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "600",
        title: "P2252",
        desc: "Sensor Negative Current Control Circuit Low Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "601",
        title: "P2253",
        desc: "Sensor Negative Current Control Circuit High Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "602",
        title: "P2254",
        desc: "Sensor Negative Current Control Circuit/Open Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "603",
        title: "P2255",
        desc: "Sensor Negative Current Control Circuit Low Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "604",
        title: "P2256",
        desc: "Sensor Negative Current Control Circuit High Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "605",
        title: "P2257",
        desc: "Air Injection System ControlACircuit Low",
        created_at: "2022-08-18 19:36:19",
        updated_at: "2022-08-18 19:36:19",
    },
    {
        vehicle_error_id: "606",
        title: "P2258",
        desc: "Air Injection System ControlACircuit High",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "607",
        title: "P2259",
        desc: "Air Injection System ControlBCircuit Low",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "608",
        title: "P225A,",
        desc: "P225B, P225C, P225D, P225E, P225F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "609",
        title: "P2260",
        desc: "Air Injection System ControlBCircuit High",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "610",
        title: "P2263/Supercharger",
        desc: "Boost System Performance",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "611",
        title: "P2265",
        desc: "in Fuel Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "612",
        title: "P2266",
        desc: "in Fuel Sensor Circuit Low",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "613",
        title: "P2267",
        desc: "in Fuel Sensor Circuit High",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "614",
        title: "P2268",
        desc: "in Fuel Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "615",
        title: "P226A",
        desc: "in Fuel Lamp Control Circuit ",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "616",
        title: "P2271",
        desc: "Sensor Signal Biased/Stuck Rich Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "617",
        title: "P2273",
        desc: "Sensor Signal Biased/Stuck Rich Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "618",
        title: "P2274",
        desc: "Sensor Signal Biased/Stuck Lean Bank 1 Sensor 3",
        created_at: "2022-08-18 19:36:20",
        updated_at: "2022-08-18 19:36:20",
    },
    {
        vehicle_error_id: "619",
        title: "P227A,",
        desc: "P227B, P227C, P227D, P227E, P227F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "620",
        title: "P2280",
        desc: "Flow Restriction/Air Leak Between Air Filter and MAF",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "621",
        title: "P2283",
        desc: "Control Pressure Sensor Circuit",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "622",
        title: "P2284",
        desc: "Control Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "623",
        title: "P2287",
        desc: "Control Pressure Sensor Circuit Intermittent ",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "624",
        title: "P2288",
        desc: "Control Pressure Too High",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "625",
        title: "P2289",
        desc: "Control Pressure Too High - Engine Off",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "626",
        title: "P228C",
        desc: "Pressure Regulator 1 Exceeded Control Limits - Pressure Too Low",
        created_at: "2022-08-18 19:36:21",
        updated_at: "2022-08-18 19:36:21",
    },
    {
        vehicle_error_id: "627",
        title: "P228F",
        desc: "Pressure Regulator 1 Exceeded Learning Limits - Too High",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "628",
        title: "P2290",
        desc: "Control Pressure Too Low",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "629",
        title: "P2291",
        desc: "Control Pressure Too Low - Engine Cranking",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "630",
        title: "P229A",
        desc: "Pressure Regulator 2 Exceeded Control Limits - Pressure Too Low",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "631",
        title: "P229C",
        desc: "Pressure Regulator 2 Exceeded Learning Limits - Too Low",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "632",
        title: "P229E",
        desc: "P22FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "633",
        title: "P2100",
        desc: "ActuatorAControl Motor Circuit/Open",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "634",
        title: "P2101",
        desc: "ActuatorAControl Motor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "635",
        title: "P2102",
        desc: "ActuatorAControl Motor Circuit Low",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "636",
        title: "P2103",
        desc: "ActuatorAControl Motor Circuit High",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "637",
        title: "P2104",
        desc: "Actuator Control System - Forced Idle",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "638",
        title: "P2105",
        desc: "Actuator Control System - Forced Engine Shutdown",
        created_at: "2022-08-18 19:36:22",
        updated_at: "2022-08-18 19:36:22",
    },
    {
        vehicle_error_id: "639",
        title: "P2107",
        desc: "Actuator Control Module Processor",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "640",
        title: "P2108",
        desc: "Actuator Control Module Performance",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "641",
        title: "P210A",
        desc: "ActuatorBControl Motor Circuit/Open",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "642",
        title: "P210C",
        desc: "ActuatorBControl Motor Circuit Low ",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "643",
        title: "P210D",
        desc: "ActuatorBControl Motor Circuit High",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "644",
        title: "P210E/Pedal",
        desc: "Position Sensor/SwitchC / FVoltage Correlation",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "645",
        title: "P210F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "646",
        title: "P2110",
        desc: "Actuator Control System - Forced Limited RPM ",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "647",
        title: "P2111",
        desc: "Actuator Control System - Stuck Open",
        created_at: "2022-08-18 19:36:23",
        updated_at: "2022-08-18 19:36:23",
    },
    {
        vehicle_error_id: "648",
        title: "P2112",
        desc: "Actuator Control System - Stuck Closed",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "649",
        title: "P2113/Pedal",
        desc: "Position SensorBMinimum Stop Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "650",
        title: "P2114/Pedal",
        desc: "Position SensorCMinimum Stop Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "651",
        title: "P2115/Pedal",
        desc: "Position SensorDMinimum Stop Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "652",
        title: "P2116/Pedal",
        desc: "Position SensorEMinimum Stop Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "653",
        title: "P2118",
        desc: "Actuator Control Motor Current Range/Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "654",
        title: "P211A,",
        desc: "P211B, P211C, P211D, P211E, P211F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "655",
        title: "P2120/Pedal",
        desc: "Position Sensor/SwitchDCircuit",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "656",
        title: "P2126/Pedal",
        desc: "Position Sensor/SwitchECircuit Range/Performance",
        created_at: "2022-08-18 19:36:24",
        updated_at: "2022-08-18 19:36:24",
    },
    {
        vehicle_error_id: "657",
        title: "P212A",
        desc: "Position Sensor/SwitchGCircuit",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "658",
        title: "P212D",
        desc: "Position Sensor/SwitchGCircuit High",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "659",
        title: "P2130/Pedal",
        desc: "Position Sensor/SwitchFCircuit",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "660",
        title: "P2132/Pedal",
        desc: "Position Sensor/SwitchFCircuit Low",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "661",
        title: "P2135",
        desc: "Throttle/Pedal Pos Sensor/Switch A / B Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "662",
        title: "P2136",
        desc: "Throttle/Pedal Pos Sensor/Switch A / C Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "663",
        title: "P2137",
        desc: "Throttle/Pedal Pos Sensor/Switch B / C Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "664",
        title: "P2138",
        desc: "Throttle/Pedal Pos Sensor/Switch D / E Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "665",
        title: "P2139",
        desc: "Throttle/Pedal Pos Sensor/Switch D / F Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "666",
        title: "P213A",
        desc: "Gas Recirculation Throttle Control CircuitB/Open ",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "667",
        title: "P213B",
        desc: "Gas Recirculation Throttle Control CircuitBRange/Performance",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "668",
        title: "P2140",
        desc: "Throttle/Pedal Pos Sensor/Switch E / F Voltage Correlation",
        created_at: "2022-08-18 19:36:25",
        updated_at: "2022-08-18 19:36:25",
    },
    {
        vehicle_error_id: "669",
        title: "P2141",
        desc: "Gas Recirculation Throttle Control CircuitALow",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "670",
        title: "P2142",
        desc: "Gas Recirculation Throttle Control CircuitAHigh",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "671",
        title: "P2143",
        desc: "Gas Recirculation Vent Control Circuit/Open ",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "672",
        title: "P2144",
        desc: "Gas Recirculation Vent Control Circuit Low",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "673",
        title: "P2145",
        desc: "Gas Recirculation Vent Control Circuit High",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "674",
        title: "P2147",
        desc: "Injector GroupASupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "675",
        title: "P2149",
        desc: "Injector GroupBSupply Voltage Circuit/Open",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "676",
        title: "P214A,",
        desc: "P214B, P214C, P214D, P214E, P214F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "677",
        title: "P2150",
        desc: "Injector GroupBSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "678",
        title: "P2151",
        desc: "Injector GroupBSupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "679",
        title: "P2153",
        desc: "Injector GroupCSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "680",
        title: "P2154",
        desc: "Injector GroupCSupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "681",
        title: "P2159",
        desc: "Speed SensorBRange/Performance",
        created_at: "2022-08-18 19:36:26",
        updated_at: "2022-08-18 19:36:26",
    },
    {
        vehicle_error_id: "682",
        title: "P215B",
        desc: "Speed - Output Shaft Speed Correlation",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "683",
        title: "P215D,",
        desc: "P215E, P215F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "684",
        title: "P2160",
        desc: "Speed SensorBCircuit Low",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "685",
        title: "P2163/Pedal",
        desc: "Position SensorAMaximum Stop Performance",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "686",
        title: "P2164/Pedal",
        desc: "Position SensorBMaximum Stop Performance",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "687",
        title: "P2165/Pedal",
        desc: "Position SensorCMaximum Stop Performance",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "688",
        title: "P2167/Pedal",
        desc: "Position SensorEMaximum Stop Performance",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "689",
        title: "P216C",
        desc: "Injector GroupESupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "690",
        title: "P216E",
        desc: "Injector GroupFSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "691",
        title: "P216F",
        desc: "Injector GroupFSupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "692",
        title: "P2172",
        desc: "Actuator Control System - Sudden High Air Flow Detected ",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "693",
        title: "P2173",
        desc: "Actuator Control System - High Air Flow Detected",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "694",
        title: "P2174",
        desc: "Actuator Control System - Sudden Low Air Flow Detected ",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "695",
        title: "P2175",
        desc: "Actuator Control System - Low Air Flow Detected",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "696",
        title: "P2177",
        desc: "System Too Lean Off Idle Bank 1",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "697",
        title: "P2178",
        desc: "System Too Rich Off Idle Bank 1",
        created_at: "2022-08-18 19:36:27",
        updated_at: "2022-08-18 19:36:27",
    },
    {
        vehicle_error_id: "698",
        title: "P2179",
        desc: "System Too Lean Off Idle Bank 2",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "699",
        title: "P217B",
        desc: "Injector GroupGSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "700",
        title: "P217C",
        desc: "Injector GroupGSupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "701",
        title: "P217D",
        desc: "Injector GroupHSupply Voltage Circuit/Open",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "702",
        title: "P217E",
        desc: "Injector GroupHSupply Voltage Circuit Low",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "703",
        title: "P217F",
        desc: "Injector GroupHSupply Voltage Circuit High",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "704",
        title: "P2180",
        desc: "System Too Rich Off Idle Bank 2",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "705",
        title: "P2181",
        desc: "Cooling System Performance",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "706",
        title: "P2182",
        desc: "Engine Coolant Temperature Sensor 2 Circuit",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "707",
        title: "P2183",
        desc: "Engine Coolant Temperature Sensor 2 Circuit Range/Performance",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "708",
        title: "P2184",
        desc: "Engine Coolant Temperature Sensor 2 Circuit Low",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "709",
        title: "P2185",
        desc: "Engine Coolant Temperature Sensor 2 Circuit High",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "710",
        title: "P2186",
        desc: "Engine Coolant Temperature Sensor 2 Circuit Interm/Erratic",
        created_at: "2022-08-18 19:36:28",
        updated_at: "2022-08-18 19:36:28",
    },
    {
        vehicle_error_id: "711",
        title: "P2187",
        desc: "System Too Lean at Idle Bank 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "712",
        title: "P2188",
        desc: "System Too Rich at Idle Bank 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "713",
        title: "P2189",
        desc: "System Too Lean at Idle Bank 2",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "714",
        title: "P218A,",
        desc: "P218B, P218C, P218D, P218E, P218F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "715",
        title: "P2190",
        desc: "System Too Rich at Idle Bank 2",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "716",
        title: "P2191",
        desc: "System Too Lean at Higher Load Bank 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "717",
        title: "P2192",
        desc: "System Too Rich at Higher Load Bank 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "718",
        title: "P2193",
        desc: "System Too Lean at Higher Load Bank 2",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "719",
        title: "P2194",
        desc: "System Too Rich at Higher Load Bank 2",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "720",
        title: "P2195",
        desc: "O2 Sensor Signal Stuck Lean Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "721",
        title: "P2196",
        desc: "O2 Sensor Signal Stuck Rich Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "722",
        title: "P2197",
        desc: "O2 Sensor Signal Stuck Lean Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "723",
        title: "P2198",
        desc: "O2 Sensor Signal Stuck Rich Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:29",
        updated_at: "2022-08-18 19:36:29",
    },
    {
        vehicle_error_id: "724",
        title: "P2199",
        desc: "Air Temperature Sensor 1/2 Correlation",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "725",
        title: "P219A",
        desc: "Bank 1 Air/Fuel Ratio Imbalance",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "726",
        title: "P219B",
        desc: "Bank 2 Air/Fuel Ratio Imbalance",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "727",
        title: "P219C",
        desc: "Cylinder #1 Imbalance Error",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "728",
        title: "P219D",
        desc: "Cylinder #2 Imbalance Error",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "729",
        title: "P219E",
        desc: "Cylinder #3 Imbalance Error",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "730",
        title: "P219F",
        desc: "Cylinder #4 Imbalance Error",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "731",
        title: "P21AA",
        desc: "P21FF ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "732",
        title: "P2000",
        desc: "NOx Adsorber Efficiency Below Threshold Bank 1",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "733",
        title: "P2001",
        desc: "NOx Adsorber Efficiency Below Threshold Bank 2 ",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "734",
        title: "P2002",
        desc: "Diesel Particulate Filter Efficiency Below Threshold Bank 1",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "735",
        title: "P2003",
        desc: "Diesel Particulate Filter Efficiency Below Threshold Bank 2",
        created_at: "2022-08-18 19:36:30",
        updated_at: "2022-08-18 19:36:30",
    },
    {
        vehicle_error_id: "736",
        title: "P2004",
        desc: "Intake Manifold Runner Control Stuck Open Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "737",
        title: "P2005",
        desc: "Intake Manifold Runner Control Stuck Open Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "738",
        title: "P2006",
        desc: "Intake Manifold Runner Control Stuck Closed Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "739",
        title: "P2007",
        desc: "Intake Manifold Runner Control Stuck Closed Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "740",
        title: "P2008",
        desc: "Intake Manifold Runner Control Circuit/Open Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "741",
        title: "P2009",
        desc: "Intake Manifold Runner Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "742",
        title: "P200A",
        desc: "Intake Manifold Runner Performance Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "743",
        title: "P200B",
        desc: "Intake Manifold Runner Performance Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "744",
        title: "P200C",
        desc: "Diesel Particulate Filter Over Temperature Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "745",
        title: "P200D",
        desc: "Diesel Particulate Filter Over Temperature Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "746",
        title: "P200E",
        desc: "Catalyst System Over Temperature Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "747",
        title: "P200F",
        desc: "Catalyst System Over Temperature Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "748",
        title: "P2010",
        desc: "Intake Manifold Runner Control Circuit High Bank 1",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "749",
        title: "P2011",
        desc: "Intake Manifold Runner Control Circuit/Open Bank 2",
        created_at: "2022-08-18 19:36:31",
        updated_at: "2022-08-18 19:36:31",
    },
    {
        vehicle_error_id: "750",
        title: "P2012",
        desc: "Intake Manifold Runner Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "751",
        title: "P2013",
        desc: "Intake Manifold Runner Control Circuit High Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "752",
        title: "P2014",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Bank 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "753",
        title: "P2015",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Range/Performance Bank 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "754",
        title: "P2016",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Low Bank 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "755",
        title: "P2017",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit High Bank 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "756",
        title: "P2018",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Intermittent Bank 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "757",
        title: "P2019",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "758",
        title: "P201A",
        desc: "Injection Valve Circuit Range/Performance Bank 2 Unit 1",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "759",
        title: "P201B,",
        desc: "P201C, P201D, P201E, P201F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "760",
        title: "P2020",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Range/Performance Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "761",
        title: "P2021",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "762",
        title: "P2022",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit High Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "763",
        title: "P2023",
        desc: "Intake Manifold Runner Position Sensor/Switch Circuit Intermittent Bank 2",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "764",
        title: "P2024",
        desc: "Evaporative Emissions (EVAP) Fuel Vapor Temperature Sensor Circuit",
        created_at: "2022-08-18 19:36:32",
        updated_at: "2022-08-18 19:36:32",
    },
    {
        vehicle_error_id: "765",
        title: "P2025",
        desc: "Evaporative Emissions (EVAP) Fuel Vapor Temperature Sensor Performance",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "766",
        title: "P2026",
        desc: "Evaporative Emissions (EVAP) Fuel Vapor Temperature Sensor Circuit Low Voltage",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "767",
        title: "P2027",
        desc: "Evaporative Emissions (EVAP) Fuel Vapor Temperature Sensor Circuit High Voltage",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "768",
        title: "P2028",
        desc: "Evaporative Emissions (EVAP) Fuel Vapor Temperature Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "769",
        title: "P2029",
        desc: "Fired Heater Disabled",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "770",
        title: "P202A",
        desc: "Tank Heater Control Circuit/Open",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "771",
        title: "P202B",
        desc: "Tank Heater Control Circuit Low",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "772",
        title: "P202C",
        desc: "Tank Heater Control Circuit High",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "773",
        title: "P202D",
        desc: "Leakage",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "774",
        title: "P202E",
        desc: "Injection Valve Circuit Range/Performance Bank 1 Unit 1",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "775",
        title: "P202F/Regeneration",
        desc: "Supply Control Circuit Range/Performance",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "776",
        title: "P2030",
        desc: "Fired Heater Performance",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "777",
        title: "P2031",
        desc: "Exhaust Gas Temperature Sensor Circuit Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "778",
        title: "P2032",
        desc: "Exhaust Gas Temperature Sensor Circuit Low Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "779",
        title: "P2033",
        desc: "Exhaust Gas Temperature Sensor Circuit High Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "780",
        title: "P2034",
        desc: "Exhaust Gas Temperature Sensor Circuit Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "781",
        title: "P2035",
        desc: "Exhaust Gas Temperature Sensor Circuit Low Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "782",
        title: "P2036",
        desc: "Exhaust Gas Temperature Sensor Circuit High Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:33",
        updated_at: "2022-08-18 19:36:33",
    },
    {
        vehicle_error_id: "783",
        title: "P2037",
        desc: "Injection Air Pressure SensorACircuit",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "784",
        title: "P2038",
        desc: "Injection Air Pressure SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "785",
        title: "P2039",
        desc: "Injection Air Pressure SensorACircuit Low",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "786",
        title: "P203A",
        desc: "Reductant Level Sensor Circuit",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "787",
        title: "P203B",
        desc: "Reductant Level Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "788",
        title: "P203C",
        desc: "Reductant Level Sensor Circuit Low",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "789",
        title: "P203D",
        desc: "Reductant Level Sensor Circuit High",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "790",
        title: "P203E",
        desc: "Reductant Level Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "791",
        title: "P203F",
        desc: "Reductant Level Too Low",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "792",
        title: "P2040",
        desc: "Injection Air Pressure SensorACircuit High",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "793",
        title: "P2041",
        desc: "Injection Air Pressure SensorACircuit Intermittent",
        created_at: "2022-08-18 19:36:34",
        updated_at: "2022-08-18 19:36:34",
    },
    {
        vehicle_error_id: "794",
        title: "P2042",
        desc: "Temperature Sensor Circuit",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "795",
        title: "P2043",
        desc: "Temperature Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "796",
        title: "P2044",
        desc: "Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "797",
        title: "P2045",
        desc: "Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "798",
        title: "P2046",
        desc: "Temperature Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "799",
        title: "P2047",
        desc: "Reductant Injection Valve Circuit/Open Bank 1 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "800",
        title: "P2048",
        desc: "Reductant Injection Valve Circuit Low Bank 1 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "801",
        title: "P2049",
        desc: "Reductant Injection Valve Circuit High Bank 1 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "802",
        title: "P204A",
        desc: "Pressure Sensor Circuit",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "803",
        title: "P204B",
        desc: "Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "804",
        title: "P204C",
        desc: "Pressure Sensor Circuit Low",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "805",
        title: "P204D",
        desc: "Pressure Sensor Circuit High",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "806",
        title: "P204E",
        desc: "Pressure Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "807",
        title: "P204F",
        desc: "System Performance Bank 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "808",
        title: "P2050",
        desc: "Reductant Injection Valve Circuit/Open Bank 2 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "809",
        title: "P2051",
        desc: "Reductant Injection Valve Circuit Low Bank 2 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "810",
        title: "P2052",
        desc: "Reductant Injection Valve Circuit High Bank 2 Unit 1",
        created_at: "2022-08-18 19:36:35",
        updated_at: "2022-08-18 19:36:35",
    },
    {
        vehicle_error_id: "811",
        title: "P2053",
        desc: "Reductant Injection Valve Circuit/Open Bank 1 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "812",
        title: "P2054",
        desc: "Reductant Injection Valve Circuit Low Bank 1 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "813",
        title: "P2055",
        desc: "Reductant Injection Valve Circuit High Bank 1 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "814",
        title: "P2056",
        desc: "Reductant Injection Valve Circuit/Open Bank 2 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "815",
        title: "P2057",
        desc: "Reductant Injection Valve Circuit Low Bank 2 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "816",
        title: "P2058",
        desc: "Reductant Injection Valve Circuit High Bank 2 Unit 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "817",
        title: "P2059",
        desc: "Injection Air Pump Control Circuit/Open",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "818",
        title: "P205A",
        desc: "Tank Temperature Sensor Circuit",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "819",
        title: "P205B",
        desc: "Tank Temperature Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "820",
        title: "P205C",
        desc: "Tank Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "821",
        title: "P205D",
        desc: "Tank Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "822",
        title: "P205E",
        desc: "Tank Temperature Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "823",
        title: "P205F",
        desc: "System Performance Bank 2",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "824",
        title: "P2060",
        desc: "Injection Air Pump Control Circuit Low",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "825",
        title: "P2061",
        desc: "Injection Air Pump Control Circuit High",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "826",
        title: "P2062/Regeneration",
        desc: "Supply Control Circuit/Open",
        created_at: "2022-08-18 19:36:36",
        updated_at: "2022-08-18 19:36:36",
    },
    {
        vehicle_error_id: "827",
        title: "P2063/Regeneration",
        desc: "Supply Control Circuit Low",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "828",
        title: "P2064/Regeneration",
        desc: "Supply Control Circuit High",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "829",
        title: "P2065",
        desc: "Fuel Level SensorBCircuit",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "830",
        title: "P2066",
        desc: "Fuel Level SensorBCircuit Performance",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "831",
        title: "P2067",
        desc: "Fuel Level SensorBCircuit Low",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "832",
        title: "P2068",
        desc: "Fuel Level SensorBCircuit High",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "833",
        title: "P2069",
        desc: "Fuel Level SensorBCircuit Intermittent",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "834",
        title: "P206E",
        desc: "Manifold Tuning (IMT) Valve Stuck Open Bank 2",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "835",
        title: "P206F",
        desc: "Manifold Tuning (IMT) Valve Stuck Closed Bank 2",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "836",
        title: "P2070",
        desc: "Manifold Tuning (IMT) Valve Stuck Open Bank 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "837",
        title: "P2071",
        desc: "Manifold Tuning (IMT) Valve Stuck Closed Bank 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "838",
        title: "P2080",
        desc: "Exhaust Gas Temperature Sensor Circuit Range/Performance Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "839",
        title: "P2081",
        desc: "Exhaust Gas Temperature Sensor Circuit Intermittent Bank 1 Sensor 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "840",
        title: "P2082",
        desc: "Exhaust Gas Temperature Sensor Circuit Range/Performance Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "841",
        title: "P2083",
        desc: "Exhaust Gas Temperature Sensor Circuit Intermittent Bank 2 Sensor 1",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "842",
        title: "P2084",
        desc: "Exhaust Gas Temperature Sensor Circuit Range/Performance Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "843",
        title: "P2085",
        desc: "Exhaust Gas Temperature Sensor Circuit Intermittent Bank 1 Sensor 2",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "844",
        title: "P2086",
        desc: "Exhaust Gas Temperature Sensor Circuit Range/Performance Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:37",
        updated_at: "2022-08-18 19:36:37",
    },
    {
        vehicle_error_id: "845",
        title: "P2087",
        desc: "Exhaust Gas Temperature Sensor Circuit Intermittent Bank 2 Sensor 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "846",
        title: "P2088",
        desc: "A Camshaft Position Actuator Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "847",
        title: "P2089",
        desc: "A Camshaft Position Actuator Control Circuit High Bank 1",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "848",
        title: "P2090",
        desc: "B Camshaft Position Actuator Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "849",
        title: "P2091",
        desc: "B Camshaft Position Actuator Control Circuit High Bank 1",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "850",
        title: "P2092",
        desc: "A Camshaft Position Actuator Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "851",
        title: "P2093",
        desc: "A Camshaft Position Actuator Control Circuit High Bank 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "852",
        title: "P2094",
        desc: "B Camshaft Position Actuator Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "853",
        title: "P2095",
        desc: "B Camshaft Position Actuator Control Circuit High Bank 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "854",
        title: "P2096",
        desc: "Post Catalyst Fuel Trim System Too Lean Bank 1",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "855",
        title: "P2097",
        desc: "Post Catalyst Fuel Trim System Too Rich Bank 1 ",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "856",
        title: "P2098",
        desc: "Post Catalyst Fuel Trim System Too Lean Bank 2",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "857",
        title: "P2099",
        desc: "Post Catalyst Fuel Trim System Too Rich Bank 2 ",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "858",
        title: "P209A",
        desc: "Injection Air Pressure SensorBCircuit",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "859",
        title: "P209B",
        desc: "Injection Air Pressure SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "860",
        title: "P209C",
        desc: "Reductant Injection Air Pressure SensorBCircuit Low",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "861",
        title: "P209D",
        desc: "Reductant Injection Air Pressure SensorBCircuit High",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "862",
        title: "P209E",
        desc: "Reductant Injection Air Pressure SensorA / BCorrelation",
        created_at: "2022-08-18 19:36:38",
        updated_at: "2022-08-18 19:36:38",
    },
    {
        vehicle_error_id: "863",
        title: "P20A0",
        desc: "Reductant Purge Control Valve Circuit /Open",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "864",
        title: "P20A1",
        desc: "Reductant Purge Control Valve Performance",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "865",
        title: "P20A2",
        desc: "Reductant Purge Control Valve Circuit Low",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "866",
        title: "P20A3",
        desc: "Reductant Purge Control Valve Circuit High",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "867",
        title: "P20A4",
        desc: "Reductant Purge Control Valve Stuck Open",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "868",
        title: "P20A5",
        desc: "Reductant Purge Control Valve Stuck Closed",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "869",
        title: "P20B9",
        desc: "Reductant HeaterAControl Circuit/Open",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "870",
        title: "P20BA",
        desc: "Reductant HeaterAControl Circuit Performance",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "871",
        title: "P20BB",
        desc: "Reductant HeaterAControl Circuit Low",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "872",
        title: "P20BC",
        desc: "Reductant HeaterAControl Circuit High",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "873",
        title: "P20BD",
        desc: "Reductant HeaterBControl Circuit/Open",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "874",
        title: "P20BE",
        desc: "Reductant HeaterBControl Circuit Performance",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "875",
        title: "P20BF",
        desc: "Reductant HeaterBControl Circuit Low",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "876",
        title: "P20C0",
        desc: "Reductant HeaterBControl Circuit High",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "877",
        title: "P20C1",
        desc: "Reductant HeaterCControl Circuit/Open",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "878",
        title: "P20C2",
        desc: "Reductant HeaterCControl Circuit Performance",
        created_at: "2022-08-18 19:36:39",
        updated_at: "2022-08-18 19:36:39",
    },
    {
        vehicle_error_id: "879",
        title: "P20C3",
        desc: "Reductant HeaterCControl Circuit Low",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "880",
        title: "P20C4",
        desc: "Reductant HeaterCControl Circuit High",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "881",
        title: "P20C5",
        desc: "Reductant HeaterDControl Circuit/Open",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "882",
        title: "P20C6",
        desc: "Reductant HeaterDControl Circuit Performance",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "883",
        title: "P20C7",
        desc: "Reductant HeaterDControl Circuit Low",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "884",
        title: "P20C8",
        desc: "Reductant HeaterDControl Circuit High",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "885",
        title: "P20CB",
        desc: "Aftertreatment Fuel InjectorAControl Circuit/Open",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "886",
        title: "P20CC",
        desc: "Aftertreatment Fuel InjectorAControl Performance",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "887",
        title: "P20CD",
        desc: "Aftertreatment Fuel InjectorAControl Circuit Low",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "888",
        title: "P20CE",
        desc: "Aftertreatment Fuel InjectorAControl Circuit High",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "889",
        title: "P20CF",
        desc: "Aftertreatment Fuel InjectorAStuck Open",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "890",
        title: "P20D0",
        desc: "Aftertreatment Fuel InjectorAStuck Closed",
        created_at: "2022-08-18 19:36:40",
        updated_at: "2022-08-18 19:36:40",
    },
    {
        vehicle_error_id: "891",
        title: "P20D1",
        desc: "Aftertreatment Fuel InjectorBControl Circuit/Open",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "892",
        title: "P20D2",
        desc: "Aftertreatment Fuel InjectorBControl Performance",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "893",
        title: "P20D3",
        desc: "Aftertreatment Fuel InjectorBControl Circuit Low",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "894",
        title: "P20D4",
        desc: "Aftertreatment Fuel InjectorBControl Circuit High",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "895",
        title: "P20D5",
        desc: "Aftertreatment Fuel InjectorBStuck Open",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "896",
        title: "P20D6",
        desc: "Aftertreatment Fuel InjectorBStuck Closed",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "897",
        title: "P20D7",
        desc: "Aftertreatment Fuel Supply Control Circuit/Open",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "898",
        title: "P20D8",
        desc: "Aftertreatment Fuel Supply Control Performance",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "899",
        title: "P20D9",
        desc: "Aftertreatment Fuel Supply Control Circuit Low",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "900",
        title: "P20DA",
        desc: "Aftertreatment Fuel Supply Control Circuit High",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "901",
        title: "P20DB",
        desc: "Aftertreatment Fuel Supply Control Stuck Open",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "902",
        title: "P20DC",
        desc: "Aftertreatment Fuel Supply Control Stuck Closed",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "903",
        title: "P20DD",
        desc: "Aftertreatment Fuel Pressure Sensor Circuit",
        created_at: "2022-08-18 19:36:41",
        updated_at: "2022-08-18 19:36:41",
    },
    {
        vehicle_error_id: "904",
        title: "P20DE",
        desc: "Aftertreatment Fuel Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "905",
        title: "P20DF",
        desc: "Aftertreatment Fuel Pressure Sensor Circuit Low",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "906",
        title: "P20E0",
        desc: "Aftertreatment Fuel Pressure Sensor Circuit High",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "907",
        title: "P20E1",
        desc: "Aftertreatment Fuel Pressure Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "908",
        title: "P20E6",
        desc: "Injection Air Pressure Too Low",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "909",
        title: "P20E7",
        desc: "Injection Air Pressure Too High",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "910",
        title: "P20EC",
        desc: "SCR NOx Catalyst - Over Temperature Bank 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "911",
        title: "P20ED",
        desc: "SCR NOx Pre-Catalyst - Over Temperature Bank 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "912",
        title: "P20EE",
        desc: "SCR NOx Catalyst Efficiency Below Threshold Bank 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "913",
        title: "P20EF",
        desc: "SCR NOx Pre- Catalyst Efficiency Below Threshold Bank 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "914",
        title: "P20F0",
        desc: "SCR NOx Catalyst - Over Temperature Bank 2",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "915",
        title: "P20F1",
        desc: "SCR NOx Pre-Catalyst - Over Temperature Bank 2",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "916",
        title: "P20F2",
        desc: "SCR NOx Catalyst Efficiency Below Threshold Bank 2",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "917",
        title: "P20F3",
        desc: "SCR NOx Pre- Catalyst Efficiency Below Threshold Bank 2",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "918",
        title: "P20F6",
        desc: "Injection Valve Stuck Open Bank 1 Unit 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "919",
        title: "P20F7",
        desc: "Injection Valve Stuck Open Bank 2 Unit 1",
        created_at: "2022-08-18 19:36:42",
        updated_at: "2022-08-18 19:36:42",
    },
    {
        vehicle_error_id: "920",
        title: "P20F8",
        desc: "P20FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "921",
        title: "P0C2F",
        desc: "Control Module Drive Motor/Generator - Engine Speed Sensor Performance",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "922",
        title: "P0A7E",
        desc: "Battery Pack Over Temperature",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "923",
        title: "P0A7F",
        desc: "Battery Pack Deterioration",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "924",
        title: "P0900",
        desc: "Actuator Circuit/Open ",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "925",
        title: "P0901",
        desc: "Actuator Circuit Range/Performance",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "926",
        title: "P0902",
        desc: "Actuator Circuit Low",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "927",
        title: "P092E,",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "928",
        title: "P095A",
        desc: "ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "929",
        title: "P0962",
        desc: "Control SolenoidAControl Circuit Low",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "930",
        title: "P0963",
        desc: "Control SolenoidAControl Circuit High",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "931",
        title: "P0964",
        desc: "Control SolenoidBControl Circuit/Open ",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "932",
        title: "P0965",
        desc: "Control SolenoidBControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "933",
        title: "P0966",
        desc: "Control SolenoidBControl Circuit Low",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "934",
        title: "P0967",
        desc: "Control SolenoidBControl Circuit High",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "935",
        title: "P0969",
        desc: "Control SolenoidCControl Circuit Range/Performance",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "936",
        title: "P096A",
        desc: "ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "937",
        title: "P097A",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:43",
        updated_at: "2022-08-18 19:36:43",
    },
    {
        vehicle_error_id: "938",
        title: "P098A",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "939",
        title: "P0993",
        desc: "Fluid Pressure Sensor/SwitchFCircuit Range/Performance",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "940",
        title: "P0995",
        desc: "Fluid Pressure Sensor/SwitchFCircuit High",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "941",
        title: "P09A0",
        desc: "P09FF ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "942",
        title: "P0800",
        desc: "Transfer Case Control System (MIL Request)",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "943",
        title: "P0801",
        desc: "Reverse Inhibit Control Circuit Malfunction",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "944",
        title: "P0802",
        desc: "Transmission Control System MIL Request Circuit/Open",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "945",
        title: "P0803",
        desc: "1-4 Upshift (Skip Shift) Solenoid Control Circuit Malfunction",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "946",
        title: "P0804",
        desc: "1-4 Upshift (Skip Shift) Lamp Control Circuit Malfunction",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "947",
        title: "P0805",
        desc: "Clutch Position Sensor Circuit",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "948",
        title: "P0806",
        desc: "Clutch Position Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "949",
        title: "P0807",
        desc: "Clutch Position Sensor Circuit Low",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "950",
        title: "P0808",
        desc: "Clutch Position Sensor Circuit High",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "951",
        title: "P0809",
        desc: "Clutch Position Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "952",
        title: "P080A",
        desc: "Clutch Position Not Learned",
        created_at: "2022-08-18 19:36:44",
        updated_at: "2022-08-18 19:36:44",
    },
    {
        vehicle_error_id: "953",
        title: "P080E,",
        desc: "P080F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "954",
        title: "P0810",
        desc: "Clutch Position Control Error",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "955",
        title: "P0811",
        desc: "Excessive Clutch Slippage",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "956",
        title: "P0812",
        desc: "Reverse Input Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "957",
        title: "P0813",
        desc: "Reverse Output Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "958",
        title: "P0814",
        desc: "Transmission Range Display Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "959",
        title: "P0815",
        desc: "Upshift Switch Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "960",
        title: "P0816",
        desc: "Downshift Switch Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "961",
        title: "P0817",
        desc: "Starter Disable Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "962",
        title: "P0818",
        desc: "Driveline Disconnect Switch Input Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "963",
        title: "P0819",
        desc: "Up and Down Shift Switch to Transmission Range Correlation",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "964",
        title: "P081A",
        desc: "Disable Circuit Low",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "965",
        title: "P081B",
        desc: "Disable Circuit High",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "966",
        title: "P081C",
        desc: "Input Circuit",
        created_at: "2022-08-18 19:36:45",
        updated_at: "2022-08-18 19:36:45",
    },
    {
        vehicle_error_id: "967",
        title: "P081D",
        desc: "Neutral Input Circuit",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "968",
        title: "P081E",
        desc: "Excessive ClutchBSlippage",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "969",
        title: "P081F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "970",
        title: "P0829",
        desc: "5-6 Shift Malfunction",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "971",
        title: "P0830",
        desc: "Clutch Pedal SwitchACircuit",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "972",
        title: "P0831",
        desc: "Clutch Pedal SwitchACircuit Low",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "973",
        title: "P0832",
        desc: "Clutch Pedal SwitchACircuit High",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "974",
        title: "P0833",
        desc: "Clutch Pedal SwitchBCircuit",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "975",
        title: "P0834",
        desc: "Clutch Pedal SwitchBCircuit Low",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "976",
        title: "P0835",
        desc: "Clutch Pedal SwitchBCircuit High",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "977",
        title: "P0836",
        desc: "Four Wheel Drive (4WD) Switch Circuit",
        created_at: "2022-08-18 19:36:46",
        updated_at: "2022-08-18 19:36:46",
    },
    {
        vehicle_error_id: "978",
        title: "P0837",
        desc: "Four Wheel Drive (4WD) Switch Circuit Range/Performance",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "979",
        title: "P0838",
        desc: "Four Wheel Drive (4WD) Switch Circuit Low",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "980",
        title: "P0839",
        desc: "Four Wheel Drive (4WD) Switch Circuit High",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "981",
        title: "P083A",
        desc: "Transmission Fluid Pressure Sensor/SwitchGCircuit",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "982",
        title: "P083B",
        desc: "Transmission Fluid Pressure Sensor/SwitchGCircuit Range/Performance",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "983",
        title: "P083C",
        desc: "Transmission Fluid Pressure Sensor/SwitchGCircuit Low",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "984",
        title: "P083D",
        desc: "Transmission Fluid Pressure Sensor/SwitchGCircuit High",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "985",
        title: "P083E",
        desc: "Transmission Fluid Pressure Sensor/SwitchGCircuit Intermittent",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "986",
        title: "P083F",
        desc: "Clutch Pedal SwitchA / BCorrelation",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "987",
        title: "P0840",
        desc: "Transmission Fluid Pressure Sensor/SwitchACircuit",
        created_at: "2022-08-18 19:36:47",
        updated_at: "2022-08-18 19:36:47",
    },
    {
        vehicle_error_id: "988",
        title: "P0841",
        desc: "Transmission Fluid Pressure Sensor/SwitchACircuit Range/Performance",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "989",
        title: "P0842",
        desc: "Transmission Fluid Pressure Sensor/SwitchACircuit Low",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "990",
        title: "P0843",
        desc: "Transmission Fluid Pressure Sensor/SwitchACircuit High",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "991",
        title: "P0844",
        desc: "Transmission Fluid Pressure Sensor/SwitchACircuit Intermittent",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "992",
        title: "P0845",
        desc: "Transmission Fluid Pressure Sensor/SwitchBCircuit",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "993",
        title: "P0846",
        desc: "Transmission Fluid Pressure Sensor/SwitchBCircuit Range/Performance",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "994",
        title: "P0847",
        desc: "Transmission Fluid Pressure Sensor/SwitchBCircuit Low",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "995",
        title: "P0848",
        desc: "Transmission Fluid Pressure Sensor/SwitchBCircuit High",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "996",
        title: "P0849",
        desc: "Transmission Fluid Pressure Sensor/SwitchBCircuit Intermittent",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "997",
        title: "P084A",
        desc: "Transmission Fluid Pressure Sensor/SwitchHCircuit",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "998",
        title: "P084B",
        desc: "Transmission Fluid Pressure Sensor/SwitchHCircuit Range/Performance",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "999",
        title: "P084C",
        desc: "Transmission Fluid Pressure Sensor/SwitchHCircuit Low",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1000",
        title: "P084D",
        desc: "Transmission Fluid Pressure Sensor/SwitchHCircuit High",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1001",
        title: "P084E",
        desc: "Transmission Fluid Pressure Sensor/SwitchHCircuit Intermittent",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1002",
        title: "P085F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1003",
        title: "P0863",
        desc: "TCM Communication Circuit",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1004",
        title: "P0864",
        desc: "TCM Communication Circuit Range/Performance",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1005",
        title: "P0865",
        desc: "TCM Communication Circuit Low",
        created_at: "2022-08-18 19:36:48",
        updated_at: "2022-08-18 19:36:48",
    },
    {
        vehicle_error_id: "1006",
        title: "P0866",
        desc: "TCM Communication Circuit High",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1007",
        title: "P086A",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1008",
        title: "P0870",
        desc: "Transmission Fluid Pressure Sensor/SwitchCCircuit",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1009",
        title: "P0871",
        desc: "Transmission Fluid Pressure Sensor/SwitchCCircuit Range/Performance",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1010",
        title: "P0872",
        desc: "Transmission Fluid Pressure Sensor/SwitchCCircuit Low",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1011",
        title: "P0873",
        desc: "Transmission Fluid Pressure Sensor/SwitchCCircuit High",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1012",
        title: "P0874",
        desc: "Transmission Fluid Pressure Sensor/SwitchCCircuit Intermittent",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1013",
        title: "P0875",
        desc: "Transmission Fluid Pressure Sensor/SwitchDCircuit",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1014",
        title: "P0876",
        desc: "Transmission Fluid Pressure Sensor/SwitchDCircuit Range/Performance",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1015",
        title: "P0877",
        desc: "Transmission Fluid Pressure Sensor/SwitchDCircuit Low",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1016",
        title: "P0878",
        desc: "Transmission Fluid Pressure Sensor/SwitchDCircuit High",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1017",
        title: "P0879",
        desc: "Transmission Fluid Pressure Sensor/SwitchDCircuit Intermittent",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1018",
        title: "P087A,",
        desc: "P087B, P087C, P087D, P087E, P087F ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1019",
        title: "P0880",
        desc: "TCM Power Input Signal",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1020",
        title: "P0881",
        desc: "TCM Power Input Signal Range/Performance",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1021",
        title: "P0882",
        desc: "TCM Power Input Signal Low",
        created_at: "2022-08-18 19:36:49",
        updated_at: "2022-08-18 19:36:49",
    },
    {
        vehicle_error_id: "1022",
        title: "P0883",
        desc: "TCM Power Input Signal High",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1023",
        title: "P0884",
        desc: "TCM Power Input Signal Intermittent",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1024",
        title: "P0885",
        desc: "TCM Power Relay Control Circuit/Open",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1025",
        title: "P0886",
        desc: "TCM Power Relay Control Circuit Low",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1026",
        title: "P0887",
        desc: "TCM Power Relay Control Circuit High",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1027",
        title: "P0888",
        desc: "TCM Power Relay Sense Circuit",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1028",
        title: "P0889",
        desc: "TCM Power Relay Sense Circuit Range/Performance",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1029",
        title: "P088C",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1030",
        title: "P0890",
        desc: "TCM Power Relay Sense Circuit Low",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1031",
        title: "P0891",
        desc: "TCM Power Relay Sense Circuit High",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1032",
        title: "P0892",
        desc: "TCM Power Relay Sense Circuit Intermittent",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1033",
        title: "P0893",
        desc: "Multiple Gears Engaged",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1034",
        title: "P0894",
        desc: "Transmission Component Slipping",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1035",
        title: "P0895",
        desc: "Shift Time Too Short",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1036",
        title: "P0896",
        desc: "Shift Time Too Long",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1037",
        title: "P089A",
        desc: "P08FF ISO/SAE Reserved ",
        created_at: "2022-08-18 19:36:50",
        updated_at: "2022-08-18 19:36:50",
    },
    {
        vehicle_error_id: "1038",
        title: "P0700",
        desc: "Transmission Control System Malfunction",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1039",
        title: "P0701",
        desc: "Transmission Control System Range/Performance",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1040",
        title: "P0702",
        desc: "Transmission Control System Electrical",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1041",
        title: "P0703",
        desc: "Torque Converter/Brake Switch B Circuit Malfunction",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1042",
        title: "P0704",
        desc: "Clutch Switch Input Circuit Malfunction",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1043",
        title: "P0705",
        desc: "Transmission Range Sensor A Circuit malfunction (PRNDL Input)",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1044",
        title: "P0706",
        desc: "Transmission Range Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1045",
        title: "P0707",
        desc: "Transmission Range Sensor A Circuit Low Input",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1046",
        title: "P0708",
        desc: "Transmission Range Sensor A Circuit High Input",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1047",
        title: "P0709",
        desc: "Transmission Range Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1048",
        title: "P070A",
        desc: "Transmission Fluid Level Sensor Circuit",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1049",
        title: "P070B",
        desc: "Transmission Fluid Level Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1050",
        title: "P070C",
        desc: "Transmission Fluid Level Sensor Circuit Low",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1051",
        title: "P070D",
        desc: "Transmission Fluid Level Sensor Circuit High",
        created_at: "2022-08-18 19:36:51",
        updated_at: "2022-08-18 19:36:51",
    },
    {
        vehicle_error_id: "1052",
        title: "P070E",
        desc: "Transmission Fluid Level Sensor Circuit intermittent/Erratic",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1053",
        title: "P070F",
        desc: "Transmission Fluid Level Too Low",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1054",
        title: "P0710",
        desc: "Transmission Fluid Temperature Sensor A Circuit Malfunction",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1055",
        title: "P0711",
        desc: "Transmission Fluid Temperature Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1056",
        title: "P0712",
        desc: "Transmission Fluid Temperature Sensor A Circuit Low Input",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1057",
        title: "P0713",
        desc: "Transmission Fluid Temperature Sensor A Circuit High Input",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1058",
        title: "P0714",
        desc: "Transmission Fluid Temperature Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1059",
        title: "P0715",
        desc: "Input/Turbine Speed Sensor A Circuit Malfunction",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1060",
        title: "P0716",
        desc: "Input/Turbine Speed Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1061",
        title: "P0717",
        desc: "Input/Turbine Speed Sensor A Circuit No Signal",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1062",
        title: "P0718",
        desc: "Input/Turbine Speed Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1063",
        title: "P0719",
        desc: "Torque Converter/Brake Switch B Circuit Low",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1064",
        title: "P071A",
        desc: "Transmission Mode SwitchACircuit",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1065",
        title: "P071B",
        desc: "Transmission Mode SwitchACircuit Low",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1066",
        title: "P071C",
        desc: "Transmission Mode SwitchACircuit High",
        created_at: "2022-08-18 19:36:52",
        updated_at: "2022-08-18 19:36:52",
    },
    {
        vehicle_error_id: "1067",
        title: "P071D",
        desc: "Transmission Mode SwitchBCircuit",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1068",
        title: "P071E",
        desc: "Transmission Mode SwitchBCircuit Low",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1069",
        title: "P071F",
        desc: "Transmission Mode SwitchBCircuit High",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1070",
        title: "P0720",
        desc: "Output Speed Sensor Circuit Malfunction",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1071",
        title: "P0721",
        desc: "Output Speed Sensor Range/Performance",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1072",
        title: "P0722",
        desc: "Output Speed Sensor No Signal",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1073",
        title: "P0723",
        desc: "Output Speed Sensor Intermittent",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1074",
        title: "P0724",
        desc: "Torque Converter/Brake Switch B Circuit High",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1075",
        title: "P0725",
        desc: "Engine Speed input Circuit Malfunction",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1076",
        title: "P0726",
        desc: "Engine Speed Input Circuit Range/Performance",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1077",
        title: "P0727",
        desc: "Engine Speed Input Circuit No Signal",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1078",
        title: "P0728",
        desc: "Engine Speed Input Circuit Intermittent",
        created_at: "2022-08-18 19:36:53",
        updated_at: "2022-08-18 19:36:53",
    },
    {
        vehicle_error_id: "1079",
        title: "P0729",
        desc: "Gear 6 Incorrect Ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1080",
        title: "P072A",
        desc: "Stuck in Neutral",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1081",
        title: "P072B",
        desc: "Stuck In Reverse",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1082",
        title: "P072C",
        desc: "Stuck in Gear 1",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1083",
        title: "P072D",
        desc: "Stuck in Gear 2",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1084",
        title: "P072E",
        desc: "Stuck in Gear 3",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1085",
        title: "P072F",
        desc: "Stuck in Gear 4",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1086",
        title: "P0730",
        desc: "Incorrect Gear Ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1087",
        title: "P0731",
        desc: "Gear I Incorrect ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1088",
        title: "P0732",
        desc: "Gear 2 Incorrect ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1089",
        title: "P0733",
        desc: "Gear 3 Incorrect ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1090",
        title: "P0734",
        desc: "Gear 4 Incorrect ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1091",
        title: "P0735",
        desc: "Gear 5 Incorrect ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1092",
        title: "P0736",
        desc: "Reverse incorrect gear ratio",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1093",
        title: "P0737",
        desc: "TCM Engine Speed Output Circuit",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1094",
        title: "P0738",
        desc: "TCM Engine Speed Output Circuit Low",
        created_at: "2022-08-18 19:36:54",
        updated_at: "2022-08-18 19:36:54",
    },
    {
        vehicle_error_id: "1095",
        title: "P0739",
        desc: "TCM Engine Speed Output Circuit High",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1096",
        title: "P073A",
        desc: "Stuck in Gear 5",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1097",
        title: "P073B",
        desc: "Stuck in Gear 6",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1098",
        title: "P073C",
        desc: "Stuck in Gear 7",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1099",
        title: "P073D",
        desc: "Unable to Engage Neutral",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1100",
        title: "P073E",
        desc: "Unable to Engage Reverse",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1101",
        title: "P073F",
        desc: "Unable to Engage Gear 1",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1102",
        title: "P0740",
        desc: "Torque Converter Clutch Circuit Malfunction",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1103",
        title: "P0741",
        desc: "Torque Converter Clutch Circuit Performance or Stuck Off",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1104",
        title: "P0742",
        desc: "Torque Converter Clutch Circuit Stock On",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1105",
        title: "P0743",
        desc: "Torque Converter Clutch Circuit Electrical",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1106",
        title: "P0744",
        desc: "Torque Converter Clutch Circuit Intermittent",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1107",
        title: "P0745",
        desc: "Pressure Control Solenoid A Malfunction",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1108",
        title: "P0746",
        desc: "Pressure Control Solenoid A Performance or Stuck Off",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1109",
        title: "P0747",
        desc: "Pressure Control Solenoid A Stuck On",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1110",
        title: "P0748",
        desc: "Pressure Control Solenoid A Electrical",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1111",
        title: "P0749",
        desc: "Pressure Control Solenoid A Intermittent",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1112",
        title: "P074A",
        desc: "Unable To Engage Gear 2",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1113",
        title: "P074B",
        desc: "Unable To Engage Gear 3",
        created_at: "2022-08-18 19:36:55",
        updated_at: "2022-08-18 19:36:55",
    },
    {
        vehicle_error_id: "1114",
        title: "P074C",
        desc: "Unable To Engage Gear 4",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1115",
        title: "P074D",
        desc: "Unable To Engage Gear 5",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1116",
        title: "P074E",
        desc: "Unable To Engage Gear 6",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1117",
        title: "P074F",
        desc: "Unable To Engage Gear 7",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1118",
        title: "P0750",
        desc: "Shift Solenoid A Malfunction",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1119",
        title: "P0751",
        desc: "Shift Solenoid A Performance or Stuck Off",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1120",
        title: "P0752",
        desc: "Shift Solenoid A Stuck On",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1121",
        title: "P0753",
        desc: "Shift Solenoid A Electrical",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1122",
        title: "P0754",
        desc: "Shift Solenoid A Intermittent",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1123",
        title: "P0755",
        desc: "Shift Solenoid B Malfunction",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1124",
        title: "P0756",
        desc: "Shift Solenoid B Performance or Stock Off",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1125",
        title: "P0757",
        desc: "Shift Solenoid B Stuck On",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1126",
        title: "P0758",
        desc: "Shift Solenoid B Electrical",
        created_at: "2022-08-18 19:36:56",
        updated_at: "2022-08-18 19:36:56",
    },
    {
        vehicle_error_id: "1127",
        title: "P0759",
        desc: "Shift Solenoid B Intermittent",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1128",
        title: "P075A",
        desc: "Shift Solenoid G Malfunction",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1129",
        title: "P075B",
        desc: "Shift Solenoid G Performance/Stuck Off",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1130",
        title: "P075C",
        desc: "Shift Solenoid G Stuck On",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1131",
        title: "P075D",
        desc: "Shift Solenoid G Electrical",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1132",
        title: "P075E",
        desc: "Shift Solenoid G Intermittent",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1133",
        title: "P075F",
        desc: "Transmission Fluid Level Too High",
        created_at: "2022-08-18 19:36:57",
        updated_at: "2022-08-18 19:36:57",
    },
    {
        vehicle_error_id: "1134",
        title: "P0760",
        desc: "Shift Solenoid C Malfunction",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1135",
        title: "P0761",
        desc: "Shift Solenoid C Performance or Stuck Off",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1136",
        title: "P0762",
        desc: "Shift Solenoid C Stuck On",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1137",
        title: "P0763",
        desc: "Shift Solenoid C Electrical",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1138",
        title: "P0764",
        desc: "Shift Solenoid C Intermittent",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1139",
        title: "P0765",
        desc: "Shift Solenoid D Malfunction",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1140",
        title: "P0766",
        desc: "Shift Solenoid D Performance or Stuck Off",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1141",
        title: "P0767",
        desc: "Shift Solenoid D Stuck On",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1142",
        title: "P0768",
        desc: "Shift Solenoid D Electrical",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1143",
        title: "P0769",
        desc: "Shift Solenoid D Intermittent",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1144",
        title: "P076A",
        desc: "Shift Solenoid H Malfunction",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1145",
        title: "P076B",
        desc: "Shift Solenoid H Performance/Stuck Off",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1146",
        title: "P076C",
        desc: "Shift Solenoid H Stuck On",
        created_at: "2022-08-18 19:36:58",
        updated_at: "2022-08-18 19:36:58",
    },
    {
        vehicle_error_id: "1147",
        title: "P076D",
        desc: "Shift Solenoid H Electrical",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1148",
        title: "P076E",
        desc: "Shift Solenoid H Intermittent",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1149",
        title: "P076F",
        desc: "Gear 7 Incorrect Ratio",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1150",
        title: "P0770",
        desc: "Shift Solenoid E Malfunction",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1151",
        title: "P0771",
        desc: "Shift Solenoid E Performance or Stuck Off",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1152",
        title: "P0772",
        desc: "Shift Solenoid E Stuck On",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1153",
        title: "P0773",
        desc: "Shift Solenoid E Electrical",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1154",
        title: "P0774",
        desc: "Shift Solenoid E Intermittent",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1155",
        title: "P0775",
        desc: "Pressure Control Solenoid B Malfunction",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1156",
        title: "P0776",
        desc: "Pressure Control Solenoid B Performance or Stuck Off",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1157",
        title: "P0777",
        desc: "Pressure Control Solenoid B Stuck On",
        created_at: "2022-08-18 19:36:59",
        updated_at: "2022-08-18 19:36:59",
    },
    {
        vehicle_error_id: "1158",
        title: "P0778",
        desc: "Pressure Control Solenoid B Electrical",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1159",
        title: "P0779",
        desc: "Pressure Control Solenoid B Intermittent",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1160",
        title: "P077A",
        desc: "Speed Sensor Circuit - Loss of Direction Signal",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1161",
        title: "P077B",
        desc: "Speed Sensor Circuit - Direction Error",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1162",
        title: "P077C,",
        desc: "P077D, P077E, P077F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1163",
        title: "P0780",
        desc: "Shift Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1164",
        title: "P0781",
        desc: "1-2 Shift Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1165",
        title: "P0782",
        desc: "2-3 Shift Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1166",
        title: "P0783",
        desc: "3-4 Shift Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1167",
        title: "P0784",
        desc: "4-5 Shift Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1168",
        title: "P0785",
        desc: "Shift Timing Solenoid A Malfunction",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1169",
        title: "P0786",
        desc: "Shift Timing Solenoid A Range/Performance",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1170",
        title: "P0787",
        desc: "Shift Timing Solenoid A Low",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1171",
        title: "P0788",
        desc: "Shift Timing Solenoid A High",
        created_at: "2022-08-18 19:37:00",
        updated_at: "2022-08-18 19:37:00",
    },
    {
        vehicle_error_id: "1172",
        title: "P0789",
        desc: "Shift Timing Solenoid A Intermittent",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1173",
        title: "P078A",
        desc: "Shift Timing Solenoid B Malfunction",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1174",
        title: "P078B",
        desc: "Shift Timing Solenoid B Range/Performance",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1175",
        title: "P078C",
        desc: "Shift Timing Solenoid B Low",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1176",
        title: "P078D",
        desc: "Shift Timing Solenoid B High",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1177",
        title: "P078E",
        desc: "Shift Timing Solenoid B Intermittent",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1178",
        title: "P078F",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1179",
        title: "P0790",
        desc: "Normal/Performance Switch Circuit Malfunction",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1180",
        title: "P0791",
        desc: "Intermediate Shaft Speed Sensor A Circuit",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1181",
        title: "P0792",
        desc: "Intermediate Shaft Speed Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1182",
        title: "P0793",
        desc: "Intermediate Shaft Speed Sensor A Circuit No Signal",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1183",
        title: "P0794",
        desc: "Intermediate Shaft Speed Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1184",
        title: "P0795",
        desc: "Pressure Control Solenoid C Malfunction",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1185",
        title: "P0796",
        desc: "Pressure Control Solenoid C Performance or Stuck off",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1186",
        title: "P0797",
        desc: "Pressure Control Solenoid C Stuck On",
        created_at: "2022-08-18 19:37:01",
        updated_at: "2022-08-18 19:37:01",
    },
    {
        vehicle_error_id: "1187",
        title: "P0798",
        desc: "Pressure Control Solenoid C Electrical",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1188",
        title: "P0799",
        desc: "Pressure Control Solenoid C Intermittent",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1189",
        title: "P079A",
        desc: "Transmission Friction ElementASlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1190",
        title: "P079B",
        desc: "Transmission Friction ElementBSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1191",
        title: "P079C",
        desc: "Transmission Friction ElementCSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1192",
        title: "P079D",
        desc: "Transmission Friction ElementDSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1193",
        title: "P079E",
        desc: "Transmission Friction ElementESlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1194",
        title: "P079F",
        desc: "Transmission Friction ElementFSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1195",
        title: "P07A0",
        desc: "Transmission Friction ElementGSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1196",
        title: "P07A1",
        desc: "Transmission Friction ElementHSlip Detected",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1197",
        title: "P07A2",
        desc: "Transmission Friction ElementAPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1198",
        title: "P07A3",
        desc: "Transmission Friction ElementAStuck On",
        created_at: "2022-08-18 19:37:02",
        updated_at: "2022-08-18 19:37:02",
    },
    {
        vehicle_error_id: "1199",
        title: "P07A4",
        desc: "Transmission Friction ElementBPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1200",
        title: "P07A5",
        desc: "Transmission Friction ElementBStuck On",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1201",
        title: "P07A6",
        desc: "Transmission Friction ElementCPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1202",
        title: "P07A7",
        desc: "Transmission Friction ElementCStuck On",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1203",
        title: "P07A8",
        desc: "Transmission Friction ElementDPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1204",
        title: "P07A9",
        desc: "Transmission Friction ElementDStuck On",
        created_at: "2022-08-18 19:37:03",
        updated_at: "2022-08-18 19:37:03",
    },
    {
        vehicle_error_id: "1205",
        title: "P07AA",
        desc: "Transmission Friction ElementEPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1206",
        title: "P07AB",
        desc: "Transmission Friction ElementEStuck On",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1207",
        title: "P07AC",
        desc: "Transmission Friction ElementFPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1208",
        title: "P07AD",
        desc: "Transmission Friction ElementFStuck On",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1209",
        title: "P07AE",
        desc: "Transmission Friction ElementGPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1210",
        title: "P07AF",
        desc: "Transmission Friction ElementGStuck On",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1211",
        title: "P07B0",
        desc: "Transmission Friction ElementHPerformance/Stuck Off",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1212",
        title: "P07B1",
        desc: "Transmission Friction ElementHStuck On",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1213",
        title: "P07B2",
        desc: "Transmission Park Position Sensor/SwitchACircuit Open",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1214",
        title: "P07B3",
        desc: "Transmission Park Position Sensor/SwitchACircuit Low",
        created_at: "2022-08-18 19:37:04",
        updated_at: "2022-08-18 19:37:04",
    },
    {
        vehicle_error_id: "1215",
        title: "P07B4",
        desc: "Transmission Park Position Sensor/SwitchACircuit High",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1216",
        title: "P07B5",
        desc: "Transmission Park Position Sensor/SwitchACircuit Performance/Low",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1217",
        title: "P07B6",
        desc: "Transmission Park Position Sensor/SwitchACircuit Performance High",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1218",
        title: "P07B7",
        desc: "Transmission Park Position Sensor/SwitchACircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1219",
        title: "P07B8",
        desc: "Transmission Park Position Sensor/SwitchBCircuit Open",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1220",
        title: "P07B9",
        desc: "Transmission Park Position Sensor/SwitchBCircuit Low",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1221",
        title: "P07BA",
        desc: "Transmission Park Position Sensor/SwitchBCircuit High",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1222",
        title: "P07BB",
        desc: "Transmission Park Position Sensor/SwitchBCircuit Performance/Low",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1223",
        title: "P07BC",
        desc: "Transmission Park Position Sensor/SwitchBCircuit Performance High",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1224",
        title: "P07BD",
        desc: "Transmission Park Position Sensor/SwitchBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1225",
        title: "P07BE",
        desc: "Transmission Park Position Sensor/SwitchA / BCorrelation",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1226",
        title: "P07BF",
        desc: "- P07FF ISO/SAE Reserved ",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1227",
        title: "P0600",
        desc: "Serial Communication Link Malfunction",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1228",
        title: "P0601\nInternal",
        desc: "Control Module Memory Check Sum Error",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1229",
        title: "P0602",
        desc: "Control Module Programming Error",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1230",
        title: "P0603",
        desc: "Internal Control Module Keep Alive Memory (KAM) Error",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1231",
        title: "P0604",
        desc: "Internal Control Module Random Access Memory (RAM) Error",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1232",
        title: "P0605",
        desc: "Internal Control Module Read Only Memory (ROM) Error",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1233",
        title: "P0606",
        desc: "PCM Processor Fault",
        created_at: "2022-08-18 19:37:05",
        updated_at: "2022-08-18 19:37:05",
    },
    {
        vehicle_error_id: "1234",
        title: "P0607",
        desc: "Control Module Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1235",
        title: "P0608",
        desc: "Control Module VSS OutputAMalfunction",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1236",
        title: "P0609",
        desc: "Control Module VSS OutputBMalfunction",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1237",
        title: "P060A",
        desc: "Internal Control Module Monitoring Processor Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1238",
        title: "P060B",
        desc: "Internal Control Module A/D Processing Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1239",
        title: "P060C",
        desc: "Internal Control Module Main Processor Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1240",
        title: "P060D",
        desc: "Internal Control Module Accelerator Pedal Position Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1241",
        title: "P060E",
        desc: "Internal Control Module Throttle Position Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1242",
        title: "P060F",
        desc: "Internal Control Module Coolant Temperature Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1243",
        title: "P0610",
        desc: "Control Module Vehicle Options Error",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1244",
        title: "P0611",
        desc: "Fuel Injector Control Module Performance",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1245",
        title: "P0612",
        desc: "Fuel Injector Control Module Relay Control",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1246",
        title: "P0613",
        desc: "TCM Processor",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1247",
        title: "P0614",
        desc: "ECM / TCM Incompatible",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1248",
        title: "P0615",
        desc: "Starter Relay Circuit",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1249",
        title: "P0616",
        desc: "Starter Relay Circuit Low",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1250",
        title: "P0617",
        desc: "Starter Relay Circuit High",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1251",
        title: "P0618",
        desc: "Alternative Fuel Control Module KAM Error",
        created_at: "2022-08-18 19:37:06",
        updated_at: "2022-08-18 19:37:06",
    },
    {
        vehicle_error_id: "1252",
        title: "P0619",
        desc: "Alternative Fuel Control Module RAM/ROM Error",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1253",
        title: "P061A",
        desc: "Control Module Torque Performance",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1254",
        title: "P061D",
        desc: "Control Module Engine Air Mass Performance",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1255",
        title: "P061E",
        desc: "Control Module Brake Signal Performance",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1256",
        title: "P0620",
        desc: "Generator Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1257",
        title: "P0621",
        desc: "Generator LampLControl Circuit Malfunction",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1258",
        title: "P0622",
        desc: "Generator FieldFControl Circuit Malfunction",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1259",
        title: "P0623",
        desc: "Generator Lamp Control Circuit",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1260",
        title: "P0624",
        desc: "Fuel Cap Lamp Control Circuit",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1261",
        title: "P0625",
        desc: "Generator Field/F Terminal Circuit Low",
        created_at: "2022-08-18 19:37:07",
        updated_at: "2022-08-18 19:37:07",
    },
    {
        vehicle_error_id: "1262",
        title: "P0626",
        desc: "Generator Field/F Terminal Circuit High",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1263",
        title: "P0627",
        desc: "Fuel PumpAControl Circuit /Open",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1264",
        title: "P0628",
        desc: "Fuel PumpAControl Circuit Low",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1265",
        title: "P0629",
        desc: "Fuel PumpAControl Circuit High",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1266",
        title: "P062A",
        desc: "PumpAControl Circuit Range/Performance",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1267",
        title: "P062B",
        desc: "Internal Control Module Fuel Injector Control Performance",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1268",
        title: "P062D",
        desc: "Fuel Injector Driver Circuit Performance Bank 1",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1269",
        title: "P062E",
        desc: "Fuel Injector Driver Circuit Performance Bank 2",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1270",
        title: "P062F",
        desc: "Internal Control Module EEPROM Error",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1271",
        title: "P0630",
        desc: "VIN Not Programmed or Incompatible – ECM/PCM",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1272",
        title: "P0631",
        desc: "VIN Not Programmed or Incompatible – TCM",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1273",
        title: "P0632",
        desc: "Odometer Not Programmed – ECM/PCM",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1274",
        title: "P0633",
        desc: "Immobilizer Key Not Programmed – ECM/PCM",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1275",
        title: "P0634",
        desc: "PCM/ECM/TCM Internal Temperature Too High",
        created_at: "2022-08-18 19:37:08",
        updated_at: "2022-08-18 19:37:08",
    },
    {
        vehicle_error_id: "1276",
        title: "P0635",
        desc: "Power Steering Control Circuit",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1277",
        title: "P0636",
        desc: "Power Steering Control Circuit Low",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1278",
        title: "P0637",
        desc: "Power Steering Control Circuit High",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1279",
        title: "P0638",
        desc: "Throttle Actuator Control Range/Performance (Bank 1)",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1280",
        title: "P0639",
        desc: "Throttle Actuator Control Range/Performance (Bank 2)",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1281",
        title: "P063A",
        desc: "Voltage Sense Circuit",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1282",
        title: "P063B",
        desc: "Voltage Sense Circuit Range/Performance ",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1283",
        title: "P063C",
        desc: "Voltage Sense Circuit Low",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1284",
        title: "P063D",
        desc: "Voltage Sense Circuit High",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1285",
        title: "P063E",
        desc: "Configuration Throttle Input Not Present ",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1286",
        title: "P063F",
        desc: "Configuration Engine Coolant Temperature Input Not Present",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1287",
        title: "P0640",
        desc: "Intake Air Heater Control Circuit",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1288",
        title: "P0641",
        desc: "Sensor Reference VoltageACircuit Open",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1289",
        title: "P0642",
        desc: "Sensor Reference VoltageACircuit Low",
        created_at: "2022-08-18 19:37:09",
        updated_at: "2022-08-18 19:37:09",
    },
    {
        vehicle_error_id: "1290",
        title: "P0643",
        desc: "Sensor Reference VoltageACircuit High",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1291",
        title: "P0644",
        desc: "Driver Display Serial Communication Circuit",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1292",
        title: "P0645",
        desc: "A/C Clutch Relay Control Circuit",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1293",
        title: "P0646",
        desc: "A/C Clutch Relay Control Circuit Low",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1294",
        title: "P0647",
        desc: "A/C Clutch Relay Control Circuit High",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1295",
        title: "P0648",
        desc: "Immobilizer Lamp Control Circuit",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1296",
        title: "P0649",
        desc: "Speed Control Lamp Control Circuit",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1297",
        title: "P064A",
        desc: "Pump Control Module",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1298",
        title: "P064B",
        desc: "Control Module",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1299",
        title: "P064D",
        desc: "Control Module O2 Sensor Processor Performance Bank 1",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1300",
        title: "P064E",
        desc: "Control Module O2 Sensor Processor Performance Bank 2",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1301",
        title: "P0650",
        desc: "Malfunction Indicator Lamp (MIL) Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1302",
        title: "P0651",
        desc: "Sensor Reference VoltageBCircuit Open",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1303",
        title: "P0652",
        desc: "Sensor Reference VoltageBCircuit Low",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1304",
        title: "P0653",
        desc: "Sensor Reference VoltageBCircuit High",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1305",
        title: "P0654",
        desc: "Engine RPM Output Circuit Malfunction",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1306",
        title: "P0655",
        desc: "Engine Hot Lamp Output Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:10",
        updated_at: "2022-08-18 19:37:10",
    },
    {
        vehicle_error_id: "1307",
        title: "P0656",
        desc: "Fuel Level Output Circuit Malfunction",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1308",
        title: "P0657",
        desc: "Actuator Supply VoltageACircuit/Open",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1309",
        title: "P0658",
        desc: "Actuator Supply VoltageACircuit Low",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1310",
        title: "P0659",
        desc: "Actuator Supply VoltageACircuit High",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1311",
        title: "P065A",
        desc: "System Performance",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1312",
        title: "P065C",
        desc: "Mechanical Performance",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1313",
        title: "P0660",
        desc: "Intake Manifold Tuning Valve Control Circuit/Open Bank 1",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1314",
        title: "P0661",
        desc: "Intake Manifold Tuning Valve Control Circuit Low Bank 1",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1315",
        title: "P0662",
        desc: "Intake Manifold Tuning Valve Control Circuit High Bank 1",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1316",
        title: "P0663",
        desc: "Intake Manifold Tuning Valve Control Circuit/Open Bank 2",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1317",
        title: "P0664",
        desc: "Intake Manifold Tuning Valve Control Circuit Low Bank 2",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1318",
        title: "P0665",
        desc: "Intake Manifold Tuning Valve Control Circuit High Bank 2",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1319",
        title: "P0666",
        desc: "PCM/ECM/TCM Internal Temperature Sensor Circuit",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1320",
        title: "P0667",
        desc: "PCM/ECM/TCM Internal Temperature Sensor Range/Performance",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1321",
        title: "P0668",
        desc: "PCM/ECM/TCM Internal Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1322",
        title: "P0669",
        desc: "PCM/ECM/TCM Internal Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:37:11",
        updated_at: "2022-08-18 19:37:11",
    },
    {
        vehicle_error_id: "1323",
        title: "P066A",
        desc: "1 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1324",
        title: "P066C",
        desc: "2 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1325",
        title: "P066F",
        desc: "3 Glow Plug Control Circuit High",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1326",
        title: "P0670",
        desc: "Glow Plug Module Control Circuit",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1327",
        title: "P0671",
        desc: "Cylinder 1 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1328",
        title: "P0672",
        desc: "Cylinder 2 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1329",
        title: "P0673",
        desc: "Cylinder 3 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1330",
        title: "P0674",
        desc: "Cylinder 4 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:12",
        updated_at: "2022-08-18 19:37:12",
    },
    {
        vehicle_error_id: "1331",
        title: "P0675",
        desc: "Cylinder 5 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1332",
        title: "P0676",
        desc: "Cylinder 6 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1333",
        title: "P0677",
        desc: "Cylinder 7 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1334",
        title: "P0678",
        desc: "Cylinder 8 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1335",
        title: "P0679",
        desc: "Cylinder 9 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1336",
        title: "P067A",
        desc: "4 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1337",
        title: "P067B",
        desc: "4 Glow Plug Control Circuit High ",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1338",
        title: "P067C",
        desc: "5 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1339",
        title: "P067D",
        desc: "5 Glow Plug Control Circuit High",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1340",
        title: "P067E",
        desc: "6 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1341",
        title: "P0680",
        desc: "Cylinder 10 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1342",
        title: "P0681",
        desc: "Cylinder 11 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1343",
        title: "P0682",
        desc: "Cylinder 12 Glow Plug Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1344",
        title: "P0683",
        desc: "Glow Plug Control Module to PCM Communication Circuit",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1345",
        title: "P0684",
        desc: "Glow Plug Control Module to PCM Communication Circuit Range/Performance",
        created_at: "2022-08-18 19:37:13",
        updated_at: "2022-08-18 19:37:13",
    },
    {
        vehicle_error_id: "1346",
        title: "P0685",
        desc: "ECM/PCM Power Relay Control Circuit Open",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1347",
        title: "P0686",
        desc: "ECM/PCM Power Relay Control Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1348",
        title: "P0687",
        desc: "ECM/PCM Power Relay Control Circuit High",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1349",
        title: "P0688",
        desc: "ECM/PCM Power Relay Sense Circuit Open",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1350",
        title: "P0689",
        desc: "ECM/PCM Power Relay Sense Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1351",
        title: "P068A/PCM",
        desc: "Power Relay De-Energized Performance - Too Early",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1352",
        title: "P068E",
        desc: "8 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1353",
        title: "P0690",
        desc: "ECM/PCM Power Relay Sense Circuit High",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1354",
        title: "P0691",
        desc: "Fan 1 Control Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1355",
        title: "P0692",
        desc: "Fan 1 Control Circuit High",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1356",
        title: "P0693",
        desc: "Fan 2 Control Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1357",
        title: "P0694",
        desc: "Fan 2 Control Circuit High",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1358",
        title: "P0695",
        desc: "Fan 3 Control Circuit Low",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1359",
        title: "P0696",
        desc: "Fan 3 Control Circuit High",
        created_at: "2022-08-18 19:37:14",
        updated_at: "2022-08-18 19:37:14",
    },
    {
        vehicle_error_id: "1360",
        title: "P0697",
        desc: "Sensor Reference VoltageCCircuit Open",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1361",
        title: "P0698",
        desc: "Sensor Reference VoltageCCircuit Low",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1362",
        title: "P0699",
        desc: "Sensor Reference VoltageCCircuit High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1363",
        title: "P069A",
        desc: "9 Glow Plug Control Circuit Low ",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1364",
        title: "P069B",
        desc: "9 Glow Plug Control Circuit High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1365",
        title: "P069C",
        desc: "10 Glow Plug Control Circuit Low",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1366",
        title: "P069D",
        desc: "10 Glow Plug Control Circuit High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1367",
        title: "P06A1",
        desc: "A/C Compressor Control Circuit Low",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1368",
        title: "P06A2",
        desc: "A/C Compressor Control Circuit High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1369",
        title: "P06A3",
        desc: "Reference VoltageDCircuit Open",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1370",
        title: "P06A5",
        desc: "Reference VoltageDCircuit High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1371",
        title: "P06A7",
        desc: "Reference VoltageBCircuit Range/Performance",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1372",
        title: "P06A8",
        desc: "Reference VoltageCCircuit Range/Performance",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1373",
        title: "P06A9",
        desc: "Reference VoltageDCircuit Range/Performance",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1374",
        title: "P06AA/ECM/TCM",
        desc: "Internal TemperatureBToo High",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1375",
        title: "P06AF",
        desc: "Management System - Forced Engine Shutdown",
        created_at: "2022-08-18 19:37:15",
        updated_at: "2022-08-18 19:37:15",
    },
    {
        vehicle_error_id: "1376",
        title: "P06B0",
        desc: "Power SupplyACircuit/Open",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1377",
        title: "P06B2",
        desc: "Power SupplyACircuit High",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1378",
        title: "P06B6",
        desc: "Control Module Knock Sensor Processor 1 Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1379",
        title: "P06B8",
        desc: "Control Module Non-Volatile Random Access Memory (NVRAM) Error ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1380",
        title: "P06B9",
        desc: "1 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1381",
        title: "P06BA",
        desc: "2 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1382",
        title: "P06BB",
        desc: "3 Glow Plug Circuit Range/Performance ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1383",
        title: "P06BC",
        desc: "4 Glow Plug Circuit Range/Performance ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1384",
        title: "P06BD",
        desc: "5 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1385",
        title: "P06BF",
        desc: "7 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1386",
        title: "P06C0",
        desc: "8 Glow Plug Circuit Range/Performance ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1387",
        title: "P06C1",
        desc: "9 Glow Plug Circuit Range/Performance ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1388",
        title: "P06C2",
        desc: "10 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1389",
        title: "P06C4",
        desc: "12 Glow Plug Circuit Range/Performance",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1390",
        title: "P06C5",
        desc: "1 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1391",
        title: "P06C6",
        desc: "2 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:16",
        updated_at: "2022-08-18 19:37:16",
    },
    {
        vehicle_error_id: "1392",
        title: "P06C7",
        desc: "3 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1393",
        title: "P06C8",
        desc: "4 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1394",
        title: "P06C9",
        desc: "5 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1395",
        title: "P06CA",
        desc: "6 Glow Plug Incorrect",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1396",
        title: "P06CB",
        desc: "7 Glow Plug Incorrect",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1397",
        title: "P06CC",
        desc: "8 Glow Plug Incorrect",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1398",
        title: "P06CD",
        desc: "9 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1399",
        title: "P06CE",
        desc: "10 Glow Plug Incorrect",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1400",
        title: "P06D0",
        desc: "12 Glow Plug Incorrect ",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1401",
        title: "P06D1",
        desc: "Control Module Ignition Coil Control Performance",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1402",
        title: "P0500",
        desc: "Vehicle Speed SensorAMalfunction",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1403",
        title: "P0501",
        desc: "Vehicle Speed SensorARange/Performance",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1404",
        title: "P0502",
        desc: "Vehicle Speed SensorALow Input",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1405",
        title: "P0503",
        desc: "Vehicle Speed SensorAIntermittent/Erratic/High",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1406",
        title: "P0504",
        desc: "Brake SwitchA / BCorrelation",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1407",
        title: "P0505",
        desc: "Idle Control System Malfunction",
        created_at: "2022-08-18 19:37:17",
        updated_at: "2022-08-18 19:37:17",
    },
    {
        vehicle_error_id: "1408",
        title: "P0506",
        desc: "Idle Control System RPM Lower Than Expected",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1409",
        title: "P0507",
        desc: "Idle Control System RPM Higher Than Expected",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1410",
        title: "P0508",
        desc: "Idle Air Control System Circuit Low",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1411",
        title: "P0509",
        desc: "Idle Air Control System Circuit High",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1412",
        title: "P050A",
        desc: "Cold Start Idle Air Control System Performance",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1413",
        title: "P050B",
        desc: "Cold Start Ignition Timing Performance",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1414",
        title: "P050C",
        desc: "Cold Start Engine Coolant Temperature Performance",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1415",
        title: "P050D",
        desc: "Cold Start Rough Idle",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1416",
        title: "P050E",
        desc: "Cold Start Engine Exhaust Temperature Too Low",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1417",
        title: "P050F",
        desc: "Brake Assist Vacuum Too Low",
        created_at: "2022-08-18 19:37:18",
        updated_at: "2022-08-18 19:37:18",
    },
    {
        vehicle_error_id: "1418",
        title: "P0510",
        desc: "Closed Throttle Position Switch Malfunction",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1419",
        title: "P0511",
        desc: "Idle Air Control Circuit",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1420",
        title: "P0512",
        desc: "Starter Request Circuit",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1421",
        title: "P0513",
        desc: "Incorrect Immobilizer Key",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1422",
        title: "P0514",
        desc: "Battery Temperature Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1423",
        title: "P0515",
        desc: "Battery Temperature Sensor Circuit",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1424",
        title: "P0516",
        desc: "Battery Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1425",
        title: "P0517",
        desc: "Battery Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1426",
        title: "P0518",
        desc: "Idle Air Control Circuit Intermittent",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1427",
        title: "P0519",
        desc: "Idle Air Control System Performance",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1428",
        title: "P051A",
        desc: "Crankcase Pressure Sensor Circuit",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1429",
        title: "P051B",
        desc: "Crankcase Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1430",
        title: "P051C",
        desc: "Crankcase Pressure Sensor Circuit Low",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1431",
        title: "P051D",
        desc: "Crankcase Pressure Sensor Circuit High",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1432",
        title: "P051E",
        desc: "Crankcase Pressure Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1433",
        title: "P051F",
        desc: "Positive Crankcase Ventilation Filter Restriction",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1434",
        title: "P0520",
        desc: "Engine Oil Pressure Sensor/Switch Circuit Malfunction",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1435",
        title: "P0521",
        desc: "Engine Oil Pressure Sensor/Switch Circuit Range/Performance",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1436",
        title: "P0522",
        desc: "Engine Oil Pressure Sensor/Switch Circuit Low Voltage",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1437",
        title: "P0523",
        desc: "Engine Oil Pressure Sensor/Switch Circuit High Voltage",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1438",
        title: "P0524",
        desc: "Engine Oil Pressure Too Low",
        created_at: "2022-08-18 19:37:19",
        updated_at: "2022-08-18 19:37:19",
    },
    {
        vehicle_error_id: "1439",
        title: "P0525",
        desc: "Cruise Control Servo Control Circuit Range/Performance",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1440",
        title: "P0526",
        desc: "Fan Speed Sensor Circuit",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1441",
        title: "P0527",
        desc: "Fan Speed Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1442",
        title: "P0528",
        desc: "Fan Speed Sensor Circuit No Signal",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1443",
        title: "P0529",
        desc: "Fan Speed Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1444",
        title: "P052A",
        desc: "Cold StartACamshaft Position Timing Over-Advanced Bank 1",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1445",
        title: "P052B",
        desc: "Cold StartACamshaft Position Timing Over-Retarded Bank 1",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1446",
        title: "P052C",
        desc: "Cold StartACamshaft Position Timing Over-Advanced Bank 2",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1447",
        title: "P052D",
        desc: "Cold StartACamshaft Position Timing Over-Retarded Bank 2",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1448",
        title: "P052E",
        desc: "Positive Crankcase Ventilation Regulator Valve Performance",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1449",
        title: "P052F",
        desc: "ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1450",
        title: "P0530",
        desc: "A/C Refrigerant Pressure SensorACircuit",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1451",
        title: "P0531",
        desc: "A/C Refrigerant Pressure SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1452",
        title: "P0532",
        desc: "A/C Refrigerant Pressure SensorACircuit Low",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1453",
        title: "P0533",
        desc: "A/C Refrigerant Pressure SensorACircuit High",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1454",
        title: "P0534",
        desc: "Air Conditioner Refrigerant Charge Loss",
        created_at: "2022-08-18 19:37:20",
        updated_at: "2022-08-18 19:37:20",
    },
    {
        vehicle_error_id: "1455",
        title: "P0535",
        desc: "A/C Evaporator Temperature Sensor Circuit",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1456",
        title: "P0536",
        desc: "A/C Evaporator Temperature Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1457",
        title: "P0537",
        desc: "A/C Evaporator Temperature Sensor Circuit Low",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1458",
        title: "P0538",
        desc: "A/C Evaporator Temperature Sensor Circuit High",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1459",
        title: "P0539",
        desc: "A/C Evaporator Temperature Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1460",
        title: "P053A",
        desc: "Positive Crankcase Ventilation Heater Control Circuit /Open",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1461",
        title: "P053B",
        desc: "Positive Crankcase Ventilation Heater Control Circuit Low",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1462",
        title: "P053C",
        desc: "Positive Crankcase Ventilation Heater Control Circuit High",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1463",
        title: "P053D,",
        desc: "P053E, P053F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1464",
        title: "P0540",
        desc: "Intake Air HeaterACircuit",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1465",
        title: "P0541",
        desc: "Intake Air HeaterACircuit Low",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1466",
        title: "P0542",
        desc: "Intake Air HeaterACircuit High",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1467",
        title: "P0543",
        desc: "Intake Air HeaterACircuit Open",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1468",
        title: "P0544",
        desc: "Exhaust Gas Temperature Sensor Circuit (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1469",
        title: "P0545",
        desc: "Exhaust Gas Temperature Sensor Circuit Low (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1470",
        title: "P0546",
        desc: "Exhaust Gas Temperature Sensor Circuit High (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:37:21",
        updated_at: "2022-08-18 19:37:21",
    },
    {
        vehicle_error_id: "1471",
        title: "P0547",
        desc: "Exhaust Gas Temperature Sensor Circuit (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1472",
        title: "P0548",
        desc: "Exhaust Gas Temperature Sensor Circuit Low (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1473",
        title: "P0549",
        desc: "Exhaust Gas Temperature Sensor Circuit High (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1474",
        title: "P054A",
        desc: "Cold StartBCamshaft Position Timing Over-Advanced Bank 1",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1475",
        title: "P054B",
        desc: "Cold StartBCamshaft Position Timing Over-Retarded Bank 1",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1476",
        title: "P054C",
        desc: "Cold StartBCamshaft Position Timing Over-Advanced Bank 2",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1477",
        title: "P054D",
        desc: "Cold StartBCamshaft Position Timing Over-Retarded Bank 2",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1478",
        title: "P054E,",
        desc: "P054F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1479",
        title: "P0550",
        desc: "Power Steering Pressure Sensor Circuit Malfunction",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1480",
        title: "P0551",
        desc: "Power Steering Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1481",
        title: "P0552",
        desc: "Power Steering Pressure Sensor Circuit Low Input",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1482",
        title: "P0553",
        desc: "Power Steering Pressure Sensor Circuit High Input",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1483",
        title: "P0554",
        desc: "Power Steering Pressure Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1484",
        title: "P0555",
        desc: "Brake Booster Pressure Sensor Circuit",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1485",
        title: "P0556",
        desc: "Brake Booster Pressure Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1486",
        title: "P0557",
        desc: "Brake Booster Pressure Sensor Circuit Low",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1487",
        title: "P0558",
        desc: "Brake Booster Pressure Sensor Circuit High",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1488",
        title: "P0559",
        desc: "Brake Booster Pressure Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:22",
        updated_at: "2022-08-18 19:37:22",
    },
    {
        vehicle_error_id: "1489",
        title: "P055A,",
        desc: "P055B, P055C, P055D, P055E, P055F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1490",
        title: "P0560",
        desc: "System Voltage Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1491",
        title: "P0561",
        desc: "System Voltage Unstable",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1492",
        title: "P0562",
        desc: "System Voltage Low",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1493",
        title: "P0563",
        desc: "System Voltage High",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1494",
        title: "P0564",
        desc: "Cruise Control Multi-Function InputACircuit",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1495",
        title: "P0565",
        desc: "Cruise Control On Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1496",
        title: "P0566",
        desc: "Cruise Control Off Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1497",
        title: "P0567",
        desc: "Cruise Control Resume Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1498",
        title: "P0568",
        desc: "Cruise Control Set Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1499",
        title: "P0569",
        desc: "Cruise Control Coast Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1500",
        title: "P056A",
        desc: "Cruise ControlIncrease DistanceSignal",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1501",
        title: "P056B",
        desc: "Cruise ControlDecrease DistanceSignal",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1502",
        title: "P056C,",
        desc: "P056D, P056E, P056F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1503",
        title: "P0570",
        desc: "Cruise Control Accel Signal Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1504",
        title: "P0571",
        desc: "Cruise Control/Brake Switch A Circuit Malfunction",
        created_at: "2022-08-18 19:37:23",
        updated_at: "2022-08-18 19:37:23",
    },
    {
        vehicle_error_id: "1505",
        title: "P0572",
        desc: "Cruise Control/Brake Switch A Circuit Low",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1506",
        title: "P0573",
        desc: "Cruise Control/Brake Switch A Circuit High",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1507",
        title: "P0574",
        desc: "Cruise Control System - Vehicle Speed Too High",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1508",
        title: "P0575",
        desc: "Cruise Control Input Circuit",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1509",
        title: "P0576",
        desc: "Cruise Control Input Circuit Low",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1510",
        title: "P0577",
        desc: "Cruise Control Input Circuit High",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1511",
        title: "P0578",
        desc: "Cruise Control Multi-Function InputACircuit Stuck",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1512",
        title: "P0579",
        desc: "Cruise Control Multi-Function InputACircuit Range/Performance",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1513",
        title: "P057A,",
        desc: "P057B, P057C, P057D, P057E, P057F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1514",
        title: "P0580",
        desc: "Cruise Control Multi-Function InputACircuit Low",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1515",
        title: "P0581",
        desc: "Cruise Control Multi-Function InputACircuit High",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1516",
        title: "P0582",
        desc: "Cruise Control Vacuum Control Circuit /Open",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1517",
        title: "P0583",
        desc: "Cruise Control Vacuum Control Circuit Low",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1518",
        title: "P0584",
        desc: "Cruise Control Vacuum Control Circuit High",
        created_at: "2022-08-18 19:37:24",
        updated_at: "2022-08-18 19:37:24",
    },
    {
        vehicle_error_id: "1519",
        title: "P0585",
        desc: "Cruise Control Multi-Function InputA / BCorrelation",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1520",
        title: "P0586",
        desc: "Cruise Control Vent Control Circuit/Open",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1521",
        title: "P0587",
        desc: "Cruise Control Vent Control Circuit Low",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1522",
        title: "P0588",
        desc: "Cruise Control Vent Control Circuit High",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1523",
        title: "P0589",
        desc: "Cruise Control Multi-Function InputBCircuit",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1524",
        title: "P058A,",
        desc: "P058B, P058C, P058D, P058E, P058F ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1525",
        title: "P0590",
        desc: "Cruise Control Multi-Function InputBCircuit Stuck",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1526",
        title: "P0591",
        desc: "Cruise Control Multi-Function InputBCircuit Range/Performance",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1527",
        title: "P0592",
        desc: "Cruise Control Multi-Function InputBCircuit Low",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1528",
        title: "P0593",
        desc: "Cruise Control Multi-Function InputBCircuit High",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1529",
        title: "P0594",
        desc: "Cruise Control Servo Control Circuit/Open",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1530",
        title: "P0595",
        desc: "Cruise Control Servo Control Circuit Low",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1531",
        title: "P0596",
        desc: "Cruise Control Servo Control Circuit High",
        created_at: "2022-08-18 19:37:25",
        updated_at: "2022-08-18 19:37:25",
    },
    {
        vehicle_error_id: "1532",
        title: "P0597",
        desc: "Thermostat Heater Control Circuit/Open",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1533",
        title: "P0598",
        desc: "Thermostat Heater Control Circuit Low",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1534",
        title: "P0599",
        desc: "Thermostat Heater Control Circuit High",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1535",
        title: "P059A",
        desc: "- P05FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1536",
        title: "P0400",
        desc: "Exhaust Gas Recirculation Flow Malfunction",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1537",
        title: "P0401",
        desc: "Exhaust Gas Recirculation Flow Insufficient Detected",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1538",
        title: "P0402",
        desc: "Exhaust Gas Recirculation Flow Excessive Detected",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1539",
        title: "P0403",
        desc: "Exhaust Gas Recirculation Circuit Malfunction",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1540",
        title: "P0404",
        desc: "Exhaust Gas Recirculation Circuit Range/Performance",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1541",
        title: "P0405",
        desc: "Exhaust Gas Recirculation Sensor A Circuit Low",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1542",
        title: "P0406",
        desc: "Exhaust Gas Recirculation Sensor A Circuit High",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1543",
        title: "P0407",
        desc: "Exhaust Gas Recirculation Sensor B Circuit Low",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1544",
        title: "P0408",
        desc: "Exhaust Gas Recirculation Sensor B Circuit High",
        created_at: "2022-08-18 19:37:26",
        updated_at: "2022-08-18 19:37:26",
    },
    {
        vehicle_error_id: "1545",
        title: "P0409",
        desc: "Exhaust Gas Recirculation SensorACircuit",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1546",
        title: "P040A",
        desc: "Gas Recirculation Temperature SensorACircuit",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1547",
        title: "P040B",
        desc: "Gas Recirculation Temperature SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1548",
        title: "P040D",
        desc: "Gas Recirculation Temperature SensorACircuit High",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1549",
        title: "P040F",
        desc: "Gas Recirculation Temperature SensorA / BCorrelation",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1550",
        title: "P0410",
        desc: "Secondary Air Injection System Malfunction",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1551",
        title: "P0411",
        desc: "Secondary Air Injection System Incorrect Flow Detected",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1552",
        title: "P0412",
        desc: "Secondary Air Injection System Switching Valve A Circuit Malfunction",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1553",
        title: "P0413",
        desc: "Secondary Air Injection System Switching Valve A Circuit Open",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1554",
        title: "P0414",
        desc: "Secondary Air Injection System Switching Valve A Circuit Shorted",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1555",
        title: "P0415",
        desc: "Secondary Air Injection System Switching Valve B Circuit Malfunction",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1556",
        title: "P0416",
        desc: "Secondary Air Injection System Switching Valve B Circuit Open",
        created_at: "2022-08-18 19:37:27",
        updated_at: "2022-08-18 19:37:27",
    },
    {
        vehicle_error_id: "1557",
        title: "P0417",
        desc: "Secondary Air Injection System Switching Valve B Circuit Shorted",
        created_at: "2022-08-18 19:37:28",
        updated_at: "2022-08-18 19:37:28",
    },
    {
        vehicle_error_id: "1558",
        title: "P0418",
        desc: "Secondary Air Injection System RelayACircuit Malfunction",
        created_at: "2022-08-18 19:37:28",
        updated_at: "2022-08-18 19:37:28",
    },
    {
        vehicle_error_id: "1559",
        title: "P0419",
        desc: "Secondary Air Injection System RelayBCircuit Malfunction",
        created_at: "2022-08-18 19:37:28",
        updated_at: "2022-08-18 19:37:28",
    },
    {
        vehicle_error_id: "1560",
        title: "P041A",
        desc: "Gas Recirculation Temperature SensorBCircuit ",
        created_at: "2022-08-18 19:37:28",
        updated_at: "2022-08-18 19:37:28",
    },
    {
        vehicle_error_id: "1561",
        title: "P041B",
        desc: "Gas Recirculation Temperature SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:37:28",
        updated_at: "2022-08-18 19:37:28",
    },
    {
        vehicle_error_id: "1562",
        title: "P041C",
        desc: "Gas Recirculation Temperature SensorBCircuit Low",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1563",
        title: "P041D",
        desc: "Gas Recirculation Temperature SensorBCircuit High",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1564",
        title: "P041E",
        desc: "Gas Recirculation Temperature SensorBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1565",
        title: "P0420",
        desc: "Catalyst System Efficiency Below Threshold (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1566",
        title: "P0421",
        desc: "Warm Up Catalyst Efficiency Below Threshold (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1567",
        title: "P0422",
        desc: "Main Catalyst Efficiency Below Threshold (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1568",
        title: "P0423",
        desc: "Heated Catalyst Efficiency Below Threshold (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1569",
        title: "P0424",
        desc: "Heated Catalyst Temperature Below Threshold (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1570",
        title: "P0425",
        desc: "Catalyst Temperature Sensor (Bank 1, Sensor 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1571",
        title: "P0426",
        desc: "Catalyst Temperature Sensor Range/Performance (Bank 1, Sensor 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1572",
        title: "P0427",
        desc: "Catalyst Temperature Sensor Low (Bank 1, Sensor 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1573",
        title: "P0428",
        desc: "Catalyst Temperature Sensor High (Bank 1, Sensor 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1574",
        title: "P0429",
        desc: "Catalyst Heater Control Circuit (Bank 1)",
        created_at: "2022-08-18 19:37:50",
        updated_at: "2022-08-18 19:37:50",
    },
    {
        vehicle_error_id: "1575",
        title: "P042A",
        desc: "Temperature Sensor Circuit (Bank 1 Sensor 2) ",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1576",
        title: "P042B",
        desc: "Temperature Sensor Circuit Range/Performance (Bank 1 Sensor 2) ",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1577",
        title: "P042C",
        desc: "Temperature Sensor Circuit Low (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1578",
        title: "P042D",
        desc: "Temperature Sensor Circuit High (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1579",
        title: "P042E",
        desc: "Gas RecirculationAControl Stuck Open",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1580",
        title: "P0430",
        desc: "Catalyst System Efficiency Below Threshold (Bank 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1581",
        title: "P0431",
        desc: "Warm Up Catalyst Efficiency Below Threshold (Bank 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1582",
        title: "P0432",
        desc: "Main Catalyst Efficiency Below Threshold (Bank 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1583",
        title: "P0433",
        desc: "Heated Catalyst Efficiency Below Threshold (Bank 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1584",
        title: "P0434",
        desc: "Heated Catalyst Temperature Below Threshold (Bank 2)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1585",
        title: "P0435",
        desc: "Catalyst Temperature Sensor Circuit Malfunction (Bank 2, Sensor 1)",
        created_at: "2022-08-18 19:37:51",
        updated_at: "2022-08-18 19:37:51",
    },
    {
        vehicle_error_id: "1586",
        title: "P0436",
        desc: "Catalyst Temperature Sensor Circuit Range/Performance (Bank 2, Sensor 1)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1587",
        title: "P0437",
        desc: "Catalyst Temperature Sensor Circuit Low (Bank 2, Sensor 1)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1588",
        title: "P0438",
        desc: "Catalyst Temperature Sensor Circuit High (Bank 2, Sensor 1)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1589",
        title: "P0439",
        desc: "Catalyst Heater Control Circuit (Bank 2)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1590",
        title: "P043A",
        desc: "Temperature Sensor Circuit (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1591",
        title: "P043B",
        desc: "Temperature Sensor Circuit Range/Performance Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1592",
        title: "P043C",
        desc: "Temperature Sensor Circuit Low (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1593",
        title: "P043E",
        desc: "Emission System Leak Detection Reference Orifice Low Flow",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1594",
        title: "P0440",
        desc: "Evaporative Emission Control System Malfunction",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1595",
        title: "P0441",
        desc: "Evaporative Emission Control System Incorrect Purge Flow",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1596",
        title: "P0442",
        desc: "Evaporative Emission Control System Leak Detected (small leak)",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1597",
        title: "P0443",
        desc: "Evaporative Emission Control System Purge Control Valve Circuit",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1598",
        title: "P0444",
        desc: "Evaporative Emission Control System Purge Control Valve Circuit Open",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1599",
        title: "P0445",
        desc: "Evaporative Emission Control System Purge Control Valve Circuit Shorted",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1600",
        title: "P0446",
        desc: "Evaporative Emission Control System Vent Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:52",
        updated_at: "2022-08-18 19:37:52",
    },
    {
        vehicle_error_id: "1601",
        title: "P0447",
        desc: "Evaporative Emission Control System Vent Control Circuit Open",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1602",
        title: "P0448",
        desc: "Evaporative Emission Control System Vent Control Circuit Shorted",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1603",
        title: "P0449",
        desc: "Evaporative Emission Control System Vent Valve/Solenoid Circuit Malfunction",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1604",
        title: "P044A",
        desc: "Gas Recirculation SensorCCircuit",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1605",
        title: "P044C",
        desc: "Gas Recirculation SensorCCircuit Low",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1606",
        title: "P044E",
        desc: "Gas Recirculation SensorCCircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1607",
        title: "P044F",
        desc: "Air Injection System Switching ValveACircuit High",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1608",
        title: "P0450",
        desc: "Evaporative Emission Control System Pressure Sensor Malfunction",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1609",
        title: "P0451",
        desc: "Evaporative Emission Control System Pressure Sensor Range/Performance",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1610",
        title: "P0452",
        desc: "Evaporative Emission Control System Pressure Sensor Low Input",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1611",
        title: "P0453",
        desc: "Evaporative Emission Control System Pressure Sensor High Input",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1612",
        title: "P0454",
        desc: "Evaporative Emission Control System Pressure Sensor Intermittent",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1613",
        title: "P0455",
        desc: "Evaporative Emission Control System Leak Detected (gross leak)",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1614",
        title: "P0456",
        desc: "Evaporative Emissions System Small Leak Detected",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1615",
        title: "P0457",
        desc: "Evaporative Emission Control System Leak Detected",
        created_at: "2022-08-18 19:37:53",
        updated_at: "2022-08-18 19:37:53",
    },
    {
        vehicle_error_id: "1616",
        title: "P0458",
        desc: "Evaporative Emission System Purge Control Valve Circuit Low",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1617",
        title: "P0459",
        desc: "Evaporative Emission System Purge Control Valve Circuit High",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1618",
        title: "P045A",
        desc: "Gas RecirculationBControl Circuit ",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1619",
        title: "P045B",
        desc: "Gas RecirculationBControl Circuit Range/Performance",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1620",
        title: "P045C",
        desc: "Gas RecirculationBControl Circuit Low ",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1621",
        title: "P045D",
        desc: "Gas RecirculationBControl Circuit High",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1622",
        title: "P045F",
        desc: "Gas RecirculationBControl Stuck Closed",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1623",
        title: "P0460",
        desc: "Fuel Level Sensor Circuit Malfunction",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1624",
        title: "P0461",
        desc: "Fuel Level Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1625",
        title: "P0462",
        desc: "Fuel Level Sensor Circuit Low Input",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1626",
        title: "P0463",
        desc: "Fuel Level Sensor Circuit High Input",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1627",
        title: "P0464",
        desc: "Fuel Level Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1628",
        title: "P0465",
        desc: "Purge Flow Sensor Circuit Malfunction",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1629",
        title: "P0466",
        desc: "Purge Flow Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:37:54",
        updated_at: "2022-08-18 19:37:54",
    },
    {
        vehicle_error_id: "1630",
        title: "P0467",
        desc: "Purge Flow Sensor Circuit Low Input",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1631",
        title: "P0468",
        desc: "Purge Flow Sensor Circuit High Input",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1632",
        title: "P0469",
        desc: "Purge Flow Sensor Circuit Intermittent",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1633",
        title: "P046A",
        desc: "Temperature Sensor 1/2 Correlation (Bank 1) ",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1634",
        title: "P046B",
        desc: "Temperature Sensor 1/2 Correlation (Bank 2)",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1635",
        title: "P046D",
        desc: "Gas Recirculation SensorACircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1636",
        title: "P046E",
        desc: "Gas Recirculation SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1637",
        title: "P046F",
        desc: "Gas Recirculation SensorBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1638",
        title: "P0470",
        desc: "Exhaust Pressure SensorACircuit",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1639",
        title: "P0471",
        desc: "Exhaust Pressure SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1640",
        title: "P0472",
        desc: "Exhaust Pressure SensorACircuit Low",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1641",
        title: "P0473",
        desc: "Exhaust Pressure SensorACircuit High",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1642",
        title: "P0474",
        desc: "Exhaust Pressure SensorACircuit Intermittent",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1643",
        title: "P0475",
        desc: "Exhaust Pressure Control ValveA ",
        created_at: "2022-08-18 19:37:55",
        updated_at: "2022-08-18 19:37:55",
    },
    {
        vehicle_error_id: "1644",
        title: "P0476",
        desc: "Exhaust Pressure Control ValveARange/Performance",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1645",
        title: "P0477",
        desc: "Exhaust Pressure Control ValveALow",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1646",
        title: "P0478",
        desc: "Exhaust Pressure Control ValveAHigh",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1647",
        title: "P0479",
        desc: "Exhaust Pressure Control ValveAIntermittent",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1648",
        title: "P047A",
        desc: "Pressure SensorBCircuit ",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1649",
        title: "P047B",
        desc: "Pressure SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1650",
        title: "P047C",
        desc: "Pressure SensorBCircuit Low",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1651",
        title: "P047D",
        desc: "Pressure SensorBCircuit High ",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1652",
        title: "P047E",
        desc: "Pressure SensorBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1653",
        title: "P0480",
        desc: "Cooling Fan I Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1654",
        title: "P0481",
        desc: "Cooling Fan 2 Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1655",
        title: "P0482",
        desc: "Cooling Fan 3 Control Circuit Malfunction",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1656",
        title: "P0483",
        desc: "Cooling Fan Rationality Check Malfunction",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1657",
        title: "P0484",
        desc: "Cooling Fan Circuit Over Current ",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1658",
        title: "P0485",
        desc: "Cooling Fan Power/Ground Circuit Malfunction",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1659",
        title: "P0486",
        desc: "Exhaust Gas Recirculation SensorBCircuit",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1660",
        title: "P0487",
        desc: "Exhaust Gas Recirculation Throttle Control CircuitAOpen",
        created_at: "2022-08-18 19:37:56",
        updated_at: "2022-08-18 19:37:56",
    },
    {
        vehicle_error_id: "1661",
        title: "P0488",
        desc: "Exhaust Gas Recirculation Throttle Control CircuitARange/Performance",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1662",
        title: "P0489",
        desc: "Exhaust Gas RecirculationAControl Circuit Low",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1663",
        title: "P048A",
        desc: "Pressure Control ValveAStuck Closed",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1664",
        title: "P048B",
        desc: "Pressure Control Valve Position Sensor/Switch Circuit",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1665",
        title: "P048C",
        desc: "Exhaust Pressure Control Valve Position Sensor/Switch Circuit Range/Performance",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1666",
        title: "P048D",
        desc: "Exhaust Pressure Control Valve Position Sensor/Switch Circuit Low",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1667",
        title: "P048E",
        desc: "Pressure Control Valve Position Sensor/Switch Circuit High",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1668",
        title: "P048F",
        desc: "Exhaust Pressure Control Valve Position Sensor/Switch Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1669",
        title: "P0490",
        desc: "Exhaust Gas RecirculationAControl Circuit High",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1670",
        title: "P0491",
        desc: "Secondary Air Injection System Insufficient Flow Bank 1",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1671",
        title: "P0492",
        desc: "Secondary Air Injection System Insufficient Flow Bank 2",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1672",
        title: "P0493",
        desc: "Fan Overspeed",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1673",
        title: "P0494",
        desc: "Fan Speed Low",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1674",
        title: "P0495",
        desc: "Fan Speed High",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1675",
        title: "P0496",
        desc: "EVAP Flow During A Non-Purge Condition",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1676",
        title: "P0497",
        desc: "Evaporative Emission System Low Purge Flow",
        created_at: "2022-08-18 19:37:57",
        updated_at: "2022-08-18 19:37:57",
    },
    {
        vehicle_error_id: "1677",
        title: "P0498",
        desc: "Evaporative Emission System Vent Valve Control Circuit Low",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1678",
        title: "P0499",
        desc: "Evaporative Emission System Vent Valve Control Circuit High",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1679",
        title: "P049A",
        desc: "Gas RecirculationBFlow",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1680",
        title: "P049B",
        desc: "Gas RecirculationBFlow Insufficient Detected",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1681",
        title: "P049C",
        desc: "Gas RecirculationBFlow Excessive Detected",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1682",
        title: "P049D",
        desc: "Gas RecirculationAControl Position Exceeded Learning Limit",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1683",
        title: "P049E",
        desc: "Gas RecirculationBControl Position Exceeded Learning Limit",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1684",
        title: "P049F",
        desc: "Pressure Control ValveB ",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1685",
        title: "P04A0",
        desc: "Pressure Control ValveBRange/Performance",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1686",
        title: "P04A1",
        desc: "Pressure Control ValveBLow",
        created_at: "2022-08-18 19:37:58",
        updated_at: "2022-08-18 19:37:58",
    },
    {
        vehicle_error_id: "1687",
        title: "P04A2",
        desc: "Pressure Control ValveBHigh",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1688",
        title: "P04A3",
        desc: "Pressure Control ValveBIntermittent",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1689",
        title: "P04A4",
        desc: "Pressure Control ValveBStuck Open",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1690",
        title: "P04A5",
        desc: "Pressure Control ValveBStuck Closed",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1691",
        title: "P04A6",
        desc: "Pressure Control ValveBPosition Sensor/Switch Circuit",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1692",
        title: "P04A7",
        desc: "Pressure Control ValveBPosition Sensor/Switch Circuit Range/Performance",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1693",
        title: "P04A8",
        desc: "Pressure Control ValveBPosition Sensor/Switch Circuit Low",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1694",
        title: "P04A9",
        desc: "Exhaust Pressure Control ValveBPosition Sensor/Switch Circuit High",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1695",
        title: "P04AA",
        desc: "Exhaust Pressure Control ValveBPosition Sensor/Switch Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1696",
        title: "P04AB",
        desc: "- P04FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1697",
        title: "P0300",
        desc: "Random/Multiple Cylinder Misfire Detected",
        created_at: "2022-08-18 19:37:59",
        updated_at: "2022-08-18 19:37:59",
    },
    {
        vehicle_error_id: "1698",
        title: "P0301",
        desc: "Cylinder 1 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1699",
        title: "P0302",
        desc: "Cylinder 2 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1700",
        title: "P0303",
        desc: "Cylinder 3 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1701",
        title: "P0304",
        desc: "Cylinder 4 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1702",
        title: "P0305",
        desc: "Cylinder 5 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1703",
        title: "P0306",
        desc: "Cylinder 6 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1704",
        title: "P0307",
        desc: "Cylinder 7 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1705",
        title: "P0308",
        desc: "Cylinder 8 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1706",
        title: "P0309",
        desc: "Cylinder 9 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1707",
        title: "P0310",
        desc: "Cylinder 10 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1708",
        title: "P0311",
        desc: "Cylinder 11 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1709",
        title: "P0312",
        desc: "Cylinder 12 Misfire Detected",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1710",
        title: "P0313",
        desc: "Misfire Detected with Low Fuel",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1711",
        title: "P0314",
        desc: "Single Cylinder Misfire (Cylinder not Specified)",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1712",
        title: "P0315",
        desc: "Crankshaft Position System Variation Not Learned",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1713",
        title: "P0316",
        desc: "Misfire Detected On Startup (First 1000 Revolutions)",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1714",
        title: "P0317",
        desc: "Rough Road Hardware Not Present",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1715",
        title: "P0318",
        desc: "Rough Road Sensor A Signal Circuit",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1716",
        title: "P0319",
        desc: "Rough Road Sensor B Signal Circuit ",
        created_at: "2022-08-18 19:38:00",
        updated_at: "2022-08-18 19:38:00",
    },
    {
        vehicle_error_id: "1717",
        title: "P031A",
        desc: "P031C, P031D, P031E, P031F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1718",
        title: "P0320",
        desc: "Ignition/Distributor Engine Speed Input Circuit Malfunction",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1719",
        title: "P0321",
        desc: "Ignition/Distributor Engine Speed Input Circuit Range/Performance",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1720",
        title: "P0322",
        desc: "Ignition/Distributor Engine Speed Input Circuit No Signal",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1721",
        title: "P0323",
        desc: "Ignition/Distributor Engine Speed Input Circuit Intermittent",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1722",
        title: "P0324",
        desc: "Knock Control System Error",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1723",
        title: "P0325",
        desc: "Knock Sensor 1 Circuit Malfunction (Bank 1 or Single Sensor)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1724",
        title: "P0326",
        desc: "Knock Sensor 1 Circuit Range/Performance (Bank 1 or Single Sensor)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1725",
        title: "P0327",
        desc: "Knock Sensor 1 Circuit Low Input (Bank 1 or Single Sensor)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1726",
        title: "P0328",
        desc: "Knock Sensor 1 Circuit High Input (Bank 1 or Single Sensor)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1727",
        title: "P0329",
        desc: "Knock Sensor 1 Circuit Intermittent (Bank 1 or Single Sensor)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1728",
        title: "P032A",
        desc: "Sensor 3 Circuit Bank 1 ",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1729",
        title: "P032B",
        desc: "Sensor 3 Circuit Range/Performance Bank 1 ",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1730",
        title: "P032C",
        desc: "Sensor 3 Circuit Low Bank 1",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1731",
        title: "P032E",
        desc: "Sensor 3 Circuit Intermittent Bank 1",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1732",
        title: "P0330",
        desc: "Knock Sensor 2 Circuit Malfunction (Bank 2)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1733",
        title: "P0331",
        desc: "Knock Sensor 2 Circuit Range/Performance (Bank 2)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1734",
        title: "P0332",
        desc: "Knock Sensor 2 Circuit Low Input (Bank 2)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1735",
        title: "P0333",
        desc: "Knock Sensor 2 Circuit High Input (Bank 2)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1736",
        title: "P0334",
        desc: "Knock Sensor 2 Circuit Intermittent (Bank 2)",
        created_at: "2022-08-18 19:38:01",
        updated_at: "2022-08-18 19:38:01",
    },
    {
        vehicle_error_id: "1737",
        title: "P0335",
        desc: "Crankshaft Position Sensor A Circuit Malfunction",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1738",
        title: "P0336",
        desc: "Crankshaft Position Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1739",
        title: "P0337",
        desc: "Crankshaft Position Sensor A Circuit Low Input",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1740",
        title: "P0338",
        desc: "Crankshaft Position Sensor A Circuit High Input",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1741",
        title: "P0339",
        desc: "Crankshaft Position Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1742",
        title: "P033A",
        desc: "Sensor 4 Circuit (Bank 2) ",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1743",
        title: "P033B",
        desc: "Sensor 4 Circuit Range/Performance (Bank 2) ",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1744",
        title: "P033C",
        desc: "Sensor 4 Circuit Low (Bank 2) ",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1745",
        title: "P033D",
        desc: "Sensor 4 Circuit High (Bank 2) ",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1746",
        title: "P033E",
        desc: "Sensor 4 Circuit Intermittent (Bank 2) ",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1747",
        title: "P033F/SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1748",
        title: "P0340",
        desc: "Camshaft Position Sensor Circuit Malfunction (Bank 1)",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1749",
        title: "P0341",
        desc: "Camshaft Position Sensor Circuit Range/Performance (Bank 1)",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1750",
        title: "P0342",
        desc: "Camshaft Position Sensor A Circuit Low Input (Bank 1)",
        created_at: "2022-08-18 19:38:02",
        updated_at: "2022-08-18 19:38:02",
    },
    {
        vehicle_error_id: "1751",
        title: "P0343",
        desc: "Camshaft Position Sensor A Circuit High Input (Bank 1)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1752",
        title: "P0344",
        desc: "Camshaft Position Sensor A Circuit Intermittent (Bank 1)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1753",
        title: "P0345",
        desc: "Camshaft Position Sensor A Circuit Malfunction (Bank 2)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1754",
        title: "P0346",
        desc: "Camshaft Position Sensor A Circuit Range/Performance (Bank 2)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1755",
        title: "P0347",
        desc: "Camshaft Position Sensor A Circuit Low Input (Bank 2)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1756",
        title: "P0348",
        desc: "Camshaft Position Sensor A Circuit High Input (Bank 2)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1757",
        title: "P0349",
        desc: "Camshaft Position Sensor A Circuit Intermittent (Bank 2)",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1758",
        title: "P034A",
        desc: "P034C, P034D, P034E, P034F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1759",
        title: "P0350",
        desc: "Ignition Coil Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1760",
        title: "P0351",
        desc: "Ignition Coil A Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1761",
        title: "P0352",
        desc: "Ignition Coil B Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:03",
        updated_at: "2022-08-18 19:38:03",
    },
    {
        vehicle_error_id: "1762",
        title: "P0353",
        desc: "Ignition Coil C Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1763",
        title: "P0354",
        desc: "Ignition Coil D Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1764",
        title: "P0355",
        desc: "Ignition Coil E Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1765",
        title: "P0356",
        desc: "Ignition Coil F Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1766",
        title: "P0357",
        desc: "Ignition Coil G Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1767",
        title: "P0358",
        desc: "Ignition Coil H Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1768",
        title: "P0359",
        desc: "Ignition Coil I Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1769",
        title: "P035A",
        desc: "P035C, P035D, P035D, P035E, P035F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1770",
        title: "P0360",
        desc: "Ignition Coil J Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:04",
        updated_at: "2022-08-18 19:38:04",
    },
    {
        vehicle_error_id: "1771",
        title: "P0361",
        desc: "Ignition Coil K Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1772",
        title: "P0362",
        desc: "Ignition Coil L Primary/Secondary Circuit Malfunction",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1773",
        title: "P0363",
        desc: "Misfire Detected - Fueling Disabled",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1774",
        title: "P0364",
        desc: "Reserved",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1775",
        title: "P0365",
        desc: "Camshaft Position SensorBCircuit (Bank 1)",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1776",
        title: "P0366",
        desc: "Camshaft Position SensorBCircuit Range/Performance (Bank 1)",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1777",
        title: "P0367",
        desc: "Camshaft Position SensorBCircuit Low (Bank 1)",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1778",
        title: "P0368",
        desc: "Camshaft Position SensorBCircuit High (Bank 1)",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1779",
        title: "P0369",
        desc: "Camshaft Position SensorBCircuit Intermittent (Bank 1)",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1780",
        title: "P036A,",
        desc: "P036B, P036C, P036D, P036E, P036F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1781",
        title: "P0370",
        desc: "Timing Reference High Resolution Signal A Malfunction",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1782",
        title: "P0371",
        desc: "Timing Reference High Resolution Signal A Too Many Pulses",
        created_at: "2022-08-18 19:38:05",
        updated_at: "2022-08-18 19:38:05",
    },
    {
        vehicle_error_id: "1783",
        title: "P0372",
        desc: "Timing Reference High Resolution Signal A Too Few Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1784",
        title: "P0373",
        desc: "Timing Reference High Resolution Signal A Intermittent/Erratic Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1785",
        title: "P0374",
        desc: "Timing Reference High Resolution Signal A No Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1786",
        title: "P0375",
        desc: "Timing Reference High Resolution Signal B Malfunction",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1787",
        title: "P0376",
        desc: "Timing Reference High Resolution Signal B Too Many Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1788",
        title: "P0377",
        desc: "Timing Reference High Resolution Signal B Too Few Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1789",
        title: "P0378",
        desc: "Timing Reference High Resolution Signal B Intermittent/Erratic Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1790",
        title: "P0379",
        desc: "Timing Reference High Resolution Signal B No Pulses",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1791",
        title: "P037A",
        desc: "P037C ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1792",
        title: "P037E",
        desc: "Plug Sense Circuit Low ",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1793",
        title: "P037F",
        desc: "Plug Sense Circuit High",
        created_at: "2022-08-18 19:38:06",
        updated_at: "2022-08-18 19:38:06",
    },
    {
        vehicle_error_id: "1794",
        title: "P0380",
        desc: "Glow Plug/Heater CircuitA ",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1795",
        title: "P0381",
        desc: "Glow Plug/Heater Indicator Circuit Malfunction",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1796",
        title: "P0382",
        desc: "Glow Plug/Heater CircuitB ",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1797",
        title: "P0383",
        desc: "Glow Plug Control Module Control Circuit Low",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1798",
        title: "P0384",
        desc: "Glow Plug Control Module Control Circuit High",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1799",
        title: "P0385",
        desc: "Crankshaft Position Sensor B Circuit Malfunction",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1800",
        title: "P0386",
        desc: "Crankshaft Position Sensor B Circuit Range/Performance",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1801",
        title: "P0387",
        desc: "Crankshaft Position Sensor B Circuit Low Input",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1802",
        title: "P0388",
        desc: "Crankshaft Position Sensor B Circuit High Input",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1803",
        title: "P0389",
        desc: "Crankshaft Position Sensor B Circuit Intermittent",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1804",
        title: "P038A,",
        desc: "P038B, P038C, P038D, P038E, P038F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1805",
        title: "P0390",
        desc: "Camshaft Position SensorBCircuit (Bank 2)",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1806",
        title: "P0391",
        desc: "Camshaft Position SensorBCircuit Range/Performance (Bank 2)",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1807",
        title: "P0392",
        desc: "Camshaft Position SensorBCircuit Low (Bank 2)",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1808",
        title: "P0393",
        desc: "Camshaft Position SensorBCircuit High (Bank 2)",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1809",
        title: "P0394",
        desc: "Camshaft Position SensorBCircuit Intermittent (Bank 2)",
        created_at: "2022-08-18 19:38:07",
        updated_at: "2022-08-18 19:38:07",
    },
    {
        vehicle_error_id: "1810",
        title: "P0395",
        desc: "P03FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1811",
        title: "P0200",
        desc: "Injector Circuit Malfunction",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1812",
        title: "P0201",
        desc: "Injector Circuit Malfunction - Cylinder 1",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1813",
        title: "P0202",
        desc: "Injector Circuit Malfunction - Cylinder 2",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1814",
        title: "P0203",
        desc: "Injector Circuit Malfunction - Cylinder 3",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1815",
        title: "P0204",
        desc: "Injector Circuit Malfunction - Cylinder 4",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1816",
        title: "P0205",
        desc: "Injector Circuit Malfunction - Cylinder 5",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1817",
        title: "P0206",
        desc: "Injector Circuit Malfunction - Cylinder 6",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1818",
        title: "P0207",
        desc: "Injector Circuit Malfunction - Cylinder 7",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1819",
        title: "P0208",
        desc: "Injector Circuit Malfunction - Cylinder 8",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1820",
        title: "P0209",
        desc: "Injector Circuit Malfunction - Cylinder 9",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1821",
        title: "P020A",
        desc: "Cylinder 1 Injection Timing",
        created_at: "2022-08-18 19:38:08",
        updated_at: "2022-08-18 19:38:08",
    },
    {
        vehicle_error_id: "1822",
        title: "P020B",
        desc: "Cylinder 2 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1823",
        title: "P020C",
        desc: "Cylinder 3 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1824",
        title: "P020D",
        desc: "Cylinder 4 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1825",
        title: "P020E",
        desc: "Cylinder 5 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1826",
        title: "P020F",
        desc: "Cylinder 6 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1827",
        title: "P0210",
        desc: "Injector Circuit Malfunction - Cylinder 10",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1828",
        title: "P0211",
        desc: "Injector Circuit Malfunction - Cylinder 11",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1829",
        title: "P0212",
        desc: "Injector Circuit Malfunction - Cylinder 12",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1830",
        title: "P0213",
        desc: "Cold Start Injector 1 Malfunction",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1831",
        title: "P0214",
        desc: "Cold Start Injector 2 Malfunction",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1832",
        title: "P0215",
        desc: "Engine Shutoff Solenoid Malfunction",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1833",
        title: "P0216",
        desc: "Injection Timing Control Circuit Malfunction",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1834",
        title: "P0217",
        desc: "Engine Overtemp Condition",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1835",
        title: "P0218",
        desc: "Transmission Over Temperature Condition",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1836",
        title: "P0219",
        desc: "Engine Overspeed Condition",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1837",
        title: "P021A",
        desc: "Cylinder 7 Injection Timing",
        created_at: "2022-08-18 19:38:09",
        updated_at: "2022-08-18 19:38:09",
    },
    {
        vehicle_error_id: "1838",
        title: "P021B",
        desc: "Cylinder 8 Injection Timing",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1839",
        title: "P021C",
        desc: "Cylinder 9 Injection Timing",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1840",
        title: "P021D",
        desc: "Cylinder 10 Injection Timing",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1841",
        title: "P021E",
        desc: "Cylinder 11 Injection Timing",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1842",
        title: "P021F",
        desc: "Cylinder 12 Injection Timing",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1843",
        title: "P0220",
        desc: "Throttle/Pedal Position Sensor/Switch B Circuit Malfunction",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1844",
        title: "P0221",
        desc: "Throttle/Pedal Position Sensor/Switch B Circuit Range/Performance",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1845",
        title: "P0222",
        desc: "Throttle/Pedal Position Sensor/Switch B Circuit Low Input",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1846",
        title: "P0223",
        desc: "Throttle/Pedal Position Sensor/Switch B Circuit High Input",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1847",
        title: "P0224",
        desc: "Throttle/Pedal Position Sensor/Switch B Circuit Intermittent",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1848",
        title: "P0225",
        desc: "Throttle/Pedal Position Sensor/Switch C Circuit Malfunction",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1849",
        title: "P0226",
        desc: "Throttle/Pedal Position Sensor/Switch C Circuit Range/Performance",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1850",
        title: "P0227",
        desc: "Throttle/Pedal Position Sensor/Switch C Circuit Low Input",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1851",
        title: "P0228",
        desc: "Throttle/Pedal Position Sensor/Switch C Circuit High Input",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1852",
        title: "P0229",
        desc: "Throttle/Pedal Position Sensor/Switch C Circuit Intermittent",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1853",
        title: "P022A",
        desc: "Charge Air Cooler Bypass ControlACircuit Open",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1854",
        title: "P022B",
        desc: "Charge Air Cooler Bypass ControlACircuit Low",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1855",
        title: "P022C",
        desc: "Charge Air Cooler Bypass ControlACircuit High",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1856",
        title: "P022D",
        desc: "Charge Air Cooler Bypass ControlBCircuit Open",
        created_at: "2022-08-18 19:38:10",
        updated_at: "2022-08-18 19:38:10",
    },
    {
        vehicle_error_id: "1857",
        title: "P022E",
        desc: "Charge Air Cooler Bypass ControlBCircuit Low",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1858",
        title: "P022F",
        desc: "Charge Air Cooler Bypass ControlBCircuit High",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1859",
        title: "P0230",
        desc: "Fuel Pump Primary Circuit Malfunction",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1860",
        title: "P0231",
        desc: "Fuel Pump Secondary Circuit Low",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1861",
        title: "P0232",
        desc: "Fuel Pump Secondary Circuit High",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1862",
        title: "P0233",
        desc: "Fuel Pump Secondary Circuit Intermittent",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1863",
        title: "P0234",
        desc: "Engine Turbocharger/Supercharger Overboost Condition",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1864",
        title: "P0235",
        desc: "Turbocharger Boost Sensor A Circuit Malfunction",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1865",
        title: "P0236",
        desc: "Turbocharger Boost Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1866",
        title: "P0237",
        desc: "Turbocharger Boost Sensor A Circuit Low",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1867",
        title: "P0238",
        desc: "Turbocharger Boost Sensor A Circuit High",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1868",
        title: "P0239",
        desc: "Turbocharger Boost Sensor B Circuit Malfunction",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1869",
        title: "P023A",
        desc: "Charge Air Cooler Coolant Pump Control Circuit Open",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1870",
        title: "P023B",
        desc: "Charge Air Cooler Coolant Pump Control Circuit Low",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1871",
        title: "P023C",
        desc: "Charge Air Cooler Coolant Pump Control Circuit High",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1872",
        title: "P023D",
        desc: "Manifold Absolute Pressure - Turbocharger/Supercharger Boost SensorACorrelation",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1873",
        title: "P023E",
        desc: "Manifold Absolute Pressure - Turbocharger/Supercharger Boost SensorBCorrelation",
        created_at: "2022-08-18 19:38:11",
        updated_at: "2022-08-18 19:38:11",
    },
    {
        vehicle_error_id: "1874",
        title: "P023F",
        desc: "Fuel Pump Secondary Circuit/Open ",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1875",
        title: "P0240",
        desc: "Turbocharger Boost Sensor B Circuit Range/Performance",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1876",
        title: "P0241",
        desc: "Turbocharger Boost Sensor B Circuit Low",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1877",
        title: "P0242",
        desc: "Turbocharger Boost Sensor B Circuit High",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1878",
        title: "P0243",
        desc: "Turbocharger Wastegate Solenoid A Malfunction",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1879",
        title: "P0244",
        desc: "Turbocharger Wastegate Solenoid A Range/Performance",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1880",
        title: "P0245",
        desc: "Turbocharger Wastegate Solenoid A Low",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1881",
        title: "P0246",
        desc: "Turbocharger Wastegate Solenoid A High",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1882",
        title: "P0247",
        desc: "Turbocharger Wastegate Solenoid B Malfunction",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1883",
        title: "P0248",
        desc: "Turbocharger Wastegate Solenoid B Range/Performance",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1884",
        title: "P0249",
        desc: "Turbocharger Wastegate Solenoid B Low",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1885",
        title: "P024A",
        desc: "Charge Air Cooler Bypass ControlARange/Performance",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1886",
        title: "P024B",
        desc: "Charge Air Cooler Bypass ControlAStuck",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1887",
        title: "P024C",
        desc: "Charge Air Cooler Bypass Position SensorACircuit",
        created_at: "2022-08-18 19:38:12",
        updated_at: "2022-08-18 19:38:12",
    },
    {
        vehicle_error_id: "1888",
        title: "P024D",
        desc: "Charge Air Cooler Bypass Position SensorACircuit Range/Performance",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1889",
        title: "P024E",
        desc: "Charge Air Cooler Bypass Position SensorACircuit Low",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1890",
        title: "P024F",
        desc: "Charge Air Cooler Bypass Position SensorACircuit High",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1891",
        title: "P0250",
        desc: "Turbocharger Wastegate Solenoid B High",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1892",
        title: "P0251",
        desc: "Injection Pump Fuel Metering Control A Malfunction (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1893",
        title: "P0252",
        desc: "Injection Pump Fuel Metering Control A Range/Performance (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1894",
        title: "P0253",
        desc: "Injection Pump Fuel Metering Control A Low (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1895",
        title: "P0254",
        desc: "Injection Pump Fuel Metering Control A High (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:13",
        updated_at: "2022-08-18 19:38:13",
    },
    {
        vehicle_error_id: "1896",
        title: "P0255",
        desc: "Injection Pump Fuel Metering Control A Intermittent (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1897",
        title: "P0256",
        desc: "Injection Pump Fuel Metering Control B Malfunction (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1898",
        title: "P0257",
        desc: "Injection Pump Fuel Metering Control B Range/Performance Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1899",
        title: "P0258",
        desc: "Injection Pump Fuel Metering Control B Low (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1900",
        title: "P0259",
        desc: "Injection Pump Fuel Metering Control B High (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1901",
        title: "P025A",
        desc: "Fuel Pump Module Control Circuit/Open",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1902",
        title: "P025B",
        desc: "Fuel Pump Module Control Circuit Range/Performance",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1903",
        title: "P025C",
        desc: "Fuel Pump Module Control Circuit Low",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1904",
        title: "P025D",
        desc: "Fuel Pump Module Control Circuit High",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1905",
        title: "P025E",
        desc: "& P025F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1906",
        title: "P0260",
        desc: "Injection Pump Fuel Metering ControlBIntermittent (Cam/Rotor/Injector)",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1907",
        title: "P0261",
        desc: "Cylinder 1 Injector Circuit Low",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1908",
        title: "P0262",
        desc: "Cylinder 1 Injector Circuit High",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1909",
        title: "P0263",
        desc: "Cylinder 1 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1910",
        title: "P0264",
        desc: "Cylinder 2 Injector Circuit Low",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1911",
        title: "P0265",
        desc: "Cylinder 2 Injector Circuit High",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1912",
        title: "P0266",
        desc: "Cylinder 2 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1913",
        title: "P0267",
        desc: "Cylinder 3 Injector Circuit Low",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1914",
        title: "P0268",
        desc: "Cylinder 3 Injector Circuit High",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1915",
        title: "P0269",
        desc: "Cylinder 3 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1916",
        title: "P026A,",
        desc: "P026B, P026C, P026D, P026E, P026F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:14",
        updated_at: "2022-08-18 19:38:14",
    },
    {
        vehicle_error_id: "1917",
        title: "P0270",
        desc: "Cylinder 4 Injector Circuit Low",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1918",
        title: "P0271",
        desc: "Cylinder 4 Injector Circuit High",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1919",
        title: "P0272",
        desc: "Cylinder 4 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1920",
        title: "P0273",
        desc: "Cylinder 5 Injector Circuit Low",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1921",
        title: "P0274",
        desc: "Cylinder 5 Injector Circuit High",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1922",
        title: "P0275",
        desc: "Cylinder 5 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1923",
        title: "P0276",
        desc: "Cylinder 6 Injector Circuit Low",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1924",
        title: "P0277",
        desc: "Cylinder 6 Injector Circuit High",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1925",
        title: "P0278",
        desc: "Cylinder 6 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1926",
        title: "P0279",
        desc: "Cylinder 7 Injector Circuit Low",
        created_at: "2022-08-18 19:38:15",
        updated_at: "2022-08-18 19:38:15",
    },
    {
        vehicle_error_id: "1927",
        title: "P027A,",
        desc: "P027B, P027C, P027D, P027E, P027F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:16",
        updated_at: "2022-08-18 19:38:16",
    },
    {
        vehicle_error_id: "1928",
        title: "P0280",
        desc: "Cylinder 7 Injector Circuit High",
        created_at: "2022-08-18 19:38:16",
        updated_at: "2022-08-18 19:38:16",
    },
    {
        vehicle_error_id: "1929",
        title: "P0281",
        desc: "Cylinder 7 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:16",
        updated_at: "2022-08-18 19:38:16",
    },
    {
        vehicle_error_id: "1930",
        title: "P0282",
        desc: "Cylinder 8 Injector Circuit Low",
        created_at: "2022-08-18 19:38:16",
        updated_at: "2022-08-18 19:38:16",
    },
    {
        vehicle_error_id: "1931",
        title: "P0283",
        desc: "Cylinder 8 Injector Circuit High",
        created_at: "2022-08-18 19:38:16",
        updated_at: "2022-08-18 19:38:16",
    },
    {
        vehicle_error_id: "1932",
        title: "P0284",
        desc: "Cylinder 8 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1933",
        title: "P0285",
        desc: "Cylinder 9 Injector Circuit Low",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1934",
        title: "P0286",
        desc: "Cylinder 9 Injector Circuit High",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1935",
        title: "P0287",
        desc: "Cylinder 9 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1936",
        title: "P0288",
        desc: "Cylinder 10 Injector Circuit Low",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1937",
        title: "P0289",
        desc: "Cylinder 10 Injector Circuit High",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1938",
        title: "P028A,",
        desc: "P028B, P028C, P028D, P028E, P028F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1939",
        title: "P0290",
        desc: "Cylinder 10 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1940",
        title: "P0291",
        desc: "Cylinder 11 Injector Circuit Low",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1941",
        title: "P0292",
        desc: "Cylinder 11 Injector Circuit High",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1942",
        title: "P0293",
        desc: "Cylinder 11 Contribution/Balance Fault",
        created_at: "2022-08-18 19:38:17",
        updated_at: "2022-08-18 19:38:17",
    },
    {
        vehicle_error_id: "1943",
        title: "P0294",
        desc: "Cylinder 12 Injector Circuit Low",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1944",
        title: "P0295",
        desc: "Cylinder 12 Injector Circuit High",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1945",
        title: "P0296",
        desc: "Cylinder 12 Contribution/Range Fault",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1946",
        title: "P0297",
        desc: "Vehicle Overspeed Condition",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1947",
        title: "P0298",
        desc: "Engine Oil Over Temperature Condition",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1948",
        title: "P0299",
        desc: "Turbocharger/SuperchargerAUnderboost Condition",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1949",
        title: "P029A",
        desc: "Cylinder 1 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1950",
        title: "P029B",
        desc: "Cylinder 1 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1951",
        title: "P029C",
        desc: "Cylinder 1 - Injector Restricted",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1952",
        title: "P029D",
        desc: "Cylinder 1 - Injector Leaking",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1953",
        title: "P029E",
        desc: "Cylinder 2 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1954",
        title: "P029F",
        desc: "Cylinder 2 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1955",
        title: "P02A0",
        desc: "Cylinder 2 - Injector Restricted",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1956",
        title: "P02A1",
        desc: "Cylinder 2 - Injector Leaking",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1957",
        title: "P02A2",
        desc: "Cylinder 3 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:18",
        updated_at: "2022-08-18 19:38:18",
    },
    {
        vehicle_error_id: "1958",
        title: "P02A3",
        desc: "Cylinder 3 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1959",
        title: "P02A4",
        desc: "Cylinder 3 - Injector Restricted",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1960",
        title: "P02A5",
        desc: "Cylinder 3 - Injector Leaking",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1961",
        title: "P02A6",
        desc: "Cylinder 4 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1962",
        title: "P02A7",
        desc: "Cylinder 4 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1963",
        title: "P02A8",
        desc: "Cylinder 4 - Injector Restricted",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1964",
        title: "P02A9",
        desc: "Cylinder 4 - Injector Leaking",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1965",
        title: "P02AA",
        desc: "Cylinder 5 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1966",
        title: "P02AB",
        desc: "Cylinder 5 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:19",
        updated_at: "2022-08-18 19:38:19",
    },
    {
        vehicle_error_id: "1967",
        title: "P02AC",
        desc: "Cylinder 5 - Injector Restricted",
        created_at: "2022-08-18 19:38:20",
        updated_at: "2022-08-18 19:38:20",
    },
    {
        vehicle_error_id: "1968",
        title: "P02AD",
        desc: "Cylinder 5 - Injector Leaking",
        created_at: "2022-08-18 19:38:20",
        updated_at: "2022-08-18 19:38:20",
    },
    {
        vehicle_error_id: "1969",
        title: "P02AE",
        desc: "Cylinder 6 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:20",
        updated_at: "2022-08-18 19:38:20",
    },
    {
        vehicle_error_id: "1970",
        title: "P02AF",
        desc: "Cylinder 6 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:20",
        updated_at: "2022-08-18 19:38:20",
    },
    {
        vehicle_error_id: "1971",
        title: "P02B0",
        desc: "Cylinder 6 - Injector Restricted",
        created_at: "2022-08-18 19:38:20",
        updated_at: "2022-08-18 19:38:20",
    },
    {
        vehicle_error_id: "1972",
        title: "P02B1",
        desc: "Cylinder 6 - Injector Leaking",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1973",
        title: "P02B2",
        desc: "Cylinder 7 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1974",
        title: "P02B3",
        desc: "Cylinder 7 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1975",
        title: "P02B4",
        desc: "Cylinder 7 - Injector Restricted",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1976",
        title: "P02B5",
        desc: "Cylinder 7 - Injector Leaking",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1977",
        title: "P02B6",
        desc: "Cylinder 8 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1978",
        title: "P02B7",
        desc: "Cylinder 8 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:21",
        updated_at: "2022-08-18 19:38:21",
    },
    {
        vehicle_error_id: "1979",
        title: "P02B8",
        desc: "Cylinder 8 - Injector Restricted",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1980",
        title: "P02B9",
        desc: "Cylinder 8 - Injector Leaking",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1981",
        title: "P02BA",
        desc: "Cylinder 9 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1982",
        title: "P02BB",
        desc: "Cylinder 9 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1983",
        title: "P02BC",
        desc: "Cylinder 9 - Injector Restricted",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1984",
        title: "P02BD",
        desc: "Cylinder 9 - Injector Leaking",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1985",
        title: "P02BE",
        desc: "Cylinder 10 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1986",
        title: "P02BF",
        desc: "Cylinder 10 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1987",
        title: "P02C0",
        desc: "Cylinder 10 - Injector Restricted",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1988",
        title: "P02C1",
        desc: "Cylinder 10 - Injector Leaking",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1989",
        title: "P02C2",
        desc: "Cylinder 11 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1990",
        title: "P02C3",
        desc: "Cylinder 11 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:22",
        updated_at: "2022-08-18 19:38:22",
    },
    {
        vehicle_error_id: "1991",
        title: "P02C4",
        desc: "Cylinder 11 - Injector Restricted",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1992",
        title: "P02C5",
        desc: "Cylinder 11 - Injector Leaking",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1993",
        title: "P02C6",
        desc: "Cylinder 12 - Fuel Trim at Max Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1994",
        title: "P02C7",
        desc: "Cylinder 12 - Fuel Trim at Min Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1995",
        title: "P02C8",
        desc: "Cylinder 12 - Injector Restricted",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1996",
        title: "P02C9",
        desc: "Cylinder 12 - Injector Leaking",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1997",
        title: "P02CA",
        desc: "Turbocharger/SuperchargerBOverboost Condition",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1998",
        title: "P02CB",
        desc: "Turbocharger/SuperchargerBUnderboost Condition",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "1999",
        title: "P02CC",
        desc: "Cylinder 1 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "2000",
        title: "P02CD",
        desc: "Cylinder 1 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "2001",
        title: "P02CE",
        desc: "Cylinder 2 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "2002",
        title: "P02CF",
        desc: "Cylinder 2 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "2003",
        title: "P02D0",
        desc: "Cylinder 3 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:23",
        updated_at: "2022-08-18 19:38:23",
    },
    {
        vehicle_error_id: "2004",
        title: "P02D1",
        desc: "Cylinder 3 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2005",
        title: "P02D2",
        desc: "Cylinder 4 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2006",
        title: "P02D3",
        desc: "Cylinder 4 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2007",
        title: "P02D4",
        desc: "Cylinder 5 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2008",
        title: "P02D5",
        desc: "Cylinder 5 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2009",
        title: "P02D6",
        desc: "Cylinder 6 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2010",
        title: "P02D7",
        desc: "Cylinder 6 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2011",
        title: "P02D8",
        desc: "Cylinder 7 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2012",
        title: "P02D9",
        desc: "Cylinder 7 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2013",
        title: "P02DA",
        desc: "Cylinder 8 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2014",
        title: "P02DB",
        desc: "Cylinder 8 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2015",
        title: "P02DC",
        desc: "Cylinder 9 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2016",
        title: "P02DD",
        desc: "Cylinder 9 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:24",
        updated_at: "2022-08-18 19:38:24",
    },
    {
        vehicle_error_id: "2017",
        title: "P02DE",
        desc: "Cylinder 10 Fuel Injector Offset Learning At Min Limit",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2018",
        title: "P02DF",
        desc: "Cylinder 10 Fuel Injector Offset Learning At Max Limit",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2019",
        title: "P02E0",
        desc: "Diesel Intake Air Flow Control Circuit/Open",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2020",
        title: "P02E1",
        desc: "Diesel Intake Air Flow Control Performance",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2021",
        title: "P02E2",
        desc: "Diesel Intake Air Flow Control Circuit Low",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2022",
        title: "P02E3",
        desc: "Diesel Intake Air Flow Control Circuit High",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2023",
        title: "P02E4",
        desc: "Diesel Intake Air Flow Control Stuck Open",
        created_at: "2022-08-18 19:38:25",
        updated_at: "2022-08-18 19:38:25",
    },
    {
        vehicle_error_id: "2024",
        title: "P02E5",
        desc: "Diesel Intake Air Flow Control Stuck Closed",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2025",
        title: "P02E6",
        desc: "Diesel Intake Air Flow Position Sensor Circuit",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2026",
        title: "P02E7",
        desc: "Diesel Intake Air Flow Position Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2027",
        title: "P02E8",
        desc: "Diesel Intake Air Flow Position Sensor Circuit Low",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2028",
        title: "P02E9",
        desc: "Diesel Intake Air Flow Position Sensor Circuit High",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2029",
        title: "P02EA",
        desc: "Diesel Intake Air Flow Position Sensor Circuit Intermittent/Erratic",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2030",
        title: "P02EB",
        desc: "Diesel Intake Air Flow Control Motor Current Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2031",
        title: "P02EC",
        desc: "Diesel Intake Air Flow Control System - High Air Flow Detected",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2032",
        title: "P02ED",
        desc: "Diesel Intake Air Flow Control System - Low Air Flow Detected",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2033",
        title: "P02EE",
        desc: "Cylinder 1 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2034",
        title: "P02EF",
        desc: "Cylinder 2 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2035",
        title: "P02F0",
        desc: "Cylinder 3 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2036",
        title: "P02F1",
        desc: "Cylinder 4 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:26",
        updated_at: "2022-08-18 19:38:26",
    },
    {
        vehicle_error_id: "2037",
        title: "P02F2",
        desc: "Cylinder 5 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2038",
        title: "P02F3",
        desc: "Cylinder 6 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2039",
        title: "P02F4",
        desc: "Cylinder 7 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2040",
        title: "P02F5",
        desc: "Cylinder 8 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2041",
        title: "P02F6",
        desc: "Cylinder 9 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2042",
        title: "P02F7",
        desc: "Cylinder 10 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2043",
        title: "P02F8",
        desc: "Cylinder 11 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2044",
        title: "P02F9",
        desc: "Cylinder 12 Injector Circuit Range/Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2045",
        title: "P02FA",
        desc: "Diesel Intake Air Flow Position Sensor Minimum/Maximum Stop Performance",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2046",
        title: "P02FB,",
        desc: "P02FC, P02FD, P02FE, P02FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2047",
        title: "P0100",
        desc: "Mass or Volume Air FlowACircuit Malfunction",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2048",
        title: "P0101",
        desc: "Mass or Volume Air FlowACircuit Range/Performance Problem",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2049",
        title: "P0102",
        desc: "Mass or Volume Air FlowACircuit Low Input",
        created_at: "2022-08-18 19:38:27",
        updated_at: "2022-08-18 19:38:27",
    },
    {
        vehicle_error_id: "2050",
        title: "P0103",
        desc: "Mass or Volume Air FlowACircuit High Input",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2051",
        title: "P0104",
        desc: "Mass or Volume Air FlowACircuit Intermittent",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2052",
        title: "P0105",
        desc: "Manifold Absolute Pressure/Barometric Pressure Circuit Malfunction",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2053",
        title: "P0106",
        desc: "Manifold Absolute Pressure/Barometric Pressure Circuit Range/Performance Problem",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2054",
        title: "P0107",
        desc: "Manifold Absolute Pressure/Barometric Pressure Circuit Low Input",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2055",
        title: "P0108",
        desc: "Manifold Absolute Pressure/Barometric Pressure Circuit High Input",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2056",
        title: "P0109",
        desc: "Manifold Absolute Pressure/Barometric Pressure Circuit Intermittent",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2057",
        title: "P010A",
        desc: "Mass or Volume Air FlowBCircuit",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2058",
        title: "P010B",
        desc: "Mass or Volume Air FlowBCircuit Range/Performance",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2059",
        title: "P010C",
        desc: "Mass or Volume Air FlowBCircuit Low",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2060",
        title: "P010D",
        desc: "Mass or Volume Air FlowBCircuit High",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2061",
        title: "P010E",
        desc: "Mass or Volume Air FlowBCircuit Intermittent/Erratic",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2062",
        title: "P010F",
        desc: "Mass or Volume Air Flow Sensor A/B Correlation",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2063",
        title: "P0110",
        desc: "Intake Air Temperature Circuit Malfunction Bank 1",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2064",
        title: "P0111",
        desc: "Intake Air Temperature Circuit Range/Performance Problem Bank 1",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2065",
        title: "P0112",
        desc: "Intake Air Temperature Circuit Low Input Bank 1",
        created_at: "2022-08-18 19:38:28",
        updated_at: "2022-08-18 19:38:28",
    },
    {
        vehicle_error_id: "2066",
        title: "P0113",
        desc: "Intake Air Temperature Circuit High Input Bank 1",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2067",
        title: "P0114",
        desc: "Intake Air Temperature Circuit Intermittent Bank 1",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2068",
        title: "P0115",
        desc: "Engine Coolant Temperature Sensor Circuit 1 Malfunction",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2069",
        title: "P0116",
        desc: "Engine Coolant Temperature Sensor Circuit 1 Range/Performance Problem",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2070",
        title: "P0117",
        desc: "Engine Coolant Temperature Sensor Circuit 1 Low Input",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2071",
        title: "P0118",
        desc: "Engine Coolant Temperature Sensor Circuit 1 High Input",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2072",
        title: "P0119",
        desc: "Engine Coolant Temperature Sensor Circuit 1 Intermittent",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2073",
        title: "P011A",
        desc: "Engine Coolant Temperature Sensor 1/2 Correlation",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2074",
        title: "P011B",
        desc: "Coolant Temperature/Intake Air Temperature Correlation",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2075",
        title: "P011C",
        desc: "Charge Air Temperature/Intake Air Temperature Correlation Bank 1",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2076",
        title: "P011D",
        desc: "Charge Air Temperature/Intake Air Temperature Correlation Bank 2",
        created_at: "2022-08-18 19:38:29",
        updated_at: "2022-08-18 19:38:29",
    },
    {
        vehicle_error_id: "2077",
        title: "P011E,",
        desc: "P011F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2078",
        title: "P0120",
        desc: "Throttle Position Sensor/Switch A Circuit Malfunction",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2079",
        title: "P0121",
        desc: "Throttle Position Sensor/Switch A Circuit Range/Performance Problem",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2080",
        title: "P0122",
        desc: "Throttle Position Sensor/Switch A Circuit Low Input",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2081",
        title: "P0123",
        desc: "Throttle Position Sensor/Switch A Circuit High Input",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2082",
        title: "P0124",
        desc: "Throttle Position Sensor/Switch A Circuit Intermittent",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2083",
        title: "P0125",
        desc: "Insufficient Coolant Temperature for Closed Loop Fuel Control",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2084",
        title: "P0126",
        desc: "Insufficient Coolant Temperature for Stable Operation",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2085",
        title: "P0127",
        desc: "Intake Air Temperature Too High",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2086",
        title: "P0128",
        desc: "Coolant Thermostat (Coolant Temperature Below Thermostat Regulating Temperature)",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2087",
        title: "P0129",
        desc: "Barometric Pressure Too Low",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2088",
        title: "P012A",
        desc: "Turbocharger/Supercharger Inlet Pressure Sensor Circuit (Downstream of throttle valve) ",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2089",
        title: "P012B",
        desc: "Turbocharger/Supercharger Inlet Pressure Sensor Circuit Range/Performance (Downstream of throttle valve) ",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2090",
        title: "P012C",
        desc: "Turbocharger/Supercharger Inlet Pressure Sensor Circuit Low (Downstream of throttle valve)",
        created_at: "2022-08-18 19:38:30",
        updated_at: "2022-08-18 19:38:30",
    },
    {
        vehicle_error_id: "2091",
        title: "P012D",
        desc: "Turbocharger/Supercharger Inlet Pressure Sensor Circuit High (Downstream of throttle valve) ",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2092",
        title: "P012E",
        desc: "Turbocharger/Supercharger Inlet Pressure Sensor Circuit Intermittent/Erratic (Downstream of throttle valve)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2093",
        title: "P012F/SAE",
        desc: "Reserved ",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2094",
        title: "P0130",
        desc: "02 Sensor Circuit Malfunction (Bank I Sensor 1)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2095",
        title: "P0131",
        desc: "02 Sensor Circuit Low Voltage (Bank I Sensor I)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2096",
        title: "P0132",
        desc: "02 Sensor Circuit High Voltage (Bank I Sensor 1)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2097",
        title: "P0133",
        desc: "02 Sensor Circuit Slow Response (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2098",
        title: "P0134",
        desc: "02 Sensor Circuit No Activity Detected (Bank I Sensor 1)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2099",
        title: "P0135",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2100",
        title: "P0136",
        desc: "02 Sensor Circuit Malfunction (Bank I Sensor 2)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2101",
        title: "P0137",
        desc: "02 Sensor Circuit Low Voltage (Bank I Sensor 2)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2102",
        title: "P0138",
        desc: "02 Sensor Circuit High Voltage (Bank I Sensor 2)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2103",
        title: "P0139",
        desc: "02 Sensor Circuit Slow Response (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2104",
        title: "P013A",
        desc: "O2 Sensor Slow Response - Rich to Lean (Bank 1 Sensor 2) ",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2105",
        title: "P013B",
        desc: "O2 Sensor Slow Response - Lean to Rich (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2106",
        title: "P013C",
        desc: "O2 Sensor Slow Response - Rich to Lean (Bank 2 Sensor 2) ",
        created_at: "2022-08-18 19:38:31",
        updated_at: "2022-08-18 19:38:31",
    },
    {
        vehicle_error_id: "2107",
        title: "P013D",
        desc: "O2 Sensor Slow Response - Lean to Rich (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2108",
        title: "P013E",
        desc: "O2 Sensor Delayed Response - Rich to Lean (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2109",
        title: "P013F",
        desc: "O2 Sensor Delayed Response - Lean to Rich (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2110",
        title: "P0140",
        desc: "02 Sensor Circuit No Activity Detected (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2111",
        title: "P0141",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 1 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2112",
        title: "P0142",
        desc: "02 Sensor Circuit Malfunction (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2113",
        title: "P0143",
        desc: "02 Sensor Circuit Low Voltage (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2114",
        title: "P0144",
        desc: "02 Sensor Circuit High Voltage (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2115",
        title: "P0145",
        desc: "02 Sensor Circuit Slow Response (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2116",
        title: "P0146",
        desc: "02 Sensor Circuit No Activity Detected (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2117",
        title: "P0147",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 1 Sensor 3)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2118",
        title: "P0148",
        desc: "Fuel Delivery Error ",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2119",
        title: "P0149",
        desc: "Fuel Timing Error",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2120",
        title: "P014A",
        desc: "O2 Sensor Delayed Response - Rich to Lean (Bank 2 Sensor 2) ",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2121",
        title: "P014B",
        desc: "O2 Sensor Delayed Response - Lean to Rich (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2122",
        title: "P014C",
        desc: "O2 Sensor Slow Response - Rich to Lean (Bank 1 Sensor 1) ",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2123",
        title: "P014D",
        desc: "O2 Sensor Slow Response - Lean to Rich (Bank 1 Sensor 1) ",
        created_at: "2022-08-18 19:38:32",
        updated_at: "2022-08-18 19:38:32",
    },
    {
        vehicle_error_id: "2124",
        title: "P014E",
        desc: "O2 Sensor Slow Response - Rich to Lean (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2125",
        title: "P0150",
        desc: "02 Sensor Circuit Malfunction (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2126",
        title: "P0151",
        desc: "02 Sensor Circuit Low Voltage (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2127",
        title: "P0152",
        desc: "02 Sensor Circuit High Voltage (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2128",
        title: "P0153",
        desc: "02 Sensor Circuit Slow Response (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2129",
        title: "P0154",
        desc: "02 Sensor Circuit No Activity Detected (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2130",
        title: "P0155",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 2 Sensor 1)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2131",
        title: "P0156",
        desc: "02 Sensor Circuit Malfunction (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2132",
        title: "P0157",
        desc: "02 Sensor Circuit Low Voltage (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2133",
        title: "P0158",
        desc: "02 Sensor Circuit High Voltage (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2134",
        title: "P0159",
        desc: "02 Sensor Circuit Slow Response (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:33",
        updated_at: "2022-08-18 19:38:33",
    },
    {
        vehicle_error_id: "2135",
        title: "P015A",
        desc: "O2 Sensor Delayed Response - Rich to Lean (Bank 1 Sensor 1)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2136",
        title: "P015B",
        desc: "O2 Sensor Delayed Response - Lean to Rich (Bank 1 Sensor 1) ",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2137",
        title: "P015C",
        desc: "O2 Sensor Delayed Response - Rich to Lean (Bank 2 Sensor 1) ",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2138",
        title: "P015D",
        desc: "O2 Sensor Delayed Response - Lean to Rich (Bank 2 Sensor 1) ",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2139",
        title: "P015E\t&",
        desc: "P015F ISO/SAE Reserved ",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2140",
        title: "P0160",
        desc: "02 Sensor Circuit No Activity Detected (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2141",
        title: "P0161",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 2 Sensor 2)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2142",
        title: "P0162",
        desc: "02 Sensor Circuit Malfunction (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2143",
        title: "P0163",
        desc: "02 Sensor Circuit Low Voltage (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2144",
        title: "P0164",
        desc: "02 Sensor Circuit High Voltage (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2145",
        title: "P0165",
        desc: "02 Sensor Circuit Slow Response (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2146",
        title: "P0166",
        desc: "02 Sensor Circuit No Activity Detected (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:34",
        updated_at: "2022-08-18 19:38:34",
    },
    {
        vehicle_error_id: "2147",
        title: "P0167",
        desc: "02 Sensor Heater Circuit Malfunction (Bank 2 Sensor 3)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2148",
        title: "P0168",
        desc: "Temperature Too High",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2149",
        title: "P0169",
        desc: "Incorrect Fuel Composition ",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2150",
        title: "P016A,",
        desc: "P016B, P016C, P016D, P016E, P016F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2151",
        title: "P0170",
        desc: "Fuel Trim Malfunction (Bank 1)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2152",
        title: "P0171",
        desc: "System too Lean (Bank 1)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2153",
        title: "P0172",
        desc: "System too Rich (Bank 1)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2154",
        title: "P0173",
        desc: "Fuel Trim Malfunction (Bank 2)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2155",
        title: "P0174",
        desc: "System too Lean (Bank 2)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2156",
        title: "P0175",
        desc: "System too Rich (Bank 2)",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2157",
        title: "P0176",
        desc: "Fuel Composition Sensor Circuit Malfunction",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2158",
        title: "P0177",
        desc: "Fuel Composition Sensor Circuit Range/Performance",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2159",
        title: "P0178",
        desc: "Fuel Composition Sensor Circuit Low Input",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2160",
        title: "P0179",
        desc: "Fuel Composition Sensor Circuit High Input",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2161",
        title: "P017A,",
        desc: "P017B, P017C, P017D, P017E, P017F ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:35",
        updated_at: "2022-08-18 19:38:35",
    },
    {
        vehicle_error_id: "2162",
        title: "P0180",
        desc: "Fuel Temperature Sensor A Circuit Malfunction",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2163",
        title: "P0181",
        desc: "Fuel Temperature Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2164",
        title: "P0182",
        desc: "Fuel Temperature Sensor A Circuit Low Input",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2165",
        title: "P0183",
        desc: "Fuel Temperature Sensor A Circuit High Input",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2166",
        title: "P0184",
        desc: "Fuel Temperature Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2167",
        title: "P0185",
        desc: "Fuel Temperature Sensor B Circuit Malfunction",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2168",
        title: "P0186",
        desc: "Fuel Temperature Sensor B Circuit Range/Performance",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2169",
        title: "P0187",
        desc: "Fuel Temperature Sensor B Circuit Low Input",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2170",
        title: "P0188",
        desc: "Fuel Temperature Sensor B Circuit High Input",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2171",
        title: "P0189",
        desc: "Fuel Temperature Sensor B Circuit Intermittent",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2172",
        title: "P018A",
        desc: "Fuel Pressure SensorBCircuit ",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2173",
        title: "P018B",
        desc: "Fuel Pressure SensorBCircuit Range/Performance",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2174",
        title: "P018C",
        desc: "Fuel Pressure SensorBCircuit Low",
        created_at: "2022-08-18 19:38:36",
        updated_at: "2022-08-18 19:38:36",
    },
    {
        vehicle_error_id: "2175",
        title: "P018D",
        desc: "Fuel Pressure SensorBCircuit High",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2176",
        title: "P018E",
        desc: "Fuel Pressure SensorBCircuit Intermittent/Erratic ",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2177",
        title: "P018F",
        desc: "Fuel System Over Pressure Relief Valve Frequent Activation",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2178",
        title: "P0190",
        desc: "Fuel Rail Pressure Sensor A Circuit",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2179",
        title: "P0191",
        desc: "Fuel Rail Pressure Sensor A Circuit Range/Performance",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2180",
        title: "P0192",
        desc: "Fuel Rail Pressure Sensor A Circuit Low Input",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2181",
        title: "P0193",
        desc: "Fuel Rail Pressure Sensor A Circuit High Input",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2182",
        title: "P0194",
        desc: "Fuel Rail Pressure Sensor A Circuit Intermittent",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2183",
        title: "P0195",
        desc: "Engine Oil Temperature Sensor Malfunction",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2184",
        title: "P0196",
        desc: "Engine Oil Temperature Sensor Range/Performance",
        created_at: "2022-08-18 19:38:37",
        updated_at: "2022-08-18 19:38:37",
    },
    {
        vehicle_error_id: "2185",
        title: "P0197",
        desc: "Engine Oil Temperature Sensor Low",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2186",
        title: "P0198",
        desc: "Engine Oil Temperature Sensor High",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2187",
        title: "P0199",
        desc: "Engine Oil Temperature Sensor Intermittent",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2188",
        title: "P019A",
        desc: "P01FF ISO/SAE Reserved",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2189",
        title: "B1200",
        desc: "Climate Control Pushbutton Circuit Failure",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2190",
        title: "B1201",
        desc: "Fuel Sender Circuit Failure",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2191",
        title: "B1202",
        desc: "Fuel Sender Circuit Open",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2192",
        title: "B1203",
        desc: "Fuel Sender Circuit Short To Battery",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2193",
        title: "B1204",
        desc: "Fuel Sender Circuit Short To Ground",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2194",
        title: "B1205",
        desc: "EIC Switch-1 Assembly Circuit Failure",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2195",
        title: "B1206",
        desc: "EIC Switch-1 Assembly Circuit Open",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2196",
        title: "B1207",
        desc: "EIC Switch-1 Assembly Circuit Short To Battery",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2197",
        title: "B1208",
        desc: "EIC Switch-1 Assembly Circuit Short To Ground",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2198",
        title: "B1209",
        desc: "EIC Switch-2 Assembly Circuit Failure",
        created_at: "2022-08-18 19:38:38",
        updated_at: "2022-08-18 19:38:38",
    },
    {
        vehicle_error_id: "2199",
        title: "B1210",
        desc: "EIC Switch-2 Assembly Circuit Open",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2200",
        title: "B1211",
        desc: "EIC Switch-2 Assembly Circuit Short To Battery",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2201",
        title: "B1212",
        desc: "EIC Switch-2 Assembly Circuit Short To Ground",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2202",
        title: "B1213",
        desc: "Anti-Theft Number of Programmed Keys Is Below Minimum",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2203",
        title: "B1214",
        desc: "Running Board Lamp Circuit Failure",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2204",
        title: "B1215",
        desc: "Running Board Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2205",
        title: "B1216",
        desc: "Emergency & Road Side Assistance Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2206",
        title: "B1217",
        desc: "Horn Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2207",
        title: "B1218",
        desc: "Horn Relay Coil Circuit Short to Vbatt",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2208",
        title: "B1219",
        desc: "Fuel Tank Pressure Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2209",
        title: "B1220",
        desc: "Fuel Tank Pressure Sensor Circuit Open",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2210",
        title: "B1222",
        desc: "Fuel Temperature Sensor #1 Circuit Failure",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2211",
        title: "B1223",
        desc: "Fuel Temperature Sensor #1 Circuit Open",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2212",
        title: "B1224",
        desc: "Fuel Temperature Sensor #1 Circuit Short to Battery",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2213",
        title: "B1225",
        desc: "Fuel Temperature Sensor #1 Circuit Short to Ground",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2214",
        title: "B1226",
        desc: "Fuel Temperature Sensor #2 Circuit Failure",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2215",
        title: "B1227",
        desc: "Fuel Temperature Sensor #2 Circuit Open",
        created_at: "2022-08-18 19:38:39",
        updated_at: "2022-08-18 19:38:39",
    },
    {
        vehicle_error_id: "2216",
        title: "B1228",
        desc: "Fuel Temperature Sensor #2 Circuit Short to Battery",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2217",
        title: "B1229",
        desc: "Fuel Temperature Sensor #2 Circuit Short to Ground",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2218",
        title: "B1231",
        desc: "Longitudinal Acceleration Threshold Exceeded",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2219",
        title: "B1232",
        desc: "See Manufacturer",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2220",
        title: "B1233",
        desc: "Glass Break Sensor Failure",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2221",
        title: "B1234",
        desc: "Mirror Switch Invalid Code",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2222",
        title: "B1235",
        desc: "Window Feedback Failure",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2223",
        title: "B1236",
        desc: "Window Feedback Loss of Signal",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2224",
        title: "B1237",
        desc: "Window Feedback Out of Range",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2225",
        title: "B1238",
        desc: "Over Temperature Fault",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2226",
        title: "B1239",
        desc: "Air Flow Blend Door Driver Circuit Failure",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2227",
        title: "B1240",
        desc: "Wiper Washer Rear Pump Relay Circuit Failure",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2228",
        title: "B1241",
        desc: "Wiper Washer Rear Pump Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2229",
        title: "B1242",
        desc: "Air Flow Recirculation Door Driver Circuit Failure",
        created_at: "2022-08-18 19:38:40",
        updated_at: "2022-08-18 19:38:40",
    },
    {
        vehicle_error_id: "2230",
        title: "B1243",
        desc: "Express Window Down Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2231",
        title: "B1244",
        desc: "Wiper Rear Motor Run Relay Circuit Failure",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2232",
        title: "B1245",
        desc: "Wiper Rear Motor Run Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2233",
        title: "B1246",
        desc: "Dim Panel Potentiometer Switch Circuit Failure",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2234",
        title: "B1247",
        desc: "Panel Dim Switch Circuit Open",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2235",
        title: "B1249",
        desc: "Blend Door Failure",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2236",
        title: "B1250",
        desc: "Air Temperature Internal Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2237",
        title: "B1251",
        desc: "Air Temperature Internal Sensor Circuit Open",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2238",
        title: "B1252",
        desc: "Air Temperature Internal Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2239",
        title: "B1253",
        desc: "Air Temperature Internal Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2240",
        title: "B1254",
        desc: "Air Temperature External Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2241",
        title: "B1255",
        desc: "Air Temperature External Sensor Circuit Open",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2242",
        title: "B1256",
        desc: "Air Temperature External Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:41",
        updated_at: "2022-08-18 19:38:41",
    },
    {
        vehicle_error_id: "2243",
        title: "B1257",
        desc: "Air Temperature External Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:42",
        updated_at: "2022-08-18 19:38:42",
    },
    {
        vehicle_error_id: "2244",
        title: "B1258",
        desc: "Solar Radiation Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:42",
        updated_at: "2022-08-18 19:38:42",
    },
    {
        vehicle_error_id: "2245",
        title: "B1259",
        desc: "Solar Radiation Sensor Circuit Open",
        created_at: "2022-08-18 19:38:42",
        updated_at: "2022-08-18 19:38:42",
    },
    {
        vehicle_error_id: "2246",
        title: "B1260",
        desc: "Solar Radiation Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:42",
        updated_at: "2022-08-18 19:38:42",
    },
    {
        vehicle_error_id: "2247",
        title: "B1261",
        desc: "Solar Radiation Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:42",
        updated_at: "2022-08-18 19:38:42",
    },
    {
        vehicle_error_id: "2248",
        title: "B1262",
        desc: "Servo Motor Defrost Circuit Failure",
        created_at: "2022-08-18 19:38:43",
        updated_at: "2022-08-18 19:38:43",
    },
    {
        vehicle_error_id: "2249",
        title: "B1263",
        desc: "Servo Motor Vent Circuit Failure",
        created_at: "2022-08-18 19:38:43",
        updated_at: "2022-08-18 19:38:43",
    },
    {
        vehicle_error_id: "2250",
        title: "B1264",
        desc: "Servo Motor Foot Circuit Failure",
        created_at: "2022-08-18 19:38:43",
        updated_at: "2022-08-18 19:38:43",
    },
    {
        vehicle_error_id: "2251",
        title: "B1265",
        desc: "Servo Motor Coolair Bypass Circuit  Failure",
        created_at: "2022-08-18 19:38:43",
        updated_at: "2022-08-18 19:38:43",
    },
    {
        vehicle_error_id: "2252",
        title: "B1266",
        desc: "Servo Motor Airintake Left Circuit Failure",
        created_at: "2022-08-18 19:38:43",
        updated_at: "2022-08-18 19:38:43",
    },
    {
        vehicle_error_id: "2253",
        title: "B1267",
        desc: "Servo Motor Airintake Right Circuit Failure",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2254",
        title: "B1268",
        desc: "Servo Motor Potentiometer Defrost Circuit Failure",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2255",
        title: "B1269",
        desc: "Servo Motor Potentiometer Defrost Circuit Open",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2256",
        title: "B1270",
        desc: "Servo Motor Potentiometer Defrost Circuit Short To Battery",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2257",
        title: "B1271",
        desc: "Servo Motor Potentiometer Defrost Circuit Short To Ground",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2258",
        title: "B1272",
        desc: "Servo Motor Potentiometer Vent Circuit Failure",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2259",
        title: "B1273",
        desc: "Servo Motor Potentiometer Vent Circuit Open",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2260",
        title: "B1274",
        desc: "Servo Motor Potentiometer Vent Circuit Short To Battery",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2261",
        title: "B1275",
        desc: "Servo Motor Potentiometer Vent Circuit Short To Ground",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2262",
        title: "B1276",
        desc: "Servo Motor Potentiometer Foot Circuit Failure",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2263",
        title: "B1277",
        desc: "Servo Motor Potentiometer Foot Circuit Open",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2264",
        title: "B1278",
        desc: "Servo Motor Potentiometer Foot Circuit Short To Battery",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2265",
        title: "B1279",
        desc: "Servo Motor Potentiometer Foot Circuit Short To Ground",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2266",
        title: "B1280",
        desc: "Servo Motor Potentiometer Coolair Circuit Failure",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2267",
        title: "B1281",
        desc: "Servo Motor Potentiometer Coolair Circuit Open",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2268",
        title: "B1282",
        desc: "Servo Motor Potentiometer Coolair Circuit Short To Battery",
        created_at: "2022-08-18 19:38:44",
        updated_at: "2022-08-18 19:38:44",
    },
    {
        vehicle_error_id: "2269",
        title: "B1283",
        desc: "Servo Motor Potentiometer Coolair Circuit Short To Ground",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2270",
        title: "B1284",
        desc: "Servo Motor Potentiometer Airintake Left Circuit Failure",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2271",
        title: "B1285",
        desc: "Servo Motor Potentiometer Airintake Left Circuit Open",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2272",
        title: "B1286",
        desc: "Servo Motor Potentiometer Airintake Left Circuit Short To Battery",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2273",
        title: "B1287",
        desc: "Servo Motor Potentiometer Airintake Left Circuit Short To Ground",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2274",
        title: "B1288",
        desc: "Servo Motor Potentiometer Airintake Right Circuit Failure",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2275",
        title: "B1289",
        desc: "Servo Motor Potentiometer Airintake Right Circuit Open",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2276",
        title: "B1290",
        desc: "Servo Motor Potentiometer Airintake Right Circuit Short To Battery",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2277",
        title: "B1291",
        desc: "Servo Motor Potentiometer Airintake Right Circuit Short To Ground",
        created_at: "2022-08-18 19:38:45",
        updated_at: "2022-08-18 19:38:45",
    },
    {
        vehicle_error_id: "2278",
        title: "B1292",
        desc: "Battery Power Relay Circuit Failure",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2279",
        title: "B1293",
        desc: "Battery Power Relay Circuit Open",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2280",
        title: "B1294",
        desc: "Battery Power Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2281",
        title: "B1295",
        desc: "Battery Power Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2282",
        title: "B1296",
        desc: "Power Supply Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2283",
        title: "B1297",
        desc: "Power Supply Sensor Circuit Open",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2284",
        title: "B1298",
        desc: "Power Supply Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2285",
        title: "B1299",
        desc: "Power Supply Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2286",
        title: "B1300",
        desc: "Power Door Lock Circuit Failure",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2287",
        title: "B1301",
        desc: "Power Door Lock Circuit Open",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2288",
        title: "B1302",
        desc: "Accessory Delay Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:38:46",
        updated_at: "2022-08-18 19:38:46",
    },
    {
        vehicle_error_id: "2289",
        title: "B1303",
        desc: "Accessory Delay Relay Coil Circuit Open",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2290",
        title: "B1304",
        desc: "Accessory Delay Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2291",
        title: "B1305",
        desc: "Accessory Delay Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2292",
        title: "B1306",
        desc: "Oil Level Switch Circuit Open",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2293",
        title: "B1307",
        desc: "Oil Level Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2294",
        title: "B1308",
        desc: "Oil Level Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2295",
        title: "B1309",
        desc: "Power Door Lock Circuit Short To Ground",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2296",
        title: "B1310",
        desc: "Power Door Unlock Circuit Failure",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2297",
        title: "B1311",
        desc: "Power Door Unlock Circuit Open",
        created_at: "2022-08-18 19:38:47",
        updated_at: "2022-08-18 19:38:47",
    },
    {
        vehicle_error_id: "2298",
        title: "B1312",
        desc: "Lamp Headlamp Input Circuit Short To Battery",
        created_at: "2022-08-18 19:38:48",
        updated_at: "2022-08-18 19:38:48",
    },
    {
        vehicle_error_id: "2299",
        title: "B1313",
        desc: "Battery Saver Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:38:48",
        updated_at: "2022-08-18 19:38:48",
    },
    {
        vehicle_error_id: "2300",
        title: "B1314",
        desc: "Battery Saver Relay Coil Circuit Open",
        created_at: "2022-08-18 19:38:48",
        updated_at: "2022-08-18 19:38:48",
    },
    {
        vehicle_error_id: "2301",
        title: "B1315",
        desc: "Battery Saver Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:38:48",
        updated_at: "2022-08-18 19:38:48",
    },
    {
        vehicle_error_id: "2302",
        title: "B1316",
        desc: "Battery Saver Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:38:48",
        updated_at: "2022-08-18 19:38:48",
    },
    {
        vehicle_error_id: "2303",
        title: "B1317",
        desc: "Battery Voltage High",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2304",
        title: "B1318",
        desc: "Battery Voltage Low",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2305",
        title: "B1319",
        desc: "Driver Door Ajar Circuit Failure",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2306",
        title: "B1320",
        desc: "Driver Door Ajar Circuit Open",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2307",
        title: "B1321",
        desc: "Driver Door Ajar Circuit Short To Battery",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2308",
        title: "B1322",
        desc: "Driver Door Ajar Circuit Short To Ground",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2309",
        title: "B1323",
        desc: "Door Ajar Lamp Circuit Failure",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2310",
        title: "B1324",
        desc: "Door Ajar Lamp Circuit Open",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2311",
        title: "B1325",
        desc: "Door Ajar Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2312",
        title: "B1326",
        desc: "Door Ajar Lamp Circuit Short To Ground",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2313",
        title: "B1327",
        desc: "Passenger Door Ajar Circuit Failure",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2314",
        title: "B1328",
        desc: "Passenger Door Ajar Circuit Open",
        created_at: "2022-08-18 19:38:49",
        updated_at: "2022-08-18 19:38:49",
    },
    {
        vehicle_error_id: "2315",
        title: "B1329",
        desc: "Passenger Door Ajar Circuit Short To Battery",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2316",
        title: "B1330",
        desc: "Passenger Door Ajar Circuit Short To Ground",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2317",
        title: "B1331",
        desc: "Decklid Ajar Rear Door Circuit Failure",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2318",
        title: "B1332",
        desc: "Decklid Ajar Rear Door Circuit Open",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2319",
        title: "B1333",
        desc: "Decklid Ajar Rear Door Circuit Short To Battery",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2320",
        title: "B1334",
        desc: "Decklid Ajar Rear Door Circuit Short To Ground",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2321",
        title: "B1335",
        desc: "Door Ajar RR Circuit Failure",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2322",
        title: "B1336",
        desc: "Door Ajar RR Circuit Open",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2323",
        title: "B1337",
        desc: "Door Ajar RR Circuit Short To Battery",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2324",
        title: "B1338",
        desc: "Door Ajar RR Circuit Short To Ground",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2325",
        title: "B1339",
        desc: "Chime Input Request Circuit Short To Battery",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2326",
        title: "B1340",
        desc: "Chime Input Request Circuit Short To Ground",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2327",
        title: "B1341",
        desc: "Power Door Unlock Circuit Short To Ground",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2328",
        title: "B1342",
        desc: "ECU Is Defective",
        created_at: "2022-08-18 19:38:50",
        updated_at: "2022-08-18 19:38:50",
    },
    {
        vehicle_error_id: "2329",
        title: "B1343",
        desc: "Heated Backlite Input Circuit Failure",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2330",
        title: "B1344",
        desc: "Heated Backlite Input Circuit Open",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2331",
        title: "B1345",
        desc: "Heated Backlite Input Circuit Short To Ground",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2332",
        title: "B1346",
        desc: "Heated Backlite Input Circuit Short To Battery",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2333",
        title: "B1347",
        desc: "Heated Backlite Relay Circuit Failure",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2334",
        title: "B1348",
        desc: "Heated Backlite Relay Circuit Open",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2335",
        title: "B1349",
        desc: "Heated Backlite Relay Short To Battery",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2336",
        title: "B1350",
        desc: "Heated Backlite Relay Short To Ground",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2337",
        title: "B1351",
        desc: "Ignition Key-In Circuit Short To Battery",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2338",
        title: "B1352",
        desc: "Ignition Key-In Circuit Failure",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2339",
        title: "B1353",
        desc: "Ignition Key-In Circuit Open",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2340",
        title: "B1354",
        desc: "Ignition Key-In Circuit Short To Ground",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2341",
        title: "B1355",
        desc: "Ignition Run Circuit Failure",
        created_at: "2022-08-18 19:38:51",
        updated_at: "2022-08-18 19:38:51",
    },
    {
        vehicle_error_id: "2342",
        title: "B1356",
        desc: "Ignition Run Circuit Open",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2343",
        title: "B1357",
        desc: "Ignition Run Circuit Short To Battery",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2344",
        title: "B1358",
        desc: "Ignition Run Circuit Short To Ground",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2345",
        title: "B1359",
        desc: "Ignition Run/Acc Circuit Failure",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2346",
        title: "B1360",
        desc: "Ignition Run/Acc Circuit Open",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2347",
        title: "B1361",
        desc: "Ignition Run/Acc Circuit Short To Battery",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2348",
        title: "B1362",
        desc: "Ignition Run/Acc Circuit Short To Ground",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2349",
        title: "B1363",
        desc: "Ignition Start Circuit Failure",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2350",
        title: "B1364",
        desc: "Ignition Start Circuit Open",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2351",
        title: "B1365",
        desc: "Ignition Start Circuit Short To Battery",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2352",
        title: "B1366",
        desc: "Ignition Start Circuit Short To Ground",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2353",
        title: "B1367",
        desc: "Ignition Tach Circuit Failure",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2354",
        title: "B1368",
        desc: "Ignition Tach Circuit Open",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2355",
        title: "B1369",
        desc: "Ignition Tach Circuit Short To Battery",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2356",
        title: "B1370",
        desc: "Ignition Tach Circuit Short To Ground",
        created_at: "2022-08-18 19:38:52",
        updated_at: "2022-08-18 19:38:52",
    },
    {
        vehicle_error_id: "2357",
        title: "B1371",
        desc: "Illuminated Entry Relay Circuit Failure",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2358",
        title: "B1372",
        desc: "Illuminated Entry Relay Circuit Open",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2359",
        title: "B1373",
        desc: "Illuminated Entry Relay Short To Battery",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2360",
        title: "B1374",
        desc: "Illuminated Entry Relay Short To Ground",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2361",
        title: "B1375",
        desc: "Oil Change Lamp Circuit Open",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2362",
        title: "B1376",
        desc: "Oil Change Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2363",
        title: "B1377",
        desc: "Oil Change Lamp Circuit Failure",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2364",
        title: "B1378",
        desc: "Oil Change Lamp Circuit Short To Ground",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2365",
        title: "B1379",
        desc: "Oil Change Reset Button Circuit Short To Ground",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2366",
        title: "B1380",
        desc: "Oil Change Reset Button Circuit Failure",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2367",
        title: "B1381",
        desc: "Oil Change Reset Button Circuit Open",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2368",
        title: "B1382",
        desc: "Oil Change Reset Button Circuit Short To Battery",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2369",
        title: "B1383",
        desc: "Oil Level Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2370",
        title: "B1384",
        desc: "Oil Level Lamp Circuit Failure",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2371",
        title: "B1385",
        desc: "Oil Level Lamp Circuit Open",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2372",
        title: "B1386",
        desc: "Oil Level Lamp Circuit Short To Ground",
        created_at: "2022-08-18 19:38:53",
        updated_at: "2022-08-18 19:38:53",
    },
    {
        vehicle_error_id: "2373",
        title: "B1387",
        desc: "Oil Temperature Sensor Circuit Open",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2374",
        title: "B1388",
        desc: "Oil Temperature Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2375",
        title: "B1389",
        desc: "Oil Temperature Sensor Circuit Failure",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2376",
        title: "B1390",
        desc: "Oil Temperature Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2377",
        title: "B1391",
        desc: "Oil Level Switch Circuit Failure",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2378",
        title: "B1392",
        desc: "Power Door Memory Lock Relay Circuit Failure",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2379",
        title: "B1393",
        desc: "Power Door Memory Lock Relay Circuit Open",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2380",
        title: "B1394",
        desc: "Power Door Memory Lock Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2381",
        title: "B1395",
        desc: "Power Door Memory Lock Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2382",
        title: "B1396",
        desc: "Power Door Lock Circuit Short To Battery",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2383",
        title: "B1397",
        desc: "Power Door Unlock Circuit Short To Battery",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2384",
        title: "B1398",
        desc: "Driver Power Window One Touch Window Relay Circuit Failure",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2385",
        title: "B1399",
        desc: "Driver Power Window One Touch Window Relay Circuit Open",
        created_at: "2022-08-18 19:38:54",
        updated_at: "2022-08-18 19:38:54",
    },
    {
        vehicle_error_id: "2386",
        title: "B1400",
        desc: "Driver Power Window One Touch Window Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2387",
        title: "B1401",
        desc: "Driver Power Window One Touch Window Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2388",
        title: "B1402",
        desc: "Driver Power Window Down Switch Circuit Failure",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2389",
        title: "B1403",
        desc: "Driver Power Window Up Switch Circuit Failure",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2390",
        title: "B1404",
        desc: "Driver Power Window Down Circuit Open",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2391",
        title: "B1405",
        desc: "Driver Power Window Down Circuit Short To Battery",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2392",
        title: "B1406",
        desc: "Driver Power Window Down Circuit Short To Ground",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2393",
        title: "B1407",
        desc: "Driver Power Window Up Circuit Open",
        created_at: "2022-08-18 19:38:55",
        updated_at: "2022-08-18 19:38:55",
    },
    {
        vehicle_error_id: "2394",
        title: "B1408",
        desc: "Driver Power Window Up Circuit Short To Battery",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2395",
        title: "B1409",
        desc: "Driver Power Window Up Circuit Short To Ground",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2396",
        title: "B1410",
        desc: "Driver Power Window Motor Circuit Failure",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2397",
        title: "B1411",
        desc: "Driver Power Window Motor Circuit Open",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2398",
        title: "B1412",
        desc: "Driver Power Window Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2399",
        title: "B1413",
        desc: "Driver Power Window Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2400",
        title: "B1414",
        desc: "Power Window LR Motor Circuit Failure",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2401",
        title: "B1415",
        desc: "Power Window LR Motor Circuit Open",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2402",
        title: "B1416",
        desc: "Power Window LR Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2403",
        title: "B1417",
        desc: "Power Window LR Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2404",
        title: "B1418",
        desc: "Passenger Power Window Motor Circuit Failure",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2405",
        title: "B1419",
        desc: "Passenger Power Window Motor Circuit Open",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2406",
        title: "B1420",
        desc: "Passenger Power Window Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2407",
        title: "B1421",
        desc: "Passenger Power Window Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:56",
        updated_at: "2022-08-18 19:38:56",
    },
    {
        vehicle_error_id: "2408",
        title: "B1422",
        desc: "Power Window RR Motor Circuit Failure",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2409",
        title: "B1423",
        desc: "Power Window RR Motor Circuit Open",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2410",
        title: "B1424",
        desc: "Power Window RR Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2411",
        title: "B1425",
        desc: "Power Window RR Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2412",
        title: "B1426",
        desc: "Lamp Seat Belt Circuit Short To Battery",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2413",
        title: "B1427",
        desc: "Lamp Seat Belt Circuit Open",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2414",
        title: "B1428",
        desc: "Lamp Seat Belt Circuit Failure",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2415",
        title: "B1429",
        desc: "Lamp Seat Belt Circuit Short To Ground",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2416",
        title: "B1430",
        desc: "Seat Belt Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2417",
        title: "B1431",
        desc: "Wiper Brake/Run Relay Circuit Failure",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2418",
        title: "B1432",
        desc: "Wiper Brake/Run Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2419",
        title: "B1433",
        desc: "Wiper Brake/Run Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2420",
        title: "B1434",
        desc: "Wiper Hi/Low Speed Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2421",
        title: "B1435",
        desc: "Wiper Hi/Low Speed Relay Coil Circuit Open",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2422",
        title: "B1436",
        desc: "Wiper Hi/Low Speed Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2423",
        title: "B1437",
        desc: "Wiper Hi/Low Speed Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2424",
        title: "B1438",
        desc: "Wiper Mode Select Switch Circuit Failure",
        created_at: "2022-08-18 19:38:57",
        updated_at: "2022-08-18 19:38:57",
    },
    {
        vehicle_error_id: "2425",
        title: "B1439",
        desc: "Wiper Mode Select Switch Circuit Open",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2426",
        title: "B1440",
        desc: "Wiper Mode Select Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2427",
        title: "B1441",
        desc: "Wiper Mode Select Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2428",
        title: "B1442",
        desc: "Door Handle Switch Circuit Failure",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2429",
        title: "B1443",
        desc: "Door Handle Switch Circuit Open",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2430",
        title: "B1444",
        desc: "Door Handle Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2431",
        title: "B1445",
        desc: "Door Handle Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2432",
        title: "B1446",
        desc: "Wiper Park Sense Circuit Failure",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2433",
        title: "B1447",
        desc: "Wiper Park Sense Circuit Open",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2434",
        title: "B1448",
        desc: "Wiper Park Sense Circuit Short To Battery",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2435",
        title: "B1449",
        desc: "Wiper Park Sense Circuit Short To Ground",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2436",
        title: "B1450",
        desc: "Wiper Wash/Delay Switch Circuit Failure",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2437",
        title: "B1451",
        desc: "Wiper Wash/Delay Switch Circuit Open",
        created_at: "2022-08-18 19:38:58",
        updated_at: "2022-08-18 19:38:58",
    },
    {
        vehicle_error_id: "2438",
        title: "B1452",
        desc: "Wiper Wash/Delay Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2439",
        title: "B1453",
        desc: "Wiper Wash/Delay Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2440",
        title: "B1454",
        desc: "Wiper Washer Fluid Lamp Circuit Failure",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2441",
        title: "B1455",
        desc: "Wiper Washer Fluid Lamp Circuit Open",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2442",
        title: "B1456",
        desc: "Wiper Washer Fluid Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2443",
        title: "B1457",
        desc: "Wiper Washer Fluid Lamp Circuit Short To Ground",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2444",
        title: "B1458",
        desc: "Wiper Washer Pump Motor Relay Circuit Failure",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2445",
        title: "B1459",
        desc: "Wiper Washer Pump Motor Relay Coil Circuit Open",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2446",
        title: "B1460",
        desc: "Wiper Washer Pump Motor Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2447",
        title: "B1461",
        desc: "Wiper Washer Pump Motor Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2448",
        title: "B1462",
        desc: "Seat Belt Switch Circuit Failure",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2449",
        title: "B1463",
        desc: "Seat Belt Switch Circuit Open",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2450",
        title: "B1464",
        desc: "Seat Belt Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2451",
        title: "B1465",
        desc: "Wiper Brake/Run Relay Circuit Open",
        created_at: "2022-08-18 19:38:59",
        updated_at: "2022-08-18 19:38:59",
    },
    {
        vehicle_error_id: "2452",
        title: "B1466",
        desc: "Wiper Hi/Low Speed Not Switching",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2453",
        title: "B1467",
        desc: "Wiper Hi/Low Speed Circuit Motor Short To Battery",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2454",
        title: "B1468",
        desc: "Chime Input Request Circuit Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2455",
        title: "B1469",
        desc: "Chime Input Request Circuit Open",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2456",
        title: "B1470",
        desc: "Lamp Headlamp Input Circuit Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2457",
        title: "B1471",
        desc: "Lamp Headlamp Input Circuit Open",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2458",
        title: "B1472",
        desc: "Lamp Headlamp Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2459",
        title: "B1473",
        desc: "Wiper Low Speed Circuit Motor Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2460",
        title: "B1474",
        desc: "Battery Saver Power Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2461",
        title: "B1475",
        desc: "Accessory Delay Relay Contact Short To Battery",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2462",
        title: "B1476",
        desc: "Wiper High Speed Circuit Motor Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2463",
        title: "B1477",
        desc: "Wiper Hi/Low Circuit Motor Short To Ground",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2464",
        title: "B1478",
        desc: "Power Window One Touch Up/Down Activated Simultaneously",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2465",
        title: "B1479",
        desc: "Wiper Washer Fluid Level Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2466",
        title: "B1480",
        desc: "Wiper Washer Fluid Level Sensor Circuit Open",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2467",
        title: "B1481",
        desc: "Wiper Washer Fluid Level Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2468",
        title: "B1482",
        desc: "Wiper Washer Fluid Level Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2469",
        title: "B1483",
        desc: "Brake Pedal Input Circuit Failure",
        created_at: "2022-08-18 19:39:00",
        updated_at: "2022-08-18 19:39:00",
    },
    {
        vehicle_error_id: "2470",
        title: "B1484",
        desc: "Brake Pedal Input Open Circuit",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2471",
        title: "B1485",
        desc: "Brake Pedal Input Circuit Battery Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2472",
        title: "B1486",
        desc: "Brake Pedal Input Circuit Ground Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2473",
        title: "B1487",
        desc: "Door Handle Right Front Circuit Failure",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2474",
        title: "B1488",
        desc: "Door Handle Right Front Circuit Open",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2475",
        title: "B1489",
        desc: "Door Handle Right Front Short To Battery",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2476",
        title: "B1490",
        desc: "Door Handle Right Front Short To Ground",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2477",
        title: "B1491",
        desc: "Ignition Cylinder Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2478",
        title: "B1492",
        desc: "Ignition Cylinder Sensor Open Circuit",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2479",
        title: "B1493",
        desc: "Ignition Cylinder Sensor Battery Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2480",
        title: "B1494",
        desc: "Ignition Cylinder Sensor Ground Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2481",
        title: "B1495",
        desc: "Decklid Punch-Out Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2482",
        title: "B1496",
        desc: "Decklid Punch-Out Sensor Open Circuit",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2483",
        title: "B1497",
        desc: "Decklid Punch-Out Sensor Battery Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2484",
        title: "B1498",
        desc: "Decklid Punch-Out Sensor Ground Short",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2485",
        title: "B1499",
        desc: "Lamp Turn Signal Left Circuit Failure",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2486",
        title: "B1500",
        desc: "Lamp Turn Signal Left Circuit Open",
        created_at: "2022-08-18 19:39:01",
        updated_at: "2022-08-18 19:39:01",
    },
    {
        vehicle_error_id: "2487",
        title: "B1501",
        desc: "Lamp Turn Signal Left Circuit Short To Battery",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2488",
        title: "B1502",
        desc: "Lamp Turn Signal Left Circuit Short To Ground",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2489",
        title: "B1503",
        desc: "Lamp Turn Signal Right Circuit Failure",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2490",
        title: "B1504",
        desc: "Lamp Turn Signal Right Circuit Open",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2491",
        title: "B1505",
        desc: "Lamp Turn Signal Right Circuit Short To Battery",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2492",
        title: "B1506",
        desc: "Lamp Turn Signal Right Circuit Short To Ground",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2493",
        title: "B1507",
        desc: "Flash To Pass Switch Circuit Failure",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2494",
        title: "B1508",
        desc: "Flash To Pass Switch Circuit Open",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2495",
        title: "B1509",
        desc: "Flash To Pass Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2496",
        title: "B1510",
        desc: "Flash To Pass Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2497",
        title: "B1511",
        desc: "Driver Door Handle  Circuit Failure",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2498",
        title: "B1512",
        desc: "Driver Door Handle  Circuit Open",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2499",
        title: "B1513",
        desc: "Driver Door Handle  Circuit Short To Battery",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2500",
        title: "B1514",
        desc: "Driver Door Handle  Circuit Short To Ground",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2501",
        title: "B1515",
        desc: "Seat Driver Occupied Switch Circuit Failure",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2502",
        title: "B1516",
        desc: "Seat Driver Occupied Switch Circuit Open",
        created_at: "2022-08-18 19:39:02",
        updated_at: "2022-08-18 19:39:02",
    },
    {
        vehicle_error_id: "2503",
        title: "B1517",
        desc: "Seat Driver Occupied Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2504",
        title: "B1518",
        desc: "Seat Driver Occupied Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2505",
        title: "B1519",
        desc: "Hood Switch Circuit Failure",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2506",
        title: "B1520",
        desc: "Hood Switch Circuit Open",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2507",
        title: "B1521",
        desc: "Hood Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2508",
        title: "B1522",
        desc: "Hood Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2509",
        title: "B1523",
        desc: "Keyless Entry Circuit Failure",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2510",
        title: "B1524",
        desc: "Keyless Entry Circuit Open",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2511",
        title: "B1525",
        desc: "Keyless Entry Circuit Short To Battery",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2512",
        title: "B1526",
        desc: "Keyless Entry Circuit Short To Ground",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2513",
        title: "B1527",
        desc: "Memory Set Switch Circuit Failure",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2514",
        title: "B1528",
        desc: "Memory Set Switch Circuit Open",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2515",
        title: "B1529",
        desc: "Memory Set Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2516",
        title: "B1530",
        desc: "Memory Set Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2517",
        title: "B1531",
        desc: "Memory 1 Switch Circuit Failure",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2518",
        title: "B1532",
        desc: "Memory 1 Switch Circuit Open",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2519",
        title: "B1533",
        desc: "Memory 1 Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2520",
        title: "B1534",
        desc: "Memory 1 Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2521",
        title: "B1535",
        desc: "Memory 2 Switch Circuit Failure",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2522",
        title: "B1536",
        desc: "Memory 2 Switch Circuit Open",
        created_at: "2022-08-18 19:39:03",
        updated_at: "2022-08-18 19:39:03",
    },
    {
        vehicle_error_id: "2523",
        title: "B1537",
        desc: "Memory 2 Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2524",
        title: "B1538",
        desc: "Memory 2 Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2525",
        title: "B1539",
        desc: "Mirror Driver Switch Assembly Circuit Failure",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2526",
        title: "B1540",
        desc: "Mirror Driver Switch Assembly Circuit Open",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2527",
        title: "B1541",
        desc: "Mirror Driver Switch Assembly Circuit Short To Battery",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2528",
        title: "B1542",
        desc: "Mirror Driver Switch Assembly Circuit Short To Ground",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2529",
        title: "B1543",
        desc: "Seat Direction Switch Assembly Circuit Failure",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2530",
        title: "B1544",
        desc: "Seat Direction Switch Assembly Circuit Open",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2531",
        title: "B1545",
        desc: "Seat Direction Switch Assembly Circuit Short To Battery",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2532",
        title: "B1546",
        desc: "Seat Direction Switch Assembly Circuit Short To Ground",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2533",
        title: "B1547",
        desc: "Power Window Master Circuit Failure",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2534",
        title: "B1548",
        desc: "Power Window Master Circuit Open",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2535",
        title: "B1549",
        desc: "Power Window Master Circuit Short To Battery",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2536",
        title: "B1550",
        desc: "Power Window Master Circuit Short To Ground",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2537",
        title: "B1551",
        desc: "Decklid Release Circuit Failure",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2538",
        title: "B1552",
        desc: "Decklid Release Circuit Open",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2539",
        title: "B1553",
        desc: "Decklid Release Circuit Short To Battery",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2540",
        title: "B1554",
        desc: "Decklid Release Circuit Short To Ground",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2541",
        title: "B1555",
        desc: "Ignition Run/Start Circuit Failure",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2542",
        title: "B1556",
        desc: "Ignition Run/Start Circuit Open",
        created_at: "2022-08-18 19:39:04",
        updated_at: "2022-08-18 19:39:04",
    },
    {
        vehicle_error_id: "2543",
        title: "B1557",
        desc: "Ignition Run/Start Circuit Short To Battery",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2544",
        title: "B1558",
        desc: "Ignition Run/Start Circuit Short To Ground",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2545",
        title: "B1559",
        desc: "Door Lock Cylinder Circuit Failure",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2546",
        title: "B1560",
        desc: "Door Lock Cylinder Circuit Open",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2547",
        title: "B1561",
        desc: "Door Lock Cylinder Circuit Short To Battery",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2548",
        title: "B1562",
        desc: "Door Lock Cylinder Circuit Short To Ground",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2549",
        title: "B1563",
        desc: "Door Ajar Circuit Failure",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2550",
        title: "B1564",
        desc: "Door Ajar Circuit Open",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2551",
        title: "B1565",
        desc: "Door Ajar Circuit Short To Battery",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2552",
        title: "B1566",
        desc: "Door Ajar Circuit Short To Ground",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2553",
        title: "B1567",
        desc: "Lamp Headlamp High-Beam Circuit Failure",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2554",
        title: "B1568",
        desc: "Lamp Headlamp High-Beam Circuit Open",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2555",
        title: "B1569",
        desc: "Lamp Headlamp High-Beam Circuit Short To Battery",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2556",
        title: "B1570",
        desc: "Lamp Headlamp High-Beam Circuit Short To Ground",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2557",
        title: "B1571",
        desc: "Door Ajar LR Circuit Failure",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2558",
        title: "B1572",
        desc: "Door Ajar LR Circuit Open",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2559",
        title: "B1573",
        desc: "Door Ajar LR Circuit  Short To Battery",
        created_at: "2022-08-18 19:39:05",
        updated_at: "2022-08-18 19:39:05",
    },
    {
        vehicle_error_id: "2560",
        title: "B1574",
        desc: "Door Ajar LR Circuit Short To Ground",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2561",
        title: "B1575",
        desc: "Lamp Park Input Circuit Failure",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2562",
        title: "B1576",
        desc: "Lamp Park Input Circuit Open",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2563",
        title: "B1577",
        desc: "Lamp Park Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2564",
        title: "B1578",
        desc: "Lamp Park Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2565",
        title: "B1579",
        desc: "Dim Panel Increase Input Circuit Failure",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2566",
        title: "B1580",
        desc: "Dim Panel Increase Input Circuit Open",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2567",
        title: "B1581",
        desc: "Dim Panel Increase Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2568",
        title: "B1582",
        desc: "Dim Panel Increase Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2569",
        title: "B1583",
        desc: "Dim Panel Decrease Input Circuit Failure",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2570",
        title: "B1584",
        desc: "Dim Panel Decrease Input Circuit Open",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2571",
        title: "B1585",
        desc: "Dim Panel Decrease Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2572",
        title: "B1586",
        desc: "Dim Panel Decrease Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2573",
        title: "B1587",
        desc: "Autolamp Delay Increase Circuit Failure",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2574",
        title: "B1588",
        desc: "Autolamp Delay Increase Circuit Open",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2575",
        title: "B1589",
        desc: "Autolamp Delay Increase Circuit Short To Battery",
        created_at: "2022-08-18 19:39:06",
        updated_at: "2022-08-18 19:39:06",
    },
    {
        vehicle_error_id: "2576",
        title: "B1590",
        desc: "Autolamp Delay Increase Circuit Short To Ground",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2577",
        title: "B1591",
        desc: "Autolamp Delay Decrease Circuit Failure",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2578",
        title: "B1592",
        desc: "Autolamp Delay Decrease Circuit Open",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2579",
        title: "B1593",
        desc: "Autolamp Delay Decrease Circuit Short To Battery",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2580",
        title: "B1594",
        desc: "Autolamp Delay Decrease Circuit Short To Ground",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2581",
        title: "B1595",
        desc: "Ignition Switch Illegal Input Code",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2582",
        title: "B1596",
        desc: "Service Continuous Codes",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2583",
        title: "B1600",
        desc: "PATS Ignition Key Transponder Signal Is Not Received",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2584",
        title: "B1601",
        desc: "PATS Received Incorrect Key-Code From Ignition Key Transponder",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2585",
        title: "B1602",
        desc: "PATS Received Invalid Format Of Key-Code From Ignition Key Transponder",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2586",
        title: "B1603",
        desc: "Lamp Anti-Theft Indicator Circuit Failure",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2587",
        title: "B1604",
        desc: "Lamp Anti-Theft Indicator Circuit Open",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2588",
        title: "B1605",
        desc: "Lamp Anti-Theft Indicator Circuit Short To Battery",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2589",
        title: "B1606",
        desc: "Lamp Anti-Theft Indicator Circuit Short To Ground",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2590",
        title: "B1607",
        desc: "Illuminated Entry Input Circuit Failure",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2591",
        title: "B1608",
        desc: "Illuminated Entry Input Open Circuit",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2592",
        title: "B1609",
        desc: "Illuminated Entry Input Short Circuit To Battery",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2593",
        title: "B1610",
        desc: "Illuminated Entry Input Short Circuit To Ground",
        created_at: "2022-08-18 19:39:07",
        updated_at: "2022-08-18 19:39:07",
    },
    {
        vehicle_error_id: "2594",
        title: "B1611",
        desc: "Wiper Rear Mode Select Switch Circuit Failure",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2595",
        title: "B1612",
        desc: "Wiper Rear Mode Select Switch Circuit Open",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2596",
        title: "B1613",
        desc: "Wiper Rear Mode Select Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2597",
        title: "B1614",
        desc: "Wiper Rear Mode Select Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2598",
        title: "B1615",
        desc: "Wiper Rear Disable Switch Circuit Failure",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2599",
        title: "B1616",
        desc: "Wiper Rear Disable Switch Circuit Open",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2600",
        title: "B1617",
        desc: "Wiper Rear Disable Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2601",
        title: "B1618",
        desc: "Wiper Rear Disable Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2602",
        title: "B1619",
        desc: "Wiper Rear Low Limit Input Circuit Failure",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2603",
        title: "B1620",
        desc: "Wiper Rear Low Limit Input Circuit Open",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2604",
        title: "B1621",
        desc: "Wiper Rear Low Limit Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2605",
        title: "B1622",
        desc: "Wiper Rear Low Limit Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2606",
        title: "B1623",
        desc: "Lamp Keypad Output Circuit Failure",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2607",
        title: "B1624",
        desc: "Lamp Keypad Output Open Circuit",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2608",
        title: "B1625",
        desc: "Lamp Keypad Output Short Circuit To Battery",
        created_at: "2022-08-18 19:39:08",
        updated_at: "2022-08-18 19:39:08",
    },
    {
        vehicle_error_id: "2609",
        title: "B1626",
        desc: "Lamp Keypad Output Short Circuit To Ground",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2610",
        title: "B1627",
        desc: "PRNDL Reverse Input Circuit Failure",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2611",
        title: "B1628",
        desc: "PRNDL Reverse Input Open Circuit",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2612",
        title: "B1629",
        desc: "PRNDL Reverse Input Short To Battery",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2613",
        title: "B1630",
        desc: "PRNDL Reverse Input Short Circuit To Ground",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2614",
        title: "B1631",
        desc: "Mirror Driver Left Circuit Failure",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2615",
        title: "B1632",
        desc: "Mirror Driver Left Circuit Open",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2616",
        title: "B1633",
        desc: "Mirror Driver Left Circuit Short To Battery",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2617",
        title: "B1634",
        desc: "Mirror Driver Left Circuit Short To Ground",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2618",
        title: "B1635",
        desc: "Mirror Driver Right Circuit Failure",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2619",
        title: "B1636",
        desc: "Mirror Driver Right Circuit Open",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2620",
        title: "B1637",
        desc: "Mirror Driver Right Circuit Short To Battery",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2621",
        title: "B1638",
        desc: "Mirror Driver Right Short To Ground",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2622",
        title: "B1639",
        desc: "Mirror Passenger Left Circuit Failure",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2623",
        title: "B1640",
        desc: "Mirror Passenger Left Circuit Open",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2624",
        title: "B1641",
        desc: "Mirror Passenger Left Circuit Short To Battery",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2625",
        title: "B1642",
        desc: "Mirror Passenger Left Circuit Short To Ground",
        created_at: "2022-08-18 19:39:09",
        updated_at: "2022-08-18 19:39:09",
    },
    {
        vehicle_error_id: "2626",
        title: "B1643",
        desc: "Mirror Passenger Right Circuit Failure",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2627",
        title: "B1644",
        desc: "Mirror Passenger Right Circuit Open",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2628",
        title: "B1645",
        desc: "Mirror Passenger Right Circuit Short To Battery",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2629",
        title: "B1646",
        desc: "Mirror Passenger Right Circuit Short To Ground",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2630",
        title: "B1647",
        desc: "Seat Driver Recline Forward Circuit Failure",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2631",
        title: "B1648",
        desc: "Seat Driver Recline Forward Circuit Open",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2632",
        title: "B1649",
        desc: "Seat Driver Recline Forward Circuit Short To Battery",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2633",
        title: "B1650",
        desc: "Seat Driver Recline Forward Circuit Short To Ground",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2634",
        title: "B1651",
        desc: "Seat Driver Recline Backward Circuit Failure",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2635",
        title: "B1652",
        desc: "Seat Driver Recline Backward Circuit Open",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2636",
        title: "B1653",
        desc: "Seat Driver Recline Backward Circuit Short To Battery",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2637",
        title: "B1654",
        desc: "Seat Driver Recline Backward Circuit Short To Ground",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2638",
        title: "B1655",
        desc: "Seat Driver Rear Up  Circuit Failure",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2639",
        title: "B1656",
        desc: "Seat Driver Rear Up Circuit Open",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2640",
        title: "B1657",
        desc: "Seat Driver Rear Up Circuit Short To Battery",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2641",
        title: "B1658",
        desc: "Seat Driver Rear Up Circuit Short To Ground",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2642",
        title: "B1659",
        desc: "Seat Driver Front Up Circuit Failure",
        created_at: "2022-08-18 19:39:10",
        updated_at: "2022-08-18 19:39:10",
    },
    {
        vehicle_error_id: "2643",
        title: "B1660",
        desc: "Seat Driver Front Up Circuit Open",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2644",
        title: "B1661",
        desc: "Seat Driver Front Up Circuit Short To Battery",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2645",
        title: "B1662",
        desc: "Seat Driver Front Up Circuit Short To Ground",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2646",
        title: "B1663",
        desc: "Seat Driver Front Up/Down Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2647",
        title: "B1664",
        desc: "Seat Driver Rear Up/Down Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2648",
        title: "B1665",
        desc: "Seat Driver Forward/Backward Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2649",
        title: "B1666",
        desc: "Seat Driver Recline Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2650",
        title: "B1667",
        desc: "Mirror Driver Up/Down Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2651",
        title: "B1668",
        desc: "Mirror Driver Right/Left Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2652",
        title: "B1669",
        desc: "Mirror Passenger Up/Down Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2653",
        title: "B1670",
        desc: "Mirror Passenger Right/Left Motor Stalled",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2654",
        title: "B1671",
        desc: "Battery Module Voltage Out Of Range",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2655",
        title: "B1672",
        desc: "Seat Driver Occupied Input Circuit Failure",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2656",
        title: "B1673",
        desc: "Seat Driver Occupied Input Circuit Open",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2657",
        title: "B1674",
        desc: "Seat Driver Occupied Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2658",
        title: "B1675",
        desc: "Seat Driver Occupied Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:11",
        updated_at: "2022-08-18 19:39:11",
    },
    {
        vehicle_error_id: "2659",
        title: "B1676",
        desc: "Battery Pack Voltage Out Of Range",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2660",
        title: "B1677",
        desc: "Alarm Panic Input Circuit Failure",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2661",
        title: "B1678",
        desc: "Alarm Panic Input Circuit Open",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2662",
        title: "B1679",
        desc: "Alarm Panic Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2663",
        title: "B1680",
        desc: "Alarm Panic Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2664",
        title: "B1681",
        desc: "PATS Transceiver Module Signal Is Not Received",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2665",
        title: "B1682",
        desc: "PATS Is Disabled (Check Link Between PATS And Transponder)",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2666",
        title: "B1683",
        desc: "Mirror Driver/Passenger Switch Circuit Failure",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2667",
        title: "B1684",
        desc: "Mirror Driver/Passenger Switch Circuit Open",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2668",
        title: "B1685",
        desc: "Lamp Dome Input Circuit Failure",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2669",
        title: "B1686",
        desc: "Lamp Dome Input Circuit Open",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2670",
        title: "B1687",
        desc: "Lamp Dome Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2671",
        title: "B1688",
        desc: "Lamp Dome Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2672",
        title: "B1689",
        desc: "Autolamp Delay Circuit Failure",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2673",
        title: "B1690",
        desc: "Autolamp Delay Circuit Open",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2674",
        title: "B1691",
        desc: "Autolamp Delay Circuit Short To Battery",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2675",
        title: "B1692",
        desc: "Autolamp Delay Circuit Short To Ground",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2676",
        title: "B1693",
        desc: "Autolamp On Circuit Failure",
        created_at: "2022-08-18 19:39:12",
        updated_at: "2022-08-18 19:39:12",
    },
    {
        vehicle_error_id: "2677",
        title: "B1694",
        desc: "Autolamp On Circuit Open",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2678",
        title: "B1695",
        desc: "Autolamp On Circuit Short To Battery",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2679",
        title: "B1696",
        desc: "Autolamp On Circuit Short To Ground",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2680",
        title: "B1697",
        desc: "Mirror Driver/Passenger Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2681",
        title: "B1698",
        desc: "Mirror Driver/Passenger Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2682",
        title: "B1701",
        desc: "Seat Driver Recline Forward Switch Circuit Failure",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2683",
        title: "B1702",
        desc: "Seat Driver Recline Forward Switch Circuit Open",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2684",
        title: "B1703",
        desc: "Seat Driver Recline Forward Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2685",
        title: "B1704",
        desc: "Seat Driver Recline Forward Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2686",
        title: "B1705",
        desc: "Seat Driver Recline Rearward Switch Circuit Failure",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2687",
        title: "B1706",
        desc: "Seat Driver Recline Rearward Switch Circuit Open",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2688",
        title: "B1707",
        desc: "Seat Driver Recline Rearward Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:13",
        updated_at: "2022-08-18 19:39:13",
    },
    {
        vehicle_error_id: "2689",
        title: "B1708",
        desc: "Seat Driver Recline Rearward Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2690",
        title: "B1709",
        desc: "Seat Driver Front Up Switch Circuit Failure",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2691",
        title: "B1710",
        desc: "Seat Driver Front Up Switch Circuit Open",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2692",
        title: "B1711",
        desc: "Seat Driver Front Up Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2693",
        title: "B1712",
        desc: "Seat Driver Front Up Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2694",
        title: "B1713",
        desc: "Seat Driver Front Down Switch Circuit Failure",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2695",
        title: "B1714",
        desc: "Seat Driver Front Down Switch Circuit Open",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2696",
        title: "B1715",
        desc: "Seat Driver Front Down Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2697",
        title: "B1716",
        desc: "Seat Driver Front Down Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2698",
        title: "B1717",
        desc: "Seat Driver Forward Switch Circuit Failure",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2699",
        title: "B1718",
        desc: "Seat Driver Forward Switch Circuit Open",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2700",
        title: "B1719",
        desc: "Seat Driver Forward Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2701",
        title: "B1720",
        desc: "Seat Driver Forward Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:14",
        updated_at: "2022-08-18 19:39:14",
    },
    {
        vehicle_error_id: "2702",
        title: "B1721",
        desc: "Seat Driver Rearward Switch Circuit Failure",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2703",
        title: "B1722",
        desc: "Seat Driver Rearward Switch Circuit Open",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2704",
        title: "B1723",
        desc: "Seat Driver Rearward Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2705",
        title: "B1724",
        desc: "Seat Driver Rearward Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2706",
        title: "B1725",
        desc: "Seat Driver Rear Up Switch Circuit Failure",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2707",
        title: "B1726",
        desc: "Seat Driver Rear Up Switch Circuit Open",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2708",
        title: "B1727",
        desc: "Seat Driver Rear Up Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2709",
        title: "B1728",
        desc: "Seat Driver Rear Up Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2710",
        title: "B1729",
        desc: "Seat Driver Rear Down Switch Circuit Failure",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2711",
        title: "B1730",
        desc: "Seat Driver Rear Down Switch Circuit Open",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2712",
        title: "B1731",
        desc: "Seat Driver Rear Down Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2713",
        title: "B1732",
        desc: "Seat Driver Rear Down Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2714",
        title: "B1733",
        desc: "Mirror Driver Vertical Switch Circuit Failure",
        created_at: "2022-08-18 19:39:15",
        updated_at: "2022-08-18 19:39:15",
    },
    {
        vehicle_error_id: "2715",
        title: "B1734",
        desc: "Mirror Driver Vertical Switch Circuit Open",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2716",
        title: "B1735",
        desc: "Mirror Driver Vertical Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2717",
        title: "B1736",
        desc: "Mirror Driver Vertical Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2718",
        title: "B1737",
        desc: "Mirror Driver Horizontal Switch Circuit Failure",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2719",
        title: "B1738",
        desc: "Mirror Driver Horizontal Switch Circuit Open",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2720",
        title: "B1739",
        desc: "Mirror Driver Horizontal Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2721",
        title: "B1740",
        desc: "Mirror Driver Horizontal Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2722",
        title: "B1741",
        desc: "Mirror Passenger Vertical Switch Circuit Failure",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2723",
        title: "B1742",
        desc: "Mirror Passenger Vertical Switch Circuit Open",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2724",
        title: "B1743",
        desc: "Mirror Passenger Vertical Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2725",
        title: "B1744",
        desc: "Mirror Passenger Vertical Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2726",
        title: "B1745",
        desc: "Mirror Passenger Horizontal Switch Circuit Failure",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2727",
        title: "B1746",
        desc: "Mirror Passenger Horizontal Switch Circuit Open",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2728",
        title: "B1747",
        desc: "Mirror Passenger Horizontal Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2729",
        title: "B1748",
        desc: "Mirror Passenger Horizontal Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:16",
        updated_at: "2022-08-18 19:39:16",
    },
    {
        vehicle_error_id: "2730",
        title: "B1749",
        desc: "Park/Neutral Switch Circuit Failure",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2731",
        title: "B1750",
        desc: "Park/Neutral Switch Circuit Open",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2732",
        title: "B1751",
        desc: "Park/Neutral Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2733",
        title: "B1752",
        desc: "Park/Neutral Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2734",
        title: "B1753",
        desc: "Hazard Flash Output Circuit Failure",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2735",
        title: "B1754",
        desc: "Hazard Flash Output Circuit Open",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2736",
        title: "B1755",
        desc: "Hazard Flash Output Circuit Short Battery",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2737",
        title: "B1756",
        desc: "Hazard Flash Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2738",
        title: "B1757",
        desc: "Seat Driver Rear Down  Circuit Failure",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2739",
        title: "B1758",
        desc: "Seat Driver Rear Down Circuit Open",
        created_at: "2022-08-18 19:39:17",
        updated_at: "2022-08-18 19:39:17",
    },
    {
        vehicle_error_id: "2740",
        title: "B1759",
        desc: "Seat Driver Rear Down Circuit Short To Battery",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2741",
        title: "B1760",
        desc: "Seat Driver Rear Down Circuit Short To Ground",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2742",
        title: "B1761",
        desc: "Seat Driver Front Down  Circuit Failure",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2743",
        title: "B1762",
        desc: "Seat Driver Front Down Circuit Open",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2744",
        title: "B1763",
        desc: "Seat Driver Front Down Circuit Short To Battery",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2745",
        title: "B1764",
        desc: "Seat Driver Front Down Circuit Short To Ground",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2746",
        title: "B1765",
        desc: "Seat Driver Forward Circuit Failure",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2747",
        title: "B1766",
        desc: "Seat Driver Forward Circuit Open",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2748",
        title: "B1767",
        desc: "Seat Driver Forward Circuit Short To Battery",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2749",
        title: "B1768",
        desc: "Seat Driver Forward Circuit Short To Ground",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2750",
        title: "B1769",
        desc: "Seat Driver Backward Circuit Failure",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2751",
        title: "B1770",
        desc: "Seat Driver Backward Circuit Open",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2752",
        title: "B1771",
        desc: "Seat Driver Backward Circuit Short To Battery",
        created_at: "2022-08-18 19:39:18",
        updated_at: "2022-08-18 19:39:18",
    },
    {
        vehicle_error_id: "2753",
        title: "B1772",
        desc: "Seat Driver Backward Circuit Short To Ground",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2754",
        title: "B1773",
        desc: "Mirror Driver Up Circuit Failure",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2755",
        title: "B1774",
        desc: "Mirror Driver Up Circuit Open",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2756",
        title: "B1775",
        desc: "Mirror Driver Up Circuit Short To Battery",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2757",
        title: "B1776",
        desc: "Mirror Driver Up Circuit Short To Ground",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2758",
        title: "B1778",
        desc: "Mirror Driver Down Circuit Failure",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2759",
        title: "B1779",
        desc: "Mirror Driver Down Circuit Open",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2760",
        title: "B1780",
        desc: "Mirror Driver Down Circuit Short To Battery",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2761",
        title: "B1781",
        desc: "Mirror Driver Down Short To Ground",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2762",
        title: "B1782",
        desc: "Mirror Passenger Up Circuit Failure",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2763",
        title: "B1783",
        desc: "Mirror Passenger Up Circuit Open",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2764",
        title: "B1784",
        desc: "Mirror Passenger Up Circuit Short To Battery",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2765",
        title: "B1785",
        desc: "Mirror Passenger Up Circuit Short To Ground",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2766",
        title: "B1786",
        desc: "Mirror Passenger Down Circuit Failure",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2767",
        title: "B1787",
        desc: "Mirror Passenger Down Circuit Open",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2768",
        title: "B1788",
        desc: "Mirror Passenger Down Circuit Short To Battery",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2769",
        title: "B1789",
        desc: "Mirror Passenger Down Circuit Short To Ground",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2770",
        title: "B1790",
        desc: "Autolamp Sensor Input Circuit Failure",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2771",
        title: "B1791",
        desc: "Autolamp Sensor Input Circuit Open",
        created_at: "2022-08-18 19:39:19",
        updated_at: "2022-08-18 19:39:19",
    },
    {
        vehicle_error_id: "2772",
        title: "B1792",
        desc: "Autolamp Sensor Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2773",
        title: "B1793",
        desc: "Autolamp Sensor Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2774",
        title: "B1794",
        desc: "Lamp Headlamp Low-Beam Circuit Failure",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2775",
        title: "B1795",
        desc: "Lamp Headlamp Low-Beam Circuit Open",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2776",
        title: "B1796",
        desc: "Lamp Headlamp Low-Beam Circuit Short To Battery",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2777",
        title: "B1797",
        desc: "Lamp Headlamp Low-Beam Circuit Short To Ground",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2778",
        title: "B1798",
        desc: "Lamp Turn Signal Front Output Circuit Failure",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2779",
        title: "B1799",
        desc: "Lamp Turn Signal Front Output Circuit Open",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2780",
        title: "B1800",
        desc: "Lamp Turn Signal Front Output Circuit Short To Battery",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2781",
        title: "B1801",
        desc: "Lamp Turn Signal Front Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2782",
        title: "B1802",
        desc: "Lamp Turn Signal Rear Output Circuit Failure",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2783",
        title: "B1803",
        desc: "Lamp Turn Signal Rear Output Circuit Open",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2784",
        title: "B1804",
        desc: "Lamp Turn Signal Rear Output Circuit Short To Battery",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2785",
        title: "B1805",
        desc: "Lamp Turn Signal Rear Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2786",
        title: "B1806",
        desc: "Lamp Tail Output Circuit Failure",
        created_at: "2022-08-18 19:39:20",
        updated_at: "2022-08-18 19:39:20",
    },
    {
        vehicle_error_id: "2787",
        title: "B1807",
        desc: "Lamp Tail Output Circuit Open",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2788",
        title: "B1808",
        desc: "Lamp Tail Output Circuit Short To Battery",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2789",
        title: "B1809",
        desc: "Lamp Tail Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2790",
        title: "B1810",
        desc: "Lamp Backup Switch Input Circuit Failure",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2791",
        title: "B1811",
        desc: "Lamp Backup Switch Input Circuit Open",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2792",
        title: "B1812",
        desc: "Lamp Backup Switch Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2793",
        title: "B1813",
        desc: "Lamp Backup Switch Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2794",
        title: "B1814",
        desc: "Wiper Rear Motor Down Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2795",
        title: "B1815",
        desc: "Wiper Rear Motor Down Relay Coil Circuit Open",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2796",
        title: "B1816",
        desc: "Wiper Rear Motor Down Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2797",
        title: "B1817",
        desc: "Wiper Rear Motor Down Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2798",
        title: "B1818",
        desc: "Wiper Rear Motor Up Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2799",
        title: "B1819",
        desc: "Wiper Rear Motor Up Relay Coil Circuit Open",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2800",
        title: "B1820",
        desc: "Wiper Rear Motor Up Relay Coil Circuit Short To Battery",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2801",
        title: "B1821",
        desc: "Wiper Rear Motor Up Relay Coil Circuit Short To Ground",
        created_at: "2022-08-18 19:39:21",
        updated_at: "2022-08-18 19:39:21",
    },
    {
        vehicle_error_id: "2802",
        title: "B1822",
        desc: "Wiper Rear Park Sense Input Circuit Failure",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2803",
        title: "B1823",
        desc: "Wiper Rear Park Sense Input Circuit Open",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2804",
        title: "B1824",
        desc: "Wiper Rear Park Sense Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2805",
        title: "B1825",
        desc: "Wiper Rear Park Sense Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2806",
        title: "B1826",
        desc: "Wiper Rear High Limit Input Circuit Failure",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2807",
        title: "B1827",
        desc: "Wiper Rear High Limit Input Circuit Open",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2808",
        title: "B1828",
        desc: "Wiper Rear High Limit Input Circuit Short To Battery",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2809",
        title: "B1829",
        desc: "Wiper Rear High Limit Input Circuit Short To Ground",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2810",
        title: "B1830",
        desc: "Door Unlock Disarm Switch Circuit Failure",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2811",
        title: "B1831",
        desc: "Door Unlock Disarm Switch Circuit Open",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2812",
        title: "B1832",
        desc: "Door Unlock Disarm Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2813",
        title: "B1833",
        desc: "Door Unlock Disarm Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2814",
        title: "B1834",
        desc: "Door Unlock Disarm Output Circuit Failure",
        created_at: "2022-08-18 19:39:22",
        updated_at: "2022-08-18 19:39:22",
    },
    {
        vehicle_error_id: "2815",
        title: "B1835",
        desc: "Door Unlock Disarm Output Circuit Open",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2816",
        title: "B1836",
        desc: "Door Unlock Disarm Output Circuit Short To Battery",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2817",
        title: "B1837",
        desc: "Door Unlock Disarm Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2818",
        title: "B1838",
        desc: "Battery Saver Power Relay Circuit Failure",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2819",
        title: "B1839",
        desc: "Wiper Rear Motor Circuit Failure",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2820",
        title: "B1840",
        desc: "Wiper Front Power Circuit Failure",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2821",
        title: "B1841",
        desc: "Wiper Front Power Circuit Open",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2822",
        title: "B1842",
        desc: "Wiper Front Power Circuit Short To Battery",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2823",
        title: "B1843",
        desc: "Wiper Front Power Circuit Short To Ground",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2824",
        title: "B1844",
        desc: "Phone Handset Circuit Failure",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2825",
        title: "B1845",
        desc: "Ignition Tamper Circuit Failure",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2826",
        title: "B1846",
        desc: "Ignition Tamper Circuit Open",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2827",
        title: "B1847",
        desc: "Ignition Tamper Circuit Short To Battery",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2828",
        title: "B1848",
        desc: "Ignition Tamper Circuit Short To Ground",
        created_at: "2022-08-18 19:39:23",
        updated_at: "2022-08-18 19:39:23",
    },
    {
        vehicle_error_id: "2829",
        title: "B1849",
        desc: "Climate Control Temperature Differential Circuit Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2830",
        title: "B1850",
        desc: "Climate Control Temperature Differential Circuit Open",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2831",
        title: "B1851",
        desc: "Climate Control Temperature Differential Circuit Short To Battery",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2832",
        title: "B1852",
        desc: "Climate Control Temperature Differential Circuit Short To Ground",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2833",
        title: "B1853",
        desc: "Climate Control Air Temperature Internal Sensor Motor Circuit Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2834",
        title: "B1854",
        desc: "Climate Control Air Temperature Internal Sensor Motor Circuit Open",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2835",
        title: "B1855",
        desc: "Climate Control Air Temperature Internal Sensor Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2836",
        title: "B1856",
        desc: "Climate Control Air Temperature Internal Sensor Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2837",
        title: "B1857",
        desc: "Climate Control On/Off Switch Circuit Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2838",
        title: "B1858",
        desc: "Climate Control A/C Pressure Switch Circuit Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2839",
        title: "B1859",
        desc: "Climate Control A/C Pressure Switch Circuit Open",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2840",
        title: "B1860",
        desc: "Climate Control A/C Pressure Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2841",
        title: "B1861",
        desc: "Climate Control A/C Pressure Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2842",
        title: "B1862",
        desc: "Climate Control A/C Lock Sensor Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2843",
        title: "B1863",
        desc: "Ground ECU Circuit Open",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2844",
        title: "B1864",
        desc: "Battery Power Supply ECU Circuit Failure",
        created_at: "2022-08-18 19:39:24",
        updated_at: "2022-08-18 19:39:24",
    },
    {
        vehicle_error_id: "2845",
        title: "B1865",
        desc: "Battery Power Supply ECU Circuit Open",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2846",
        title: "B1866",
        desc: "Battery Power Supply ECU Circuit Short To Battery",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2847",
        title: "B1867",
        desc: "Battery Power Supply ECU Circuit Short To Ground",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2848",
        title: "B1868",
        desc: "Lamp Air Bag Warning Indicator Circuit Failure",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2849",
        title: "B1869",
        desc: "Lamp Air Bag Warning Indicator Circuit Open",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2850",
        title: "B1870",
        desc: "Lamp Air Bag Warning Indicator Circuit Short To Battery",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2851",
        title: "B1871",
        desc: "Passenger Air Bag Disable Module Fault",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2852",
        title: "B1872",
        desc: "Turn Signal / Hazard Power Feed Circuit Short To Battery",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2853",
        title: "B1873",
        desc: "Turn Signal / Hazard Power Feed Circuit Short To Ground",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2854",
        title: "B1874",
        desc: "Cellular Phone Handset Not Present",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2855",
        title: "B1875",
        desc: "Turn Signal / Hazard Switch Signal Circuit Failure",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2856",
        title: "B1876",
        desc: "Seatbelt Driver Pretensioner Circuit Failure",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2857",
        title: "B1877",
        desc: "Seatbelt Driver Pretensioner Circuit Open",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2858",
        title: "B1878",
        desc: "Seatbelt Driver Pretensioner Circuit Short to Battery",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2859",
        title: "B1879",
        desc: "Seatbelt Driver Pretensioner Circuit Short to Ground",
        created_at: "2022-08-18 19:39:25",
        updated_at: "2022-08-18 19:39:25",
    },
    {
        vehicle_error_id: "2860",
        title: "B1880",
        desc: "Seatbelt Passenger Pretensioner Circuit Failure",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2861",
        title: "B1881",
        desc: "Seatbelt Passenger Pretensioner Circuit Open",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2862",
        title: "B1882",
        desc: "Seatbelt Passenger Pretensioner Circuit Short to Battery",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2863",
        title: "B1883",
        desc: "Seatbelt Passenger Pretensioner Circuit Short to Ground",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2864",
        title: "B1884",
        desc: "PAD Warning Lamp Inoperative",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2865",
        title: "B1885",
        desc: "Seatbelt Driver Pretensioner Circuit Resistance Low on Squib",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2866",
        title: "B1886",
        desc: "Seatbelt Passenger Pretensioner Circuit Resistance Low on Squib",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2867",
        title: "B1887",
        desc: "Air Bag Driver Circuit Resistance Low or Shorted Together",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2868",
        title: "B1888",
        desc: "Air Bag Passenger Circuit Resistance Low or Shorted Together",
        created_at: "2022-08-18 19:39:26",
        updated_at: "2022-08-18 19:39:26",
    },
    {
        vehicle_error_id: "2869",
        title: "B1889",
        desc: "Passenger Airbag Disable Module Sensor Obstructed",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2870",
        title: "B1890",
        desc: "PAD Warning Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2871",
        title: "B1891",
        desc: "Air Bag Tone Warning Indicator Circuit Short to Battery",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2872",
        title: "B1892",
        desc: "Air Bag Tone Warning Indicator Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2873",
        title: "B1893",
        desc: "GPS Antenna Open Circuit",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2874",
        title: "B1894",
        desc: "Wiper Rear Motor Speed Sense Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2875",
        title: "B1897",
        desc: "Horn Switch Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2876",
        title: "B1898",
        desc: "Chime Input #2 Circuit Short to Ground",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2877",
        title: "B1899",
        desc: "Microphone Input Signal Circuit Open",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2878",
        title: "B1900",
        desc: "Driver Side Airbag Fault",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2879",
        title: "B1901",
        desc: "Air Bag Crash Sensor #1 Feed/Return Circuit Short To Ground",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2880",
        title: "B1902",
        desc: "Air Bag Crash Sensor #1 Ground Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2881",
        title: "B1903",
        desc: "Air Bag Crash Sensor #1 Ground Circuit Short To Battery",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2882",
        title: "B1904",
        desc: "Air Bag Crash Sensor #2 Feed/Return Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2883",
        title: "B1905",
        desc: "Air Bag Crash Sensor #2 Feed/Return Circuit Short To Battery",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2884",
        title: "B1906",
        desc: "Air Bag Crash Sensor #2 Feed/Return Circuit Short To Ground",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2885",
        title: "B1907",
        desc: "Air Bag Crash Sensor #2 Ground Circuit Failure",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2886",
        title: "B1908",
        desc: "Air Bag Crash Sensor #2 Ground Circuit Short To Battery",
        created_at: "2022-08-18 19:39:27",
        updated_at: "2022-08-18 19:39:27",
    },
    {
        vehicle_error_id: "2887",
        title: "B1909",
        desc: "Air Bag Crash Sensor #2 Ground Circuit Short To Ground",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2888",
        title: "B1910",
        desc: "Air Bag Diagnostic Monitor Ground Circuit Failure",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2889",
        title: "B1911",
        desc: "Air Bag Diagnostic Monitor Ground Circuit Short To Battery",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2890",
        title: "B1912",
        desc: "Air Bag Diagnostic Monitor Ground Circuit Short To Ground",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2891",
        title: "B1913",
        desc: "Air Bag Driver/Passenger Circuit Short To Ground",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2892",
        title: "B1914",
        desc: "Air Bag Crash Sensors #1 / #2  Circuit Short To Ground",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2893",
        title: "B1915",
        desc: "Air Bag Driver Circuit Failure",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2894",
        title: "B1916",
        desc: "Air Bag Driver Circuit Short To Battery",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2895",
        title: "B1917",
        desc: "Air Bag Memory Clear Circuit Failure",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2896",
        title: "B1918",
        desc: "Air Bag Memory Clear Circuit Open",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2897",
        title: "B1919",
        desc: "Air Bag Memory Clear Circuit Short To Battery",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2898",
        title: "B1920",
        desc: "Air Bag Passenger Circuit Failure",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2899",
        title: "B1921",
        desc: "Air Bag Diagnostic Monitor Ground Circuit Open",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2900",
        title: "B1922",
        desc: "Air Bag Safing Sensor Output Circuit Short To Battery",
        created_at: "2022-08-18 19:39:28",
        updated_at: "2022-08-18 19:39:28",
    },
    {
        vehicle_error_id: "2901",
        title: "B1923",
        desc: "Air Bag Memory Clear Circuit Short To Ground",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2902",
        title: "B1924",
        desc: "Air Bag Internal Diagnostic Monitor Fault or System Disarm Fault",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2903",
        title: "B1925",
        desc: "Air Bag Passenger Circuit Short To Battery",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2904",
        title: "B1926",
        desc: "Air Bag Passenger Pressure Switch Circuit Failure",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2905",
        title: "B1927",
        desc: "Passenger Side Airbag Fault",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2906",
        title: "B1928",
        desc: "Air Bag Safing Sensor Output Circuit Failure",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2907",
        title: "B1929",
        desc: "Air Bag Safing Sensor Output Circuit Open",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2908",
        title: "B1930",
        desc: "Air Bag Safing Sensor Output Circuit Short To Ground",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2909",
        title: "B1931",
        desc: "Air Bag Crash Sensor #1 Feed/Return Circuit Failure",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2910",
        title: "B1932",
        desc: "Air Bag Driver Circuit Open",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2911",
        title: "B1933",
        desc: "Air Bag Passenger Circuit Open",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2912",
        title: "B1934",
        desc: "Air Bag Driver Inflator Circuit Resistance Low on Squib",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2913",
        title: "B1935",
        desc: "Air Bag Passenger Inflator Circuit Resistance Low on Squib",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2914",
        title: "B1936",
        desc: "Air Bag Driver Circuit Short To Ground",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2915",
        title: "B1937",
        desc: "Air Bag Passenger Pressure Switch Circuit Open",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2916",
        title: "B1938",
        desc: "Air Bag Passenger Circuit Short To Ground",
        created_at: "2022-08-18 19:39:29",
        updated_at: "2022-08-18 19:39:29",
    },
    {
        vehicle_error_id: "2917",
        title: "B1939",
        desc: "Air Bag Passenger Pressure Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2918",
        title: "B1941",
        desc: "Air Bag Crash Sensor #1 Feed/Return Circuit Open",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2919",
        title: "B1942",
        desc: "Air Bag Crash Sensor #2 Feed/Return Circuit Open",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2920",
        title: "B1943",
        desc: "Air Bag Crash Sensor #1 Ground Circuit Short To Ground",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2921",
        title: "B1944",
        desc: "Air Bag Crash Sensor #1 Ground Circuit Open",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2922",
        title: "B1945",
        desc: "Air Bag Crash Sensor #2 Ground Circuit Open",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2923",
        title: "B1946",
        desc: "Climate Control A/C Post Evaporator Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2924",
        title: "B1947",
        desc: "Climate Control A/C Post Evaporator Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2925",
        title: "B1948",
        desc: "Climate Control Water Temperature Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2926",
        title: "B1949",
        desc: "Climate Control Water Temperature Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2927",
        title: "B1950",
        desc: "Seat Rear Up/Down Potentiometer Feedback Circuit Failure",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2928",
        title: "B1951",
        desc: "Seat Rear Up/Down Potentiometer Feedback Circuit Open",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2929",
        title: "B1952",
        desc: "Seat Rear Up/Down Potentiometer Feedback Circuit Short To Battery",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2930",
        title: "B1953",
        desc: "Seat Rear Up/Down Potentiometer Feedback Circuit Short To Ground",
        created_at: "2022-08-18 19:39:30",
        updated_at: "2022-08-18 19:39:30",
    },
    {
        vehicle_error_id: "2931",
        title: "B1954",
        desc: "Seat Front Up/Down Potentiometer Feedback Circuit Failure",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2932",
        title: "B1955",
        desc: "Seat Front Up/Down Potentiometer Feedback Circuit Open",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2933",
        title: "B1956",
        desc: "Seat Front Up/Down Potentiometer Feedback Circuit Short To Battery",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2934",
        title: "B1957",
        desc: "Seat Front Up/Down Potentiometer Feedback Circuit Short To Ground",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2935",
        title: "B1958",
        desc: "Seat Recline Forward/Backward Potentiometer Feedback Circuit Failure",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2936",
        title: "B1959",
        desc: "Seat Recline Forward/Backward Potentiometer Feedback Circuit Open",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2937",
        title: "B1960",
        desc: "Seat Recline Forward/Backward Potentiometer Feedback Circuit Short To Battery",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2938",
        title: "B1961",
        desc: "Seat Recline Forward/Backward Potentiometer Feedback Circuit Short To Ground",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2939",
        title: "B1962",
        desc: "Seat Horizontal Forward/Rearward Potentiometer Feedback Circuit Failure",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2940",
        title: "B1963",
        desc: "Seat Horizontal Forward/Rearward Potentiometer Feedback Circuit Open",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2941",
        title: "B1964",
        desc: "Seat Horizontal Forward/Rearward Potentiometer Feedback Circuit Short To Battery",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2942",
        title: "B1965",
        desc: "Seat Horizontal Forward/Rearward Potentiometer Feedback Circuit Short To Ground",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2943",
        title: "B1966",
        desc: "A/C Post Heater Sensor Circuit Failure",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2944",
        title: "B1967",
        desc: "A/C Post Heater Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:39:31",
        updated_at: "2022-08-18 19:39:31",
    },
    {
        vehicle_error_id: "2945",
        title: "B1968",
        desc: "A/C Water Pump Detection Circuit Failure",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2946",
        title: "B1969",
        desc: "A/C Clutch Magnetic Control Circuit Failure",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2947",
        title: "B1970",
        desc: "Passenger Seatback Forward Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2948",
        title: "B1971",
        desc: "Passenger Seatback Rearward Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2949",
        title: "B1972",
        desc: "Passenger Rear Seat Up Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2950",
        title: "B1973",
        desc: "Passenger Rear Seat Down Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2951",
        title: "B1979",
        desc: "Passenger Seat Rearward Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2952",
        title: "B1980",
        desc: "Bulb - Outage Condition Detected",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2953",
        title: "B1981",
        desc: "Memory Off Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2954",
        title: "B1984",
        desc: "Seat Switch Lumbar Inflate Circuit Failure",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2955",
        title: "B1985",
        desc: "Seat Switch Lumbar Deflate Circuit Failure",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2956",
        title: "B1987",
        desc: "Pedal Forward / Rearward Motor Stalled",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2957",
        title: "B1988",
        desc: "Pedal Position Forward Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2958",
        title: "B1989",
        desc: "Pedal Position Rearward Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:39:32",
        updated_at: "2022-08-18 19:39:32",
    },
    {
        vehicle_error_id: "2959",
        title: "B1990",
        desc: "Pedal Forward / Rearward Potentiometer Feedback Circuit Failure",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2960",
        title: "B1991",
        desc: "Pedal Forward / Rearward Potentiometer Feedback Circuit Short to Battery",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2961",
        title: "B1992",
        desc: "Driver Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2962",
        title: "B1993",
        desc: "Driver Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2963",
        title: "B1994",
        desc: "Driver Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2964",
        title: "B1995",
        desc: "Driver Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2965",
        title: "B1996",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2966",
        title: "B1997",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:39:33",
        updated_at: "2022-08-18 19:39:33",
    },
    {
        vehicle_error_id: "2967",
        title: "B1998",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2968",
        title: "B1999",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2969",
        title: "B2100",
        desc: "Door Driver Key Cylinder Switch Failure",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2970",
        title: "B2101",
        desc: "Head Rest Switch Circuit Failure",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2971",
        title: "B2102",
        desc: "Antenna Circuit Short to Ground",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2972",
        title: "B2103",
        desc: "Antenna Not Connected",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2973",
        title: "B2104",
        desc: "Door Passenger Key Cylinder Switch Failure",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2974",
        title: "B2105",
        desc: "Throttle Position Input Out of Range Low",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2975",
        title: "B2106",
        desc: "Throttle Position Input Out of Range High",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2976",
        title: "B2107",
        desc: "Front Wiper Motor Relay Circuit Short to Vbatt",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2977",
        title: "B2108",
        desc: "Trunk Key Cylinder Switch Failure",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2978",
        title: "B2109",
        desc: "Heated Wind Shield Relay Short to Vbatt (changed from Failure 2/6/97)",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2979",
        title: "B2110",
        desc: "Front Wiper Motor Relay Circuit Open (changed from Failure 2/6/97)",
        created_at: "2022-08-18 19:39:34",
        updated_at: "2022-08-18 19:39:34",
    },
    {
        vehicle_error_id: "2980",
        title: "B2111",
        desc: "All Door Lock Input Short to Ground",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2981",
        title: "B2112",
        desc: "Door Driver Set Switch Stuck Failure",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2982",
        title: "B2113",
        desc: "Heated Windshield Input Short to Ground",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2983",
        title: "B2114",
        desc: "Front Washer Input Short to Ground",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2984",
        title: "B2115",
        desc: "Rear Washer Input Short to Ground",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2985",
        title: "B2116",
        desc: "Door Driver Reset Switch Stuck Failure",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2986",
        title: "B2117",
        desc: "Driver Side",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2987",
        title: "B2118",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2988",
        title: "B2119",
        desc: "Compressor Failure",
        created_at: "2022-08-18 19:39:35",
        updated_at: "2022-08-18 19:39:35",
    },
    {
        vehicle_error_id: "2989",
        title: "B2120",
        desc: "Door Passenger Set Switch Stuck Failure",
        created_at: "2022-08-18 19:42:00",
        updated_at: "2022-08-18 19:42:00",
    },
    {
        vehicle_error_id: "2990",
        title: "B2122",
        desc: "Driver Side Satellite Communication Circuit Short to Ground",
        created_at: "2022-08-18 19:42:00",
        updated_at: "2022-08-18 19:42:00",
    },
    {
        vehicle_error_id: "2991",
        title: "B2123",
        desc: "Passenger Side Satellite Communication Circuit Short to Ground",
        created_at: "2022-08-18 19:42:00",
        updated_at: "2022-08-18 19:42:00",
    },
    {
        vehicle_error_id: "2992",
        title: "B2124",
        desc: "Door Passenger Reset Switch Stuck Failure",
        created_at: "2022-08-18 19:42:00",
        updated_at: "2022-08-18 19:42:00",
    },
    {
        vehicle_error_id: "2993",
        title: "B2128",
        desc: "Central Lock Motor Failure",
        created_at: "2022-08-18 19:42:00",
        updated_at: "2022-08-18 19:42:00",
    },
    {
        vehicle_error_id: "2994",
        title: "B2129",
        desc: "Central Lock Feedback Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "2995",
        title: "B2130",
        desc: "Double Lock Timeout Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "2996",
        title: "B2131",
        desc: "Double Lock Feedback Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "2997",
        title: "B2132",
        desc: "Dimmer switch Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "2998",
        title: "B2133",
        desc: "Brake Motor Warning lamp Circuit Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "2999",
        title: "B2134",
        desc: "Brake Motor Warning lamp Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3000",
        title: "B2135",
        desc: "Park Brake Applied Warning Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3001",
        title: "B2136",
        desc: "Park Brake Applied Warning Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3002",
        title: "B2139",
        desc: "Data Mismatch (receive data does not match what was expected)",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3003",
        title: "B2141",
        desc: "NVM Configuration Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3004",
        title: "B2142",
        desc: "NVM TIC Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3005",
        title: "B2143",
        desc: "NVM Memory Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3006",
        title: "B2144",
        desc: "NVM Alarm Data Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3007",
        title: "B2145",
        desc: "NVM RF HR Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3008",
        title: "B2146",
        desc: "Seat Recline Motor Position Out of Range",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3009",
        title: "B2148",
        desc: "PWM Input Circuit Failure",
        created_at: "2022-08-18 19:42:01",
        updated_at: "2022-08-18 19:42:01",
    },
    {
        vehicle_error_id: "3010",
        title: "B2149",
        desc: "Seat Front Vertical Motor Position Out of Range",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3011",
        title: "B2150",
        desc: "Power Supply #1 Circuit Short to Ground",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3012",
        title: "B2151",
        desc: "Power Supply #2 Circuit Short to Ground",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3013",
        title: "B2152",
        desc: "Seat Rear Vertical Motor Position Out of Range",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3014",
        title: "B2153",
        desc: "Rear Echo Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3015",
        title: "B2154",
        desc: "Front Echo Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3016",
        title: "B2155",
        desc: "Seat Horizontal Motor Position Out of Range",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3017",
        title: "B2156",
        desc: "Rear Doppler Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:02",
        updated_at: "2022-08-18 19:42:02",
    },
    {
        vehicle_error_id: "3018",
        title: "B2157",
        desc: "Front Doppler Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3019",
        title: "B2158",
        desc: "Seat Recline Motor Memory Position Out of Range",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3020",
        title: "B2159",
        desc: "Memory #1 output Short to Ground",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3021",
        title: "B2160",
        desc: "Memory #1 output Short to VBatt",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3022",
        title: "B2161",
        desc: "Seat Front Vertical Motor Memory Position Out of Range",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3023",
        title: "B2162",
        desc: "Data Mismatch #2 (receive data does not match what was expected)",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3024",
        title: "B2163",
        desc: "Clutch Position Fault",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3025",
        title: "B2164",
        desc: "Seat Rear Vertical Motor Memory Position Out of Range",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3026",
        title: "B2165",
        desc: "Gear shift position Fault",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3027",
        title: "B2166",
        desc: "Gear select position Fault",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3028",
        title: "B2167",
        desc: "Seat Horizontal Motor Memory Position Out of Range",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3029",
        title: "B2168",
        desc: "Unable to confirm Unlock Condition",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3030",
        title: "B2169",
        desc: "Unable to confirm lock Condition",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3031",
        title: "B2170",
        desc: "Steering Column Lock Switch Circuit Failure",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3032",
        title: "B2172",
        desc: "Inertia Switch input Circuit Open",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3033",
        title: "B2174",
        desc: "Window Driver Rear Remote Up Switch Short to Battery",
        created_at: "2022-08-18 19:42:03",
        updated_at: "2022-08-18 19:42:03",
    },
    {
        vehicle_error_id: "3034",
        title: "B2175",
        desc: "A/C Request Signal Circuit Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3035",
        title: "B2176",
        desc: "Overdrive switch circuit short to Vbatt",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3036",
        title: "B2177",
        desc: "Interior Scanning Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3037",
        title: "B2178",
        desc: "Window Driver Rear Remote Down Switch Short to Battery",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3038",
        title: "B2179",
        desc: "Front Wiper Select Switch 'A' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3039",
        title: "B2180",
        desc: "Front Wiper Select Switch 'B' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3040",
        title: "B2181",
        desc: "Front Wiper Select Switch 'C' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3041",
        title: "B2182",
        desc: "Window Passenger Front Remote Up Switch Short to Battery",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3042",
        title: "B2183",
        desc: "Front Wiper Select Switch 'H' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3043",
        title: "B2184",
        desc: "Front Wiper Select Switch 'W' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3044",
        title: "B2185",
        desc: "Rear Wiper Select Switch 'D' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3045",
        title: "B2186",
        desc: "Window Passenger Front Remote Down Switch Short to Battery",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3046",
        title: "B2187",
        desc: "Rear Wiper Select Switch 'B' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3047",
        title: "B2188",
        desc: "Rear Wiper Select Switch 'E' Short to Ground",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3048",
        title: "B2190",
        desc: "Window Passenger Rear Remote Up Switch Short to Battery",
        created_at: "2022-08-18 19:42:04",
        updated_at: "2022-08-18 19:42:04",
    },
    {
        vehicle_error_id: "3049",
        title: "B2194",
        desc: "Window Passenger Rear Remote Down Switch Short to Battery",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3050",
        title: "B2195",
        desc: "Driver Window  Up / Down Power Circuit Short to Ground",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3051",
        title: "B2196",
        desc: "Passenger Window Up / Down Power Circuit Short to Ground",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3052",
        title: "B2197",
        desc: "TV Module Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3053",
        title: "B2198",
        desc: "TrafficMaster Module Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3054",
        title: "B2199",
        desc: "VICS Module Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3055",
        title: "B2200",
        desc: "No Communication to TV Module (No Fitting of TV)",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3056",
        title: "B2201",
        desc: "No Communication With Traffic MasterModule",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3057",
        title: "B2202",
        desc: "No Communication to VICS Module (No Fitting of VICS)",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3058",
        title: "B2203",
        desc: "CD-ROM Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3059",
        title: "B2204",
        desc: "GPS Antenna Connection Open or Short",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3060",
        title: "B2205",
        desc: "GPS Receiver Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3061",
        title: "B2206",
        desc: "Gyroscope Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3062",
        title: "B2207",
        desc: "ECU ROM Checksum Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3063",
        title: "B2208",
        desc: "Communication Link to Display and Switch Module Error",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3064",
        title: "B2209",
        desc: "Interior Lamp Override Switch Open Circuit",
        created_at: "2022-08-18 19:42:05",
        updated_at: "2022-08-18 19:42:05",
    },
    {
        vehicle_error_id: "3065",
        title: "B2210",
        desc: "Interior Lamp Override Switch Short to Ground",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3066",
        title: "B2211",
        desc: "Low Coolant Lamp Output Circuit Short to Battery",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3067",
        title: "B2214",
        desc: "Window Passenger Front Up Switch Short to Battery",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3068",
        title: "B2215",
        desc: "Window Passenger Front Down Switch Short to Battery",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3069",
        title: "B2219",
        desc: "Window Driver Front Current Feedback Exceeded",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3070",
        title: "B2220",
        desc: "Window Driver Rear Current Feedback Exceeded",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3071",
        title: "B2221",
        desc: "Window Passenger Front Current Feedback Exceeded",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3072",
        title: "B2222",
        desc: "Window Passenger Rear Current Feedback Exceeded",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3073",
        title: "B2223",
        desc: "Mirror Driver Drive Circuit Failure",
        created_at: "2022-08-18 19:42:06",
        updated_at: "2022-08-18 19:42:06",
    },
    {
        vehicle_error_id: "3074",
        title: "B2224",
        desc: "Mirror Passenger Drive Circuit Failure",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3075",
        title: "B2225",
        desc: "Front Crash Sensor Mount Fault",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3076",
        title: "B2226",
        desc: "Front Crash Sensor Internal Fault",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3077",
        title: "B2227",
        desc: "Front Crash Sensor Driver Communications Fault",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3078",
        title: "B2228",
        desc: "Air Bag Driver Circuit Short to Ground - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3079",
        title: "B2229",
        desc: "Air Bag Passenger Circuit Short to Ground - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3080",
        title: "B2230",
        desc: "Air Bag Driver Circuit Short to Battery - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3081",
        title: "B2231",
        desc: "Air Bag Passenger Circuit Short to Battery - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3082",
        title: "B2232",
        desc: "Air Bag Driver Circuit Open - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3083",
        title: "B2233",
        desc: "Air Bag passenger Circuit Open - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3084",
        title: "B2234",
        desc: "Air Bag Driver Inflator Circuit Resistance Low on Squib - Loop #2",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3085",
        title: "B2235",
        desc: "Air Bag Passenger Inflator Circuit",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3086",
        title: "B2236",
        desc: "Weak or Defected Electric Vehicle Battery Module Fault",
        created_at: "2022-08-18 19:42:07",
        updated_at: "2022-08-18 19:42:07",
    },
    {
        vehicle_error_id: "3087",
        title: "B2237",
        desc: "Vehicle Signal indicating Park While VSS Present",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3088",
        title: "B2238",
        desc: "Power Cable For Power Sliding Door Broken",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3089",
        title: "B2239",
        desc: "Rear Cargo Door Set Switch Stuck (Short to Ground)",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3090",
        title: "B2240",
        desc: "Rear Cargo Door Reset Switch Stuck (Short to Ground)",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3091",
        title: "B2241",
        desc: "Rear Cargo Door Lock Circuit Short to Ground",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3092",
        title: "B2242",
        desc: "Rear Cargo Door Unlock Circuit Open",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3093",
        title: "B2243",
        desc: "Driver Rear Door Ajar Circuit Open",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3094",
        title: "B2244",
        desc: "Driver Sliding Door Ajar Circuit Short to GND",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3095",
        title: "B2245",
        desc: "Passenger Rear Door Ajar Circuit Open",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3096",
        title: "B2246",
        desc: "Passenger Sliding Door Ajar Circuit Short to GND",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3097",
        title: "B2247",
        desc: "EV Battery Pack Temperature Fault",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3098",
        title: "B2248",
        desc: "Heated Windshield Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:42:08",
        updated_at: "2022-08-18 19:42:08",
    },
    {
        vehicle_error_id: "3099",
        title: "B2249",
        desc: "Head Lamp Relay Coil Short to Battery",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3100",
        title: "B2250",
        desc: "All Doors Unlock Relay Circuit Failure",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3101",
        title: "B2251",
        desc: "Parklamp Output Relay Driver Circuit Failure",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3102",
        title: "B2252",
        desc: "Parklamp Output Relay Dirver Short to Battery",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3103",
        title: "B2300",
        desc: "Seat Driver Memory Position Error",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3104",
        title: "B2301",
        desc: "Seat Passenger Memory Position Error",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3105",
        title: "B2302",
        desc: "Seat Headrest Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3106",
        title: "B2303",
        desc: "Seat Headrest Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3107",
        title: "B2304",
        desc: "Seat Headrest Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3108",
        title: "B2305",
        desc: "Seat Headrest Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3109",
        title: "B2306",
        desc: "Seat Headrest Motor Stalled",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3110",
        title: "B2310",
        desc: "Mirror Driver Memory Position Error",
        created_at: "2022-08-18 19:42:09",
        updated_at: "2022-08-18 19:42:09",
    },
    {
        vehicle_error_id: "3111",
        title: "B2311",
        desc: "Mirror Passenger Memory Position Error",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3112",
        title: "B2312",
        desc: "Mirror Passenger Horizontal Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3113",
        title: "B2313",
        desc: "Mirror Passenger Horizontal Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3114",
        title: "B2314",
        desc: "Mirror Passenger Horizontal Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3115",
        title: "B2315",
        desc: "Mirror Passenger Horizontal Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3116",
        title: "B2316",
        desc: "Mirror Passenger Vertical Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3117",
        title: "B2317",
        desc: "Mirror Passenger Vertical Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3118",
        title: "B2318",
        desc: "Mirror Passenger Vertical Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3119",
        title: "B2319",
        desc: "Mirror Passenger Vertical Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3120",
        title: "B2320",
        desc: "Mirror Driver Horizontal Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3121",
        title: "B2321",
        desc: "Mirror Driver Horizontal Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3122",
        title: "B2322",
        desc: "Mirror Driver Horizontal Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3123",
        title: "B2323",
        desc: "Mirror Driver Horizontal Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3124",
        title: "B2324",
        desc: "Mirror Driver Vertical Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3125",
        title: "B2325",
        desc: "Mirror Driver Vertical Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3126",
        title: "B2326",
        desc: "Mirror Driver Vertical Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:10",
        updated_at: "2022-08-18 19:42:10",
    },
    {
        vehicle_error_id: "3127",
        title: "B2327",
        desc: "Mirror Driver Vertical Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3128",
        title: "B2328",
        desc: "Column Reach Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3129",
        title: "B2329",
        desc: "Column Reach Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3130",
        title: "B2330",
        desc: "Column Reach Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3131",
        title: "B2331",
        desc: "Column Reach Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3132",
        title: "B2332",
        desc: "Column Tilt Feedback Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3133",
        title: "B2333",
        desc: "Column Tilt Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3134",
        title: "B2334",
        desc: "Column Tilt Feedback Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3135",
        title: "B2335",
        desc: "Column Tilt Feedback Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3136",
        title: "B2336",
        desc: "Mirror Switch Assembly Circuit Failure",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3137",
        title: "B2337",
        desc: "Mirror Switch Assembly Circuit Open",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3138",
        title: "B2338",
        desc: "Mirror Switch Assembly Circuit Short to Battery",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3139",
        title: "B2339",
        desc: "Mirror Switch Assembly Circuit Short to Ground",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3140",
        title: "B2340",
        desc: "Column Reach Motor Stalled",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3141",
        title: "B2341",
        desc: "Column Tilt Motor Stalled",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3142",
        title: "B2342",
        desc: "Seat Switch Reference Voltage Positive Common Open Circuit",
        created_at: "2022-08-18 19:42:11",
        updated_at: "2022-08-18 19:42:11",
    },
    {
        vehicle_error_id: "3143",
        title: "B2343",
        desc: "Seat Switch Reference Voltage Positive Common Supply Low Voltage",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3144",
        title: "B2344",
        desc: "Seat Switch Reference Voltage Positive Common Supply Voltage Fault",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3145",
        title: "B2345",
        desc: "Seat Switch Reference Voltage Negative Common Open Circuit",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3146",
        title: "B2346",
        desc: "Mirror Switch Reference Voltage Positive Common Open Circuit",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3147",
        title: "B2347",
        desc: "Mirror Switch Reference Voltage Positive Common Supply Low Voltage",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3148",
        title: "B2348",
        desc: "Mirror Switch Reference Voltage Positive Common Supply Voltage Fault",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3149",
        title: "B2349",
        desc: "Mirror Switch Reference Voltage Negative Common Open Circuit",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3150",
        title: "B2350",
        desc: "Steering Column Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3151",
        title: "B2351",
        desc: "Steering Column Switch Circuit Failure",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3152",
        title: "B2352",
        desc: "Driver Memory Power Switch Indicator Circuit Short to Battery",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3153",
        title: "B2353",
        desc: "Driver Mirror Power Driver Circuit Short Ground",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3154",
        title: "B2354",
        desc: "Driver Mirror Horizontal / Vertical Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3155",
        title: "B2355",
        desc: "Passenger Mirror Horizontal / Vertical Feedback Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3156",
        title: "B2357",
        desc: "Driver Window Down Current Sense Low Circuit Failure",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3157",
        title: "B2362",
        desc: "Remote Open/Close signal Circuit Short to Ground",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3158",
        title: "B2363",
        desc: "Optical Sensor System Failure",
        created_at: "2022-08-18 19:42:12",
        updated_at: "2022-08-18 19:42:12",
    },
    {
        vehicle_error_id: "3159",
        title: "B2364",
        desc: "Fuel Filler Door Circuit Open",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3160",
        title: "B2365",
        desc: "B-pillar Power Sliding Door Open/Close Switch Input Ckt Short to Gnd",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3161",
        title: "B2366",
        desc: "IP Power Sliding Door Open/Close switch Ckt Short to Gnd",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3162",
        title: "B2367",
        desc: "Power Sliding Door Override Switch Input Ckt Short to Gnd",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3163",
        title: "B2368",
        desc: "Steering Column Switch Circuit Out of Range",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3164",
        title: "B2369",
        desc: "Chime OUTPUT Request Ckt Short to Ground",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3165",
        title: "B2373",
        desc: "LED #1 Circuit Short to Battery",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3166",
        title: "B2374",
        desc: "Power Sliding Detent (Latch) Circuit  Failure",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3167",
        title: "B2380",
        desc: "Heater Coolant Temp sensor circuit Short to GND",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3168",
        title: "B2381",
        desc: "Heater Coolant Temp sensor circuit Open",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3169",
        title: "B2384",
        desc: "Audio Reverse Aid Mute Input Ckt Failure",
        created_at: "2022-08-18 19:42:13",
        updated_at: "2022-08-18 19:42:13",
    },
    {
        vehicle_error_id: "3170",
        title: "B2385",
        desc: "Audio Navigation Mute Input Ckt  Failure",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3171",
        title: "B2401",
        desc: "Audio Tape Deck Mechanism Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3172",
        title: "B2402",
        desc: "Audio CD/DJ Thermal Shutdown Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3173",
        title: "B2403",
        desc: "Audio CD/DJ Internal Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3174",
        title: "B2404",
        desc: "Audio Steering Wheel Switch Circuit Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3175",
        title: "B2405",
        desc: "Audio Single-Disc CD Player Thermal Shutdown Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3176",
        title: "B2406",
        desc: "Audio Single-Disc CD Player Internal Fault",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3177",
        title: "B2416",
        desc: "Climate Control Recirculation Actuator Out of Limits",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3178",
        title: "B2425",
        desc: "Remote Keyless Entry Out of Synchronization",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3179",
        title: "B2426",
        desc: "Passenger Solar Radiation Sensor  Circuit Open",
        created_at: "2022-08-18 19:42:14",
        updated_at: "2022-08-18 19:42:14",
    },
    {
        vehicle_error_id: "3180",
        title: "B2427",
        desc: "Passenger Solar Radiation Sensor  Circuit Short to Ground",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3181",
        title: "B2428",
        desc: "A/C Post Heater Sensor #2 Circuit Failure",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3182",
        title: "B2429",
        desc: "A/C Post Heater Sensor #2 Circuit Short to Ground",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3183",
        title: "B2431",
        desc: "Transponder Programming Failed",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3184",
        title: "B2432",
        desc: "Drivers Seat Belt Buckle Switch Circuit Open",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3185",
        title: "B2433",
        desc: "Drivers Seat Belt Buckle Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3186",
        title: "B2434",
        desc: "Drivers Seat Belt Buckle Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3187",
        title: "B2435",
        desc: "Drivers Seat Belt Buckle Switch Resistance out of Range",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3188",
        title: "B2436",
        desc: "Passengers Seat Belt Buckle Switch Circuit Open",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3189",
        title: "B2437",
        desc: "Passengers Seat Belt Buckle Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3190",
        title: "B2438",
        desc: "Passengers Seat Belt Buckle Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3191",
        title: "B2439",
        desc: "Passengers Seat Belt Buckle Switch Resistance out of Range",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3192",
        title: "B2440",
        desc: "Passenger Side",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3193",
        title: "B2441",
        desc: "Driver Side",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3194",
        title: "B2442",
        desc: "Intrusion Sensor Fault",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3195",
        title: "B2443",
        desc: "Powertrain Performance Mode Switch Circuit failure",
        created_at: "2022-08-18 19:42:15",
        updated_at: "2022-08-18 19:42:15",
    },
    {
        vehicle_error_id: "3196",
        title: "B2444",
        desc: "Driver Side Crash Sensor Internal Fault",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3197",
        title: "B2445",
        desc: "Passenger Side Crash Sensor Internal Fault",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3198",
        title: "B2446",
        desc: "RESCU/VEMS Input Circuit Open",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3199",
        title: "B2447",
        desc: "RESCU/VEMS Input Circuit Short to Battery",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3200",
        title: "B2448",
        desc: "RESCU/VEMS Input Circuit Short to Ground",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3201",
        title: "B2449",
        desc: "Aux Heater Glow Plug Circuit Short to Ground",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3202",
        title: "B2450",
        desc: "Aux Heater Glow Plug Circuit Open",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3203",
        title: "B2451",
        desc: "Aux Heater Fuel Pump Circuit Short to Ground",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3204",
        title: "B2452",
        desc: "Aux Heater Fuel Pump Circuit Open",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3205",
        title: "B2453",
        desc: "Aux Heater Blower Fan Circuit Short to Ground",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3206",
        title: "B2454",
        desc: "Aux Heater Blower Fan Circuit Open",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3207",
        title: "B2455",
        desc: "Aux Heater Blower Faulted",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3208",
        title: "B2456",
        desc: "Aux Heater Coolant Sensor Circuit Short to Ground",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3209",
        title: "B2457",
        desc: "Aux Heater Coolant Sensor Circuit Open",
        created_at: "2022-08-18 19:42:16",
        updated_at: "2022-08-18 19:42:16",
    },
    {
        vehicle_error_id: "3210",
        title: "B2458",
        desc: "Aux Heater Overheat Sensor Circuit Short to Ground",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3211",
        title: "B2459",
        desc: "Aux Heater Overheat Sensor Circuit Open",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3212",
        title: "B2460",
        desc: "Aux Heater Flame Sensor Circuit Short to Ground",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3213",
        title: "B2461",
        desc: "Aux Heater Flame Sensor Circuit Open",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3214",
        title: "B2462",
        desc: "Aux Heater Flame Out Fault",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3215",
        title: "B2463",
        desc: "Aux Heater Overheat Fault",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3216",
        title: "B2464",
        desc: "Aux Heater Start Time Exceeded",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3217",
        title: "B2465",
        desc: "Aux Heater Start Counter Overrun/System Locked (same as below ?)",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3218",
        title: "B2466",
        desc: "Aux Heater Overheat Counter Overrun/System Locked",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3219",
        title: "B2467",
        desc: "Aux Heater Cool Down Time Exceeded (may be con to a453-5 )",
        created_at: "2022-08-18 19:42:17",
        updated_at: "2022-08-18 19:42:17",
    },
    {
        vehicle_error_id: "3220",
        title: "B2468",
        desc: "Aux Heater Coolant Pump Circuit Short to Ground",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3221",
        title: "B2469",
        desc: "Aux Heater Coolant Pump Circuit Open",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3222",
        title: "B2470",
        desc: "Interior Fan Control Circuit Short to Ground",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3223",
        title: "B2471",
        desc: "Interior Fan Control Circuit Open",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3224",
        title: "B2472",
        desc: "Fog Lamp Switch  Failure",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3225",
        title: "B2473",
        desc: "Passenger Door Disarm Switch ckt Short to Ground",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3226",
        title: "B2474",
        desc: "Passenger Door Lock Switch Circuit Shorted to Ground",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3227",
        title: "B2475",
        desc: "Passenger Door Unlock Switch Circuit Shorted to Ground",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3228",
        title: "B2476",
        desc: "RADIO PRESENT SWITCH Ckt Failure",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3229",
        title: "B2477",
        desc: "Module Configuration Failure",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3230",
        title: "B2478",
        desc: "ANTI THEFT INPUT SIGNAL SHORT TO GROUND",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3231",
        title: "B2479",
        desc: "BRAKE PARK SWITCH CIRCUIT SHORT TO GROUND",
        created_at: "2022-08-18 19:42:18",
        updated_at: "2022-08-18 19:42:18",
    },
    {
        vehicle_error_id: "3232",
        title: "B2480",
        desc: "LF CORNER LAMP OUTPUT Ckt Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3233",
        title: "B2481",
        desc: "Convertible Top Up/Down switch Fault",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3234",
        title: "B2482",
        desc: "RF CORNER LAMP OUTPUT Ckt Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3235",
        title: "B2483",
        desc: "Enable Signal Open Circuit",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3236",
        title: "B2484",
        desc: "Disable Signal Short to Ground",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3237",
        title: "B2485",
        desc: "LF SIDE REPEATER LAMP OUTPUT Ckt Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3238",
        title: "B2487",
        desc: "RF SIDE REPEATER LAMP OUTPUT Ckt Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3239",
        title: "B2489",
        desc: "UNDERHOOD LAMP OUTPUT CIRCUIT Failure",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3240",
        title: "B2490",
        desc: "UNDERHOOD LAMP OUTPUT CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3241",
        title: "B2491",
        desc: "RF PARK LAMP OUTPUT CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3242",
        title: "B2492",
        desc: "Already Programmed (Test Mode DTC Only !!!)",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3243",
        title: "B2493",
        desc: "LF PARK LAMP OUTPUT CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3244",
        title: "B2494",
        desc: "ANTI THEFT HORN OUTPUT CIRCUIT Short to Batt",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3245",
        title: "B2495",
        desc: "ANTI THEFT HORN OUTPUT CIRCUIT Failure",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3246",
        title: "B2496",
        desc: "ANTI THEFT HORN OUTPUT CIRCUIT Short to Gnd",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3247",
        title: "B2499",
        desc: "COURTESY LAMP OUTPUT Failure",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3248",
        title: "B2500",
        desc: "COURTESY LAMP OUTPUT Ckt Short to Battery",
        created_at: "2022-08-18 19:42:19",
        updated_at: "2022-08-18 19:42:19",
    },
    {
        vehicle_error_id: "3249",
        title: "B2501",
        desc: "LF LAMP LOW BEAM CIRCUIT Failure",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3250",
        title: "B2502",
        desc: "LF LAMP LOW BEAM CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3251",
        title: "B2503",
        desc: "RF LAMP LOW BEAM CIRCUIT Failure",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3252",
        title: "B2504",
        desc: "RF LAMP LOW BEAM CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3253",
        title: "B2505",
        desc: "LF LAMP HIGH BEAM CIRCUIT Failure",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3254",
        title: "B2506",
        desc: "LF LAMP HIGH BEAM CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3255",
        title: "B2507",
        desc: "RF LAMP HIGH BEAM CIRCUIT Failure",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3256",
        title: "B2508",
        desc: "RF LAMP HIGH BEAM CIRCUIT Short to Battery",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3257",
        title: "B2509",
        desc: "Rear Fog Lamp Switch Circuit Failure",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3258",
        title: "B2510",
        desc: "Main Blower Motor Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:42:20",
        updated_at: "2022-08-18 19:42:20",
    },
    {
        vehicle_error_id: "3259",
        title: "B2511",
        desc: "Horn Output Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3260",
        title: "B2512",
        desc: "Front Fog Lamp Relay Ckt Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3261",
        title: "B2513",
        desc: "Blower (Fan) Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3262",
        title: "B2514",
        desc: "Blower (Fan) Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3263",
        title: "B2515",
        desc: "Heater Blower Relay Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3264",
        title: "B2516",
        desc: "Blower Control Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3265",
        title: "B2517",
        desc: "Emergency Power Off System Faulted",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3266",
        title: "B2518",
        desc: "Compressor Overtemp Fault",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3267",
        title: "B2519",
        desc: "High Mount Stop Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3268",
        title: "B2520",
        desc: "High Mount Stop Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3269",
        title: "B2523",
        desc: "License Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3270",
        title: "B2524",
        desc: "License Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3271",
        title: "B2525",
        desc: "Left Rear Backup Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3272",
        title: "B2526",
        desc: "Left Rear Backup Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3273",
        title: "B2527",
        desc: "Left Rear Stop lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3274",
        title: "B2528",
        desc: "Left Rear Stop lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3275",
        title: "B2529",
        desc: "Left Rear  Turn Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3276",
        title: "B2530",
        desc: "Left Rear  Turn Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3277",
        title: "B2531",
        desc: "Right Rear Backup Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3278",
        title: "B2532",
        desc: "Right Rear Backup Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3279",
        title: "B2533",
        desc: "Right Rear Stop lamp Circuit Failure",
        created_at: "2022-08-18 19:42:21",
        updated_at: "2022-08-18 19:42:21",
    },
    {
        vehicle_error_id: "3280",
        title: "B2534",
        desc: "Right Rear Stop lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3281",
        title: "B2535",
        desc: "Right Rear  Turn Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3282",
        title: "B2536",
        desc: "Right Rear  Turn Lamp Circuit Short to Battery",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3283",
        title: "B2539",
        desc: "Aux A/C Mode Position Reference Circuit Short to Ground",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3284",
        title: "B2540",
        desc: "Aux A/C Mode Position Reference Circuit Short to Battery",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3285",
        title: "B2543",
        desc: "Aux A/C Control Switch Reference Circuit Short to Ground",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3286",
        title: "B2544",
        desc: "Aux A/C Control Switch Reference Circuit Short to Battery",
        created_at: "2022-08-18 19:42:22",
        updated_at: "2022-08-18 19:42:22",
    },
    {
        vehicle_error_id: "3287",
        title: "B2545",
        desc: "System Power Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3288",
        title: "B2546",
        desc: "System Power Relay Circuit Failure",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3289",
        title: "B2550",
        desc: "LAMP DOME OUTPUT Circuit Short to Ground",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3290",
        title: "B2553",
        desc: "Disable Signal Output Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3291",
        title: "B2554",
        desc: "LAMP DOME OUTPUT Circuit Failure",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3292",
        title: "B2555",
        desc: "LAMP DOME OUTPUT Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3293",
        title: "B2556",
        desc: "Enable Signal  Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3294",
        title: "B2557",
        desc: "Left Power Sliding Door Open/Close Output Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3295",
        title: "B2558",
        desc: "Right Power Sliding Door Open/Close Output Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3296",
        title: "B2559",
        desc: "Aux A/C Blower Motor Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3297",
        title: "B2560",
        desc: "Aux A/C Blower Motor Relay Circuit Short to Ground",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3298",
        title: "B2561",
        desc: "Aux A/C Blower Speed 1 Circuit Failure",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3299",
        title: "B2562",
        desc: "Aux A/C Blower Speed 1 Circuit Short to Ground",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3300",
        title: "B2563",
        desc: "Aux A/C Blower Speed 2 Circuit Failure",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3301",
        title: "B2564",
        desc: "Aux A/C Blower Speed 2 Circuit Short to Ground",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3302",
        title: "B2565",
        desc: "Right Tail Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3303",
        title: "B2566",
        desc: "Right Tail Lamp Circuit Short to Ground",
        created_at: "2022-08-18 19:42:23",
        updated_at: "2022-08-18 19:42:23",
    },
    {
        vehicle_error_id: "3304",
        title: "B2567",
        desc: "Reverse Mirror Output Circuit Failure",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3305",
        title: "B2568",
        desc: "Reverse Mirror Output Circuit Short to Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3306",
        title: "B2569",
        desc: "Liftgate Disarm Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3307",
        title: "B2570",
        desc: "Right Lamp Outage Signal Circuit Short to Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3308",
        title: "B2571",
        desc: "Left Lamp Outage Signal Circuit Short to Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3309",
        title: "B2580",
        desc: "Aux Blower Sense Switch Circuit Failure",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3310",
        title: "B2581",
        desc: "Passenger Seat Occupant Detection Circuit Short To Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3311",
        title: "B2582",
        desc: "Passenger Seat Occupant Detection Circuit Open",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3312",
        title: "B2583",
        desc: "Child Seat Detection Circuit Short to Ground",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3313",
        title: "B2584",
        desc: "Child Seat Detection Circuit Open",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3314",
        title: "B2585",
        desc: "Anti Theft Input Signal Circuit Short To Battery",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3315",
        title: "B2586",
        desc: "Headlamp Mode Select Circuit Failure",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3316",
        title: "B2587",
        desc: "Passenger Seat Occupant Detection Circuit Short To Battery",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3317",
        title: "B2588",
        desc: "Child Seat Detection Circuit Short To Battery",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3318",
        title: "B2589",
        desc: "Unexpected Door Reversal During Close",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3319",
        title: "B2590",
        desc: "Vehicle Park/Speed Signal Circuit Failure",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3320",
        title: "B2591",
        desc: "Detent Signal Missing During Unlatch",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3321",
        title: "B2592",
        desc: "PSD Not Fully Closed (Module Commanded Successfully)",
        created_at: "2022-08-18 19:42:24",
        updated_at: "2022-08-18 19:42:24",
    },
    {
        vehicle_error_id: "3322",
        title: "B2593",
        desc: "Power Sliding Door Opened During Module Close Command",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3323",
        title: "B2594",
        desc: "No Movement Detected After an Unlatch During Power Open",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3324",
        title: "B2595",
        desc: "Anti Theft Input Signal Circuit Failure",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3325",
        title: "B2596",
        desc: "Headlamp Aim Output Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3326",
        title: "B2597",
        desc: "Headlamp Aim Output Relay Circuit Failure",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3327",
        title: "B2598",
        desc: "Headlamp Relay Circuit Failure",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3328",
        title: "B2599",
        desc: "Tailgate Release Open Circuit",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3329",
        title: "B2600",
        desc: "Double Locking Door Motor Frozen",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3330",
        title: "B2601",
        desc: "No Latch Signal Sensed on Closing and Door Reversed",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3331",
        title: "B2602",
        desc: "Missing Latch Signal During Power Sliding Door Unlatch",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3332",
        title: "B2603",
        desc: "PSD Not Fully Closed During Self-Test",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3333",
        title: "B2604",
        desc: "Power Sliding Door On/Off Switch Open Circuit",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3334",
        title: "B2605",
        desc: "Disable Signal Open Circuit",
        created_at: "2022-08-18 19:42:25",
        updated_at: "2022-08-18 19:42:25",
    },
    {
        vehicle_error_id: "3335",
        title: "B2606",
        desc: "A/C Temperature Sensor Out of Range",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3336",
        title: "C1091",
        desc: "Speed Wheel Sensor All Coherency Failure",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3337",
        title: "C1095",
        desc: "ABS Hydraulic Pump Motor Circuit Failure",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3338",
        title: "C1096",
        desc: "ABS Hydraulic Pump Motor Circuit Open",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3339",
        title: "C1097",
        desc: "ABS Hydraulic Pump Motor Circuit Short To Ground",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3340",
        title: "C1098",
        desc: "ABS Hydraulic Pump Motor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3341",
        title: "C1100",
        desc: "ABS Pump Switch Circuit Failure",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3342",
        title: "C1101",
        desc: "ABS Hydraulic Valve Circuit Failure",
        created_at: "2022-08-18 19:42:26",
        updated_at: "2022-08-18 19:42:26",
    },
    {
        vehicle_error_id: "3343",
        title: "C1102",
        desc: "ABS Acceleration Switch Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3344",
        title: "C1103",
        desc: "ABS Hydraulic Brake Switch Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3345",
        title: "C1104",
        desc: "Traction Control Active Lamp - Circuit Short to Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3346",
        title: "C1105",
        desc: "Traction Control Disable Lamp - Circuit Short to Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3347",
        title: "C1106",
        desc: "Traction Control Disable Switch Circuit Short to Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3348",
        title: "C1107",
        desc: "ABS Function Enabled Input Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3349",
        title: "C1109",
        desc: "Speed Control Actuator Assembly Cable Release  Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3350",
        title: "C1110",
        desc: "ABS Power Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3351",
        title: "C1111",
        desc: "ABS Power Relay Coil Open Circuit",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3352",
        title: "C1112",
        desc: "ABS Power Relay Coil Short Circuit To Ground",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3353",
        title: "C1113",
        desc: "ABS Power Relay Coil Short Circuit To Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3354",
        title: "C1114",
        desc: "ABS Power Relay Output Short Circuit To Ground",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3355",
        title: "C1115",
        desc: "ABS Power Relay Output Short Circuit To Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3356",
        title: "C1116",
        desc: "Starter Motor Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3357",
        title: "C1117",
        desc: "RPM Input Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3358",
        title: "C1123",
        desc: "Vehicle Speed Sensor Input Short to Battery",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3359",
        title: "C1124",
        desc: "Input shaft speed signal missing/faulted",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3360",
        title: "C1125",
        desc: "Brake Fluid Level Sensor Input Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3361",
        title: "C1126",
        desc: "Cruise Control Command Switch Assembly Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3362",
        title: "C1127",
        desc: "Cruise Control Deactivator Brake Switch Circuit Failure",
        created_at: "2022-08-18 19:42:27",
        updated_at: "2022-08-18 19:42:27",
    },
    {
        vehicle_error_id: "3363",
        title: "C1132",
        desc: "Clutch position ckt short to ground",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3364",
        title: "C1133",
        desc: "Clutch position ckt short to battery",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3365",
        title: "C1134",
        desc: "Gear Shift position short to ground",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3366",
        title: "C1135",
        desc: "Gear Shift position short to battery",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3367",
        title: "C1136",
        desc: "Gear Select position short to ground",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3368",
        title: "C1137",
        desc: "ECU is Defective",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3369",
        title: "C1138",
        desc: "Gear Select position short to Battery",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3370",
        title: "C1139",
        desc: "Wheel Speed Sensor Center Tone Ring Missing Tooth Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3371",
        title: "C1140",
        desc: "Hydraulic Base Brake Failure",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3372",
        title: "C1141",
        desc: "Wheel Speed Sensor LF Tone Ring Tooth Missing Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3373",
        title: "C1142",
        desc: "Wheel Speed Sensor RF Tone Ring Tooth Missing Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3374",
        title: "C1143",
        desc: "Wheel Speed Sensor LR Tone Ring Tooth Missing Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3375",
        title: "C1144",
        desc: "Wheel Speed Sensor RR Tone Ring Tooth Missing Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3376",
        title: "C1145",
        desc: "Speed Wheel Sensor RF Input Circuit Failure",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3377",
        title: "C1146",
        desc: "Speed Wheel Sensor RF Circuit Open",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3378",
        title: "C1148",
        desc: "Speed Wheel Sensor RF Coherency Fault",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3379",
        title: "C1149",
        desc: "Hydraulic Fluid Pressure/ Flow Circuit Failure",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3380",
        title: "C1150",
        desc: "Two Speed Rear Axle Input Switch Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3381",
        title: "C1155",
        desc: "Speed Wheel Sensor LF Input Circuit Failure",
        created_at: "2022-08-18 19:42:28",
        updated_at: "2022-08-18 19:42:28",
    },
    {
        vehicle_error_id: "3382",
        title: "C1156",
        desc: "Speed Wheel Sensor LF Circuit Open",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3383",
        title: "C1157",
        desc: "Park Brake Actuator Assembly Switch Applied Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3384",
        title: "C1158",
        desc: "Speed Wheel Sensor LF Coherency Fault",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3385",
        title: "C1159",
        desc: "Hydraulic Fluid Pressure/ Flow Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3386",
        title: "C1161",
        desc: "Air Pressure Low Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3387",
        title: "C1162",
        desc: "Park Brake Switch # 2 Released Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3388",
        title: "C1163",
        desc: "Park Brake Switch # 2 Applied Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3389",
        title: "C1164",
        desc: "Park Brake Actuator Assembly Switch Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3390",
        title: "C1165",
        desc: "Speed Wheel Sensor RR Input Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3391",
        title: "C1166",
        desc: "Speed Wheel Sensor RR Input Open Circuit",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3392",
        title: "C1167",
        desc: "Park Brake Actuator Assembly Switch Released Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3393",
        title: "C1168",
        desc: "Speed Wheel Sensor RR Coherency Fault",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3394",
        title: "C1169",
        desc: "ABS Fluid Dumping Exceeds Maximum Timing",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3395",
        title: "C1170",
        desc: "PRNDL  Switch Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3396",
        title: "C1172",
        desc: "Park Brake Switch # 1 Applied Circuit Failure",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3397",
        title: "C1173",
        desc: "Park Brake Switch # 1 Released Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:29",
        updated_at: "2022-08-18 19:42:29",
    },
    {
        vehicle_error_id: "3398",
        title: "C1174",
        desc: "Park Brake Switch # 2 Applied Circuit Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3399",
        title: "C1175",
        desc: "Speed Wheel Sensor LR Input Circuit Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3400",
        title: "C1176",
        desc: "Speed Wheel Sensor LR Circuit Open",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3401",
        title: "C1177",
        desc: "Park Brake Actuator Assembly Switch Released Circuit Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3402",
        title: "C1178",
        desc: "Speed Wheel Sensor LR Coherency Fault",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3403",
        title: "C1179",
        desc: "Speed Control Actuator Assembly Cable Slack Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3404",
        title: "C1180",
        desc: "Park Brake Valve Solenoid #1 Sense Input Circuit Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3405",
        title: "C1181",
        desc: "Park Brake Valve Solenoid #1 Sense Input Circuit Short to Gound",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3406",
        title: "C1182",
        desc: "Park Lamp Flash Relay Circuit Failure",
        created_at: "2022-08-18 19:42:30",
        updated_at: "2022-08-18 19:42:30",
    },
    {
        vehicle_error_id: "3407",
        title: "C1183",
        desc: "Park Lamp Flash Relay Circuit Short to Battery",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3408",
        title: "C1184",
        desc: "ABS System Is Not Operational",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3409",
        title: "C1185",
        desc: "ABS Power Relay Output Circuit Failure",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3410",
        title: "C1186",
        desc: "ABS Power Relay Output Open Circuit",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3411",
        title: "C1187",
        desc: "Brake Fluid Level Sensor Input Open Circuit",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3412",
        title: "C1188",
        desc: "Brake Fluid Level Sensor Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3413",
        title: "C1189",
        desc: "Brake Fluid Level Sensor Input Short Circuit To Ground",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3414",
        title: "C1190",
        desc: "Speed Wheel Sensor LF Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3415",
        title: "C1191",
        desc: "Speed Wheel Sensor LF Input Short Circuit To Ground",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3416",
        title: "C1192",
        desc: "Speed Wheel Sensor RF Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3417",
        title: "C1193",
        desc: "Speed Wheel Sensor RF Input Short Circuit To Ground",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3418",
        title: "C1194",
        desc: "ABS Outlet Valve Coil LF Circuit Failure",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3419",
        title: "C1195",
        desc: "ABS Outlet Valve Coil LF Circuit Open",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3420",
        title: "C1196",
        desc: "ABS Outlet Valve Coil LF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3421",
        title: "C1197",
        desc: "ABS Outlet Valve Coil LF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3422",
        title: "C1198",
        desc: "ABS Inlet Valve Coil LF Circuit Failure",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3423",
        title: "C1199",
        desc: "ABS Inlet Valve Coil LF Circuit Open",
        created_at: "2022-08-18 19:42:31",
        updated_at: "2022-08-18 19:42:31",
    },
    {
        vehicle_error_id: "3424",
        title: "C1200",
        desc: "ABS Inlet Valve Coil LF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3425",
        title: "C1201",
        desc: "ABS Inlet Valve Coil LF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3426",
        title: "C1202",
        desc: "ABS Outlet Valve Coil Rear Circuit Failure",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3427",
        title: "C1203",
        desc: "ABS Outlet Valve Coil Rear Circuit Open",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3428",
        title: "C1204",
        desc: "ABS Outlet Valve Coil Rear Circuit Short To Battery",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3429",
        title: "C1205",
        desc: "ABS Outlet Valve Coil Rear Circuit Short To Ground",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3430",
        title: "C1206",
        desc: "ABS Inlet Valve Coil Rear Circuit Failure",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3431",
        title: "C1207",
        desc: "ABS Inlet Valve Coil Rear Circuit Open",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3432",
        title: "C1208",
        desc: "ABS Inlet Valve Coil Rear Circuit Short To Battery",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3433",
        title: "C1209",
        desc: "ABS Inlet Valve Coil Rear Circuit Short To Ground",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3434",
        title: "C1210",
        desc: "ABS Outlet Valve Coil RF Circuit Failure",
        created_at: "2022-08-18 19:42:32",
        updated_at: "2022-08-18 19:42:32",
    },
    {
        vehicle_error_id: "3435",
        title: "C1211",
        desc: "ABS Outlet Valve Coil RF Circuit Open",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3436",
        title: "C1212",
        desc: "ABS Outlet Valve Coil RF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3437",
        title: "C1213",
        desc: "ABS Outlet Valve Coil RF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3438",
        title: "C1214",
        desc: "ABS Inlet Valve Coil RF Circuit Failure",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3439",
        title: "C1215",
        desc: "ABS Inlet Valve Coil RF Circuit Open",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3440",
        title: "C1216",
        desc: "ABS Inlet Valve Coil RF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3441",
        title: "C1217",
        desc: "ABS Inlet Valve Coil RF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3442",
        title: "C1218",
        desc: "Lamp ABS Warning Output Circuit Failure",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3443",
        title: "C1219",
        desc: "Lamp ABS Warning Output Circuit Open",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3444",
        title: "C1220",
        desc: "Lamp ABS Warning Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3445",
        title: "C1221",
        desc: "Lamp ABS Warning Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3446",
        title: "C1222",
        desc: "Speed Wheel Mismatch",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3447",
        title: "C1223",
        desc: "Lamp Brake Warning Output Circuit Failure",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3448",
        title: "C1224",
        desc: "Lamp Brake Warning Output Circuit Open",
        created_at: "2022-08-18 19:42:33",
        updated_at: "2022-08-18 19:42:33",
    },
    {
        vehicle_error_id: "3449",
        title: "C1225",
        desc: "Lamp Brake Warning Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3450",
        title: "C1226",
        desc: "Lamp Brake Warning Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3451",
        title: "C1227",
        desc: "Speed Wheel Sensor LR Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3452",
        title: "C1228",
        desc: "Speed Wheel Sensor LR Input Short Circuit To Ground",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3453",
        title: "C1229",
        desc: "Speed Wheel Sensor Rear Center Coherency Fault",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3454",
        title: "C1230",
        desc: "Speed Wheel Sensor Rear Center Input Circuit Failure",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3455",
        title: "C1231",
        desc: "Speed Wheel Sensor Rear Center Circuit Open",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3456",
        title: "C1232",
        desc: "Speed Wheel Sensor Rear Center Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3457",
        title: "C1233",
        desc: "Speed Wheel LF Input Signal Missing",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3458",
        title: "C1234",
        desc: "Speed Wheel RF Input Signal Missing",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3459",
        title: "C1235",
        desc: "Speed Wheel RR Input Signal Missing",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3460",
        title: "C1236",
        desc: "Speed Wheel LR Input Signal Missing",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3461",
        title: "C1237",
        desc: "Speed Wheel Rear Input Signal Missing",
        created_at: "2022-08-18 19:42:34",
        updated_at: "2022-08-18 19:42:34",
    },
    {
        vehicle_error_id: "3462",
        title: "C1238",
        desc: "ABS Hydraulic Pressure Differential Switch Input Circuit Failure",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3463",
        title: "C1239",
        desc: "ABS Hydraulic Pressure Differential Switch Input  Open Circuit",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3464",
        title: "C1240",
        desc: "ABS Hydraulic Pressure Differential Switch Input Short Circuit To Battery",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3465",
        title: "C1241",
        desc: "ABS Hydraulic Pressure Differential Switch Input Short Circuit To Ground",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3466",
        title: "C1242",
        desc: "ABS Outlet Valve Coil LR Circuit Failure",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3467",
        title: "C1243",
        desc: "ABS Outlet Valve Coil LR Circuit Open",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3468",
        title: "C1244",
        desc: "ABS Outlet Valve Coil LR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3469",
        title: "C1245",
        desc: "ABS Outlet Valve Coil LR Circuit Short To Ground",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3470",
        title: "C1246",
        desc: "ABS Outlet Valve Coil RR Circuit Failure",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3471",
        title: "C1247",
        desc: "ABS Outlet Valve Coil RR Circuit Open",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3472",
        title: "C1248",
        desc: "ABS Outlet Valve Coil RR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3473",
        title: "C1249",
        desc: "ABS Outlet Valve Coil RR Circuit Short To Ground",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3474",
        title: "C1250",
        desc: "ABS Inlet Valve Coil LR Circuit Failure",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3475",
        title: "C1251",
        desc: "ABS Inlet Valve Coil LR Circuit Open",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3476",
        title: "C1252",
        desc: "ABS Inlet Valve Coil LR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3477",
        title: "C1253",
        desc: "ABS Inlet Valve Coil LR Circuit Short To Ground",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3478",
        title: "C1254",
        desc: "ABS Inlet Valve Coil RR Circuit Failure",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3479",
        title: "C1255",
        desc: "ABS Inlet Valve Coil RR Circuit Open",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3480",
        title: "C1256",
        desc: "ABS Inlet Valve Coil RR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3481",
        title: "C1257",
        desc: "ABS Inlet Valve Coil RR Circuit Short To Ground",
        created_at: "2022-08-18 19:42:35",
        updated_at: "2022-08-18 19:42:35",
    },
    {
        vehicle_error_id: "3482",
        title: "C1258",
        desc: "Speed Wheel LF Comparison Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3483",
        title: "C1259",
        desc: "Speed Wheel RF Comparison Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3484",
        title: "C1260",
        desc: "Speed Wheel RR Comparison Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3485",
        title: "C1261",
        desc: "Speed Wheel LR Comparison Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3486",
        title: "C1262",
        desc: "Lamp Warning Relay Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3487",
        title: "C1263",
        desc: "Lamp Warning Relay Circuit Open",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3488",
        title: "C1264",
        desc: "Lamp Warning Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3489",
        title: "C1265",
        desc: "Lamp Warning Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3490",
        title: "C1266",
        desc: "ABS Valve Power Relay Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3491",
        title: "C1267",
        desc: "ABS Functions Temporarily Disabled",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3492",
        title: "C1268",
        desc: "Motor Relay # 1 Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3493",
        title: "C1269",
        desc: "Motor Relay # 1 Circuit Short to Battery",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3494",
        title: "C1270",
        desc: "Motor # 1 Input Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3495",
        title: "C1271",
        desc: "Motor # 1 Input Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3496",
        title: "C1272",
        desc: "Motor # 2 Input Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3497",
        title: "C1273",
        desc: "Motor # 2 Input Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3498",
        title: "C1274",
        desc: "Solenoid Relay # 1 Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3499",
        title: "C1275",
        desc: "Solenoid Relay # 1 Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3500",
        title: "C1276",
        desc: "Park Brake Actuator Assembly Switch Applied Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3501",
        title: "C1277",
        desc: "STEERING Wheel Angle 1and 2 Circuit Failure",
        created_at: "2022-08-18 19:42:36",
        updated_at: "2022-08-18 19:42:36",
    },
    {
        vehicle_error_id: "3502",
        title: "C1278",
        desc: "STEERING Wheel Angle 1and 2 Signal Faulted",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3503",
        title: "C1279",
        desc: "Yaw Rate Sensor circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3504",
        title: "C1280",
        desc: "Yaw Rate Sensor Signal Fault",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3505",
        title: "C1281",
        desc: "Lateral Accelerometer circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3506",
        title: "C1282",
        desc: "Lateral Accelerometer Signal Fault",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3507",
        title: "C1283",
        desc: "Switch Test Signal Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3508",
        title: "C1284",
        desc: "Oil Pressure Switch Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3509",
        title: "C1285",
        desc: "Booster Solenoid circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3510",
        title: "C1286",
        desc: "Booster Mechanical Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3511",
        title: "C1287",
        desc: "Booster Pedal Force switch circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3512",
        title: "C1288",
        desc: "Pressure Transducer Main / Primary Input Circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3513",
        title: "C1289",
        desc: "Pressure Transducer Redundant / Secondary Input Circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3514",
        title: "C1400",
        desc: "Traction Control Valve RF Circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3515",
        title: "C1401",
        desc: "Traction Control Valve RF Circuit Open",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3516",
        title: "C1402",
        desc: "Traction Control Valve RF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3517",
        title: "C1403",
        desc: "Traction Control Valve RF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3518",
        title: "C1404",
        desc: "Traction Control Valve Rear Circuit Failure",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3519",
        title: "C1405",
        desc: "Traction Control Valve Rear Circuit Open",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3520",
        title: "C1406",
        desc: "Traction Control Valve Rear Circuit Short To Ground",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3521",
        title: "C1407",
        desc: "Traction Control Valve Rear Circuit Short To Battery",
        created_at: "2022-08-18 19:42:37",
        updated_at: "2022-08-18 19:42:37",
    },
    {
        vehicle_error_id: "3522",
        title: "C1410",
        desc: "Traction Control Valve LF Circuit Failure",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3523",
        title: "C1411",
        desc: "Traction Control Valve LF Circuit Open",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3524",
        title: "C1412",
        desc: "Traction Control Valve LF Circuit Short To Ground",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3525",
        title: "C1413",
        desc: "Traction Control Valve LF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3526",
        title: "C1414",
        desc: "Incorrect Module Design Level",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3527",
        title: "C1415",
        desc: "Incorrect Module Configuration",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3528",
        title: "C1416",
        desc: "Damper RF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:38",
        updated_at: "2022-08-18 19:42:38",
    },
    {
        vehicle_error_id: "3529",
        title: "C1417",
        desc: "Damper RF Circuit Short to Ground",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3530",
        title: "C1418",
        desc: "Damper RF Circuit Failure",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3531",
        title: "C1419",
        desc: "Damper RF Circuit Open",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3532",
        title: "C1420",
        desc: "Hydraulic Fluid Pressure/ Flow Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3533",
        title: "C1421",
        desc: "Damper LF Circuit Short To Battery",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3534",
        title: "C1422",
        desc: "Damper LF Circuit Short to Ground",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3535",
        title: "C1423",
        desc: "Damper LF Circuit Failure",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3536",
        title: "C1424",
        desc: "Damper LF Circuit Open",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3537",
        title: "C1425",
        desc: "Damper RR Circuit Short to Ground",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3538",
        title: "C1426",
        desc: "Damper RR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3539",
        title: "C1427",
        desc: "Damper RR Circuit Open",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3540",
        title: "C1428",
        desc: "Damper RR Circuit Failure",
        created_at: "2022-08-18 19:42:39",
        updated_at: "2022-08-18 19:42:39",
    },
    {
        vehicle_error_id: "3541",
        title: "C1429",
        desc: "Input-shaft-speed input circuit failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3542",
        title: "C1430",
        desc: "Damper LR Circuit Open",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3543",
        title: "C1431",
        desc: "Damper LR Circuit Short To Battery",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3544",
        title: "C1432",
        desc: "Damper LR Circuit Short to Ground",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3545",
        title: "C1433",
        desc: "Damper LR Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3546",
        title: "C1435",
        desc: "Accelerometer Rear Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3547",
        title: "C1436",
        desc: "Accelerometer Rear Circuit Signal Is Not Sensed",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3548",
        title: "C1437",
        desc: "Accelerometer Rear Circuit Short To Ground",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3549",
        title: "C1438",
        desc: "Accelerometer Rear Circuit Short To Battery",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3550",
        title: "C1439",
        desc: "Vehicle Acceleration EEC-IV Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3551",
        title: "C1440",
        desc: "Pressure Transducer Main / Primary signal Faulted",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3552",
        title: "C1441",
        desc: "Steering Phase A Circuit Signal Is Not Sensed",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3553",
        title: "C1442",
        desc: "Steering Phase B Circuit Signal Is Not Sensed",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3554",
        title: "C1443",
        desc: "Steering Phase A Circuit Short To Ground",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3555",
        title: "C1444",
        desc: "Steering Phase B Circuit Short To Ground",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3556",
        title: "C1445",
        desc: "Speed Vehicle Signal Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3557",
        title: "C1446",
        desc: "Brake Switch Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3558",
        title: "C1447",
        desc: "Traction Control Module Request Circuit Failure",
        created_at: "2022-08-18 19:42:40",
        updated_at: "2022-08-18 19:42:40",
    },
    {
        vehicle_error_id: "3559",
        title: "C1448",
        desc: "Lamp Adaptive Damping Warning Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3560",
        title: "C1449",
        desc: "Traction Control Motor Coherency Fault",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3561",
        title: "C1450",
        desc: "Traction Control Motor Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3562",
        title: "C1451",
        desc: "Traction Control Motor Circuit Open",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3563",
        title: "C1452",
        desc: "Traction Control Motor Circuit Short to Battery",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3564",
        title: "C1453",
        desc: "Traction Control Motor Circuit Short to Ground",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3565",
        title: "C1454",
        desc: "Front Lateral Accelerometer Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3566",
        title: "C1455",
        desc: "Accelerometer Front Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3567",
        title: "C1456",
        desc: "Accelerometer Front Circuit Is Not Sensed",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3568",
        title: "C1457",
        desc: "Accelerometer Front Circuit Short To Ground",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3569",
        title: "C1458",
        desc: "Accelerometer Front Circuit Short To Battery",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3570",
        title: "C1459",
        desc: "Adaptive Mode Switch Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3571",
        title: "C1460",
        desc: "Vehicle Accelerometer Power Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3572",
        title: "C1461",
        desc: "Vehicle Accelerometer Power Circuit Short to Battery",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3573",
        title: "C1462",
        desc: "Left Front Vertical Accelerometer Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3574",
        title: "C1463",
        desc: "Right Front Vertical Accelerometer Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3575",
        title: "C1464",
        desc: "Pressure Transducer Redundant / Secondary Signal Faulted",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3576",
        title: "C1465",
        desc: "Damper High Side Front Circuit Short To Battery",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3577",
        title: "C1466",
        desc: "Damper Circuit Failure",
        created_at: "2022-08-18 19:42:41",
        updated_at: "2022-08-18 19:42:41",
    },
    {
        vehicle_error_id: "3578",
        title: "C1467",
        desc: "Damper High Side Rear Circuit Short To Battery",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3579",
        title: "C1468",
        desc: "Damper Low Side Front Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3580",
        title: "C1469",
        desc: "Damper Low Side Rear Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3581",
        title: "C1495",
        desc: "Traction Control Motor Potentiometer Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3582",
        title: "C1496",
        desc: "Traction Control Motor Potentiometer Circuit Open",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3583",
        title: "C1497",
        desc: "Traction Control Motor Potentiometer Circuit Short to Battery",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3584",
        title: "C1498",
        desc: "Traction Control Motor Potentiometer Circuit Short to Ground",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3585",
        title: "C1499",
        desc: "Transfer Case Contact Plate 'A' Encoder Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3586",
        title: "C1500",
        desc: "Transfer Case Contact Plate 'B' Encoder Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3587",
        title: "C1501",
        desc: "Transfer Case Contact Plate 'C' Encoder Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3588",
        title: "C1502",
        desc: "Transfer Case Contact Plate 'D' Encoder Circuit Failure",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3589",
        title: "C1503",
        desc: "Dynamic Stability Control Left Front Valve Malfunction",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3590",
        title: "C1504",
        desc: "Dynamic Stability Control Right Front Valve Malfunction",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3591",
        title: "C1505",
        desc: "Dynamic Stability Control Left Rear Valve Malfunction",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3592",
        title: "C1506",
        desc: "Dynamic Stability Control Right Rear Valve Malfunction",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3593",
        title: "C1507",
        desc: "Traction Control of Brake Exceeds Time-Out",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3594",
        title: "C1508",
        desc: "Traction Control of Engine Exceeds Time-Out",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3595",
        title: "C1510",
        desc: "Right Front Wheel Pressure Reduction Performance Problem",
        created_at: "2022-08-18 19:42:42",
        updated_at: "2022-08-18 19:42:42",
    },
    {
        vehicle_error_id: "3596",
        title: "C1511",
        desc: "Left Front Wheel Pressure Reduction Performance Problem",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3597",
        title: "C1512",
        desc: "Right Rear Wheel Pressure Reduction Performance Problem",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3598",
        title: "C1513",
        desc: "Left rear Wheel Pressure Reduction Performance Problem",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3599",
        title: "C1699",
        desc: "Left Rear Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3600",
        title: "C1700",
        desc: "Left Rear Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3601",
        title: "C1701",
        desc: "Left Rear Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3602",
        title: "C1702",
        desc: "RightRear Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3603",
        title: "C1703",
        desc: "Right Rear Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3604",
        title: "C1704",
        desc: "Right Rear Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3605",
        title: "C1705",
        desc: "Left Rear Center Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3606",
        title: "C1706",
        desc: "Left Rear Center Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:43",
        updated_at: "2022-08-18 19:42:43",
    },
    {
        vehicle_error_id: "3607",
        title: "C1707",
        desc: "Left Rear Center Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3608",
        title: "C1708",
        desc: "Right Rear Center Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3609",
        title: "C1709",
        desc: "Right Rear Center Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3610",
        title: "C1710",
        desc: "Right Rear Center Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3611",
        title: "C1711",
        desc: "Left Front Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3612",
        title: "C1712",
        desc: "Left Front Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3613",
        title: "C1713",
        desc: "Left Front Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3614",
        title: "C1714",
        desc: "Right Front Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3615",
        title: "C1715",
        desc: "Right Front Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3616",
        title: "C1716",
        desc: "Right Front Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3617",
        title: "C1717",
        desc: "Left Front Center Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3618",
        title: "C1718",
        desc: "Left Front Center Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:44",
        updated_at: "2022-08-18 19:42:44",
    },
    {
        vehicle_error_id: "3619",
        title: "C1719",
        desc: "Left Front Center Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3620",
        title: "C1721",
        desc: "Air Suspension Height Sensor Power Circuit Open",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3621",
        title: "C1722",
        desc: "Air Suspension Height Sensor Power Circuit Short To Battery",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3622",
        title: "C1723",
        desc: "Air Suspension Height Sensor Power Circuit Short To Ground",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3623",
        title: "C1724",
        desc: "Air Suspension Height Sensor Power Circuit Failure",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3624",
        title: "C1725",
        desc: "Air Suspension Front Pneumatic Failure",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3625",
        title: "C1726",
        desc: "Air Suspension Rear Pneumatic Failure",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3626",
        title: "C1727",
        desc: "Air Suspension Reservoir Pneumatic Failure",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3627",
        title: "C1728",
        desc: "Transfer  Case unable to transition between 2H and 4H",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3628",
        title: "C1729",
        desc: "Transfer  Case unable to transition between 4H and 4L",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3629",
        title: "C1730",
        desc: "Reference Voltage Out of Range (+5 v)",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3630",
        title: "C1731",
        desc: "Air Suspension LF Corner Up Timeout",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3631",
        title: "C1732",
        desc: "Air Suspension LF Corner Down Timeout",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3632",
        title: "C1733",
        desc: "Air Suspension RF Corner Up Timeout",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3633",
        title: "C1734",
        desc: "Air Suspension RF Corner Down Timeout",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3634",
        title: "C1735",
        desc: "Air Suspension LR Corner Up Timeout",
        created_at: "2022-08-18 19:42:45",
        updated_at: "2022-08-18 19:42:45",
    },
    {
        vehicle_error_id: "3635",
        title: "C1736",
        desc: "Air Suspension LR Corner Down Timeout",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3636",
        title: "C1737",
        desc: "Air Suspension RR Corner Up Timeout",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3637",
        title: "C1738",
        desc: "Air Suspension RR Corner Down Timeout",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3638",
        title: "C1739",
        desc: "Right Front Center Sensor Circuit Short to Vbat",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3639",
        title: "C1740",
        desc: "Right Front Center Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3640",
        title: "C1741",
        desc: "Right Front Center Sensor Circuit Fault",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3641",
        title: "C1742",
        desc: "Rear Sounder Circuit Failure",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3642",
        title: "C1743",
        desc: "Rear Sounder Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3643",
        title: "C1744",
        desc: "Front Sounder Circuit Failure",
        created_at: "2022-08-18 19:42:46",
        updated_at: "2022-08-18 19:42:46",
    },
    {
        vehicle_error_id: "3644",
        title: "C1745",
        desc: "Front Sounder Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3645",
        title: "C1748",
        desc: "Switch input Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3646",
        title: "C1749",
        desc: "Trailer Input Circuit Failure",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3647",
        title: "C1750",
        desc: "Accelerator Position Sensor Out of Range",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3648",
        title: "C1751",
        desc: "Vehicle Speed Sensor # 1 Output Circuit Short to Vbatt",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3649",
        title: "C1752",
        desc: "Vehicle Speed Sensor # 1 Output Circuit Short to Gnd",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3650",
        title: "C1753",
        desc: "Hydraulic Clutch Actuator Valve Signal Fault",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3651",
        title: "C1754",
        desc: "Hydraulic Clutch Actuator Valve Circuit Failure",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3652",
        title: "C1755",
        desc: "Power Limit Shutdown Fault",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3653",
        title: "C1756",
        desc: "Air Suspension Front Height Sensor High (SE) Signal Circuit Failure",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3654",
        title: "C1757",
        desc: "Air Suspension Front Height Sensor High (SE) Signal Circuit Open",
        created_at: "2022-08-18 19:42:47",
        updated_at: "2022-08-18 19:42:47",
    },
    {
        vehicle_error_id: "3655",
        title: "C1758",
        desc: "Air Suspension Front Height Sensor High (SE) Signal Circuit Short To Battery",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3656",
        title: "C1759",
        desc: "Air Suspension Front Height Sensor High (SE) Signal Circuit Short To Ground",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3657",
        title: "C1760",
        desc: "Air Suspension Rear Height Sensor High (SE) Signal Circuit Failure",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3658",
        title: "C1761",
        desc: "Air Suspension Rear Height Sensor High (SE) Signal Circuit Open",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3659",
        title: "C1762",
        desc: "Air Suspension Rear Height Sensor High (SE) Signal Circuit Short To Battery",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3660",
        title: "C1763",
        desc: "Air Suspension Rear Height Sensor High (SE) Signal Circuit Short To Ground",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3661",
        title: "C1765",
        desc: "Air Suspension Rear Height Sensor Low Signal Circuit Failure",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3662",
        title: "C1766",
        desc: "Air Suspension Rear Height Sensor Low Signal Circuit Open",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3663",
        title: "C1767",
        desc: "Air Suspension Rear Height Sensor Low Signal Circuit Short To Battery",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3664",
        title: "C1768",
        desc: "Air Suspension Rear Height Sensor Low Signal Circuit Short To Ground",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3665",
        title: "C1770",
        desc: "Air Suspension Vent Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3666",
        title: "C1771",
        desc: "Air Suspension Vent Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3667",
        title: "C1772",
        desc: "Air Suspension Vent Solenoid Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3668",
        title: "C1773",
        desc: "Air Suspension Vent Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:48",
        updated_at: "2022-08-18 19:42:48",
    },
    {
        vehicle_error_id: "3669",
        title: "C1774",
        desc: "Coolant Temp Out of Range",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3670",
        title: "C1775",
        desc: "DC-DC Converter Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3671",
        title: "C1776",
        desc: "Heater System Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3672",
        title: "C1777",
        desc: "Vacuum Pressure Circuit Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3673",
        title: "C1778",
        desc: "Power Steering Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3674",
        title: "C1779",
        desc: "Blower Switch Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3675",
        title: "C1780",
        desc: "Temperature Select Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3676",
        title: "C1781",
        desc: "Engine Coolant Temperature Signal Missing/Fault",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3677",
        title: "C1790",
        desc: "Air Suspension LR Air Spring/Shock Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3678",
        title: "C1791",
        desc: "Air Suspension LR Air Spring/Shock Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3679",
        title: "C1792",
        desc: "Air Suspension LR Air Spring/Shock Solenoid Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3680",
        title: "C1793",
        desc: "Air Suspension LR Air Spring/Shock Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:49",
        updated_at: "2022-08-18 19:42:49",
    },
    {
        vehicle_error_id: "3681",
        title: "C1795",
        desc: "Air Suspension RR Air Spring/Shock Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3682",
        title: "C1796",
        desc: "Air Suspension RR Air Spring/Shock Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3683",
        title: "C1797",
        desc: "Air Suspension RR Air Spring/Shock Solenoid Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3684",
        title: "C1798",
        desc: "Air Suspension RR Air Spring/Shock Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3685",
        title: "C1800",
        desc: "Air Suspension Reservoir Solenoid Circuit Failure",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3686",
        title: "C1805",
        desc: "Mismatched PCM and/or ABS-TC Module",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3687",
        title: "C1813",
        desc: "Air Suspension LR Vent Request Exceeded Max Timing",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3688",
        title: "C1814",
        desc: "Air Suspension RR Vent Request Exceeded Max Timing",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3689",
        title: "C1818",
        desc: "Air Suspension LR Air Compress Request Exceeded Max Timing",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3690",
        title: "C1819",
        desc: "Air Suspension RR Air Compress Request Exceeded Max Timing",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3691",
        title: "C1820",
        desc: "Air Suspension RF Air Compress Request Exceeded Max Timing",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3692",
        title: "C1830",
        desc: "Air Suspension Compressor Relay Circuit Failure",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3693",
        title: "C1831",
        desc: "Air Suspension Compressor Relay Circuit Open",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3694",
        title: "C1832",
        desc: "Air Suspension Compressor Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3695",
        title: "C1833",
        desc: "Air Suspension Compressor  Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3696",
        title: "C1834",
        desc: "Gauge Drive Current Fault",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3697",
        title: "C1835",
        desc: "Current Sense Circuit Failure",
        created_at: "2022-08-18 19:42:50",
        updated_at: "2022-08-18 19:42:50",
    },
    {
        vehicle_error_id: "3698",
        title: "C1836",
        desc: "Battery Temp out of Range",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3699",
        title: "C1837",
        desc: "Battery Heater Circuit Failure",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3700",
        title: "C1838",
        desc: "Charging System Fault",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3701",
        title: "C1839",
        desc: "Leakage Fault",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3702",
        title: "C1840",
        desc: "Air Suspension Disable Switch Circuit Failure",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3703",
        title: "C1841",
        desc: "Air Suspension Disable Switch Circuit Open",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3704",
        title: "C1842",
        desc: "Air Suspension Disable Switch Circuit Short To Battery",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3705",
        title: "C1843",
        desc: "Air Suspension Disable Switch Circuit Short To Ground",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3706",
        title: "C1844",
        desc: "Air Suspension Secondary Front Inflator Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3707",
        title: "C1845",
        desc: "Air Suspension Front Inflator Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3708",
        title: "C1846",
        desc: "Air Suspension Front Inflator Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3709",
        title: "C1847",
        desc: "Air Suspension Front Inflator Solenoid Output Circuit Short To  Battery",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3710",
        title: "C1848",
        desc: "Air Suspension Front Inflator Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3711",
        title: "C1849",
        desc: "Master Cylinder Pressure Out of Range",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3712",
        title: "C1850",
        desc: "Air Suspension Warning Lamp Circuit Failure",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3713",
        title: "C1851",
        desc: "Air Suspension Warning Lamp Circuit Open",
        created_at: "2022-08-18 19:42:51",
        updated_at: "2022-08-18 19:42:51",
    },
    {
        vehicle_error_id: "3714",
        title: "C1852",
        desc: "Air Suspension Warning Lamp Circuit Short To Battery",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3715",
        title: "C1853",
        desc: "Air Suspension Warning Lamp Circuit Short To Ground",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3716",
        title: "C1854",
        desc: "Motor Temperature Out of Range",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3717",
        title: "C1855",
        desc: "Acceleration Position Sensor Conflict",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3718",
        title: "C1856",
        desc: "Traction Motor Encoder  circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3719",
        title: "C1859",
        desc: "PRNDL Input #2 Circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3720",
        title: "C1860",
        desc: "PRNDL Input #3 Circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3721",
        title: "C1861",
        desc: "PRNDL Input #4 Circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3722",
        title: "C1862",
        desc: "Contactor Circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3723",
        title: "C1863",
        desc: "External Charging Fault",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3724",
        title: "C1864",
        desc: "Battery Module Fault",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3725",
        title: "C1865",
        desc: "Air Suspension Rear Inflator Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:52",
        updated_at: "2022-08-18 19:42:52",
    },
    {
        vehicle_error_id: "3726",
        title: "C1866",
        desc: "Air Suspension Rear Inflator Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3727",
        title: "C1867",
        desc: "Air Suspension Rear Inflator Solenoid Output Circuit Short To  Battery",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3728",
        title: "C1868",
        desc: "Air Suspension Rear Inflator Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3729",
        title: "C1869",
        desc: "Air Suspension Gate Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3730",
        title: "C1870",
        desc: "Air Suspension Gate Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3731",
        title: "C1871",
        desc: "Air Suspension Gate Solenoid Output Circuit Short To  Battery",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3732",
        title: "C1872",
        desc: "Air Suspension Gate Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3733",
        title: "C1873",
        desc: "Air Suspension RF Air Spring Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3734",
        title: "C1874",
        desc: "Air Suspension RF Air Spring Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3735",
        title: "C1875",
        desc: "Air Suspension RF Air Spring Solenoid Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:53",
        updated_at: "2022-08-18 19:42:53",
    },
    {
        vehicle_error_id: "3736",
        title: "C1876",
        desc: "Air Suspension RF Air Spring Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3737",
        title: "C1877",
        desc: "Air Suspension LF Air Spring Solenoid Output Circuit Failure",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3738",
        title: "C1878",
        desc: "Air Suspension LF Air Spring Solenoid Output Circuit Open",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3739",
        title: "C1879",
        desc: "Air Suspension LF Air Spring Solenoid Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3740",
        title: "C1880",
        desc: "Air Suspension LF Air Spring Solenoid Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3741",
        title: "C1881",
        desc: "Air Suspension RF Height Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3742",
        title: "C1882",
        desc: "Air Suspension RF Height Sensor Circuit Open",
        created_at: "2022-08-18 19:42:54",
        updated_at: "2022-08-18 19:42:54",
    },
    {
        vehicle_error_id: "3743",
        title: "C1883",
        desc: "Air Suspension RF Height Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3744",
        title: "C1884",
        desc: "Air Suspension RF Height Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3745",
        title: "C1885",
        desc: "Air Suspension RR Height Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3746",
        title: "C1886",
        desc: "Air Suspension RR Height Sensor Circuit Open",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3747",
        title: "C1887",
        desc: "Air Suspension RR Height Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3748",
        title: "C1888",
        desc: "Air Suspension RR Height Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3749",
        title: "C1889",
        desc: "Air Suspension LF Height Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3750",
        title: "C1890",
        desc: "Air Suspension LF Height Sensor Circuit Open",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3751",
        title: "C1891",
        desc: "Air Suspension LF Height Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3752",
        title: "C1892",
        desc: "Air Suspension LF Height Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3753",
        title: "C1893",
        desc: "Air Suspension LR Height Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3754",
        title: "C1893",
        desc: "Air Suspension LR Height Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3755",
        title: "C1894",
        desc: "Air Suspension LR Height Sensor Circuit Open",
        created_at: "2022-08-18 19:42:55",
        updated_at: "2022-08-18 19:42:55",
    },
    {
        vehicle_error_id: "3756",
        title: "C1895",
        desc: "Air Suspension LR Height Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3757",
        title: "C1895",
        desc: "Air Suspension LR Height Sensor Circuit Short To Battery",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3758",
        title: "C1896",
        desc: "Air Suspension LR Height Sensor Circuit Short To Ground",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3759",
        title: "C1897",
        desc: "Steering VAPS II Circuit Loop Failure",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3760",
        title: "C1897",
        desc: "Steering VAPS II Circuit Loop Failure",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3761",
        title: "C1898",
        desc: "Steering VAPS II Circuit Loop Open",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3762",
        title: "C1898",
        desc: "Steering VAPS II Circuit Loop Open",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3763",
        title: "C1899",
        desc: "Steering VAPS II Circuit Loop Short To Battery",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3764",
        title: "C1900",
        desc: "Steering VAPS II Circuit Loop Short To Ground",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3765",
        title: "C1901",
        desc: "Ride Control RR Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3766",
        title: "C1901",
        desc: "Ride Control RR Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3767",
        title: "C1902",
        desc: "Ride Control RR Shock Actuator Circuit Open",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3768",
        title: "C1903",
        desc: "Ride Control RR Shock Actuator Circuit Short To Battery",
        created_at: "2022-08-18 19:42:56",
        updated_at: "2022-08-18 19:42:56",
    },
    {
        vehicle_error_id: "3769",
        title: "C1904",
        desc: "Ride Control RR Shock Actuator Circuit Short To Ground",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3770",
        title: "C1905",
        desc: "Ride Control LR Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3771",
        title: "C1906",
        desc: "Ride Control LR Shock Actuator Circuit Open",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3772",
        title: "C1906",
        desc: "Ride Control LR Shock Actuator Circuit Open",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3773",
        title: "C1907",
        desc: "Ride Control LR Shock Actuator Circuit Short To Battery",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3774",
        title: "C1908",
        desc: "Ride Control LR Shock Actuator Circuit Short To Ground",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3775",
        title: "C1908",
        desc: "Ride Control LR Shock Actuator Circuit Short To Ground",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3776",
        title: "C1909",
        desc: "Ride Control RF Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3777",
        title: "C1909",
        desc: "Ride Control RF Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3778",
        title: "C1910",
        desc: "Ride Control RF Shock Actuator Circuit Open",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3779",
        title: "C1911",
        desc: "Ride Control RF Shock Actuator Circuit Short To Battery",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3780",
        title: "C1912",
        desc: "Ride Control RF Shock Actuator Circuit Short To Ground",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3781",
        title: "C1913",
        desc: "Ride Control LF Shock Actuator Circuit Failure",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3782",
        title: "C1914",
        desc: "Ride Control LF Shock Actuator Circuit Open",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3783",
        title: "C1915",
        desc: "Ride Control LF Shock Actuator Circuit Short To Battery",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3784",
        title: "C1915",
        desc: "Ride Control LF Shock Actuator Circuit Short To Battery",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3785",
        title: "C1916",
        desc: "Ride Control LF Shock Actuator Circuit Short To Ground",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3786",
        title: "C1917",
        desc: "Steering EVO Out-of-Range Fault",
        created_at: "2022-08-18 19:42:57",
        updated_at: "2022-08-18 19:42:57",
    },
    {
        vehicle_error_id: "3787",
        title: "C1918",
        desc: "Air Suspension Ride Height Select Switch Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3788",
        title: "C1920",
        desc: "Led #1 Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3789",
        title: "C1921",
        desc: "VAPS Solenoid Actuator Output Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3790",
        title: "C1922",
        desc: "VAPS Solenoid Actuator Output Circuit Open",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3791",
        title: "C1923",
        desc: "VAPS Solenoid Actuator Output Circuit Short To Battery",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3792",
        title: "C1924",
        desc: "VAPS Solenoid Actuator Output Circuit Short To Ground",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3793",
        title: "C1925",
        desc: "VAPS Solenoid Actuator Return Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3794",
        title: "C1926",
        desc: "VAPS Solenoid Actuator Return Circuit Open",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3795",
        title: "C1927",
        desc: "VAPS Solenoid Actuator Return Circuit Short To Battery",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3796",
        title: "C1928",
        desc: "VAPS Solenoid Actuator Return Circuit Short To Ground",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3797",
        title: "C1929",
        desc: "Air Suspension Front Compressor Relay Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3798",
        title: "C1930",
        desc: "Air Suspension Front Compressor Relay Circuit Open",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3799",
        title: "C1931",
        desc: "Air Suspension Front Compressor Relay Circuit Short To Battery",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3800",
        title: "C1932",
        desc: "Air Suspension Front Compressor Relay Circuit Short To Ground",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3801",
        title: "C1933",
        desc: "Solenoid Current Out Of Range",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3802",
        title: "C1934",
        desc: "HPU (Hydraulic Pump Unit) Pressurisation Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3803",
        title: "C1935",
        desc: "Chime Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3804",
        title: "C1935",
        desc: "Chime Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3805",
        title: "C1936",
        desc: "Hydraulic Pump Relay Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3806",
        title: "C1937",
        desc: "Steering Wheel Angle Sensor Offset Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3807",
        title: "C1938",
        desc: "Invalid Steering Wheel Angle Sensor ID",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3808",
        title: "C1939",
        desc: "Brake Pressure Switch Input Circuit Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3809",
        title: "C1940",
        desc: "Brake Pressure Switch Mechanical Failure",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3810",
        title: "C1942",
        desc: "Unrecognized Powertrain Configuration",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3811",
        title: "C1943",
        desc: "Airbag Deployment Indication Input Fault",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3812",
        title: "C1944",
        desc: "Gauge Driver Circuit Fault",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3813",
        title: "C1945",
        desc: "Park Switch Indicates Park with Vehicle Moving",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3814",
        title: "C1946",
        desc: "Seat Track Position Switch Circuit Open",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3815",
        title: "C1947",
        desc: "Seat Track Position Switch Circuit Short to Ground",
        created_at: "2022-08-18 19:42:58",
        updated_at: "2022-08-18 19:42:58",
    },
    {
        vehicle_error_id: "3816",
        title: "C1948",
        desc: "Seat Track Position Switch Circuit Resistance Out of Range",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3817",
        title: "C1949",
        desc: "Accelerometer Sensor Circuit Open",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3818",
        title: "C1950",
        desc: "Accelerometer Sensor Circuit Failure",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3819",
        title: "C1949",
        desc: "Accelerometer Sensor Circuit Open",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3820",
        title: "C1951",
        desc: "Lateral Accelerometer Sensor Circuit Open",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3821",
        title: "C1952",
        desc: "Yaw Rate Sensor Circuit Open",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3822",
        title: "C1952",
        desc: "Yaw Rate Sensor Circuit Open",
        created_at: "2022-08-18 19:42:59",
        updated_at: "2022-08-18 19:42:59",
    },
    {
        vehicle_error_id: "3823",
        title: "C1953",
        desc: "Master Cylinder Pressure Sensor Circuit Open",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3824",
        title: "C1954",
        desc: "Master Cylinder Pressure Sensor Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3825",
        title: "C1955",
        desc: "Steering Angle Sensor Circuit Open",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3826",
        title: "C1955",
        desc: "Steering Angle Sensor Circuit Open",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3827",
        title: "C1956",
        desc: "Steering Angle Sensor Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3828",
        title: "C1956",
        desc: "Steering Angle Sensor Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3829",
        title: "C1957",
        desc: "Dynamic Stability Control Valve RF Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3830",
        title: "C1958",
        desc: "Dynamic Stability Control Valve LF Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3831",
        title: "C1959",
        desc: "Lateral Accelerometer Sensor Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3832",
        title: "C1960",
        desc: "Driver Brake Apply Circuit Fault",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3833",
        title: "C1961",
        desc: "Park Lamp Relay Coil Circuit Failure",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3834",
        title: "C1962",
        desc: "Park Lamp Relay Coil Short to Battery",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3835",
        title: "C1963",
        desc: "Stability Control Inhibit Warning",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3836",
        title: "C2767",
        desc: "Reserved - TBD",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3837",
        title: "U1000",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3838",
        title: "U1001",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3839",
        title: "U1002",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:00",
        updated_at: "2022-08-18 19:43:00",
    },
    {
        vehicle_error_id: "3840",
        title: "U1003",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3841",
        title: "U1004",
        desc: "SCP (J1850) Invalid or Missing Data for EEC Programming",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3842",
        title: "U1005",
        desc: "SCP (J1850) Invalid or Missing Data for EEC Programming",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3843",
        title: "U1005",
        desc: "SCP (J1850) Invalid or Missing Data for EEC Programming",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3844",
        title: "U1006",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3845",
        title: "U1007",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3846",
        title: "U1008",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Torque",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3847",
        title: "U1009",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Torque",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3848",
        title: "U1010",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Air Intake",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3849",
        title: "U1011",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Air Intake",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3850",
        title: "U1012",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3851",
        title: "U1013",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:01",
        updated_at: "2022-08-18 19:43:01",
    },
    {
        vehicle_error_id: "3852",
        title: "U1014",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3853",
        title: "U1014",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3854",
        title: "U1015",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3855",
        title: "U1016",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3856",
        title: "U1017",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3857",
        title: "U1018",
        desc: "SCP (J1850) Invalid or Missing Data for Throttle",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3858",
        title: "U1019",
        desc: "SCP (J1850) Invalid or Missing Data for Throttle",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3859",
        title: "U1019",
        desc: "SCP (J1850) Invalid or Missing Data for Throttle",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3860",
        title: "U1020",
        desc: "SCP (J1850) Invalid or Missing Data for Air Conditioning Clutch",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3861",
        title: "U1021",
        desc: "SCP (J1850) Invalid or Missing Data for Air Conditioning Clutch",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3862",
        title: "U1021",
        desc: "SCP (J1850) Invalid or Missing Data for Air Conditioning Clutch",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3863",
        title: "U1022",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3864",
        title: "U1023",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3865",
        title: "U1024",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3866",
        title: "U1024",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3867",
        title: "U1025",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3868",
        title: "U1025",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3869",
        title: "U1026",
        desc: "SCP (J1850) Invalid or Missing Data for Engine RPM",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3870",
        title: "U1027",
        desc: "SCP (J1850) Invalid or Missing Data for Engine RPM",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3871",
        title: "U1028",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3872",
        title: "U1028",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:02",
        updated_at: "2022-08-18 19:43:02",
    },
    {
        vehicle_error_id: "3873",
        title: "U1029",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3874",
        title: "U1030",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #1",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3875",
        title: "U1031",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #1",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3876",
        title: "U1031",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #1",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3877",
        title: "U1032",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3878",
        title: "U1032",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3879",
        title: "U1033",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3880",
        title: "U1034",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3881",
        title: "U1035",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3882",
        title: "U1035",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3883",
        title: "U1036",
        desc: "SCP (J1850) Invalid or Missing Data for Wheels",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3884",
        title: "U1037",
        desc: "SCP (J1850) Invalid or Missing Data for Wheels",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3885",
        title: "U1037",
        desc: "SCP (J1850) Invalid or Missing Data for Wheels",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3886",
        title: "U1038",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3887",
        title: "U1039",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id / Vehicle Speed",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3888",
        title: "U1040",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3889",
        title: "U1041",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3890",
        title: "U1041",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3891",
        title: "U1042",
        desc: "SCP (J1850) Invalid or Missing Data for Traction Control",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3892",
        title: "U1043",
        desc: "SCP (J1850) Invalid or Missing Data for Traction Control",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3893",
        title: "U1044",
        desc: "SCP (J1850) Invalid or Missing Data for Traction Motor",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3894",
        title: "U1045",
        desc: "SCP (J1850) Invalid or Missing Data for Traction Motor",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3895",
        title: "U1046",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:03",
        updated_at: "2022-08-18 19:43:03",
    },
    {
        vehicle_error_id: "3896",
        title: "U1047",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3897",
        title: "U1048",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3898",
        title: "U1049",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3899",
        title: "U1050",
        desc: "SCP (J1850) Invalid or Missing Data for Brakes",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3900",
        title: "U1050",
        desc: "SCP (J1850) Invalid or Missing Data for Brakes",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3901",
        title: "U1051",
        desc: "SCP (J1850) Invalid or Missing Data for Brakes",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3902",
        title: "U1052",
        desc: "SCP (J1850) Invalid or Missing Data for Steering / Steering Wheel",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3903",
        title: "U1053",
        desc: "SCP (J1850) Invalid or Missing Data for Steering / Steering Wheel",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3904",
        title: "U1053",
        desc: "SCP (J1850) Invalid or Missing Data for Steering / Steering Wheel",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3905",
        title: "U1054",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3906",
        title: "U1055",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3907",
        title: "U1056",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Configuration",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3908",
        title: "U1056",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Configuration",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3909",
        title: "U1057",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Configuration",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3910",
        title: "U1057",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Configuration",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3911",
        title: "U1058",
        desc: "SCP (J1850) Invalid or Missing Data for Transmission / Transaxle / PRNDL",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3912",
        title: "U1059",
        desc: "SCP (J1850) Invalid or Missing Data for Transmission / Transaxle / PRNDL",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3913",
        title: "U1060",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3914",
        title: "U1060",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3915",
        title: "U1061",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3916",
        title: "U1061",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:04",
        updated_at: "2022-08-18 19:43:04",
    },
    {
        vehicle_error_id: "3917",
        title: "U1062",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3918",
        title: "U1062",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3919",
        title: "U1063",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3920",
        title: "U1063",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3921",
        title: "U1064",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3922",
        title: "U1065",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3923",
        title: "U1066",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3924",
        title: "U1067",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3925",
        title: "U1068",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3926",
        title: "U1069",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3927",
        title: "U1070",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Sensors",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3928",
        title: "U1071",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Sensors",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3929",
        title: "U1072",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Coolant",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3930",
        title: "U1073",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Coolant",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3931",
        title: "U1074",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Oil",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3932",
        title: "U1075",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Oil Temp",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3933",
        title: "U1076",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3934",
        title: "U1077",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3935",
        title: "U1078",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:05",
        updated_at: "2022-08-18 19:43:05",
    },
    {
        vehicle_error_id: "3936",
        title: "U1079",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:06",
        updated_at: "2022-08-18 19:43:06",
    },
    {
        vehicle_error_id: "3937",
        title: "U1080",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:06",
        updated_at: "2022-08-18 19:43:06",
    },
    {
        vehicle_error_id: "3938",
        title: "U1081",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:06",
        updated_at: "2022-08-18 19:43:06",
    },
    {
        vehicle_error_id: "3939",
        title: "U1082",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Systems Other",
        created_at: "2022-08-18 19:43:06",
        updated_at: "2022-08-18 19:43:06",
    },
    {
        vehicle_error_id: "3940",
        title: "U1082",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Systems Other",
        created_at: "2022-08-18 19:43:06",
        updated_at: "2022-08-18 19:43:06",
    },
    {
        vehicle_error_id: "3941",
        title: "U1083",
        desc: "SCP (J1850) Invalid or Missing Data for Engine Systems Other",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3942",
        title: "U1084",
        desc: "SCP (J1850) Invalid or Missing Data for Powertrain Status Request",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3943",
        title: "U1085",
        desc: "SCP (J1850) Invalid or Missing Data for Powertrain Status Request",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3944",
        title: "U1085",
        desc: "SCP (J1850) Invalid or Missing Data for Powertrain Status Request",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3945",
        title: "U1086",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3946",
        title: "U1087",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3947",
        title: "U1088",
        desc: "SCP (J1850) Invalid or Missing Data for Suspension",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3948",
        title: "U1089",
        desc: "SCP (J1850) Invalid or Missing Data for Suspension",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3949",
        title: "U1090",
        desc: "SCP (J1850) Invalid or Missing Data for Non-Legislated Diagnostics",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3950",
        title: "U1090",
        desc: "SCP (J1850) Invalid or Missing Data for Non-Legislated Diagnostics",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3951",
        title: "U1091",
        desc: "SCP (J1850) Invalid or Missing Data for Non-Legislated Diagnostics",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3952",
        title: "U1092",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3953",
        title: "U1093",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3954",
        title: "U1094",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #2",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3955",
        title: "U1095",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #2",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3956",
        title: "U1096",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3957",
        title: "U1097",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3958",
        title: "U1098",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed Control",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3959",
        title: "U1098",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed Control",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3960",
        title: "U1099",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Speed Control",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3961",
        title: "U1100",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:07",
        updated_at: "2022-08-18 19:43:07",
    },
    {
        vehicle_error_id: "3962",
        title: "U1100",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3963",
        title: "U1101",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3964",
        title: "U1102",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3965",
        title: "U1103",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3966",
        title: "U1104",
        desc: "SCP (J1850) Invalid or Missing Data for Chassis Status Request",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3967",
        title: "U1105",
        desc: "SCP (J1850) Invalid or Missing Data for Chassis Status Request",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3968",
        title: "U1106",
        desc: "SCP (J1850) Invalid or Missing Data for Legislated Diagnostics",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3969",
        title: "U1107",
        desc: "SCP (J1850) Invalid or Missing Data for Legislated Diagnostics",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3970",
        title: "U1108",
        desc: "SCP (J1850) Invalid or Missing Data for Electric Traction Drive (Inverter)",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3971",
        title: "U1109",
        desc: "SCP (J1850) Invalid or Missing Data for Electric Traction Drive (Inverter)",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3972",
        title: "U1110",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3973",
        title: "U1111",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3974",
        title: "U1112",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3975",
        title: "U1112",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3976",
        title: "U1113",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3977",
        title: "U1114",
        desc: "SCP (J1850) Invalid or Missing Data for Charging System",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3978",
        title: "U1115",
        desc: "SCP (J1850) Invalid or Missing Data for Charging System",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3979",
        title: "U1115",
        desc: "SCP (J1850) Invalid or Missing Data for Charging System",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3980",
        title: "U1116",
        desc: "SCP (J1850) Invalid or Missing Data for Electrical Energy Management",
        created_at: "2022-08-18 19:43:08",
        updated_at: "2022-08-18 19:43:08",
    },
    {
        vehicle_error_id: "3981",
        title: "U1117",
        desc: "SCP (J1850) Invalid or Missing Data for Electrical Energy Management",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3982",
        title: "U1118",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3983",
        title: "U1119",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3984",
        title: "U1120",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3985",
        title: "U1121",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3986",
        title: "U1122",
        desc: "SCP (J1850) Invalid or Missing Data for Odometer",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3987",
        title: "U1123",
        desc: "SCP (J1850) Invalid or Missing Data for Odometer",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3988",
        title: "U1124",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3989",
        title: "U1125",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3990",
        title: "U1125",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3991",
        title: "U1126",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3992",
        title: "U1127",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3993",
        title: "U1127",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3994",
        title: "U1128",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3995",
        title: "U1129",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3996",
        title: "U1130",
        desc: "SCP (J1850) Invalid or Missing Data for Fuel System",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3997",
        title: "U1131",
        desc: "SCP (J1850) Invalid or Missing Data for Fuel System",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3998",
        title: "U1131",
        desc: "SCP (J1850) Invalid or Missing Data for Fuel System",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "3999",
        title: "U1132",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Motion",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "4000",
        title: "U1132",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Motion",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "4001",
        title: "U1133",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Motion",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "4002",
        title: "U1134",
        desc: "SCP (J1850) Invalid or Missing Data for Ignition Switch / Starter",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "4003",
        title: "U1135",
        desc: "SCP (J1850) Invalid or Missing Data for Ignition Switch / Starter",
        created_at: "2022-08-18 19:43:09",
        updated_at: "2022-08-18 19:43:09",
    },
    {
        vehicle_error_id: "4004",
        title: "U1135",
        desc: "SCP (J1850) Invalid or Missing Data for Ignition Switch / Starter",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4005",
        title: "U1136",
        desc: "SCP (J1850) Invalid or Missing Data for Telltales",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4006",
        title: "U1137",
        desc: "SCP (J1850) Invalid or Missing Data for Telltales",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4007",
        title: "U1138",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4008",
        title: "U1139",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4009",
        title: "U1139",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4010",
        title: "U1140",
        desc: "SCP (J1850) Invalid or Missing Data for Gateway",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4011",
        title: "U1140",
        desc: "SCP (J1850) Invalid or Missing Data for Gateway",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4012",
        title: "U1141",
        desc: "SCP (J1850) Invalid or Missing Data for Gateway",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4013",
        title: "U1142",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4014",
        title: "U1142",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4015",
        title: "U1143",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4016",
        title: "U1144",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4017",
        title: "U1145",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4018",
        title: "U1145",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4019",
        title: "U1146",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Security",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4020",
        title: "U1147",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Security",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4021",
        title: "U1148",
        desc: "SCP (J1850) Invalid or Missing Data for Audio Control",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4022",
        title: "U1149",
        desc: "SCP (J1850) Invalid or Missing Data for Audio Control",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4023",
        title: "U1150",
        desc: "SCP (J1850) Invalid or Missing Data for Audible Warnings",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4024",
        title: "U1150",
        desc: "SCP (J1850) Invalid or Missing Data for Audible Warnings",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4025",
        title: "U1151",
        desc: "SCP (J1850) Invalid or Missing Data for Audible Warnings",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4026",
        title: "U1152",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #3",
        created_at: "2022-08-18 19:43:10",
        updated_at: "2022-08-18 19:43:10",
    },
    {
        vehicle_error_id: "4027",
        title: "U1153",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #3",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4028",
        title: "U1154",
        desc: "SCP (J1850) Invalid or Missing Data for Compact Disc",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4029",
        title: "U1155",
        desc: "SCP (J1850) Invalid or Missing Data for Compact Disc",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4030",
        title: "U1155",
        desc: "SCP (J1850) Invalid or Missing Data for Compact Disc",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4031",
        title: "U1156",
        desc: "SCP (J1850) Invalid or Missing Data for Digital Signal Processing",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4032",
        title: "U1156",
        desc: "SCP (J1850) Invalid or Missing Data for Digital Signal Processing",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4033",
        title: "U1157",
        desc: "SCP (J1850) Invalid or Missing Data for Digital Signal Processing",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4034",
        title: "U1158",
        desc: "SCP (J1850) Invalid or Missing Data for Antenna",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4035",
        title: "U1159",
        desc: "SCP (J1850) Invalid or Missing Data for Antenna",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4036",
        title: "U1159",
        desc: "SCP (J1850) Invalid or Missing Data for Antenna",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4037",
        title: "U1160",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4038",
        title: "U1161",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4039",
        title: "U1162",
        desc: "SCP (J1850) Invalid or Missing Data for Digital Audio Tape",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4040",
        title: "U1163",
        desc: "SCP (J1850) Invalid or Missing Data for Digital Audio Tape",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4041",
        title: "U1164",
        desc: "SCP (J1850) Invalid or Missing Data for Tuner / Receiver",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4042",
        title: "U1165",
        desc: "SCP (J1850) Invalid or Missing Data for Tuner / Receiver",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4043",
        title: "U1166",
        desc: "SCP (J1850) Invalid or Missing Data for Cassette Tape",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4044",
        title: "U1166",
        desc: "SCP (J1850) Invalid or Missing Data for Cassette Tape",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4045",
        title: "U1167",
        desc: "SCP (J1850) Invalid or Missing Data for Cassette Tape",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4046",
        title: "U1168",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4047",
        title: "U1169",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4048",
        title: "U1169",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:11",
        updated_at: "2022-08-18 19:43:11",
    },
    {
        vehicle_error_id: "4049",
        title: "U1170",
        desc: "SCP (J1850) Invalid or Missing Data for Cellular Phone / Paging System",
        created_at: "2022-08-18 19:43:12",
        updated_at: "2022-08-18 19:43:12",
    },
    {
        vehicle_error_id: "4050",
        title: "U1171",
        desc: "SCP (J1850) Invalid or Missing Data for Cellular Phone / Paging System",
        created_at: "2022-08-18 19:43:12",
        updated_at: "2022-08-18 19:43:12",
    },
    {
        vehicle_error_id: "4051",
        title: "U1172",
        desc: "SCP (J1850) Invalid or Missing Data for Remote Button Control",
        created_at: "2022-08-18 19:43:12",
        updated_at: "2022-08-18 19:43:12",
    },
    {
        vehicle_error_id: "4052",
        title: "U1172",
        desc: "SCP (J1850) Invalid or Missing Data for Remote Button Control",
        created_at: "2022-08-18 19:43:12",
        updated_at: "2022-08-18 19:43:12",
    },
    {
        vehicle_error_id: "4053",
        title: "U1173",
        desc: "SCP (J1850) Invalid or Missing Data for Remote Button Control",
        created_at: "2022-08-18 19:43:12",
        updated_at: "2022-08-18 19:43:12",
    },
    {
        vehicle_error_id: "4054",
        title: "U1173",
        desc: "SCP (J1850) Invalid or Missing Data for Remote Button Control",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4055",
        title: "U1174",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4056",
        title: "U1175",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4057",
        title: "U1176",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4058",
        title: "U1176",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4059",
        title: "U1177",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4060",
        title: "U1178",
        desc: "SCP (J1850) Invalid or Missing Data for Climate Control (HVAC)",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4061",
        title: "U1179",
        desc: "SCP (J1850) Invalid or Missing Data for Climate Control (HVAC)",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4062",
        title: "U1179",
        desc: "SCP (J1850) Invalid or Missing Data for Climate Control (HVAC)",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4063",
        title: "U1180",
        desc: "SCP (J1850) Invalid or Missing Data for Personalization (Memory) Features",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4064",
        title: "U1181",
        desc: "SCP (J1850) Invalid or Missing Data for Personalization (Memory) Features",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4065",
        title: "U1182",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4066",
        title: "U1182",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4067",
        title: "U1183",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4068",
        title: "U1184",
        desc: "SCP (J1850) Invalid or Missing Data for Window Wiper / Washer",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4069",
        title: "U1184",
        desc: "SCP (J1850) Invalid or Missing Data for Window Wiper / Washer",
        created_at: "2022-08-18 19:43:13",
        updated_at: "2022-08-18 19:43:13",
    },
    {
        vehicle_error_id: "4070",
        title: "U1185",
        desc: "SCP (J1850) Invalid or Missing Data for Window Wiper / Washer",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4071",
        title: "U1186",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4072",
        title: "U1187",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4073",
        title: "U1188",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4074",
        title: "U1188",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4075",
        title: "U1189",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4076",
        title: "U1189",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4077",
        title: "U1190",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4078",
        title: "U1191",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4079",
        title: "U1192",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4080",
        title: "U1193",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4081",
        title: "U1194",
        desc: "SCP (J1850) Invalid or Missing Data for Mirrors",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4082",
        title: "U1195",
        desc: "SCP (J1850) Invalid or Missing Data for Mirrors",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4083",
        title: "U1196",
        desc: "SCP (J1850) Invalid or Missing Data for Door Locks",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4084",
        title: "U1196",
        desc: "SCP (J1850) Invalid or Missing Data for Door Locks",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4085",
        title: "U1197",
        desc: "SCP (J1850) Invalid or Missing Data for Door Locks",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4086",
        title: "U1198",
        desc: "SCP (J1850) Invalid or Missing Data for External Access (Doors)",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4087",
        title: "U1199",
        desc: "SCP (J1850) Invalid or Missing Data for External Access (Doors)",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4088",
        title: "U1200",
        desc: "SCP (J1850) Invalid or Missing Data for Seat Motion / Control",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4089",
        title: "U1201",
        desc: "SCP (J1850) Invalid or Missing Data for Seat Motion / Control",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4090",
        title: "U1202",
        desc: "SCP (J1850) Invalid or Missing Data for Windows",
        created_at: "2022-08-18 19:43:14",
        updated_at: "2022-08-18 19:43:14",
    },
    {
        vehicle_error_id: "4091",
        title: "U1203",
        desc: "SCP (J1850) Invalid or Missing Data for Windows",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4092",
        title: "U1203",
        desc: "SCP (J1850) Invalid or Missing Data for Windows",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4093",
        title: "U1204",
        desc: "SCP (J1850) Invalid or Missing Data for Steering Column",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4094",
        title: "U1205",
        desc: "SCP (J1850) Invalid or Missing Data for Steering Column",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4095",
        title: "U1205",
        desc: "SCP (J1850) Invalid or Missing Data for Steering Column",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4096",
        title: "U1206",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4097",
        title: "U1207",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4098",
        title: "U1208",
        desc: "SCP (J1850) Invalid or Missing Data for Seat Switches",
        created_at: "2022-08-18 19:43:15",
        updated_at: "2022-08-18 19:43:15",
    },
    {
        vehicle_error_id: "4099",
        title: "U1208",
        desc: "SCP (J1850) Invalid or Missing Data for Seat Switches",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4100",
        title: "U1209",
        desc: "SCP (J1850) Invalid or Missing Data for Seat Switches",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4101",
        title: "U1210",
        desc: "SCP (J1850) Invalid or Missing Data for Restraints",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4102",
        title: "U1210",
        desc: "SCP (J1850) Invalid or Missing Data for Restraints",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4103",
        title: "U1211",
        desc: "SCP (J1850) Invalid or Missing Data for Restraints",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4104",
        title: "U1212",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4105",
        title: "U1213",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4106",
        title: "U1213",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4107",
        title: "U1214",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4108",
        title: "U1214",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4109",
        title: "U1215",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4110",
        title: "U1216",
        desc: "SCP (J1850) Invalid or Missing Data for External Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4111",
        title: "U1217",
        desc: "SCP (J1850) Invalid or Missing Data for External Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4112",
        title: "U1217",
        desc: "SCP (J1850) Invalid or Missing Data for External Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4113",
        title: "U1218",
        desc: "SCP (J1850) Invalid or Missing Data for External Lamps",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4114",
        title: "U1219",
        desc: "SCP (J1850) Invalid or Missing Data for External Lamps",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4115",
        title: "U1220",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4116",
        title: "U1220",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4117",
        title: "U1221",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Lamp Outage",
        created_at: "2022-08-18 19:43:16",
        updated_at: "2022-08-18 19:43:16",
    },
    {
        vehicle_error_id: "4118",
        title: "U1222",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Lamps",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4119",
        title: "U1223",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Lamps",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4120",
        title: "U1224",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4121",
        title: "U1225",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4122",
        title: "U1226",
        desc: "SCP (J1850) Invalid or Missing Data for Body Status Request",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4123",
        title: "U1227",
        desc: "SCP (J1850) Invalid or Missing Data for Body Status Request",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4124",
        title: "U1228",
        desc: "SCP (J1850) Invalid or Missing Data for Tires",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4125",
        title: "U1229",
        desc: "SCP (J1850) Invalid or Missing Data for Tires",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4126",
        title: "U1230",
        desc: "SCP (J1850) Invalid or Missing Data for Electric Defrost",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4127",
        title: "U1231",
        desc: "SCP (J1850) Invalid or Missing Data for Electric Defrost",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4128",
        title: "U1232",
        desc: "SCP (J1850) Invalid or Missing Data for Navigation",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4129",
        title: "U1233",
        desc: "SCP (J1850) Invalid or Missing Data for Navigation",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4130",
        title: "U1234",
        desc: "SCP (J1850) Invalid or Missing Data for Displays",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4131",
        title: "U1235",
        desc: "SCP (J1850) Invalid or Missing Data for Displays",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4132",
        title: "U1236",
        desc: "SCP (J1850) Invalid or Missing Data for Memory Storage",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4133",
        title: "U1236",
        desc: "SCP (J1850) Invalid or Missing Data for Memory Storage",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4134",
        title: "U1237",
        desc: "SCP (J1850) Invalid or Missing Data for Memory Storage",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4135",
        title: "U1238",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #4",
        created_at: "2022-08-18 19:43:17",
        updated_at: "2022-08-18 19:43:17",
    },
    {
        vehicle_error_id: "4136",
        title: "U1239",
        desc: "SCP (J1850) Invalid or Missing Data for Experimental #4",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4137",
        title: "U1240",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4138",
        title: "U1241",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4139",
        title: "U1242",
        desc: "SCP (J1850) Invalid or Missing Data for Exterior Environment",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4140",
        title: "U1242",
        desc: "SCP (J1850) Invalid or Missing Data for Exterior Environment",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4141",
        title: "U1243",
        desc: "SCP (J1850) Invalid or Missing Data for Exterior Environment",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4142",
        title: "U1244",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Environment",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4143",
        title: "U1245",
        desc: "SCP (J1850) Invalid or Missing Data for Interior Environment",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4144",
        title: "U1246",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4145",
        title: "U1247",
        desc: "SCP (J1850) Invalid or Missing Data for Primary Id",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4146",
        title: "U1248",
        desc: "SCP (J1850) Invalid or Missing Data for Time / Date",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4147",
        title: "U1249",
        desc: "SCP (J1850) Invalid or Missing Data for Time / Date",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4148",
        title: "U1250",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Id (VIN)",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4149",
        title: "U1251",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Id (VIN)",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4150",
        title: "U1251",
        desc: "SCP (J1850) Invalid or Missing Data for Vehicle Id (VIN)",
        created_at: "2022-08-18 19:43:18",
        updated_at: "2022-08-18 19:43:18",
    },
    {
        vehicle_error_id: "4151",
        title: "U1252",
        desc: "SCP (J1850) Invalid or Missing Data for Class A Functions",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4152",
        title: "U1253",
        desc: "SCP (J1850) Invalid or Missing Data for Class A Functions",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4153",
        title: "U1252",
        desc: "SCP (J1850) Invalid or Missing Data for Class A Functions",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4154",
        title: "U1254",
        desc: "SCP (J1850) Invalid or Missing Data for Network Control",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4155",
        title: "U1255",
        desc: "SCP (J1850) Invalid or Missing Data for Network Control",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4156",
        title: "U1255",
        desc: "SCP (J1850) Invalid or Missing Data for Network Control",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4157",
        title: "U1260",
        desc: "SCP (J1850) Single Ended (+) Circuit Failure",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4158",
        title: "U1261",
        desc: "SCP (J1850) Single Ended (-) Circuit Failure",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4159",
        title: "U1261",
        desc: "SCP (J1850) Single Ended (-) Circuit Failure",
        created_at: "2022-08-18 19:43:19",
        updated_at: "2022-08-18 19:43:19",
    },
    {
        vehicle_error_id: "4160",
        title: "U1262",
        desc: "SCP (J1850) Communication Bus Fault",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4161",
        title: "U1308",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read  Engine Torque",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4162",
        title: "U1308",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read  Engine Torque",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4163",
        title: "U1341",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read Vehicle Speed",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4164",
        title: "U1341",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read Vehicle Speed",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4165",
        title: "U1430",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read Fuel System",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4166",
        title: "U1451",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read Audible Warnings /Anti-Theft Module",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4167",
        title: "U1451",
        desc: "SCP (J1850) Invalid or Missing Data for Function Read Audible Warnings /Anti-Theft Module",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4168",
        title: "U1612",
        desc: "SCP (J1850) Lack of Acknowledgment for Primary Id",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4169",
        title: "U1736",
        desc: "SCP (J1850) Lack of Acknowledgment for  Telltales",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4170",
        title: "U1736",
        desc: "SCP (J1850) Lack of Acknowledgment for  Telltales",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4171",
        title: "U1750",
        desc: "SCP (J1850) Lack of Acknowledgment for  Audible Warnings",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4172",
        title: "U1794",
        desc: "SCP (J1850) Lack of Acknowledgment for Mirrors",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4173",
        title: "U1794",
        desc: "SCP (J1850) Lack of Acknowledgment for Mirrors",
        created_at: "2022-08-18 19:43:20",
        updated_at: "2022-08-18 19:43:20",
    },
    {
        vehicle_error_id: "4174",
        title: "U1797",
        desc: "SCP (J1850) Lack of Acknowledgment for  Door Locks",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4175",
        title: "U1798",
        desc: "SCP (J1850) Lack of Acknowledgment for  External Access (Doors)",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4176",
        title: "U1806",
        desc: "SCP (J1850) Lack of Acknowledgment for Primary Id",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4177",
        title: "U1900",
        desc: "CAN Communication Bus Fault",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4178",
        title: "U1950",
        desc: "UPB Communication Bus Fault",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4179",
        title: "U2000",
        desc: "Audio Rear Control Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4180",
        title: "U2001",
        desc: "Audio Tape Deck Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4181",
        title: "U2002",
        desc: "Audio Bezel is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4182",
        title: "U2003",
        desc: "Audio Compact Disk / Disk Jockey Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4183",
        title: "U2003",
        desc: "Audio Compact Disk / Disk Jockey Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4184",
        title: "U2004",
        desc: "Audio Steering Wheel Control Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4185",
        title: "U2005",
        desc: "Audio Rear Integrated Control Panel Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4186",
        title: "U2006",
        desc: "Audio Remote Climate Control Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4187",
        title: "U2006",
        desc: "Audio Remote Climate Control Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4188",
        title: "U2007",
        desc: "Audio Navigation Unit is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4189",
        title: "U2008",
        desc: "Audio Phone is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4190",
        title: "U2009",
        desc: "Audio Front Control Module (ACM) is Not Responding",
        created_at: "2022-08-18 19:43:21",
        updated_at: "2022-08-18 19:43:21",
    },
    {
        vehicle_error_id: "4191",
        title: "U2010",
        desc: "Module is Not Responding (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4192",
        title: "U2010",
        desc: "Module is Not Responding (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4193",
        title: "U2011",
        desc: "Module Transmitted Invalid Data (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4194",
        title: "U2012",
        desc: "Communication Bus Error (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4195",
        title: "U2013",
        desc: "Compass Module is not Responding",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4196",
        title: "U2014",
        desc: "Audio Subwoofer Unit is Not Responding",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4197",
        title: "U2014",
        desc: "Audio Subwoofer Unit is Not Responding",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4198",
        title: "U2015",
        desc: "Signal Link Fault (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4199",
        title: "U2015",
        desc: "Signal Link Fault (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4200",
        title: "U2016",
        desc: "Signal Link Short to Ground (Non SCP) / From NGV Module",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4201",
        title: "U2016",
        desc: "Signal Link Short to Ground (Non SCP) / From NGV Module",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4202",
        title: "U2017",
        desc: "Driver Side Crash Sensor Communication Fault (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4203",
        title: "U2018",
        desc: "Passenger Side Crash Sensor Communication Fault (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4204",
        title: "U2018",
        desc: "Passenger Side Crash Sensor Communication Fault (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4205",
        title: "U2019",
        desc: "Audio Voice Module Not Responding",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4206",
        title: "U2020",
        desc: "Audio Center Amp is not responding",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4207",
        title: "U2021",
        desc: "Invalid /fault data received (Non SCP)",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4208",
        title: "U2150",
        desc: "SCP (J1850) Invalid Data from REM",
        created_at: "2022-08-18 19:43:22",
        updated_at: "2022-08-18 19:43:22",
    },
    {
        vehicle_error_id: "4209",
        title: "U2152",
        desc: "SCP (J1850) Invalid Data from GEM",
        created_at: "2022-08-18 19:43:23",
        updated_at: "2022-08-18 19:43:23",
    },
    {
        vehicle_error_id: "4210",
        title: "U2152",
        desc: "SCP (J1850) Invalid Data from GEM",
        created_at: "2022-08-18 19:43:23",
        updated_at: "2022-08-18 19:43:23",
    },
    {
        vehicle_error_id: "4211",
        title: "U2160",
        desc: "SCP (J1850) Invalid Data from IC",
        created_at: "2022-08-18 19:43:23",
        updated_at: "2022-08-18 19:43:23",
    },
    {
        vehicle_error_id: "4212",
        title: "U2195",
        desc: "SCP (J1850) Invalid Data from SCLM",
        created_at: "2022-08-18 19:43:23",
        updated_at: "2022-08-18 19:43:23",
    },
    {
        vehicle_error_id: "4213",
        title: "U2500",
        desc: "(CAN) Lack of Acknowledgement From Engine Management",
        created_at: "2022-08-18 19:43:23",
        updated_at: "2022-08-18 19:43:23",
    },
    {
        vehicle_error_id: "4214",
        title: "P0000",
        desc: "Reserved",
        created_at: "2022-08-18 19:46:04",
        updated_at: "2022-08-18 19:46:04",
    },
    {
        vehicle_error_id: "4215",
        title: "SAE",
        desc: "Reserved",
        created_at: "2022-08-18 19:46:04",
        updated_at: "2022-08-18 19:46:04",
    },
];
export default Data