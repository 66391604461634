import React, { useEffect, useState } from "react";
import logo from "../../Assets/carLogo2.png";
import image4 from "../../Assets/car1.png";
import NavBar from "../../components/NavBar";
import styles from "./Home.module.css";
import Button from "../../components/Button";
import MaintenanceTip from "../../components/MaintenanceTip";
import LiveMetrics from "./LiveMetrics";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DiagnosisForm from "./DiagnosisForm";
import Logos from "../../utils/CarLogo";
import Images from "../../utils/CarImages";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/redux-store/Index";

function Home(props) {
  const [diagReport, setDiagReport] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [diagReportForm, setDiagReportForm] = useState(false);
  const [data, setData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [err, setErr] = useState([]);
  const [errInput, setErrInput] = useState([]);
  const [onlineStatus, setOnineStatus] = useState("");
  const [vinData, setVinData] = useState([]);
  let deviceID = useSelector((state) => state.auth.deviceID);
  let vin = useSelector((state) => state.auth.vin);
  let vehicleDetails = useSelector((state) => state.auth.vehicleDetailsAlt);
  let plate_no = useSelector((state) => state.auth.plateNumber);
  let dispatch = useDispatch();

  let diagnoseDataHandler = () => {
    setDiagReport(true);
    setFormIsValid(false);
  };

  useEffect(() => {
    let liveData = async () => {
      let url = `https://api.dev.motormata.com/api/v1/dongle/vehicle/live-data/${deviceID}`;
      setLoading(true);
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data1 = await res.json();
      setLoading(false);
      if (res.ok) {
        dispatch(authActions.resetLiveMetrics());
        dispatch(authActions.dongleID(deviceID));
        dispatch(authActions.error(data1));
        dispatch(authActions.vin(data1.vehicle_location.vehicle_vin));
        dispatch(authActions.metrics(data1));
        setData(data1);
        setErr(data1.vehicle_errors);
      } else {
        console.log(data1);
      }
      if (
        data1?.vehicle_live_data?.engine_speed > 0 &&
        data1?.vehicle_live_data?.speed > 0
      ) {
        setOnineStatus("Online");
      } else if (
        data1?.vehicle_live_data?.engine_speed === 0 &&
        data1?.vehicle_live_data?.speed === 0
      ) {
        setOnineStatus("Parked");
      } else if (
        data1?.vehicle_live_data?.engine_speed > 0 &&
        data1?.vehicle_live_data?.speed === 0
      ) {
        setOnineStatus("Idling");
      } else if (data1?.vehicle_live_data?.online === false) {
        setOnineStatus("Offline");
      }
    };
    liveData();

    const timer = setInterval(() => {
      liveData();
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [deviceID, dispatch]);

  useEffect(() => {
    let vinHandler = async () => {
      let url = `https://api.dev.motormata.com/api/v1/dongle/vindecoder/${vin}`;
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      if (res.ok) {
        dispatch(authActions.resetVehicleData());
        dispatch(authActions.details(data));
        setVinData(data);
      } else {
        console.log(data);
      }
    };
    vinHandler();
  }, [vin, dispatch]);
  useEffect(() => {}, []);
  useEffect(() => {
    let error = async () => {
      let url = `https://bpms.motormata.com/api/v1/dongle/vehicle/errors/${err}`;
      // console.log(url)
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      if (res.ok) {
        setErrInput(data);
      } else {
        console.log(data);
      }
    };
    error();
  }, [err]);
  useEffect(() => {
    let userData = async () => {
      let url2 = `https://bpms.motormata.com/api/v1/noauth/cusinfo/${plate_no}`;
      let res = await fetch(url2, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data1 = await res.json();
      setPersonalData(data1);
      if (res.ok) {
        console.log(data1);
        dispatch(authActions.resetUserData());
        dispatch(authActions.user(data1));
        if (data1.basic === "No Record Found") {
          setDiagReportForm(true);
        } else {
          setDiagReportForm(false);
        }
      } else {
        console.log(data1);
      }
    };
    userData();
  }, [plate_no, dispatch]);

  function truncate(input) {
    if (input.length > 5) {
      return input.substring(0, 23) + "...";
    }
    return input;
  }

  let Logo = Logos?.find(
    (data, index) =>
      data.name?.toUpperCase() === vinData.vehicle_make?.toUpperCase() ||
      data.name?.toUpperCase() ===
        vehicleDetails[0]?.vehicleName2?.toUpperCase()
  );

  let carImage = Images?.find(
    (data, index) =>
      data.name?.toUpperCase() ===
        `${
          vinData.vehicle_year
        }-${vinData.vehicle_make?.toUpperCase()}-${vinData.vehicle_model?.toUpperCase()}` ||
      data.name?.toUpperCase() ===
        `${vehicleDetails[0]?.vehicleYear2?.toUpperCase()}-${vehicleDetails[0]?.vehicleName2?.toUpperCase()}-${vehicleDetails[0]?.vehicleModel2?.toUpperCase()}`
  );

  // useEffect(() => {
  //   // Logos.map((data, index) => console.log(data.name));
  //   console.log(Logo?.logo);
  //   console.log(vinData.vehicle_make);
  //   console.log(vehicleDetails[0]?.vehicleName2);
  // }, [Logo?.logo, vinData.vehicle_make]);

  return (
    <section className={styles.subscription}>
      <ToastContainer />
      <NavBar setShowMenu={props.setShowMenu} />
      <div className={styles.subscription__body}>
        <div className={styles.subscription__bodyInner}>
          <div className={styles.carType}>
            <div className={styles.logo}>
              <img src={Logo?.logo || logo} alt="car logo" />
            </div>
            <div className={styles.subscription__wrapper}>
              <img src={carImage?.image || image4} alt="dongle" />
            </div>
            <div>
              <p>{onlineStatus}</p>
            </div>
          </div>
          <div className={styles.vehicleDetails}>
            <div>
              <h4>
                {vinData.vehicle_make?.toUpperCase() ||
                  vehicleDetails[0]?.vehicleName2?.toUpperCase()}
              </h4>
              <p>
                {vinData.vehicle_year || vehicleDetails[0]?.vehicleYear2}{" "}
                {vinData.vehicle_model?.toUpperCase() ||
                  vehicleDetails[0]?.vehicleModel2?.toUpperCase()}
              </p>
            </div>
            <div>
              <p>VIN: {vinData.vehicle_vin || "Not available"}</p>
              <p>{plate_no?.toUpperCase()}</p>
            </div>
          </div>
        </div>
        <section className={styles.diagnose}>
          <h4>Diagnosis</h4>
          <h5>Diagnose your car now to know your car maintenance status</h5>
          <div className={styles.diagnose__wrapper}>
            {!diagReport && (
              <div className={styles.diagnose__wrapper__inner}>
                {!loading && (
                  <p> &#62; Error - No Error code to display yet.</p>
                )}
                {loading && <p>Loading live data. Please wait.🙏🏾</p>}
              </div>
            )}
            {diagReport &&
              errInput?.map((data, index) => (
                <div key={index} className={styles.diagnose__wrapper__inner}>
                  <div className={styles.error}>
                    <p> &#62; {data.title}</p>
                    <p className={styles.desc}>{truncate(data.desc)}</p>
                  </div>
                  <div>
                    <Link to={`/error/${data.title}`}>
                      <p>View</p>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          <Button disabled={!formIsValid} onClick={diagnoseDataHandler}>
            Diagnose
          </Button>
        </section>
        <section className={styles.diagnose}>
          {diagReport && (
            <DiagnosisForm
              personalData={personalData}
              report={diagReportForm}
              setDiagReportForm={setDiagReportForm}
            />
          )}
          <h1>Live Metrics</h1>
          <LiveMetrics loading={loading} data={data.vehicle_live_data} />
        </section>
        <MaintenanceTip />
      </div>
    </section>
  );
}

export default Home;
