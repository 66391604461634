import React, { useEffect, useState } from "react";
import Data from "../../components/VehicleErr";
import styles from "./ErrorPage.module.css";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
function ErrorPage() {
  const [errName, setErrName] = useState("");
  const { errorId } = useParams();
  const project = Data.find((list) => String(list.title) === errorId);
  useEffect(() => {
    if (project?.title.includes("P")) {
      setErrName("Powertrain");
    } else if (project?.title.includes("B")) {
      setErrName("Body");
    } else if (project?.title.includes("C")) {
      setErrName("Chasis");
    } else if (project?.title.includes("B")) {
      setErrName("Body");
    } else {
      setErrName("Communication");
    }
  }, [project?.title]);
  return (
    <div className={styles.body}>
      <div className={styles.body__cancelButton}>
        <Link to="/home">
          <div className={styles.body__cancelButton__inner}>
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
          </div>
        </Link>
      </div>
      <h4>Diagnostic Code</h4>
      <div className={styles.heading}>
        <div>
          <p>Error code:</p>
          <h5>{project?.title}</h5>
        </div>
        <div>
          <p>Phase:</p>
          <h3>{errName}</h3>
        </div>
      </div>
      <div className={styles.desc}>
        <h3>Request for further details!</h3>
        <h2>Details!</h2>
        <p>{project?.desc}</p>
      </div>
      <div className={styles.warning}>
        <p>
          Kindly take action on the following Error Code listed above to avoid
          further damage to your vehicle. You can contact us now for immediate
          service.{" "}
        </p>
      </div>
      <div className={styles.buttons}>
        <Link to="/home">
          <Button>Request for car diagnostic report</Button>
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
