import React, { useRef, useImperativeHandle } from 'react'
import styles from './Input.module.css'

const Input = React.forwardRef((props, ref) => {
    const inputRef = useRef()

    let input = inputRef.current?.value

    useImperativeHandle(ref, () => {
        return {
            value: input
        }
    })
    return (
        <div className={styles.form__inner}>
            <label
                className={props.invalid ? `${styles.invalid}` : ''}
                htmlFor='number'>{props.label}</label>
            <input
                className={props.invalid ? `${styles.invalidInput}` : ''}
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                type={props.type}
                name={props.name}
                ref={inputRef}
                placeholder={props.placeholder}
                required
                />
        </div>
    )
})

export default Input