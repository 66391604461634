import "./App.css";
import { Navigate, Route, Routes } from "react-router";
import Login from "./Pages/auth/Login";
import Home from "./Pages/home/Home";
import ErrorPage from "./Pages/home/ErrorPage";
import Menu from "./components/Menu";
import { useState } from "react";
import { useSelector } from "react-redux";
import Report from "./Pages/report/Report";
import RegisterVehicle from "./Pages/auth/RegisterVehicle";
import NotFound from "./Pages/NotFound/NotFound";

function App() {
  let [showMenu, setShowMenu] = useState(false);
  let closeMenuHandler = () => {
    setShowMenu(false);
  };
  let loggedIn = useSelector((state) => state.auth.userLoggedIn);
  return (
    <div className="App">
      <div className="menu" onClick={closeMenuHandler}>
        {showMenu && <Menu setShowMenu={setShowMenu} />}
      </div>
      <Routes>
        <Route
          path="/"
          element={
            (!loggedIn && <Login />) || (loggedIn && <Navigate to="/home" />)
          }
        />
        <Route
          path="/home"
          element={
            (loggedIn && (
              <Home setShowMenu={setShowMenu} showMenu={showMenu} />
            )) ||
            (!loggedIn && <Navigate to="/" />)
          }
        />
        <Route
          path="/error/:errorId"
          element={(loggedIn && <ErrorPage />) || <Navigate to="/" />}
        />
        <Route path="/report/:Id" element={<Report />} />
        <Route path="/registervehicle" element={<RegisterVehicle />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
